import React, { PureComponent } from 'react';
import './messageTemplates.scss';
import CreateTemplateComponent from './components/CreateTemplate.component';
import MessagesTemplatesListComponent from './components/MessagesTemplatesList.component';

class MessageTemplatesComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedTemplate: null
        };
    }

    editHandle = data => {
        this.setState({
            selectedTemplate: data
        });
    };

    render() {
        const { selectedTemplate } = this.state;

        return (
            <div className='user-details-message-templates shadow4 border-radius'>
                <CreateTemplateComponent
                    item={selectedTemplate}
                />
                <MessagesTemplatesListComponent
                    editHandle={this.editHandle}
                />
            </div>
        );
    }
}

export default MessageTemplatesComponent;
