import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import * as serviceWorker from './registerServiceWorker';

import App from './app/App';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({ dsn: 'https://2ba3f5e8b1294a679252278150871fe6@sentry.io/4359235' });
}

ReactDOM.render((
    <App/>
), document.getElementById('root'));

serviceWorker.unregister();
