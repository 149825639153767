import React, { Component } from 'react';
import { observer } from 'mobx-react';
import messagingStore from '../../../stores/messaging.store';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import onClickOutside from 'react-onclickoutside';
import DefaultImageComponent from '../DefaultImage.component';
import { InputForm } from '../forms';
import SelectRecruiterDropdown from '../recruitersSelectBox/SelectRecruiterDropdown.component'
import './styles.scss';

@observer
class InboxMessagingAssignmentSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            searchText: '',
            resultsList: []
        };
    }

    menuToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    selectEvent = slug => {
        // messagingStore.changeAssignee(slug);
        this.props.selectEvent(slug);
        this.setState({
            open: false
        });
    };

    inputChange = e => {
        const text = e.target.value;
        this.setState({
            searchText: text,
            resultsList: userStore.recruitersList.filter(s => s.firstName.toLowerCase().includes(text.toLowerCase()))
        });
    };

    componentDidMount() {
        this.setState({
            resultsList: userStore.recruitersList
        });
    }

    handleClickOutside = evt => {
        this.setState({ open: false });
    };

    handleAssignedChange = (slugList, category, newAssigned) => {
        if (!newAssigned) return;
        this.selectEvent(newAssigned.slug);
        messagingStore.activeInboxMode = messagingStore.inboxMode.LIST
    }

    render() {
        const { open, searchText, resultsList } = this.state;
        const { selectedSlug, isNewMode } = this.props;

        const currentUser = userStore.user;
        let selectedItem = currentUser;

        if (selectedSlug) {
            selectedItem = userStore.users[selectedSlug];
        } else if (messagingStore.conversationInboxSelect) {
            selectedItem = userStore.users[messagingStore.conversationInboxSelect];
        }

        let selectedUserName = utilsService.getDisplayName(selectedItem);

        if (isNewMode) {
          return (
            <SelectRecruiterDropdown
              isAssignedTo
              recruiters={resultsList}
              selectedItem={selectedItem}
              onFilterChange={this.handleAssignedChange}
            />
          );
        }

        if (!selectedItem) {
            selectedItem = 'Default';
            selectedUserName = 'No assignment';
        }

        return (
            <div className='modern-select inbox-messages-assigment-select'>
                {selectedItem && (
                    <div
                        onClick={this.menuToggle}
                        className="modern-select-visible-part">

                        <div className="modern-select-visible-part-name">
                            <span className="title">
                              Assigned to:
                            </span>
                            {selectedUserName}
                        </div>
                    </div>
                )}
                {open && (
                    <div className="modern-select-list shadow4">
                        <InputForm
                            value={searchText}
                            type={'text'}
                            inputChange={this.inputChange}
                            classes={'modern-select-list-search-input'}
                        />
                        <div className="custom-scroll">
                            {
                                resultsList.map((item, idx) => {
                                    const selectedItemName = utilsService.getDisplayName(item);

                                    return (
                                        <div
                                            onClick={() => this.selectEvent(item.slug)}
                                            key={item._id}
                                            className="modern-select-list-item">

                                            <DefaultImageComponent
                                                url={utilsService.getProfileImage(item)}
                                                alt={selectedItemName}
                                                profileName={selectedItemName}
                                                isExposed={true}
                                            />
                                            <div className="modern-select-unvisible-part-name">
                                                {item._id === currentUser._id ? 'Me' : selectedItemName}
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default onClickOutside(InboxMessagingAssignmentSelect);
