import React, { Component } from 'react';
import './complexInput2.scss';
import { CloseIcon2 } from '../../svg';
import onClickOutside from 'react-onclickoutside';


class ComplexInput2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: props.value ? props.value : ''
        };
    }

    openMe = () => {
        const { open } = this.state;
        this.handleOpenChanged(!open);
    };

    componentDidUpdate(prevProps) {
        const { value } = this.props
        if (value !== prevProps.value) {
            this.setState({value: value});
        }
    }

    handleClickOutside = evt => {
        this.handleOpenChanged();
    };

    removeHandle = () => {
        const { inputChanged } = this.props;
        this.setState({value: ''}, () => {
            inputChanged('');
        });
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleOpenChanged();
        }
    }

    handleOpenChanged = (open) => {
        const { inputChanged } = this.props;
        const { value } = this.state;
        this.setState({open: open}, () => {
            if(!open) {
                inputChanged(value);
            }
        });
    }

    renderSelectedItem = () => {
        const { value } = this.state;

        return (

            <div className={'tag-render'}>
                <div className="close-icon"
                    onClick={(e) => this.removeHandle()}>
                    <CloseIcon2 />
                </div>
                <div title={value}>
                    <label className="multi-render-item-label">{value}</label>
                </div>
            </div>

        )
    }

    render() {
        const { classes, label } = this.props;
        const { open, value } = this.state;

        return (
            <div className={'form-item complex-input2-wrapper ' + classes}>
                <div className={"main-input-wrapper" + (open ? ' open' : '')}>
                    <input type="text"
                        readOnly={!open}
                        onClick={this.openMe}
                        placeholder={label}
                        value={open ? value : label}
                        onChange={e => this.setState({ value: e.target.value })}
                        onKeyDown={this.handleKeyDown}
                        className={'main-input ' + (open ? ' open' : '')}
                    />
                    <span className={open ? "arrow-icon" : "plus-icon"}></span>
                </div>
                {!open && value && value.length > 0 && this.renderSelectedItem()}
            </div>
        );
    }
}


ComplexInput2.propTypes = {
};

ComplexInput2.defaultProps = {

};

export default onClickOutside(ComplexInput2);
