import React, {lazy, Suspense} from 'react';

import NavigationBar
    from '../../components/navigationBar/NavigationBar.component';
import {observer} from 'mobx-react';
import './pipelines.scss';
import processStore from '../../../stores/process.store';
import jobStore from '../../../stores/job.store';

const Applicants = lazy(() => import('../../components/applicants/Applicants.component'));

@observer
class PipelinesView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
    }

    async componentDidMount() {
        document.title = 'Pipelines - Ethosia';

        if (processStore.processesPipelinesGridAll.length === 0) {
            await jobStore.getJobs();
            const jobSlugs = jobStore.getJobSlugs();
            await processStore.getProcesses(true, jobSlugs);
            await processStore.getPipelinesGridData().then(() => this.setState({ loader: false }));
        } else {
            this.setState({ loader: false });
        }
    }

    render() {
        const { gridIndex } = this.props.match.params;
        return (
            <div className='main-content-space'>
                <Suspense fallback={<div>Loading...</div>}>
                    <NavigationBar/>
                    <Applicants
                        loader={this.state.loader}
                        data={processStore.processesPipelinesGrid.slice()}
                        gridIndex={gridIndex}
                    />
                </Suspense>
            </div>
        );
    }
}

export default PipelinesView;
