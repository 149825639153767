import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import './projectMenu.scss';

class ProjectsMenuComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className='projects-bucket-menu'>
                <NavLink to="/projects"
                         activeClassName='active'>
                    Projects
                </NavLink>
                <div className="shadow3 projects-bucket-menu-hidden">
                    <ul>
                        <li>Huj</li>
                        <li>BrightSource Data Analytics</li>
                        <li>ברייטסורס-טירו</li>
                        <li>Rafi Test Company 2</li>
                        <li>Rafi Test Company 2 other namemad md amwdmawm mmdmamwdawd</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default ProjectsMenuComponent;
