import fetchService from "../../../services/fetch.service";

export const searchSettingsApi = {
  getMany: ({ search, type, recruiterName, updatedAt, offset = 0, limit = 10 }) => {
    const searchParams = new URLSearchParams();

    search && searchParams.set('search', search);
    recruiterName && searchParams.set('recruiterName', recruiterName);
    type && searchParams.set('type', type);
    updatedAt && searchParams.set('updatedAt', updatedAt);
    searchParams.set('offset', offset + '');
    searchParams.set('limit', limit + '');

    return fetchService.authenticatedGet(`/search-settings?${searchParams.toString()}`);
  },
  createOne: (body) => {
    return fetchService.authenticatedPost(`/search-settings`, body);
  },
  pushEvent: (id, body) => {
    return fetchService.authenticatedPatch(`/search-settings/${id}`, body);
  },
  deleteOne: (settingId) => {
    return fetchService.authenticatedDelete(`/search-settings/${settingId}`);
  }
}
