import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {PipelineFilterBar} from '../../../../../../entities/reports/model/pipeline/PipelineFilterBar/PipelineFilterBar';
import {PipelineReportTable} from '../../../../../../entities/reports/model/pipeline/PipelineReportTable/PipelineReportTable';
import {pipelineReportStore} from '../../../../../../entities/reports/model/report.store';
import './PipelineReportDashboard.scss';

export const PipelineReportDashboard = forwardRef(function PipelineReportDashboard(props, ref) {
  useImperativeHandle(ref, () => ({
    refetch: pipelineReportStore.refetch,
  }));

  useEffect(() => {
    pipelineReportStore.onInit();
  }, []);

  return <div {...props} className='pipeline-report-container' ref={ref} >
    <PipelineFilterBar />
    <PipelineReportTable />
  </div>
});
