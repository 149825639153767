import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import './dashboardActivitiesConversations.scss';
import { DotsIcon } from '../../../../../../components/svg';
import utilsService from '../../../../../../../services/utils.service';
import { observer } from 'mobx-react';
import messagingStore from '../../../../../../../stores/messaging.store';
import Loader from '../../../../../../components/loader/Loader.component';
import { Link } from 'react-router-dom';


const DashboardActivitiesConversations = observer((props) => {

    const { selectedRecruiterSlugs} = props

    const [unreadMessageCount, setUnreadMessageCount] = useState(null)


    useEffect(() => {

        let unmounted = false;

        const getUnreadMessageCount = async () => {
            if(!unmounted) {
                setUnreadMessageCount(null);
            }
            const selectedRecruiterSlugsId = selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0 ?
                 selectedRecruiterSlugs : null;
            const unreadMessageCount = await messagingStore.getUnreadMessagesCount({ status:1, assignedToUserSlugs: selectedRecruiterSlugsId });
            if(!unmounted) {
                setUnreadMessageCount(unreadMessageCount);
            }
        }

        getUnreadMessageCount();

        return () => {
            unmounted = true;
        }

    }, [selectedRecruiterSlugs])


    const renderItem = (type, name, number) => {
        const icon = utilsService.getConversationsProviderIcon(type);

        const getIconClass = (type) => {
            const providersClassesMap = {
                '1': 'mail',
                '2': 'sms',
                '3': 'whatsapp',
                '4': 'chat',
                '5': 'discussion'
            };
            return providersClassesMap[type];
        }

        const getTypeClass = (type) => {
            const providersClassesMap = {
                '1': 'mail',
            };
            return providersClassesMap[type];
        }


        const getNumberClass = (type, number) => {
            let newClass = ' number '
            const typeClass = getTypeClass(type);
            if (typeClass) {
                return `${newClass} ${typeClass}`;
            }
            else {
                if (number > 0) {
                    return `${newClass}`;
                }
                else {
                    return `${newClass} none`;
                }
            }
        }

        const getUrl = () => {
            const recruitersIds = selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0 ? 
                selectedRecruiterSlugs.join(',') : 'all'
            return `/inbox?recruiterId=${recruitersIds}&type=${type}&id=0`
        }

        return (
            <div className="item">
                <div className={"inner-item " + getTypeClass(type)}>
                    <div className={'icon-wrapper ' + getIconClass(type)}>
                        {icon}
                    </div>
                    <label className="type ">{name}</label>
                    <Link to={getUrl()}>
                        <label className={getNumberClass(type, number)}>{number}</label>
                     </Link>
                </div>

            </div>
        )
    }


    return (
        <div className='dashboard-activities-conversations-wrapper'>
            <div className="more-menu">
                <DotsIcon />

            </div>

            <h1 className="title">New Conversations</h1>
            <div className="content">
                {!unreadMessageCount ? <Loader /> :
                    <Fragment>
                        {renderItem("1", 'Email', unreadMessageCount.emails)}
                        {renderItem("3", 'WhatsApp', unreadMessageCount.whatsapp)}
                        {renderItem("2", 'SMS', unreadMessageCount.sms)}
                        {renderItem("5", 'Discussions', unreadMessageCount.discussions)}
                        {renderItem("4", 'Chats', '-')}
                    </Fragment>

                }
            </div>
        </div>

    )
});

DashboardActivitiesConversations.propTypes = {
    // bla: PropTypes.string,
};

DashboardActivitiesConversations.defaultProps = {
    linkTo: ''
};

export default DashboardActivitiesConversations
