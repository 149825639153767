import React from 'react';
import './statusBar.scss';

const StatusBar = props => {
    const { text, isFailed } = props;

    const getStatusColor = calculatedStatus => {
        switch(calculatedStatus) {
            case 'Low Availability':
            case 'Not Available':
            case 'Close':
            case 'Low Deleted':
                return 'red';

            case 'Medium Availability':
            case 'Maybe':
            case 'Hold':
            case 'Pending':
                return 'yellow'; 
                    
            case 'High Availability':
            case 'Available':
            case 'Open':
            case 'Activated':
                return 'green';

            case 'Request To Join':
                return 'grey';
            case 'Profiling':
                return 'blue';
            case 'Scraped':
                return 'azure';
            case 'Ready To Invite':
                return 'purple';
            case 'Reinvite':
                return 'pink';
                
            default:
                return ''
        }
    }

    return (
        <div className='status-bar row'>
            <div className={"status " + (isFailed ? "failed " : '') + getStatusColor(text)}/>
            {text && (
                <h5 className="status-bar-text">
                    {text}
                </h5>
            )}
        </div>
    );
};

export default React.memo(StatusBar);
