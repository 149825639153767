import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';
import utilsService from '../services/utils.service';
import userStore from './user.store';
import notificationsStore from './notifications.store';
import errorStore from './error.store';
import profileStore from './profile.store';
import crypto from 'crypto';
import messagingStore from './messaging.store';

class CompanyStore {
    @observable companies = {};
    @observable totalSearchedCompanies = 0;
    @observable searchedCompanies = [];
    @observable searchedCompaniesBySlug = [];
    @observable myCompanies = [];
    @observable myCompaniesBySlug = {};
    @observable companiesNames = [];
    @observable companiesNamesWithJobs = [];
    @observable companyToShow = null;
    @observable companyManagement = [];

    companiesSearchRequestCounter = 0;
    totalSearchedCompaniesCounter = 0;

    @action.bound
    async getCompanies() {
        const response = await fetchService.authenticatedGet('/companies');
        this.companies = utilsService.normalizeApiData(response?.data);
    }

    @action.bound
    async getMyCompanies() {
        const response = await fetchService.authenticatedGet('/my-companies');
        this.myCompanies = response.data;
        this.myCompaniesBySlug = utilsService.normalizeApiData(response?.data);
    }

    @action.bound
    async searchCompanies(selectedRecruiter, filter, skip, isCount = false) {
        if (isCount) {
            const response = await fetchService.authenticatedPost('/companies-search', { selectedRecruiter, filter, 
                isCount, requestNumber: ++this.totalSearchedCompaniesCounter });

            if(response && response.data?.requestNumber === this.totalSearchedCompaniesCounter) {
                this.totalSearchedCompanies = response.data?.totalCompanies || 0;
            }

            return false;
        }

        const response = await fetchService.authenticatedPost('/companies-search', {selectedRecruiter: selectedRecruiter, filter: filter,
             limit: 20, skip: skip, requestNumber: ++this.companiesSearchRequestCounter });
        if(response?.data?.requestNumber === this.companiesSearchRequestCounter) {
            if(skip > 0) {
                this.searchedCompanies.push(...response?.data?.companies);
            }
            else {
                this.searchedCompanies = response?.data?.companies;
                // this.totalSearchedCompanies = response?.data?.totalCompanies;
                userStore.onlineRecruiters[userStore.user?._id] = 1;
            }
            this.searchedCompaniesBySlug = utilsService.normalizeApiData(this.searchedCompanies);
            return true
        }

        return false;
    }

    @action.bound
    async searchCompaniesPartialName(body) {
        const response = await fetchService.authenticatedPost('/companies-search-partial-name', body);
        const data = response?.data;
        if(data) {
            const searchCompaniesFormatted = data.map(comp => {
                comp.value = comp.name;
                comp.elId = comp._id;
                return comp;
            }); 
            
            return searchCompaniesFormatted;
        }
    }

    searchCompaniesByPartialNameByText = async (text, moreParams) => {
        const body = {
            select: "_id slug name",
            limit: 10000,
            text: text,
            ...moreParams
        }

        return await this.searchCompaniesPartialName(body)
    }

    @action.bound
    async getCompaniesByIds(ids) {
        const filteredIds = utilsService.filterStoreIds(ids, Object.keys(this.companies));
        if (filteredIds.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 100);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/companies?ids=${chunk.join(',')}`);;
            });

            const response = await Promise.all(promises);
            let data = [];
            response.forEach(resp => {
                data = [...data, ...resp.data];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.companies = utilsService.mergeApiData(normalizedData, this.companies);
        }
    }

    @action.bound
    async getCompaniesBySlugs(slugs, forceGetData = false) {
        let filteredIds = utilsService.filterStoreIds(slugs, Object.keys(this.companies));
        if (forceGetData) {
            filteredIds = slugs
        }
        if (filteredIds.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/companies?slugs=${chunk.join(',')}`);;
            });

            const response = await Promise.all(promises);
            let data = [];
            response.forEach(resp => {
                data = [...data, ...resp.data];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.companies = utilsService.mergeApiData(normalizedData, this.companies);
        }
    }

    @action.bound
    async getCompaniesByRecruiterId(recruiterId) {
        const response =  await fetchService.authenticatedGet(`/companies?recId=${recruiterId}`);
        this.myCompanies = response?.data;
        this.myCompaniesBySlug = utilsService.normalizeApiData(response?.data);
    }

    @action.bound
    async getCompanyBySlug(slug) {
        if (this.companies[slug]) {
            this.companyToShow = this.companies[slug];
        } else {

            // temp code to understand why slug something null
            if(!slug) {
                this.sendError()
            }
            const response = await fetchService.authenticatedGet(`/companies-slug/${slug}`);
            if(response && response.data) {
                this.companies[response.data.slug] = response.data;
                this.companyToShow = response.data;
            }
        }
        return this.companyToShow;
    }

    sendError() {
        const conversation = messagingStore.conversationsListById[messagingStore.conversationItemSelect];
        const errorObject = {
            userId: userStore.user._id,
            userEmail: userStore.user.email,
            sender: 'Front End',
            requestId: crypto.randomBytes(16).toString('hex'),
            // message: error && error.message,
            stacktrace: Error().stack,
            type: 'Company-Crush',
            href: window.location.href,
            conversation: conversation ? conversation : null
        }
    
        errorStore.sendError(errorObject);
    }

    @action.bound
    async invitePersons(invitesDetails) {
        const companyId = userStore.userCompany._id;
        const body = {
            invitesArr: invitesDetails,
            companyId: companyId
        };
        const invitesResponse = await fetchService.authenticatedPost(`/companies/invite-persons`, body);
        if(!invitesResponse.data || !invitesResponse.data.addedUsers) {
            notificationsStore.showToast('Failed to invite users. Please try again.', 'failure');
            return;
        }

        invitesResponse.data.addedUsers.forEach(person => {
            userStore.users[person.slug] = person;
        })
        // userStore.userCompany = invitesResponse.data.company;
        notificationsStore.showToast('Invitation was sent successfully.', 'success');
    }

    @action.bound
    async invitePersonsByRecruiter(companyId, invitesDetails) {
        const body = {
            invitesArr: invitesDetails
        };
        const invitesResponse = await fetchService.authenticatedPost(`/companies/invite-persons`, body);
        if(!invitesResponse || !invitesResponse.data || !invitesResponse.data.addedUsers) {
            notificationsStore.showToast('Failed to invite users. Please try again.', 'failure');
            return;
        }

        invitesResponse.data.addedUsers.forEach(person => {
            userStore.users[person.slug] = person;
        })
        notificationsStore.showToast('Invitation was sent successfully.', 'success');

        return invitesResponse.data.addedUsers;
    }

    @action.bound
    async updateCompany(data) {
        let isError = false;
        const resp = await fetchService.authenticatedPost(`/companies`, data);
        if (resp.data.isError) {
            isError = resp.data.errorMessage;
        } else {
            this.companies[resp.data.slug] = resp.data;
            userStore.setUserCompany(resp.data);
        }
        return {isError: isError, companySlug: resp.data ? resp.data.slug : undefined};
    }

    @action.bound
    appendDataFromSearch(data) {
        const normalizedData = utilsService.normalizeApiData(data);
        this.companies = utilsService.mergeApiData(normalizedData, this.companies);
    }

    @action.bound
    /* DO NOT USE - Network response 4 MB */
    async getCompaniesNames() {
        if (this.companiesNames.length > 0) return;

        const response = await fetchService.authenticatedGet('/companies-names');
        if(response?.find?.(res => res.isError)) {
            return;
        }
        this.companiesNames = response?.data;
        this.companiesNamesWithJobs = response?.data?.filter(q => q.totalJobs > 0);
    }

    @action.bound
    async getCustomers() {
        //if (this.getCustomers.length > 0) return;

        const response = await fetchService.authenticatedGet('/customers');
        this.customers = response?.data;
        this.customersWithJobs = response?.data?.filter(q => (q.totalJobs > 0 || q.customerStatus===1));
    }


    @action.bound
    async getCompanyManagement(id) {
        if(!this.companyManagement[id]) {
            const response = await fetchService.authenticatedGet(`/company-management/${id}`);
            this.companyManagement[id] = response?.data;
        }

        return this.companyManagement[id];
    }

    @action.bound
    async updateCompanyManagement(companyId, mId, body) {
        const response = await fetchService.authenticatedPatch(`/company-management/${mId}`, body);
        if(response?.data?.management) {
            this.companyManagement[companyId] = response?.data?.management;
        }
        if(response.data.company) {
            this.companies[response?.data?.company?.slug] = response?.data?.company;
        }

        return response && response.data;
    }

    @action.bound
    async updateCompanyPartial(id, body) {
        const response = await fetchService.authenticatedPatch(`/company/${id}`, body);
        this.companies[response?.data?.slug] = response?.data;
    }

    @action.bound
    async addEmployeeToCompany(data) {
        const resp = await fetchService.authenticatedPost(`/companies-add-emp`, data);
        return resp.data;
    }

    @action.bound
    async removeEmployerFromCompany(companyId, userId) {
        const resp = await fetchService.authenticatedDelete(`/companies/${companyId}/profile/${userId}`);
        if(resp?.data) {
            this.companies[resp.data.slug] = resp.data;
        }
        return resp?.data;  
    }

    @action.bound
    async getListDuplicateCompany(type = 'list') {
        const resp = await fetchService.authenticatedGet(`/duplicate-company?type=${type}`);
        if (resp?.data) {
            return resp.data;
        }
        return false;
    }

    @action.bound
    async updateDuplicateCompany(id, data) {
        const resp = await fetchService.authenticatedPatch(`/duplicate-company/${id}`, data);
        if (resp?.data) {
            return resp.data;
        }
        return false;
    }

    @action.bound
    async createDuplicateCompany(data) {
        const resp = await fetchService.authenticatedPost(`/duplicate-company`, data);
        if (resp?.data) {
            return resp.data;
        }
        return false;
    }

    @action.bound
    async getMergeCompaniesResult(data) {
        const resp = await fetchService.authenticatedPost(`/companies/merge/test`, data);
        if(!resp || !resp.data || resp.data.isError) {
            notificationsStore.showToast('Failed to merge companies. ' + resp?.data?.errorMessage , 'failure');
            return;
        }
        return resp.data;
    }

    @action.bound
    async doMergeCompanies(data) {
        const resp = await fetchService.authenticatedPost(`/companies/merge`, data);
        if(!resp || !resp.data) {
            notificationsStore.showToast('Failed to merge companies. Please try again.', 'failure');
            return;
        }
        if (resp.data?.isError) {
            notificationsStore.showToast(resp.data.errorMessage, 'failure');
            return;
        }
        notificationsStore.showToast('Companies are merged successfully.', 'success');
        return resp.data;
    }

    findProcessSlugById(id) {
        return Object.keys(this.companies).find(key => this.companies[key]._id === id);
    }

    async getCompanyManagementAttachment(fileName) {
        const resp = await fetchService.authenticatedGet(`/company-docs/${fileName}`);
        if (resp.data && resp.data[0]) {
            return resp.data[0];
        }
        return false;
    }

    getCompanyById(id) {
        const companySlug = this.findCompanySlugById(id);
        return this.companies[companySlug];
    }
    findCompanySlugById(id) {
        return Object.keys(this.companies).find(key => this.companies[key]._id === id);
    }

}

const companyStore = new CompanyStore();

export default companyStore;
