import {action, observable} from 'mobx';
import Config from 'Config';
import fetchService from '../services/fetch.service';
import {doSignOut} from '../services/auth.service';
import utilsService from '../services/utils.service';
import notificationsStore from './notifications.store';
import profileStore from './profile.store';

const baseUrl = Config.serverUrl; //'http://localhost:4000';

class UserStore {
    @observable user = null;
    @observable error = null;
    @observable logged = false;
    @observable needUpdatePass = false;
    @observable userToken = null;
    @observable userCompany = null;
    @observable users = {};
    @observable recruitersLoaded = false;
    @observable recruitersList = [];
    @observable onlineRecruiters = {}


    @observable searchedUsers = [];
    @observable searchedUsersCount = 0;
    @observable userByFilterRequest = {
        counter: 0,
        general: 0
    };

    @action.bound
    async authenticateUser(googleJwt) {
        this.userToken = googleJwt;
        const request = new Request(`${baseUrl}/auth/`, {
            method: 'POST',
            body: JSON.stringify({ token: googleJwt }),
            headers: { 'content-type': 'application/json' }
        });

        const authResult = await fetch(request);

        if (authResult.status === 403) {
            this.user = null;
        } else {
            const json = await authResult.json();
            this.user = json.data;
            this.user.displayName = this.user.firstName + ' ' + this.user.lastName;
            this.logged = true;
        }

    }

    @action.bound
    async getUserEmailByToken(token) {
        this.userToken = '';
        const response = await fetchService.get(`/user-email-by-token?signupToken=${token}`);
        if (response && response.data) {
            return response.data;
        } else {
            return '';
        }
    }
    @action.bound
    async setUserPasswordByToken(token, firstName, lastName, password) {
        this.userToken = '';
        const body = {signupToken:token, firstName:firstName, lastName:lastName, password: password};
        const response = await fetchService.post(`/signup-by-token`, body);
        if (response && response.data) {
            return response.data;
        } else {
            return undefined;
        }
    }

    @action.bound
    async getCurrentUser(googleJwt) {
        this.userToken = googleJwt;

        fetchService.authenticatedGet(`/user?company=true`).then(response => {
            if (response && response.data) {
                this.userCompany = response.data.company;
            }
        });

        const response = await fetchService.authenticatedGet(`/user?user=true`);
        if (response && response.data) {
            this.user = response.data.user;
            // this.userCompany = response.data.company;
            if (this.user) {
                this.user.displayName = this.user.firstName + ' ' + this.user.lastName;
                this.users[this.user.slug] = this.user;
                if(!this.user.profile && this.user.profileId) {
                    // this.user.profile = await profileStore.getProfileById(this.user.profileId);
                    profileStore.getProfileById(this.user.profileId).then(profile => {
                        this.user.profile = profile;
                    })
                }
            }
            this.logged = true;
            return this.user;
        } else {
            return false;
        }
    }

    @action.bound
    async enableUser(userId) {
        const response = await fetchService.patch(`/enable-account`, userId);
        return response.data;
    }

    @action.bound
    async updateUserImage(userId, imageBase64) {
        const response = await fetchService.authenticatedPost(`/users/${userId}/updateImage`, {imageBase64: imageBase64});
        return response.data;
    }

    @action.bound
    async logOut() {
        this.user = null;
        this.logged = false;
        this.userToken = null;
        await doSignOut();
        window.location.href = '/';
    }

    @action.bound
    async allowSignInToUser() {
        const response = await fetchService.authenticatedPatch(
            `/allow-signin/${this.user._id}`, {});
        return response.data;
    }

    @action.bound
    async setLastSignInDate(userId) {
        await fetchService.authenticatedPatch(`/users/${userId}/last-signin`, {});
    }

    async getCustomToken() {
        const response = await fetchService.authenticatedGet(
            `/customtoken/${this.user.userId}`);
        return response.data;
    }

    @action.bound
    async changeSettings(body) {
        const user = this.user;
        const response = await fetchService.authenticatedPatch(
            `/users/${user._id}/settings`, body);
        this.user = response.data;
    }

    @action.bound
    async removeUserFromCompany(userId) {
        const companyId = this.userCompany._id;
        const response = await fetchService.authenticatedDelete(
            `/companies/${companyId}/profile/${userId}`);
        this.userCompany = response.data;
    }

    @action.bound
    async getUserByProfileId(profileId) {
        const response = await fetchService.authenticatedGet(`/user-by-profile-id/${profileId}`);
        return response && response.data ? response.data : null;
    }

    @action.bound
    async getUserByCompanyId(companyId) {
        const response = await fetchService.authenticatedGet(`/user-by-company-id/${companyId}`);
        return response && response.data ? response.data : null;
    }

    @action.bound
    setUserCompany(company) {
        this.userCompany = company;
    }

    @action.bound
    async updateUser(fields) {
        const response = await fetchService.authenticatedPatch(`/update-user`, fields);
        return response?.data;
    }
    async createUser(fields) {
        const response = await fetchService.authenticatedPost(`/create-users`, fields);
        return response?.data;
    }
    async inviteUsers(data) {
        const response = await fetchService.authenticatedPost(`/invite-users`, data);
        return response?.data;
    }
    async inviteTalents(data) {
        const response = await fetchService.authenticatedPost(`/invite-talents`, data);
        return response?.data;
    }
    async reinviteTalents() {
        const response = await fetchService.authenticatedPost(`/reinvite-talents`);
        return response?.data;
    }
    async profilingProfiles() {
        const response = await fetchService.authenticatedPost(`/send-profiling`);
        return response?.data;
    }
    async removeTalent(email) {
        const response = await fetchService.authenticatedDelete(`/remove-talent/${email}`);
        return response?.data;
    }

    async overwriteTalentData(email) {
        const response = await fetchService.authenticatedPut(`/overwrite-data-talent/${email}`);
        return response?.data;
    }

    @action.bound
    async getUsersByIds(ids) {
        const uniqIds = utilsService.uniqArrayValues(ids);
        const filteredIds = utilsService.filterStoreIdsWithSlugs(uniqIds, this.users);
        let data = [];
        if (filteredIds.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredIds, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/users?ids=${chunk.join(',')}`);
            });

            const response = await Promise.all(promises);

            if (response && response[0]){
                response.forEach(resp => {
                    data = [...data, ...resp.data];
                });
            }

            const normalizedData = utilsService.normalizeApiData(data);
            this.users = utilsService.mergeApiData(normalizedData, this.users);
            const profiles = [];
            data.forEach(user => {
                if (user.profile) {
                    profiles.push(user.profile);
                }
            });
            profileStore.mergeData(profiles);
        }
        return data;
    }

    @action.bound
    async getUsersBySlugs(slugs) {
        const uniqeSlugs = utilsService.uniqArrayValues(slugs);
        const filteredSlugs = utilsService.filterStoreIdsWithSlugs(uniqeSlugs, this.users);
        let data = [];
        if (filteredSlugs.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredSlugs, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/users?slugs=${chunk.join(',')}`);
            });

            const response = await Promise.all(promises);

            response.forEach(resp => {
                data = [...data, ...resp.data];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.users = utilsService.mergeApiData(normalizedData, this.users);
            const profiles = [];
            data.forEach(user => {
                if (user.profile) {
                    profiles.push(user.profile);
                }
            });
            profileStore.mergeData(profiles);
        }
        return data;
    }

    @action.bound
    async getUsersByEmails(emails) {
        const uniqeSlugs = utilsService.uniqArrayValues(emails);
        const filteredSlugs = utilsService.filterStoreIdsWithSlugs(uniqeSlugs, this.users);
        let data = [];
        if (filteredSlugs.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredSlugs, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/users?emails=${chunk.join(',')}`);
            });

            const response = await Promise.all(promises);

            response.forEach(resp => {
                data = [...data, ...resp.data];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.users = utilsService.mergeApiData(normalizedData, this.users);
            const profiles = [];
            data.forEach(user => {
                if (user.profile) {
                    profiles.push(user.profile);
                }
            });
            profileStore.mergeData(profiles);
        }
        return data;
    }

    @action.bound
    async getUsersByCompanyIds(companyIds) {
        const uniqeSlugs = utilsService.uniqArrayValues(companyIds);
        const filteredSlugs = utilsService.filterStoreIdsWithSlugs(uniqeSlugs, this.users);
        let data = [];
        if (filteredSlugs.length > 0) {
            const chunks = utilsService.splitArrayIntoChunks(filteredSlugs, 30);
            const promises = chunks.map(chunk => {
                return fetchService.authenticatedGet(`/users?companyIds=${chunk.join(',')}`);
            });

            const response = await Promise.all(promises);

            response.forEach(resp => {
                data = [...data, ...resp.data];
            });

            const normalizedData = utilsService.normalizeApiData(data);
            this.users = utilsService.mergeApiData(normalizedData, this.users);
            const profiles = [];
            data.forEach(user => {
                if (user.profile) {
                    profiles.push(user.profile);
                }
            });
            profileStore.mergeData(profiles);
        }
        return data;
    }

    @action.bound
    async getUsersByFilter(isOnlyCount, skip, searchText, companyName, groups, permissionType, applicationAccess, status, options) {
        if(isOnlyCount) {
            this.userByFilterRequest.counter++;
            const response = await fetchService.authenticatedPost(`/get-users-by-filters`, {isOnlyCount: isOnlyCount, skip: skip, searchText: searchText,
                companyName: companyName, groups: groups, permissionType: permissionType, applicationAccess: applicationAccess,
                 status: status, requestNumber: this.userByFilterRequest.counter});

            //we want to update UI only for last result
            // if(response.data.requestNumber === this.userByFilterRequest.counter) {
            //     return response && response.data;
            // }

            return response;
        }
        else {
            this.userByFilterRequest.general++;
            const response = await fetchService.authenticatedPost(`/get-users-by-filters`, {isOnlyCount: isOnlyCount, skip: skip, searchText: searchText,
                companyName: companyName, groups: groups, permissionType: permissionType, applicationAccess: applicationAccess,
                 status: status, requestNumber: this.userByFilterRequest.general, ...options });

            //we want to update UI only for last result
            // if(response.data.requestNumber === this.userByFilterRequest.general) {
            //     return response && response.data;
            // }

            return response;
        }
    }

    @action.bound
    async searchUsers(filters, isOnlyCount = false) {
        if (isOnlyCount) {
            const response = await fetchService.authenticatedPost('/get-users-by-filters', {
                ...filters,
                isOnlyCount: true,
                requestNumber: ++this.userByFilterRequest.counter
            });

            const data = response?.data;

            if (this.userByFilterRequest.counter === data?.requestNumber) {
                this.searchedUsersCount = data?.totalUsers || 0;
            }
        } else {
            const response = await fetchService.authenticatedPost('/get-users-by-filters', {
                ...filters,
                pageSize: 50,
                requestNumber: ++this.userByFilterRequest.general,
                // ...(sortedByTalent ? { lastProfileId: lastItemId } : { lastUserId: lastItemId })
            });

            const data = response?.data;

            if (this.userByFilterRequest.general === data?.requestNumber) {
                this.searchedUsers = data?.users || [];
            }
        }
    }

    @action.bound
    findUsersByIds(ids) {
        const users = [];
        ids && ids.forEach(id => {
            const user = Object.values(this.users).find(value => value._id === id);
            if (user) {
                users.push(user);
            }
        });
        return users;
    }

    @action.bound
    async getUserById(id) {
        const userSlug = this.findUserSlugById(id);
        if(userSlug) {
            return this.users[userSlug];
        }
        else {
            await this.getUsersByIds([id]);
            const tmp = this.findUserSlugById(id);
            return this.users[tmp];
        }
    }

    getUserById2 = (id) => {
        const userSlug = this.findUserSlugById(id);
        if(userSlug) {
            return this.users[userSlug];
        }
        return null;
    }

    recruiterLoader = null;

    @action.bound
    async getRecruiters() {
        if (this.recruitersLoaded) {
            return true;
        }

        if (!this.recruiterLoader) {
            this.recruiterLoader = fetchService.authenticatedGet('/v2/recruiters');
        }

        const response = await this.recruiterLoader;
        this.recruiterLoader = null;
        const normalizedData = utilsService.normalizeApiData(response.data);
        this.users = utilsService.mergeApiData(normalizedData, this.users);
        this.filterRecruiters();
        this.recruitersLoaded = true;
        return true;
    }

    @action.bound
    async inviteEmployer(slug, body) {
        const response = await fetchService.authenticatedPatch(`/users/${slug}/invite`, body);
        this.users[response.data.slug] = response.data;
        notificationsStore.showToast('Invitation was sent successfully.', 'success');
    }
    @action.bound
    async revokeUsersFromEmployer(body) {
        const response = await fetchService.authenticatedPost(`/companies/block-user`, body);
        response.data && response.data.users.forEach(user => {
            if(user) {
                this.users[user.slug] = user;
            }
        });
        notificationsStore.showToast('Users Revoke completed successfully.', 'success');
    }

    @action.bound
    async deleteRecruiter(body) {
        const response = await fetchService.authenticatedPost(`/recruiters/remove-from-system`, body);
        return response;
    }

    @action.bound
    async setProfilePermissions(id, body) {
        const response = await fetchService.authenticatedPatch(`/users/${id}/permissions`, body);
        if(response && response.data && response.data.user) {
            this.users[response.data.user.slug] = response.data.user;
        }
        else if(response && response.data) {
            this.users[response.data.slug] = response.data;
        }
    }

    findUsersSlugByIds(ids) {
        const tempArr = [];
        const keys = Object.keys(this.users);
        const length = keys.length;
        for (let i = length - 1; i >= 0; i--) {
            const slug = keys[i];
            const user = this.users[slug];
            if (ids?.includes(user._id)) {
                tempArr.push(user);
            }
        }

        return tempArr;
    }

    findUserSlugById(id) {
        return Object.keys(this.users).find(key => this.users[key]._id === id);
    }

    @action.bound
    async deleteUser(user) {
        const response = await fetchService.authenticatedPost(`/delete-user`, {userId: user._id});
        return response;
    }
    @action.bound
    async getNonUsersByCompany(company) {
        const response = await fetchService.authenticatedPost(`/get-pofile-without-users-by-company`, {company: company});
        return response;
    }

    @action.bound
    async setUserStatus(body) {
        const response = await fetchService.authenticatedPatch(`/users/${this.user._id}/status`, body);
        if(response?.data) {
            this.onlineRecruiters[this.user._id] = response.data.status ? response.data.status : null
        }
        return response;
    }

    @action.bound
    async changeCurrentRecruiterStatus(body) {
        const response = await fetchService.authenticatedPatch(`/users/current-user-status`, body);
        if(response?.data) {
            this.onlineRecruiters[this.user._id] = response.data.status
        }
        return response;
    }

    @action.bound
    async getOnlineUsers() {
        const response = await fetchService.authenticatedGet(`/users/online`);
        if(response?.data) {
            this.onlineRecruiters = response.data
        }
        return response;
    }

    @action.bound
    filterRecruiters() {
        const keys = Object.keys(this.users);
        const res = [];
        keys.forEach(key => {
            if (this.users[key].userType === 3 && this.users[key].canSignIn) {
                res.push(this.users[key]);
            }
        });
        this.recruitersList = res;
    }

}

const userStore = new UserStore();

export default userStore;
