import React, { Component } from 'react';
import './complexRangerChuck.scss';
import StyledRadioButtonsComponent from '../../styledRadioButttons/StyledRadioButtons.component';
import { InputForm } from '..';
import data from '../../../../constants/data';
import searchStore from '../../../../stores/search.store';
import utilsService from '../../../../services/utils.service';
import { ChangeIcon, CloseIcon2 } from '../../svg';

class ComplexRangerChuck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            value: { min: props.min, max: props.max },
            radioSelectIndex: 0,
            newRealValue: props.realValue
        };
    }

    openMe = () => {
        this.setState({
            open: !this.state.open
        });
    };

    inputChange = (e, typeC) => {
        const { min, max } = this.props;
        const { value, newRealValue } = this.state;
        let screenValue = { ...value }
        screenValue[typeC] = e.target.value;
        this.setState({ value: screenValue.toLocaleString() })
        const tempRealValue = utilsService.doMeAFavor(newRealValue)
        tempRealValue[typeC] = e.target.value.length > 0 ? Number(e.target.value) : (typeC === 'min' ? min : max);
        this.setState({ newRealValue: tempRealValue });
    };

    formatRange() {
        const { newRealValue } = this.state;
        return `${newRealValue.min}-${newRealValue.max}`;
    }

    buttonClickHandle = (isReset, isExclude) => {
        const { min, max, buttonClick, slideEv, radioSelect } = this.props;
        const { newRealValue, radioSelectIndex } = this.state;
        let realValueTemp = utilsService.doMeAFavor(newRealValue);
        if (isReset) {
            realValueTemp = { ...realValueTemp, max: max, min: min, isExclude: false }
            this.setState({ open: false, value: realValueTemp, isExclude: false })
            realValueTemp.isExclude = isExclude
        }
        else {
            this.setState({
                open: false,
                isExclude: isExclude
            });
            realValueTemp.isExclude = isExclude;
        }

        radioSelect && radioSelect(radioSelectIndex);
        slideEv(realValueTemp);
        buttonClick();
    };

    radioSelect = (index) => {
        const { type } = this.props;
        if (type === 'selectedSalary') {
            this.setState({ radioSelectIndex: index });
        }
    };

    renderSelectedItem = () => {
        const { realValue } = this.props

        return (

            <div className={'tag-render ' + (realValue.isExclude ? 'excluded' : '')}>
                <div className={"change-icon " + (!realValue.isExclude ? 'include' : '')}
                    onClick={(e) => this.buttonClickHandle(false, !realValue.isExclude)}>
                    <ChangeIcon/>
                </div>
                <div className="close-icon"
                    onClick={(e) => this.buttonClickHandle(true)}>
                    <CloseIcon2 />
                </div>
                <label className="multi-render-item-label">{`${realValue.min.toLocaleString()}-${realValue.max.toLocaleString()}`}</label>
            </div>

        )
    }

    getTitle = () => {
        const { type, label, realSelectedRadioIndex } = this.props;
        if (type === 'selectedSalary') {
            return `${label} ${realSelectedRadioIndex === 0 ? ('(Monthly)') : ('(Hourly)')}`
        }
        return label
    }

    render() {
        const { classes, type } = this.props;
        const { open, value, isExclude, radioSelectIndex } = this.state;

        return (
            <div className={'form-item complex-ranger-chuck-wrapper ' + classes + (isExclude ? ' exclude' : '')}>
                <div className={"main-input-wrapper" + (open ? ' open' : '')}>
                    <input type="text"
                        readOnly={true}
                        onClick={this.openMe}
                        value={this.getTitle()}
                        className={'main-input ' + (open ? ' open' : '')}
                    />

                    <span className={open ? "arrow-icon" : "plus-icon"}></span>
                </div>
                {!open && this.renderSelectedItem()}
                {open && (
                    <div className="ranger-chuck">
                        {type === 'selectedSalary' && (
                            <RadioButtonComponent
                                selected={radioSelectIndex}
                                radioSelectEv={this.radioSelect}
                                data={data.salary_types}
                                type={'selectedSalary'}
                                classes={'salary-type'}
                            />
                        )}

                        <div className="range-inputs">
                            <InputForm
                                value={value.min}
                                type={'number'}
                                inputChange={e => this.inputChange(e, 'min')}
                            />

                            <span className="to">to</span>

                            <InputForm
                                value={value.max}
                                type={'number'}
                                inputChange={e => this.inputChange(e, 'max')}
                            />
                        </div>

                        <div className="button-group">
                            <button
                                onClick={() => this.buttonClickHandle(true)}
                                className="button link">Reset
                            </button>
                            <div className="add-buttons">
                                {searchStore.selectedTopAdvanced === 0 && <button
                                    onClick={() => this.buttonClickHandle(false, true)}
                                    className="button link left exclude">Exclude
                                </button>}
                                <button
                                    onClick={() => this.buttonClickHandle()}
                                    className="button link left">Add
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const RadioButtonComponent = props => {
    const { selected, radioSelectEv, label, data, type, classes } = props;

    const clickHandle = (e, button, index) => {
        radioSelectEv(index, type);
    };

    return (
        <div className={`${classes} form-item`}>
            {label && (<label>{label}</label>)}
            <StyledRadioButtonsComponent
                buttons={data}
                activeIndex={selected}
                clickHandle={clickHandle}
            />
        </div>
    );
};

ComplexRangerChuck.propTypes = {
};

ComplexRangerChuck.defaultProps = {

};

export default ComplexRangerChuck;
