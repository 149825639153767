import React from 'react'
import './navIcon.scss'

function NavIcon({ onClick, isActive = false }) {
  return (
    <div onClick={onClick} className={`nav-icon ${isActive ? 'open' : ''}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}

export default NavIcon
