import React, { Component } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import './quillBS.scss'
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute,true);
const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass,true);
const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass,true);
const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass,true);
const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass,true);
const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass,true);
const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass,true);
const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle,true);
const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle,true);
const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle,true);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle,true);
const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle,true);
const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ['12px', '14px', '18px', '24px'];
Quill.register(SizeStyle,true);

class QuillBS extends Component {

    handleSignatureChanged = (signatureBody) => {
        const { onSignatureChange } = this.props;
        const formattedSignature = `<div style= 'text-align:left'><br/><br/><br/><br/><br/>${signatureBody}</div>`
        onSignatureChange && onSignatureChange(formattedSignature);
    }

    getSignature = () => {
        const user = userStore.user;
        if(user.signatureBody) {
            const signatureAfterChange = user.signatureBody.replace('<br/><br/><br/><br/><br/>', '');
            return signatureAfterChange;
        }
        const formattedPhones = utilsService.extractPhones(user.profile);
        return `<p><strong>${utilsService.capitalizeString(user.displayName)}</strong></p>
            ${user.profile ? '<p>' + utilsService.getProfilePosition(user.profile) + '</p>' : ''}
            ${formattedPhones['Business Phone']  ? '<p>O ' + formattedPhones['Business Phone'] + '</p>' : ''}
            ${formattedPhones['Personal Mobile']  ? '<p>M ' + formattedPhones['Personal Mobile'] + '</p>' : ''}
            <a href='http://www.ethosia.co.il' rel="noopener noreferrer" target="_blank" style='color: #0052CC;'>www.ethosia.co.il</a>`; 
        
    }

    render() {
        const {isInEditMode, onChange, classes, hideToolbar, onSignatureChange} = this.props;
        let {html} = this.props;
        let editorHtml = html;  
        const isContainsHebrewChars = utilsService.isContainsHebrewChars(editorHtml)
        let rightAlignClass;
        if(isContainsHebrewChars || !editorHtml.length || editorHtml === '<p><br></p>') {
            rightAlignClass = 'right-align';
        }

        const modules = {
            toolbar: [
                [{ 'size': ['12px', '14px', '18px', '24px'] }],
                ['bold', 'italic', 'underline','strike' /* ,rush - since editoHtml.replace -  'blockquote' */],
                [/* crush - since editoHtml.replace {'list': 'ordered'}, {'list': 'bullet'}, */ {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                [{ 'direction': 'rtl' }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
            ],
        }

        let classesNames = 'quill-bs-container';
        if (classes) {
            classesNames += ' ' + classes;
        }
        
        return (
            <>
                <div className={classesNames + ' ' + (rightAlignClass ? rightAlignClass : '')} >
                    <ReactQuill
                        value={editorHtml}
                        readOnly={!isInEditMode}
                        onChange={onChange}
                        modules={isInEditMode ? (hideToolbar ? { toolbar: false } : modules) : {toolbar: false}} 
                        theme={isInEditMode ? 'snow' : 'bubble'} />
                </div>
                {onSignatureChange && <div className={classesNames + ' signature'}>
                    <ReactQuill 
                        value={this.getSignature()}
                        readOnly={!isInEditMode}
                        onChange={(signatureBody) => this.handleSignatureChanged(signatureBody)}
                        modules={{toolbar: false}} 
                        theme={'snow'} />
                </div>}
            </>
        )

    }
}

export default QuillBS;
