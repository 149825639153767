import React, { useState } from 'react';
import ModalComponent from '../modal/Modal.component';
import AddToMatchesComponent from '../addToMatches/AddToMatches.component';
import { CloseIcon } from '../svg';
import ProcessAlreadyExistsComponent from '../processAlradyExists/ProcessAlreadyExists.component';

const AddToMatchesModal = props => {
    const { addToMatches, sendCandidates, profiles, jobsSlug, jobs, jobLimit, candidateLimit, isFromMatches } = props;

    const [showSomeExists, setShowSomeExists] = useState(false);
    const [someExistsData, setSomeExistsData] = useState(null);

    const callbackWithExistsDataHandle = (data, response) => {
        setShowSomeExists(true);
        setSomeExistsData(data);
    };

    const sentCallback = data => {
        props.requestFinished && props.requestFinished('send', data);
    };


    return (
        <ModalComponent 
            onClose={() => props.closeEvent()}
            isDraggable={true}
        >
            {!showSomeExists && (
                <div className={'modal-box add-to-matches-modal ' + props.classes}>
                    <div onClick={props.closeEvent}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>
                    <AddToMatchesComponent
                        profiles={profiles}
                        jobsSlug={jobsSlug}
                        jobs={jobs}
                        addToMatches={addToMatches}
                        sendCandidates={sendCandidates}
                        jobLimit={jobLimit}
                        candidateLimit={candidateLimit}
                        closeEvent={props.closeEvent}
                        someExists={callbackWithExistsDataHandle}
                        isFromMatches={isFromMatches}
                        requestFinished={(status, data) => sentCallback(data)}
                        {...{ ...props }}
                    />
                </div>
            )}
            {showSomeExists && (
                <div className={'modal-box show-exists-processes ' + props.classes}>
                    <div onClick={props.closeEvent}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>
                    <ProcessAlreadyExistsComponent
                        data={someExistsData}
                        closeEvent={props.closeEvent}
                        sentCallback={sentCallback}
                    />
                </div>
            )}
        </ModalComponent>
    );
};

export default AddToMatchesModal;
