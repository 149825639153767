import React, { Component } from 'react';

import messagingStore from '../../../stores/messaging.store';
import { observer } from 'mobx-react';
import './senccv.scss';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import SelectMessagingTemplateComponent from '../selectMessagingTemplate/SelectMessagingTemplate.component';
import htmlToDraft from 'html-to-draftjs';
import userStore from '../../../stores/user.store';



@observer
class SendCvComponent2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCcBcc: false
        };
    }

    inputChange = (e, type) => {
        messagingStore[type] = e.target.value;
    };

    onEditorStateChange = emailBody => {
        messagingStore.sendCVEmailBody = emailBody;
    };

    async componentDidMount() {
        messagingStore._sendCVInProcess = true;
        await messagingStore.getMessagingTemplates({ field: 'userId', value: userStore.user._id });

    }

    componentWillUnmount() {
        messagingStore.sendCVEmailBody = EditorState.createEmpty();
    }

    showCcBccHandle = () => {
        this.setState({
            showCcBcc: !this.state.showCcBcc
        });
    };

    chooseTemplateEvent = template => {
        const blocksFromHtml = htmlToDraft(template.body);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const bodyData = ContentState.createFromBlockArray(contentBlocks, entityMap);
        messagingStore.sendCVEmailBody = EditorState.createWithContent(bodyData);
    };

    render() {
        const emailBody = messagingStore.sendCVEmailBody;

        return (
            <div className='send-cv-component new-message-super-search-input-fancy'>
                <div className="multi-with-cc-bcc">

                    <SelectMessagingTemplateComponent
                        chooseTemplateEvent={this.chooseTemplateEvent}
                        conType={1}
                    />

                    <Editor
                        toolbarHidden={true}
                        editorClassName="email-editor"
                        toolbarClassName="toolbar-class-job"
                        editorState={emailBody}
                        onEditorStateChange={this.onEditorStateChange}
                    />
                </div>
            </div>
        );
    }
}

SendCvComponent2.propTypes = {
    
};

SendCvComponent2.defaultProps = {

};


export default SendCvComponent2;
