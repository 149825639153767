import React, { Component } from 'react';
import { observer } from 'mobx-react';
import searchStore from '../../../../stores/search.store';
import MultiSelectComponent from '../../forms/MultiSelect.component';
import messagingStore from '../../../../stores/messaging.store';
import userStore from '../../../../stores/user.store';
import utilsService from '../../../../services/utils.service';
@observer
class SuperSearchJobsInputComponent extends Component {
    constructor(props) {
        super(props);
        this._searching = null;
        this.typingTimeout = 0;
    }

    async componentDidMount() {
        this._searching = false;
        if (this.props.jobsSlug || (searchStore.jobsSearchTo && searchStore.jobsSearchTo.length > 0)) {

            const slug = this.props.jobsSlug ? this.props.jobsSlug : searchStore.jobsSearchTo[0].slug;

            await this.query('#' + slug);
            const jobSearchResults = searchStore.jobsSearchResults;
            searchStore.jobsSearchTo = jobSearchResults;
            if(this.props.addedNewCandidateHandler) {
                this.props.addedNewCandidateHandler();
            }
            messagingStore.sendCVEmailListSelected = [];
            messagingStore.sendCVEmailList = [];

            let temp = [];

            searchStore.jobsSearchResults.forEach(job => {
                if (job && job.emailsForCv && job.emailsForCv.length > 0) {
                    const result = job.emailsForCv.map((item) => {
                        return {
                            elId: item, value: item
                        };
                    });
                    temp = [...temp, ...result];
                }
            });

            messagingStore.sendCVEmailListSelected = temp;
            messagingStore.sendCVEmailList = temp;

            this.updateCompaniesUsersSearchResults();
        }
    }

    updateCompaniesUsersSearchResults = async () => {
        let companyUsersIds = [];
        let emailsForCvContactsIds = [];
        searchStore.jobsSearchTo.forEach(job => {
            if (job) {

                job.companyUsers && job.companyUsers.forEach(item => {
                    companyUsersIds.push(item);
                })

                job.emailsForCvContacts && job.emailsForCvContacts.forEach(item => {
                        emailsForCvContactsIds.push(item);
                })
            }
        });

        await userStore.getUsersByIds([...companyUsersIds, ...emailsForCvContactsIds]);

        const companyUsers = userStore.findUsersByIds(companyUsersIds)

        // searchStore.jobsSearchTo.forEach(job => {
        //     if(job.emailsFromCompanyEmails && job.emailsFromCompanyEmails.forEach(email => {
        //         companyUsers.push({companyEmail: email});
        //     }));
        // });


        searchStore.setCompaniesUsersSearchResults(companyUsers);

        const emailsForCvContacts = userStore.findUsersByIds(emailsForCvContactsIds);
        // searchStore.jobsSearchTo.forEach(job => {
        //     job.emailsFromCompanyEmails && job.emailsFromCompanyEmails.forEach(item => {
        //         emailsForCvContacts.push({companyEmail: item});
        //     });
        // });
        
        searchStore.companiesUsersSearchSetPersonsTo(emailsForCvContacts);       
    }

    componentWillUnmount() {
        searchStore.jobsSearchTo = [];
        searchStore.jobsSearchResults = [];
    }

    removeMultiItem = reactItem => {
        const text = reactItem.props.children[0];
        const update = searchStore.jobsSearchTo.filter((objItem) => objItem.title !== text);
        searchStore.setJobsSearchTo(update);

        //we want to delete all companies users 
        searchStore.setCompaniesUsersSearchTo(update);
        this.updateCompaniesUsersSearchResults();

        if (this.props.addedNewCandidateHandler) {
            this.props.addedNewCandidateHandler();
        }
    };

    selectMultiItem = item => {
        // If exist then remove
        let update = [];

        const limit = this.props.limit;

        if (searchStore.jobsSearchTo.some(objItem => objItem._id === item._id)) {
            update = searchStore.jobsSearchTo.filter((objItem) => objItem._id !== item._id);
        } else {
            if (limit && searchStore.jobsSearchTo.length < limit) {
                update = [...searchStore.jobsSearchTo, item];
            } else if (!limit) {
                update = [...searchStore.jobsSearchTo, item];
            } else {
                update = searchStore.jobsSearchTo;
            }
        }

        searchStore.setJobsSearchTo(update);
        this.updateCompaniesUsersSearchResults();

        if(this.props.addedNewCandidateHandler) {
            this.props.addedNewCandidateHandler();
        }
    };

    query = async text => {

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }
        
        await new Promise(resolve => this.typingTimeout = setTimeout(async () => {
            if (text && text.length > 2 && !this._searching) {
                const firstChar = text.charAt(0);
                const data = {
                    text: text,
                    searchIn: 'jobsName'
                };
    
                if (firstChar === '@') {
                    data.searchIn = 'company';
                    data.text = text.substr(1);
                } else if (firstChar === '#') {
                    data.searchIn = 'jobsId';
                    data.text = text.substr(1);
                }
    
                this._searching = true;
                await searchStore.jobsSearch(data);
                this._searching = false;
            } else if (text === '') {
                searchStore.jobsSearchResults = [];
            }

            resolve();
        }, 700));
    };

    multiItemRenderer = (item, { handleClick, modifiers, index }) => {

        let classes = 'multi-item jobs-super-search-input-multi-item';
        const selectedItems = searchStore.jobsSearchTo.slice();
        if (selectedItems.some(objItem => objItem._id === item._id)) {
            classes += ' selected';
        }
        if (modifiers.active) {
            classes += ' active';
        }

        return (
            <div
                className={classes}
                key={index}
                onClick={handleClick}
            >
                <span className="name">
                    {item.title}
                </span>
                {item.company && (
                    <span className="company">
                        {` @ ${item.companyName}`}
                    </span>
                )}

            </div>
        );
    };

    itemPredicate = (item, cat) => {
        return item;
    };

    tagsMultiRender = job => {

        let isJobPublished = true;
        if(job.jobsBoards !== undefined) {
            if(!job.jobsBoards) {
                isJobPublished = false;
            }
            else if(job.jobsBoards.status === 0) {
                isJobPublished = false
            }
        }

        return ( 
            <span className='jobs-super-search-input-tag'>{utilsService.removeWrongCharsFromString(job.title)} <span className='company'>{` @ ${job.companyName}`}</span><span>{`${!isJobPublished ? '<Not Published>' : ''}`}</span></span>
        );
    };

    render() {
        const selectedItems = searchStore.jobsSearchTo;
        const searchResults = searchStore.jobsSearchResults;

        return (
            <div className='jobs-super-search-input'>
                <MultiSelectComponent
                    items={searchResults}
                    validation={true}
                    valid={true}
                    selectedItems={selectedItems}
                    openOnKeyDown={true}
                    query={this.query}
                    customRenderer={this.multiItemRenderer}
                    customPredicate={this.itemPredicate}
                    customTagsMultiRender={this.tagsMultiRender}
                    removeItem={item => this.removeMultiItem(item)}
                    selectItem={item => this.selectMultiItem(item)}
                    mainOuterClass={'custom-scroll'}
                />
            </div>
        );
    }
}

export default SuperSearchJobsInputComponent;
