import React, { useState } from 'react';
import ModalComponent from '../modal/Modal.component';
import { CloseIcon } from '../svg';
import { TextAreaForm } from '../forms';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';



const WriteCommentModal = props => {

    const { process } = props

    const [comment, setComment] = useState('')

    const doneButtonPressed = async  () => {

        const body = {
            status: process.status,
            processSlug: process.slug,
            profileId: process.profileId,
            comment: comment,
            eventTypeId: process.status,
            decisionMakerEmail: userStore.user.email,
            decisionMakerName: userStore.user.displayName,
            actionType: 2
          };
      
          await processStore.changeStatus(process._id, body, false, false);

        props.closeEvent()
    } 

    return (

        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box modal-box-comment'>
                <div className="write-comment-wrapper">
                    <h5>Write a Comment</h5>
                    <div onClick={() => props.closeEvent()}
                        className='circle-close modal-close'>
                        <CloseIcon />
                    </div>

                    <TextAreaForm
                        value={comment}
                        placeHolder={'Type here...'}
                        type={'text'}
                        inputChange={e => setComment(e.target.value)}
                    />

                    <button
                        onClick={() => doneButtonPressed()}
                        className={'button black done-button'}>
                        Done
                    </button>
                </div>
            </div>
        </ModalComponent>

    );
};

export default WriteCommentModal;
