import { Autocomplete, EditableField, EditableImageField } from '@brightsource/brightsource-ui-lib';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSearchCompanies, useSearchWorldCities } from '../../../../services/search.service';
import utilsService from '../../../../services/utils.service';
import metaDataStore from '../../../../stores/meta-data.store';
import notificationsStore from '../../../../stores/notifications.store';
import profileStore from '../../../../stores/profile.store';
import userStore from '../../../../stores/user.store';

const PersonalInfoTab = observer(() => {
  const { countries = [] } = metaDataStore.categories;
  const { _id, firstName = '', lastName = '', email = '', profileId } = userStore.user;

  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [company, setCompany] = useState(null);

  const profileSlug = profileStore.findProfileSlugById(profileId);
  const profile = profileStore.profiles[profileSlug];

  const {
    cities,
    isSearching: isCitySearching,
    onSearchDebounce: onSearchCityDebounce,
  } = useSearchWorldCities(country ? country.value : '', true);

  const {
    companies,
    isSearching: isCompanySearching,
    onSearchDebounce: onSearchCompanyDebounce,
  } = useSearchCompanies();

  const userCity = useMemo(() => {
    if (profile && profile.locationData) {
      return utilsService.getWorldCityOption(profile.locationData);
    }

    return null;
  }, [profile]);

  const userCountry = useMemo(() => {
    if (profile && profile.locationData) {
      const countryOption = countries.find((c) => c.value === profile.locationData.country);
      return countryOption ?? null;
    }

    return null;
  }, [profile, countries]);

  const userCompany = userStore.userCompany ? userStore.userCompany : null;

  useEffect(() => {
    setCity(userCity);
  }, [userCity]);

  useEffect(() => {
    setCountry(userCountry);
  }, [userCountry]);

  useEffect(() => {
    setCompany(userCompany);
  }, [userCompany]);

  const onUserSave = async (newData) => {
    if (!userStore.user) return;

    const response = await userStore.updateUser({
      ...userStore.user,
      ...newData,
    });

    if (response?.isError) {
      notificationsStore.showToast(response.errorMessage, 'failure');
    } else {
      notificationsStore.showToast('Update user successfully', 'success');
    }
  };

  const onLocationSave = async () => {
    const isError = await profileStore.updateProfile(profileId, { locationData: city });

    if (isError) {
      notificationsStore.showToast('Failed to update user profile', 'failure');
    } else {
      notificationsStore.showToast('Update user profile successfully', 'success');
    }
  };

  const handleAvatarSave = (selectedImage) => {
    // if (!selectedImage) return;

    userStore
      .updateUserImage(_id, selectedImage)
      .then(() => notificationsStore.showToast('Update user avatar successfully', 'success'));
  };

  const handleAvatarError = () => {
    notificationsStore.showToast('Image width and height must not exceed 1000px', 'failure');
  };

  return (
    <Wrapper>
      <EditableImageField
        label="Profile Image"
        initialImage={utilsService.getProfileImage(userStore.user)}
        onImageSave={handleAvatarSave}
        onImageError={handleAvatarError}
        avatarProps={{ name: utilsService.getDisplayName(userStore.user, true) }}
      />

      <Row>
        <label>First Name</label>
        <span>{firstName ? firstName : 'None'}</span>
      </Row>

      <Row>
        <label>Last Name</label>
        <span>{lastName ? lastName : 'None'}</span>
      </Row>

      <Row>
        <label>Email</label>
        <span>{email ? email : 'None'}</span>
      </Row>

      <Row>
        <label>Company</label>
        <span>{company ? company.name : 'None'}</span>
      </Row>

      {/* <EditableTextField
        label="First Name"
        initialText={firstName}
        onTextSave={(value) => onUserSave({ firstName: value })}
        inputProps={{
          disabled: true,
        }}
      />

      <EditableTextField
        label="Last Name"
        initialText={lastName}
        onTextSave={(value) => onUserSave({ lastName: value })}
        inputProps={{
          disabled: true,
        }}
      />

      <EditableTextField
        label="Email"
        initialText={email}
        onTextSave={(value) => onUserSave({ email: value })}
        inputProps={{
          disabled: true,
        }}
      />

      <EditableField
        label="Company"
        onSave={() => onUserSave({ companyId: company ? company._id : '' })}
        onCancel={() => setCompany(userCompany)}
        renderContent={(isEdit) => {
          if (!isEdit) {
            return <span className="editable-value">{company ? company.name : 'None'}</span>;
          }

          return (
            <Autocomplete
              freeSolo
              options={companies}
              getOptionLabel={(x) => x.name}
              isOptionEqualToValue={(a, b) => a._id === b._id}
              value={company}
              onChange={(_e, value) => setCompany(value)}
              placeholder="Enter company name"
              disableFilterOptions
              loading={isCompanySearching}
              onInputChange={(_event, value) => onSearchCompanyDebounce(value)}
              disabled
            />
          );
        }}
      /> */}

      <EditableField
        label="City"
        onSave={onLocationSave}
        onCancel={() => setCity(userCity)}
        renderContent={(isEdit) => {
          if (!isEdit) {
            return <span className="editable-value">{city ? city.value : 'None'}</span>;
          }

          return (
            <Autocomplete
              freeSolo
              options={cities}
              getOptionLabel={(x) => x.value}
              isOptionEqualToValue={(a, b) => a.elId === b.elId}
              value={city}
              onChange={(_e, value) => setCity(value)}
              placeholder="Enter city name"
              disableFilterOptions
              loading={isCitySearching}
              onInputChange={(_event, value) => onSearchCityDebounce(value)}
              searchAsYouType
            />
          );
        }}
      />

      <EditableField
        label="Country"
        onSave={() => {}}
        onCancel={() => setCountry(userCountry)}
        renderContent={(isEdit) => {
          if (!isEdit) {
            return <span className="editable-value">{country ? country.value : 'None'}</span>;
          }

          return (
            <Autocomplete
              multiple={false}
              options={countries}
              getOptionLabel={(x) => x.value}
              isOptionEqualToValue={(a, b) => a.elId === b.elId}
              value={country}
              onChange={(_e, value) => setCountry(value)}
            />
          );
        }}
      />
    </Wrapper>
  );
});

export default PersonalInfoTab;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    font-size: 16px;
    font-weight: 400;
    color: #404040;
  }

  span {
    font-size: 1rem;
    font-weight: 400;
    color: #6c6c6c;
  }
`;
