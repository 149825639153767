import {action, observable} from 'mobx';
import fetchService from '../services/fetch.service';
import userStore from './user.store';

class NotificationsAlertsStore {
    selectedRecruiter = undefined;
    @observable notifications = [];


    constructor() {
        setInterval(() => {
            this.getNotifications();
        }, 52999);
    }

    @action.bound
    async getNotifications() {
        let recSlug = userStore.user && userStore.user.slug;
        let recId = userStore.user && userStore.user._id;
        if(this.selectedRecruiter) {
            recSlug = this.selectedRecruiter.slug;
            recId = this.selectedRecruiter._id;
        }
        if(!recSlug) {
            return;
        }
        const resp = await fetchService.authenticatedPost(`/get-notifications`, {recruiterId: recId, recruiterSlug: recSlug});
        if(resp && resp.data){
            this.notifications = resp.data;   
        }
    }
    @action.bound
    async markNotificationsAsRead(ids) {
        await fetchService.authenticatedPost(`/notifications-update`, {ids: ids});
    }
    @action.bound
    async deleteNotifications(ids) {
        await fetchService.authenticatedPost(`/notifications-delete`, {ids: ids});
    }
}

const notificationsBellStore = new NotificationsAlertsStore();

export default notificationsBellStore;
