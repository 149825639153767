import React, {PureComponent} from 'react';

import './styledRadioButtons.scss';

class StyledRadioButtonsComponent extends PureComponent {
    render() {
        const { buttons, activeIndex, clickHandle, isMultiSelect, activeIndexes } = this.props;

        let selectedIndex = [activeIndex];
        if(isMultiSelect && activeIndexes && Array.isArray(activeIndexes)) {
            selectedIndex = activeIndexes;
        }

        return (
            <div className='styled-radio-buttons'>
                {buttons.map((button, idx) => {
                    let buttonHtml = '';
                    if (typeof button === 'string') {
                        buttonHtml = <button
                            onClick={(e) => clickHandle(e, button, idx)}
                            className={selectedIndex.includes(idx) ? 'styled-radio-button active' : 'styled-radio-button'}
                            key={idx}>
                            {button}
                        </button>;
                    } else {
                        buttonHtml = <button
                            onClick={(e) => clickHandle(e, button, idx)}
                            className={selectedIndex.includes(idx) ? 'styled-radio-button active' : 'styled-radio-button'}
                            key={idx}>
                            {button.value}
                        </button>;
                    }
                    return (buttonHtml);
                })}
            </div>
        );
    }
}

export default StyledRadioButtonsComponent;
