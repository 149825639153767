import React, { Fragment, useState, useEffect, Suspense } from 'react';
import { observer } from "mobx-react"
import { Button, Collapse } from '@blueprintjs/core';
import './searchResults2.scss';
import NavigationBar from '../../components/navigationBar/NavigationBar.component';
import SearchResultsList from './components/SearchResultsList.component'
import SearchResultsTitle from './components/SearchResultsTitle.component';
import CandidateOverviewComponent2 from '../talent/components/CandidateOverview.component.2';
import searchStore from '../../../stores/search.store';
import utilsService from '../../../services/utils.service'
import { AddToMatchesModal, SendConversationModalComponent } from '../../components/modalComponents';
import Loader from '../../components/loader/Loader.component';
import notesStore from '../../../stores/notes.store';
import { BackIcon } from '../../components/svg';
import SuggestModal from '../../components/modalComponents/SuggestModal.component';
import SearchResultsFilter from './components/SearchResultsFilter.component';
import AddNoteModal2 from '../../components/modalComponents/AddNoteModal2.component';
import JobSummaryComponent from '../jobs/components/singleJob/JobSummary.component';
import companyStore from '../../../stores/company.store';
import userStore from '../../../stores/user.store';
import { updateData, hasRecruiterParam, buildQuery } from './searchResultsUtills'
import metaDataStore from '../../../stores/meta-data.store';
import RecruiterSearchComponent from '../../components/search/RecruiterSearch.component';




let timerToSendRequest;

const SearchResults2 = observer((props) => {

    const { history, location } = props

    const [resultsList, setResultsList] = useState(null)
    const [resultsNumber, setResultsNumber] = useState(null)
    const [selectedResult, setSelectedResult] = useState(null)
    const [loader, setLoader] = useState(true);
    const [waitForMoreResultsLoader, setWaitForMoreResultsLoader] = useState(false);
    const [modal, setModal] = useState({
        sendCV: false,
        addToMatches: false,
        sendMessage: false,
        sendSuggest: false,
        addNote: false,
        activeResult: null,
    })
    const [isDataReady, setIsDataReady] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);


    // useEffect(() => {
    //     // return () => {
    //       // && history.location.pathname === "any specific path")
    //       if (history.action === "POP") {
    //           console.log('yes');
    //         history.replace(history.location.pathname, /* the new state */);
    //       }
    //     };
    //   }, [history])

    useEffect(() => {

        if (shouldRenderScreen()) {

            document.title = 'Search Results - Ethosia';

            const getResults = async () => {

                setLoader(true);
                const state = location.state;

                if (state && state.screen !== 'advanced-search') {
                    let type = state.type;
                    let value = state.value;

                    //recognize came from "top recruiter search"
                    if(state.screen === 'top-search') {
                        if(type === 'free-text') {
                            searchStore.initAllAdvancedSearch();
                            searchStore.setSearchValue('keyWords', value);
    
                            await doAdvancedSearch();
                        }
                        else {
                            await searchStore.recruiterFromNavigationSearchSubmit(type, value);
                            const resultsFromServer = searchStore.results[searchStore.selectRightTop(searchStore.selectedTopAdvanced)];
        
                            if (resultsFromServer.specialSearch && resultsFromServer.total === 1) {
                                if (searchStore.selectedTopAdvanced === 0) {
                                    history.replace(`/profile/${resultsFromServer.results[0].slug}`);
                                }
                                else if (searchStore.selectedTopAdvanced === 1) {
                                    history.replace(`/jobs/${resultsFromServer.results[0].slug}`);
                                }
                                else if (searchStore.selectedTopAdvanced === 3) {
                                    const type = resultsFromServer.type;
                                    if (type === 'profile') {
                                        history.replace(`/profile/${resultsFromServer.results[0].slug}`);
                                    }
                                    else if (type === 'company') {
                                        history.replace(`/company/${resultsFromServer.results[0].slug}`);
                                    }
                                }
                                else {
                                    continueGetResults(resultsFromServer);
                                }
                            }
                            else {
                                continueGetResults(resultsFromServer);
                            }
                        }
                    }
                }
                
                else {
                    doAdvancedSearch()
                }
            }

            async function doAdvancedSearch() {
                replaceURL();
                const results = await searchStore.doSearch();
                if (results) {
                    const resultsFromServer = searchStore.results[searchStore.selectRightTop(searchStore.selectedTopAdvanced)];
                    continueGetResults(resultsFromServer);
                }
            }

            const getRecruiters = async () => {
                await userStore.getRecruiters();
                handleDataReceivedFromServer('recruiters')
            }

            if (!location || location.search.length === 0) {
                setIsDataReady(true)
                getResults();
            }
            else {
                getRecruiters();
            }

        }
        else {
            props.history.push('/search');
        }

    }, [location.state])

    const isLocationParamsStatusReady = () => {
        const metaDataReady = !!Object.values(metaDataStore.categories).length > 0;
        const recruitersReady = !hasRecruiterParam(location.search) || 
            userStore.recruitersLoaded;    
        return metaDataReady &&  recruitersReady;
    }

    const continueGetResults = async (resultsFromServer, isScrollToEnd) => {
        const { results = [] } = resultsFromServer;

        if (searchStore.selectedTopAdvanced === 0) {
            const profileIds = results.map(item => item._id).join(',');
            await notesStore.getPrimaryNotes(profileIds, 'profile');
        }
        else if (searchStore.selectedTopAdvanced === 1) {
            const companySlugs = new Set();
            const usersIdsArray = new Set();
            results.forEach(job => {
                companySlugs.add(job.companySlug);
                if (job.recruiterInChargeId) {
                    usersIdsArray.add(job.recruiterInChargeId);
                }
                if (job.hrManagers && job.hrManagers.length > 0) {
                    usersIdsArray.add(job.hrManagers[0]);
                }
            });
            await Promise.all([
                companyStore.getCompaniesBySlugs(Array.from(companySlugs)),
                userStore.getUsersByIds(usersIdsArray)
            ])
        }
        if (resultsFromServer && results.length > 0 && !isScrollToEnd) {
            setSelectedResult(results[0]);
        }

        setCheckedItems({ ...resultsFromServer, results });
        setResultsList({ ...resultsFromServer, results });
        setResultsNumber(results.length)
        setLoader(false);
    }

    const filterDataChanged = () => {

        setLoader(true)
        clearTimeout(timerToSendRequest)
        replaceURL();
        timerToSendRequest = setTimeout(async () => {
            const results = await searchStore.doSearch();
            if (results) {
                const results = searchStore.results[searchStore.selectRightTop
                    (searchStore.selectedTopAdvanced)];
                continueGetResults(results);
            }
        }, 2000)
    }

    const replaceURL = () => {
        const { history } = props;
        const query = buildQuery()
        utilsService.replaceUrl(history, `/search-results2`, query)
    }
    const handleDataReceivedFromServer = async (type) => {

        if (location && location.search) {
            if (isLocationParamsStatusReady() && (
                type === 'metaData' ||
                (type === 'recruiters' && hasRecruiterParam(location.search)))) {
                setIsDataReady(true);
                await updateData(location.search);
                filterDataChanged();
            }
        }

    }

    const getTotalResultsMessage = () => {
        const searchType = searchStore.selectedTopAdvanced;
        let type;
        if (searchType === 0) {
            type = 'profiles'
        }
        else if (searchType === 1) {
            type = 'jobs'
        }

        return `${resultsList.total} ${type}, ${resultsList.results.length} are displayed`;
    }

    const handleItemSelected = (selectedItem) => {
        setSelectedResult(selectedItem);
    }

    const handleCheckTitleActionChanged = (checked) => {
        const tempList = JSON.parse(JSON.stringify(resultsList))
        tempList.results.forEach(item => {
            item.check = checked;
        })
        setResultsList(tempList)
    }

    const handleCheckItemChanged = (selectedItem) => {
        const tempList = JSON.parse(JSON.stringify(resultsList))
        let tempProcessItem = tempList.results.find(item => item.slug === selectedItem.slug)
        tempProcessItem.check = !tempProcessItem.check
        setResultsList(tempList);
    }

    const actionEventHandler = async (result, currentModal) => {
        setModal({ ...modal, [currentModal]: true, activeResult: result })
    };

    const handleRemoveFromTitle = () => {
        const tempList = JSON.parse(JSON.stringify(resultsList))
        tempList.results = tempList.results.filter(item => !item.check);
        setResultsList(tempList);
    }

    const handleRemoveItem = (removedItems) => {
        const tempList = JSON.parse(JSON.stringify(resultsList))
        tempList.results = tempList.results.filter(item => removedItems.some
            (removedItem => removedItem.slug !== item.slug))
        setResultsList(tempList);
    }

    const getRelevantItems = (type) => {
        const { location } = props

        //if search candidates for specific job (add to matches)
        if (type === 1 && location && location.state && location.state.jobMatchSlug) {
            return [location.state.jobMatchSlug]
        }

        if (type !== searchStore.selectedTopAdvanced) {
            return [];
        }
        if (modal.activeResult) {
            return [modal.activeResult.slug];
        }
        else {
            return resultsList.results.filter(item => item.check).map(item => item.slug);
        }
    }

    const getSendMessageItems = () => {
        if (modal.activeResult) {
            return [modal.activeResult];
        }
        else {
            return resultsList.results.filter(item => item.check);
        }
    }

    const backButtonHandle = (e) => {
        e.preventDefault();
        searchStore.backFromResults = true;
        props.history.replace('/search');
    }

    //status or availability
    const handleStatusChanged = (elId) => {
        const results = searchStore.results[searchStore.selectRightTop
            (searchStore.selectedTopAdvanced)];
        const tempList = JSON.parse(JSON.stringify(results))
        if (elId !== -1) {
            tempList.results = results.results.filter(item => getItemFromList(item) === elId);
        }

        setResultsList(tempList);
    }

    const getItemFromList = (item) => {
        const searchType = searchStore.selectedTopAdvanced;
        if (searchType === 0) {
            return utilsService.getCandidateAvailabilityNumber(item);
        }
        if (searchType === 1) {
            return item.status;
        }
    }

    const handleScrollBottom = async () => {
        if (resultsList.results.length < resultsList.total && !waitForMoreResultsLoader) {
            setWaitForMoreResultsLoader(true)
            const results = await searchStore.doSearch(resultsList.results.length);
            if (results) {
                const results = searchStore.results[searchStore.selectRightTop
                    (searchStore.selectedTopAdvanced)];
                continueGetResults(results, true);
                setWaitForMoreResultsLoader(false)
            }
        }
    }

    const renderRightLeg = () => {
        const searchType = searchStore.selectedTopAdvanced;
        if (resultsList.results.length > 0) {

            //profiles
            if (searchType === 0) {
                return <CandidateOverviewComponent2
                    disableActions={true}
                    isProfileSearchResults={true}
                    profile={selectedResult}
                />
            }

            //jobs
            else if (searchType === 1) {
                const company = companyStore.companies[selectedResult.companySlug];
                return <JobSummaryComponent
                    job={selectedResult}
                    company={company}
                />
            }
        }

    }

    const setCheckedItems = (resultsFromServer) => {
        const checkedResultsList = resultsList && resultsList.results.filter(item => item.check);
        checkedResultsList && checkedResultsList.forEach(element => {
            const result = resultsFromServer && resultsFromServer.results.find(resultFromServer => resultFromServer._id === element._id);
            result.check = true;
        });
    }

    const shouldRenderScreen = () => {
        return !location || location.search.length > 0 || searchStore.searched
    }

    const shouldRenderFilter = () => {
        return (!location.state || location.state.screen === 'advanced-search' || 
            location.state.type === 'free-text');
    }

    return (
        <div className='search-results-wrapper'>
            {shouldRenderScreen() && <Suspense fallback={<div>Loading...</div>}>
                <NavigationBar />
                
                <div className="top">
                    <div className="top-wrapper">
                        <Button minimal onClick={(e) => backButtonHandle(e)} className='back-to-search-button'>
                            Back to Search
                        </Button>
                    </div>
                </div>
                <div className='search-results-main max-width'>
                    <div className={"filter custom-scroll"}>
                        <div className='filter-top'>
                            <h1 dangerouslySetInnerHTML={{
                                    __html: utilsService.formatToInnerHtml
                                        (loader ? ' searching... ' : getTotalResultsMessage())
                            }} />

                            {shouldRenderFilter() && <Button
                                className='filter-top-toggle'
                                rightIcon={filterOpen ? 'chevron-up' : 'chevron-down'}
                                onClick={() => setFilterOpen(x => !x)}
                                disabled={loader}
                            >
                                filter
                            </Button>}
                        </div>

                        <Collapse isOpen={filterOpen} keepChildrenMounted className='filter-mobile-collapse'>
                            {shouldRenderFilter() && <SearchResultsFilter
                                filterDataChanged={filterDataChanged}
                                metaDataReceived={() => handleDataReceivedFromServer('metaData')}
                                allNeededDataReady={isDataReady}
                            />}
                        </Collapse>                   
                    </div>
                    {(loader || !userStore.recruitersLoaded) ? <Loader /> :
                        <Fragment>
                            <div className="main">
                                <SearchResultsTitle
                                    fullResults={searchStore.results[searchStore.selectRightTop
                                        (searchStore.selectedTopAdvanced)].results}
                                    results={resultsList.results}
                                    checkActionChanged={handleCheckTitleActionChanged}
                                    actionEventHandler={actionEventHandler}
                                    statusChanged={handleStatusChanged}
                                    removeFromTitle={handleRemoveFromTitle}
                                />
                                <SearchResultsList
                                    results={resultsList.results}
                                    total={resultsList.total}
                                    resultsNumber={resultsNumber}
                                    itemSelected={(result) => handleItemSelected(result)}
                                    selectedItem={selectedResult}
                                    checkActionChanged={handleCheckItemChanged}
                                    actionEventHandler={actionEventHandler}
                                    removeItem={handleRemoveItem}
                                    history={props.history}
                                    scrollBottomHandler={() => handleScrollBottom()}
                                    isWaitingForMoreResults={waitForMoreResultsLoader}
                                />
                            </div>
                            <div className="right-leg">
                                {renderRightLeg()}
                            </div>
                        </Fragment>
                    }

                    {modal.sendCV && (
                        <AddToMatchesModal
                            sendCandidates={true}
                            profiles={getRelevantItems(0)}
                            jobsSlug={getRelevantItems(1)}
                            closeEvent={() => setModal({ ...modal, sendCV: false })}
                        />
                    )}

                    {modal.addToMatches && (
                        <AddToMatchesModal
                            addToMatches={true}
                            profiles={getRelevantItems(0)}
                            jobsSlug={getRelevantItems(1)}
                            jobLimit={1}
                            closeEvent={() => setModal({ ...modal, addToMatches: false })}
                        />
                    )}

                    {modal.sendSuggest && (
                        <SuggestModal
                            classes='add-to-leeds-modal'
                            sendCandidates={true}
                            profiles={getRelevantItems(0)}
                            jobs={[]}
                            jobsSlug={[]}
                            closeEvent={() => setModal({ ...modal, sendSuggest: false })}
                        />
                    )}

                    {modal.addNote && (
                        <AddNoteModal2
                            closeEvent={() => setModal({ ...modal, addNote: false })}
                            profileId={modal.activeResult._id}
                        />
                    )}

                    {modal.sendMessage && (<SendConversationModalComponent
                        closeEvent={() => setModal({ ...modal, sendMessage: false })}
                        replyTo={null}
                        items={getSendMessageItems()}
                    />)}

                </div>
            </Suspense>}
        </div>
    )
});

SearchResults2.propTypes = {
    // bla: PropTypes.string,
};

SearchResults2.defaultProps = {
    // bla: 'test',
};

export default SearchResults2;

