export const LINKEDIN_CODE = 47;
export const DEFAULT_COUNTRY_STATE = {
  COUNTRY: 'il',
  CALLING_CODE: '972'
}
export const SEARCH_SETTINGS_PROJECT_TYPES = {
  PROJECT: 'project',
  CAMPAIGN: 'campaign'
}

export const DOWNLOAD_TYPES = {
  INVALID_LINKEDIN: '0',
  INVALID_PHONES: '1',
  INVALID_EMAILS: '2',
};
