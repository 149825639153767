import React, { PureComponent } from 'react';
import jobStore from '../../../stores/job.store';
import profileStore from '../../../stores/profile.store';
import processStore from '../../../stores/process.store';
import { CloseIcon } from '../svg';
import Loader from '../loader/Loader.component';
import '../processOverviewBox/processOverviewBox.scss';
import ProcessOverviewBottomDataComponent from '../processOverviewBox/ProcessOverviewBottomData.component';
import RightSidePreviewComponent from '../../components/processOverviewBox/RightSidePreview.component';
import notificationsStore from '../../../stores/notifications.store';
import ModalComponent from '../modal/Modal.component';


class DeleteMatchesModal2 extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            isUpdateLoader: false,
            selectOptions: null,
            commentToSend: '',
            disableClickButton: false,
            profile: null,
            job: null,
            isValid: false
        };
    }

    async componentDidMount() {
        const { profilesSlug, jobsSlug } = this.props;

        const promises = [];

        let profile;
        let job;
        if (profilesSlug && profilesSlug.length) {
            const profileSlug = profilesSlug[0];
            profile = profileStore.profiles[profileSlug];
            if (!profile) {
                promises.push(profileStore.getProfilesBySlugs([profileSlug]));
            }
        }

        if (jobsSlug && jobsSlug.length) {
            const jobSlug = jobsSlug[0];
            job = jobStore.allJobs[jobSlug];
            if (!job) {
                promises.push(jobStore.getJobsBySlugs([jobSlug]));
            }
        }

        await Promise.all(promises);

        if (!profile && profilesSlug && profilesSlug.length) {
            profile = profileStore.profiles[profilesSlug[0]];
        }

        if (!profile && jobsSlug && jobsSlug.length) {
            job = jobStore.allJobs[jobsSlug[0]];;
        }

        this.setState({ loader: false, profile, job })
    }

    processCommentEvent = comment => {
        this.setState({
            commentToSend: comment.target.value
        });
    };

    updateProcessHandle2 = async () => {
        const { requestFinished, screen, closeEvent } = this.props;
        const { commentToSend, selectOptions, profile, job } = this.state;

        if (this.state.disableClickButton) { // Prevent multiple clicks on the Update button.
            return;
        }
        this.setState({ disableClickButton: true, isUpdateLoader: true });

        const data = {
            company: job.company,
            companySlug: job.companySlug,
            job: job._id,
            jobSlug: job.slug,
            profile: profile._id,
            profileSlug: profile.slug,
            status: selectOptions.id,
            comment: commentToSend
        }
        const response = await processStore.createProcesses([data])

        const alreadyInProcess = response.find(item => item.errorMessage && item.isError);
        if (alreadyInProcess) {
            const type = (screen === 'jobScreen' ? 'Jobs' : 'Profiles');
            notificationsStore.showToast(`One or more ${type} already in process`, 'failure');
        }
        else {
            notificationsStore.showToast(`Delete match completed successfully`, 'success');
        }

        const filteredSuccessfulResponse = response.filter(item => !item.isError);
        requestFinished && requestFinished("delete", filteredSuccessfulResponse);

        closeEvent();
        this.setState({ disableClickButton: false, isUpdateLoader: false });
    };

    selectOptionsHandle = (e, option) => {
        this.setState({selectOptions: option, isValid: true});
    };

    render() {
        const { closeEvent } = this.props;
        const {loader, selectOptions, commentToSend, profile, job,
             disableClickButton, isValid } = this.state;

        return (

            <ModalComponent
                isDraggable={true}
                onClose={() => closeEvent()}>
                <div className='modal-box process-overview-modal unmatch-overview-modal'>
                    <div className='process-overview-box2'>
                        {loader && (
                            <Loader />
                        )}

                        {!loader && (
                            <div className="process-overview-wrap">
                                <strong className="handle dragging-handle" />
                                <div className="process-overview-header">
                                    <header>
                                        <h1>Unmatch Update</h1>
                                        <div onClick={closeEvent}
                                            className='circle-close modal-close'>
                                            <CloseIcon />
                                        </div>
                                    </header>
                                </div>
                                <div className='process-overview-body'>
                                    <div className="left-side">

                                        <div className="item selector">
                                            {!loader && (
                                                <div className="process-overview-actions-handle">
                                                    <ProcessOverviewBottomDataComponent
                                                        actionId={56}
                                                        jobSlug={job ? job.slug : null}
                                                        selectOptions={selectOptions}
                                                        commentToSend={commentToSend}
                                                        processCommentEvent={this.processCommentEvent}
                                                        selectOptionsHandle={this.selectOptionsHandle}
                                                    />
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="right-side">
                                        <RightSidePreviewComponent
                                            jobSlug={job ? job.slug : null}
                                            profile={profile}
                                            companySlug={job ? job.companySlug : null}
                                        />
                                    </div>
                                </div>
                                <footer>
                                    <button
                                        onClick={this.updateProcessHandle2}
                                        disabled={!isValid || disableClickButton}
                                        className={isValid ? 'button bk-darkblue update-process ' + (this.state.isUpdateLoader ? 'loading' : '') : 'button invalid update-process'}>Update
                                    </button>
                                </footer>

                            </div>
                        )}
                    </div>
                </div>


            </ModalComponent>

        );
    }
}

export default DeleteMatchesModal2;
