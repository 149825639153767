import React from 'react';
import onClickOutside from 'react-onclickoutside';
import './ComplexInput.scss';

class ComplexInputComponent extends React.Component {
    // const ComplexInputComponent = (props) => {

    constructor(props) {
        super(props);

        this._inputRef = React.createRef();
    }

    onClickLbl = () => {
        this._inputRef && this._inputRef.current && this._inputRef.current.focus();
    }

    handleClickOutside = evt => {
        this.props.onClickOutside && this.props.onClickOutside();
    };

    getMainClasses = () => {
        const { isLoading, isDisabled } = this.props;
        let classes = 'complexInputContainer';
        if (isLoading) {
            classes += ' loading';
        }
        if(isDisabled) {
            classes += ' disabled';
        }

        return classes;
    }

    render() {
        const { placeholder, value, onChange, onClick, onKeyPress, valid, icon } = this.props;

        return (
            <div className={this.getMainClasses()}>
                {!value && this.props.isRTL && <label className="placeholder rtl" onClick={this.onClickLbl}>{placeholder}</label>}
                <input
                    ref={this._inputRef}
                    dir={this.props.isRTL ? 'rtl' : 'ltr'}
                    value={value || ""}
                    className={"input " + (valid ? '' : 'invalid')}
                    onChange={onChange}
                    onClick={(e) => {onClick ? onClick(e) : e.stopPropagation();}}
                    onKeyPress={(e) => {onKeyPress ? onKeyPress(e) : e.stopPropagation();}}>
                </input>
                {!value && !this.props.isRTL &&
                    <label className="placeholder" onClick={this.onClickLbl}>
                        {placeholder}
                    </label>
                }
                {icon && 
                    <div className='icon'>{icon}</div>
                }
            </div>
        );
    }
}

ComplexInputComponent.defaultProps = {
    valid: true,
};

export default onClickOutside(ComplexInputComponent);
