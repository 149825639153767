export class QueryParamsBuilder {
  constructor(query) {
    this.query = query;
    this.queryParams = new URLSearchParams();
  }

  setParam(key) {
    this.query[key] !== null && this.query[key] !== undefined && this.queryParams.set(key, this.query[key]);
  }

  build() {
    return this.queryParams.toString();
  }
}

export class QueryParamUtils {
  static packMany(value) {
    return value.join(',');
  }

  static unpackMany(value) {
    return value.split(',');
  }
}
