import React, { PureComponent } from 'react';
import './roles.scss';

class RolesComponent extends PureComponent {

    handleRoleSelectEvent = (e, item) => {
        const { roleSelectEvent, selectedRoles } = this.props;
        const tempItem = selectedRoles.find(role => role.elId === item.elId && role.type === 2);
        if(tempItem) {
            item.type = 2;
        }
        roleSelectEvent(e, item)
    }

    render() {
        const { rolesList, selectedRoles } = this.props;
        
        return (
            <div className="roles-wrapper">
                {rolesList && (
                    <div className="roles-list">
                        {rolesList.map((item) => {
                            const checked = selectedRoles.find(selectedRole =>  selectedRole.elId === item.elId);
                            let styles = 'role-button';
                            if (checked) {

                                if(checked.type === 2) {
                                    styles += ' excluded';
                                }
                                else {
                                    styles += ' checked';
                                }
                            }
                            return (
                                <div
                                    key={item.elId}
                                    className="role-item">
                                    <div
                                        onClick={e => this.handleRoleSelectEvent(e, item)}
                                        className={styles}>
                                        {item.value}
                                    </div>
                                </div>);
                        })}
                    </div>
                )}
            </div>
        );
    }
}

export default RolesComponent;
