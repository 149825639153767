import React, { Component } from 'react';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';

class ExposeProcessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            message: 'Would you like to send a request to reveal their contact information?',
            exposed: false
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ updating: true });
        const id = this.props.data.process._id;
        const body = {
            status: this.props.data.process.status,
            profileId: this.props.data.process.profileId ? this.props.data.process.profileId : this.props.data.process.profile,
            comment: '',
            eventTypeId: 3,
            eventType: 'Exposure',
            decisionMakerEmail: userStore.user.email,
            decisionMakerName: userStore.user.displayName
        };

        await processStore.changeStatus(id, body, this.props.isSingleJobView, this.props.isSingleCompanyView);
        this.handleCloseClick();
        // this.setState(
        //     { updating: false, exposed: true, message: 'The request needs candidate allowance, will be updated shortly' });
    };

    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    handleSuccessClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.successEvent();
        }
    };

    render() {
        return (
            <div className={this.state.exposed ? 'action-process expose-component exposed' : 'action-process expose-component'}>
                <div>
                    <h3>Reveal candidates details?</h3>
                    <h4>{this.state.message}</h4>
                </div>

                {this.state.exposed && (<footer className='center'>
                    <button
                        onClick={this.handleSuccessClick}
                        className={`button blue okbutton`}
                    >
                        Ok
                    </button>
                </footer>)}

                {!this.state.exposed && (<footer>
                    <button
                        onClick={this.handleCloseClick}
                        className='button gray'>
                        Cancel
                    </button>
                    <button
                        onClick={this.handleSubmit}
                        className={`button blue handler ${this.state.updating ? 'loading' : ''}`}
                    >
                        Send request
                    </button>
                </footer>)}

            </div>
        );
    }
}

export default ExposeProcessComponent;
