import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import messagingStore from '../../../stores/messaging.store';
import userStore from '../../../stores/user.store';

@observer
class ConversationsNavLink extends Component {
    async componentDidMount() {
        const user = userStore.user;
        if (!messagingStore.updatesObserverLoaded) {
            const idOfUserForInbox = messagingStore.getCurrentUserIdOfInbox();
            await Promise.all([
                messagingStore.getUnreadMessagesCount({ id: user._id, idOfUserForInbox: idOfUserForInbox }),
                userStore.getRecruiters()
            ]);

            setInterval(() => {
                messagingStore.getUpdates(user._id, true);
            }, 37003);
            messagingStore.updatesObserverLoaded = true;
        }
    }

    render() {

        let totalCounts = 0;
        if(messagingStore.totalNewMessagesCount) {
            const totalLoggedUser = messagingStore.totalNewMessagesCount.totalLoggedUser;
            if(totalLoggedUser) {
                totalCounts = totalLoggedUser;
            }
        }

        return (
            <Fragment>
                <NavLink to="/inbox"
                        className='conversations'
                        onClick={() => {
                            this.props.onCloseMenu();
                            messagingStore.activeInboxMode = messagingStore.inboxMode.LIST;
                        }}
                         activeClassName='active'>{`Conversations (${totalCounts})`}</NavLink>
            </Fragment>
        );
    }
}

export default ConversationsNavLink;
