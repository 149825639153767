import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import data from '../../../constants/data';
import {SelectForm} from '../forms';
import Loader from '../loader/Loader.component';
import {CloseIcon} from '../svg';

const TeamSetRow = (props) => {
    const { profile, selectedRoles, selectChange, removeFromCompany, inviteMember, invited } = props;
    const slug = profile.slug;
    const isInvited = invited.includes(slug);
    return (
        <div className='team-mates'>
            <div className='name bordered border-radius'>
                {utilsService.getDisplayName(profile)}
            </div>
            <div>
                <SelectForm
                    name={'permissions'}
                    value={selectedRoles[slug]}
                    selectList={data.employers_permissions}
                    inputChange={e => selectChange(e, slug)}
                />
            </div>

            <div className='bordered border-radius email'>
                {profile.email}
            </div>
            <div className='buttons'>
                <button
                    disabled
                    // onClick={() => removeFromCompany(profile)}
                    className="button gray disabled">Remove
                </button>
                <button
                    onClick={() => inviteMember(profile)}
                    disabled={isInvited}
                    className={isInvited ? 'button invited' : 'button black'}>{isInvited ? '✓' : 'Invite'}
                </button>
            </div>
        </div>
    );
};

class SetUpYourTeamModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            selectedRoles: {},
            invited: []
        };
    }

    async componentDidMount() {
        const profileIds = userStore.userCompany.users;
        await userStore.getUsersByIds(profileIds);
        this.setState({ loader: false });
    }

    selectChange = (e, slug) => {
        const selectedRoles = this.state.selectedRoles;
        selectedRoles[slug] = Number(e.target.value);
        this.setState({ selectedRoles: selectedRoles });
    };

    inviteMember = async profile => {
        const { selectedRoles, invited } = this.state;
        const slug = profile.slug;

        const permisssion = selectedRoles[slug] ? selectedRoles[slug] : 1;

        const body = {
            permission: permisssion,
            invitedBy: userStore.user
        };

        await userStore.inviteEmployer(slug, body);
        this.setState({ invited: [...invited, slug] });
    };

    removeFromCompany = async profile => {
        await userStore.removeUserFromCompany(profile._id);
    };

    render() {

        const { selectedRoles, loader, invited } = this.state;

        const gridHeader = ['Name', 'Role', 'Mail'];

        const profiles = [];
        const users = userStore.userCompany && userStore.userCompany.users ? userStore.userCompany.users : [];
        users.forEach((id) => {
            const profileKey = Object.keys(userStore.users)
                .find(key => userStore.users[key]._id === id
                    && !userStore.users[key].isAdmin
                    && !userStore.users[key].canSignIn);
            if (profileKey) {
                profiles.push(userStore.users[profileKey]);
            }
        });

        return (
            <ModalComponent onClose={() => this.props.closeEvent()}>
                <div className='modal-box set-up-your-team-modal'>
                    <header>
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                        <h2>Set up your team</h2>
                        <p>We’ve found people from your organization in our system.</p>
                        <p>Would you like to add them to your team?</p>
                    </header>

                    {loader && (
                        <Loader/>
                    )}

                    {!loader && (
                        <section className="modal-body">
                            <div className="set-up-team-grid">
                                <ul className='grid-header'>
                                    {gridHeader.map((item, idx) => {
                                        return (
                                            <li
                                                key={'header-key' + idx + item}
                                            >
                                                {item}
                                            </li>
                                        );
                                    })}
                                </ul>

                                <div className="grid-body">
                                    {profiles.map(profile => {
                                        return (
                                            <TeamSetRow
                                                key={profile._id}
                                                profile={profile}
                                                selectedRoles={selectedRoles}
                                                selectChange={(e, slug) => this.selectChange(e, slug)}
                                                inviteMember={this.inviteMember}
                                                removeFromCompany={this.removeFromCompany}
                                                invited={invited}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </section>
                    )}
                </div>
            </ModalComponent>
        );
    }
}

export default SetUpYourTeamModal;
