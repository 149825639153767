import React from 'react';
import {NearTrain, NearRoad6} from '../svg';

const Transportations = props => {
    const { industryDistrict } = props;

    const getTransportationsView = () => {
        const transportationsView = [];
        if(industryDistrict) {
            if(industryDistrict.NearTrain === 1) {
                transportationsView.push(<NearTrain key={1}/>);
            }
            if(industryDistrict.NearRoad6 === 1) {
                transportationsView.push(<NearRoad6 key={2}/>);
            }
        }

        return transportationsView;
    }

    const transportationsView = getTransportationsView();
    return (
        <div className='transportations'>
            {transportationsView.map(item => {
                return item;
            })}
        </div>
    );
};

export default React.memo(Transportations);
