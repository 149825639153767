import React from 'react';
import {WeSearchForTalentsIcon} from './svg';

const EmptySingleJobGrid = () => {
    return (
        <div className="empty-single-job-grid">
            <div className="icon-wrapper">
                <WeSearchForTalentsIcon/>
            </div>
            <h3>We're on it</h3>
            <p>working on bringing you the best fits</p>
        </div>
    );
};

export default React.memo(EmptySingleJobGrid);
