import React, { PureComponent } from 'react';
import Loader from '../../../components/loader/Loader.component';
import {EditIcon, CloseIcon, VeeeIcon } from '../../../components/svg';
import profileStore from '../../../../stores/profile.store';
import 'react-trumbowyg/dist/trumbowyg.min.css'
import Trumbowyg from 'react-trumbowyg'
import $ from 'jquery'
import './cv2component.scss'
import ModernSelectComponent from '../../../components/forms/ModernSelect.component';
import metaDataStore from '../../../../stores/meta-data.store';


class Cv2Component extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            fileName: '',
            hasPublicCV: false,
            isInEditMode: false,
            cvHtml: null,
            selectedCVLang: {elId: 0, value: 'en'},
            cvId: undefined

        };

        this.cvLang = undefined;
    }

    async componentDidMount() {
        await this.loadCV();

        const promises = this.bringMetaDataCategories();
        await Promise.all(promises)
    
        const {
          cv,
        } = metaDataStore.categories;
        this.cvLang = cv.lang;
    
    }
    bringMetaDataCategories() {
        const categories = [
            'cv',
        ];
    
        return categories.map(cat => {
            return metaDataStore.getCategory(cat, true);
        });
    }
    
    async loadCV() {
        const { profile } = this.props;
        const profileSlug = profile.slug;
        const response = await profileStore.getCvForEdit(profileSlug, undefined);
        if(!response) {
            this.setState({
                loader: false
            });
            return;
        }

        const fileName = response.fileName;
        const cvHtml = response.data;
        const cvType = response.cvType;
        const cvId = response.cvId;
        this.setState({
            loader: false,
            fileName: fileName,
            cvHtml: cvHtml,
            cvType: cvType,
            hasPublicCV: cvHtml && cvType.elId === 1 ? true : false,
            selectedCVLang: response.cvLang,
            cvId: cvId
        });
    }

    handleEditClick = async (e) => {
        this.setState({isInEditMode: true});
    }
    handleMakePublic = async (e) => {
        this.setState({loader: true});

        const { profile } = this.props;
        const {fileName, selectedCVLang, cvId, cvType} = this.state;
        const profileSlug = profile.slug;
        const changedHtml = this.getHtmlFromEditor();
        const response = await profileStore.saveCv(profileSlug, fileName, changedHtml, {elId: 1, value: 'public'}, selectedCVLang, true, cvType.elId === 0 ? undefined : cvId);
        if(!response) {
            await this.loadCV();
        }
        else {
            if(response.cvs) {
                const newlyCreatedPublicCv = response.cvs.find(cv => cv?.cvType?.elId === 1 && cv.isPrimary);
                if(newlyCreatedPublicCv) {
                    await profileStore.createCoveredCV(profileSlug, newlyCreatedPublicCv._id);
                }
            }

            this.setState({
                isInEditMode: false,
                loader: false,
                hasPublicCV: true,
                cvHtml: changedHtml
            });
        }
    }
    handleOnCloseClick = async (e) => {
        this.setState({isInEditMode: false});
    }
    getHtmlFromEditor() {
        const html = $(`#cveditor`).trumbowyg('html');
        return html;
    }
    
    render() {
        const { cvHtml, loader, isInEditMode, hasPublicCV, selectedCVLang } = this.state;

        const publicStatus = hasPublicCV ? 'Public' : 'Not Public';
        const langStatus = selectedCVLang && selectedCVLang.elId === 1 ? 'Hebrew' : 'English';
        return (
            <div className='talent-cv custom-scroll'>
                {!loader && cvHtml && !isInEditMode && (
                    <div className="header-not-edit">
                        <div></div>
                        <div className="">
                            <h3 className={'status ' + (hasPublicCV ? 'public' : 'not-public')}>
                                <p>{`${publicStatus} - ${langStatus}`}</p>
                            </h3>
                        </div>
                        {!isInEditMode  && (
                            <div className={`edit-item`} onClick={this.handleEditClick}>
                                <EditIcon/>
                            </div>
                        )}
                    </div>
                )}
                {!loader && isInEditMode && (
                    <div className="header-edit-mode">
                        <div>
                            <ModernSelectComponent
                                list={this.cvLang}
                                indexMark='elId'
                                valueMark='value'
                                selected={selectedCVLang.elId}
                                title={'Language:'}
                                selectEvent={(cvLang) => this.setState({selectedCVLang: cvLang})}
                            />
                        </div>
                        <div className="close-icon-container" onClick={this.handleOnCloseClick}>
                            <CloseIcon/>
                        </div>
                        <div className="vee-icon-container" onClick={this.handleMakePublic}>
                            <VeeeIcon/>
                        </div>
                    </div>
                )}
                {loader && (
                    <div className="cv-loader">
                        <Loader/>
                    </div>
                )}
                {!loader && !cvHtml && (
                    <div className="candidate-missing-cv">Candidate Is Missing CV</div>
                )}
                {!isInEditMode && cvHtml && (
                    <div className='cv-tab'>
                        <div  className="html-container">
                            <div dangerouslySetInnerHTML={{__html: cvHtml}}></div>
                        </div>
                    </div>
                )}
                {isInEditMode && cvHtml && (
                    <div className='cv-tab'>
                        <div className="html-container-for-edit">
                            <Trumbowyg 
                                id='cveditor'
                                data={cvHtml}
                                autogrow={true}
                                onChange={this.handleHtmlChanged}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Cv2Component;
