import React from 'react';
import ModalComponent from '../modal/Modal.component';
import ExposeRecruiterProcessComponent from '../actions/ExposeRecruiterProcess.component';

const ExposeRecruiterProcessModal = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box expose-modal expose-recruiter-modal'>
                <ExposeRecruiterProcessComponent
                    isSingleJobView={props.isSingleJobView}
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>
    );
};

export default ExposeRecruiterProcessModal;
