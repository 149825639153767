import React, {Component} from 'react';
import userStore from '../../../stores/user.store';
import {doPasswordReset} from '../../../services/auth.service';
import {LogoIcon, SentMessageIcon} from '../../components/svg';
import {Link} from 'react-router-dom';
import './auth.scss';

class ForgotPasswordView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            errorMessage: '',
            successMessage: ''
        };
    }

    handleSubmit = e => {
        const { email } = this.state;
        if (email) {
            this.sendPasswordResetEmail(email);
        }
        e.preventDefault();
    };

    sendPasswordResetEmail = email => {
        doPasswordReset(email).then(() => {
            this.setState({ successMessage: 'Help is on the way' });
        }).catch(() => {
            this.setState({ successMessage: 'Help is on the way' });
        });
    };

    inputValueChangeHandle(e, type) {
        this.setState({
            [type]: e.target.value
        });
    }

    componentDidMount() {
        if (userStore.logged) {
            this.proceedToUrl();
        }
        document.title = 'Reset Password - Ethosia';
    }

    proceedToUrl = () => {
        this.props.history.push('/inbox');
    };

    render() {
        return (
            <div>
                <main className="auth login border-radius shadow4 reset-password">
                    <React.Fragment>
                        <div className="login-icon-wrapper">
                            <LogoIcon/>
                        </div>
                        {!this.state.successMessage && (<form className="form">
                            <h1>Reset password</h1>
                            <p>Type the email you signed up with and we'll send you a link to reset your password.</p>
                            <div className="form-item reset-email">
                                <label>Email</label>
                                <input
                                    type="email"
                                    className="email"
                                    value={this.state.email}
                                    onChange={e => this.inputValueChangeHandle(e, 'email')}
                                />
                            </div>

                            <div className="form-item center submit">
                                {this.state.errorMessage && (
                                    <p
                                        className="login-message error">{this.state.errorMessage}</p>
                                )}
                                <button onClick={this.handleSubmit} className="button blue">
                                    Send link
                                </button>
                            </div>

                            <Link
                                className='login-back-link'
                                to={'/login'}>
                                &#60; Back to login
                            </Link>
                        </form>)}

                        {this.state.successMessage && (
                            <div className="success-reset">
                                <SentMessageIcon/>
                                <p
                                    className="login-message success">{this.state.successMessage}</p>
                                <p className="sub-text">
                                    We sent an email with a link to reset your password to {this.state.email}.
                                    <div>This link will be valid for the next 24 hours.</div>
                                    <br/>
                                    <div>Didn't get an email? Check your spam folder or contact us.</div>
                                </p>

                                <p>

                                </p>
                                <Link
                                    className='login-back-link'
                                    to={'/login'}>
                                    &#60; Back to login
                                </Link>
                            </div>)}
                    </React.Fragment>
                </main>
            </div>
        );
    }
}

export default ForgotPasswordView;
