import fetchService from '../services/fetch.service';
import { action, observable } from 'mobx';
import profileStore from './profile.store';
import userStore from './user.store';
import utilsService from '../services/utils.service';
import dayjs from 'dayjs';
import searchStore from './search.store';
import { EditorState } from 'draft-js';
import processStore from './process.store';
import { Base64 } from 'js-base64';
import metaDataStore from './meta-data.store';
import stringTokenReplacerService from '../services/stringTokenReplacer.service';
import data from '../constants/data';
import companyStore from './company.store';

class MessagingStore {
    @observable inboxUserSelect = 0;
    @observable providersSelect = 1;
    @observable conversationStatusSelect = 131;
    @observable conversationStatusFilterSelect = {
        id: -1,
        subId: -1
    }
    @observable conversationStatusSortSelect = data.conversationsSort[0].elId;
    @observable conversationInboxSelect = 0; // selected recruites array

    @observable conversationsListById = {};
    @observable currentConversationsListBuild = [];

    @observable conversationsSearchValue = '';
    @observable conversationItemSelect = 0;
    @observable lastMessagesByConversationId = {};
    @observable messagesByConversationId = {};
    @observable messagesToShow = [];
    @observable attachmentToShowUrl = null;
    @observable newMessageComponentDisplay = false;

    // CV Sending
    _sendCVInProcess = false;
    @observable sendCVEmailList = [];
    @observable sendCVEmailListSelected = [];
    @observable sendCVEmailCc = '';
    @observable sendCVEmailBcc = '';
    @observable sendCVEmailBody = EditorState.createEmpty();

    // Profile conversations
    profileOrCompanyMessages = [];
    @observable profileOrCompanyMessagesFilterActions = {
        userSelected: 'all_users',
        fromDate: null,
        toDate: null,
        text: ''
    };
    @observable profileOrCompanyMessagesFiltered = [];


    @observable messagesTemplateList = [];
    @observable messagesTemplateListFiltered = [];
    @observable messagesTemplateType = 0;

    // Navigation link counter and conversations update
    updatesObserverLoaded = false;
    updatesRequired = false;
    @observable totalNewMessagesCount = {
        total: 0,
        emails: 0,
        sms: 0,
        whatsapp: 0
    };

    @observable recruitersConversationsCount = {};

    inboxMode = {
        LIST: 'list',
        MAIN: 'main',
        PROFILE: 'profile'
    };

    @observable activeInboxMode = this.inboxMode.LIST;

    allConversationsLoaded = false;
    allConversationsLoadedByProvider = {
        1: false,
        2: false,
        3: false
    };
    timestampOfLastScrolledConversationByProvider = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0
    };

    timestampOfLastConversationByProvider = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0
    };

    conversationProviders = [
        { name: 'Email', id: 1, idStr: 'emails' },
        { name: 'SMS', id: 2, idStr: 'sms' },
        { name: 'Whatsapp', id: 3, idStr: 'whatsapp' },
        { name: 'Chat', id: 4, idStr: 'chat' },
        { name: 'Discussions', id: 5, idStr: 'discussions' }
    ];

    async sendMessage(data, isConversation = false) {
        const resp = await fetchService.authenticatedPost('/conversations-send', data);
        // let conversation = resp.data;
        //let conversation = null;
        let isError = false;
        const error = resp?.find?.(res => res.isError);
        if (error) {
            return resp;
        }
        // else {
        //     conversation = resp?.data?.[0];
        // }
        // if (Array.isArray(resp.data)) {
        //     if (!resp.data[0].error) {

        //     } else {
        //         isError = true;
        //     }
        // }

        //update user if signature changed
        let signatureBody;
        if (data && Array.isArray(data)) {
            signatureBody = data[0].signatureBody;
        }
        else {
            signatureBody = data.signatureBody;
        }
        if (signatureBody) {
            const tempUser = userStore.user;
            tempUser.signatureBody = signatureBody;
            const normalizedData = utilsService.normalizeApiData([tempUser]);
            userStore.users = utilsService.mergeApiData(normalizedData, userStore.users);
        }

        return resp.data;
    }

    sortConversationsList() {

        // sort only if last update
        if (this.conversationStatusSortSelect === 1) {
            const conversationsList = Object.values(this.conversationsListById);
            const sortedList = conversationsList.slice().sort((a, b) => b.updatedAt - a.updatedAt);
            this.conversationsListById = utilsService.normalizeApiData(sortedList, '_id');
        }
    }

    async updateConversationUpdatedAt(conversationId, timestamp, isSort = true) {
        if (conversationId && this.conversationsListById[conversationId]) {
            this.conversationsListById[conversationId].updatedAt = timestamp;
        }

        if (isSort) {
            this.sortConversationsList();
        }
        
        await this.buildConversations();
    }

    async getConversationsByAssignedId(allRecruiters = false, limit = 80) {
        const cTypes = messagingStore.conversationProviders.map(a => {
            return {
                id: a.id,
                timestamp: this.timestampOfLastConversationByProvider[a.id]
            };
        });

        const body = {
            limit: limit,
            cType: cTypes,
            id: allRecruiters ? null : this.getCurrentUserIdOfInbox(),
        };

        this.getRightStatus(body);

        this.updatesRequired = false;
        const resp = await fetchService.authenticatedPost(
            `/conversations`, body);
        this.updatesRequired = true;
        if (resp && resp.data && resp.data.data) {
            const data = resp.data.data;
            if (data && data.length < limit) {
                this.allConversationsLoaded = true;
            }

            if (data && data.length > 0) {
                this.setLastConversationTimestampWithReset(data);
                this.conversationsListById = utilsService.normalizeApiData(data, '_id');
            }
        }
    }

    async getConversationsMessagesByType(id, body, type) {
        let resp = [];
        if (type === 'profile') {
            body.userId = id;
            resp = await fetchService.authenticatedPost(
                `/conversations-profile`, body);
        }
        if (type === 'company') {
            body.companyId = id;
            body.type = 1
            resp = await fetchService.authenticatedPost(
                `/conversations-company`, body);
        }
        const data = resp.data.data;

        if (!data.error) {
            this.profileOrCompanyMessages = data.sort((x, y) => (x.pinned === y.pinned) ? 0 : x.pinned ? -1 : 1);
            this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessages;
        }
        return resp.data.data;
    }

    setLastConversationTimestamp(list, isByScroll) {
        if (isByScroll && list.length > 0) {
            const listLength = list.length;
            const conversationType = list[0].conversationType;
            this.timestampOfLastScrolledConversationByProvider[conversationType] = list[listLength - 1].updatedAt;

        } else {
            list.forEach(item => {
                const conversationType = item.conversationType;
                this.timestampOfLastConversationByProvider[conversationType] = item.updatedAt;
            });
        }
    }

    setLastConversationTimestampWithReset(list) {
        this.timestampOfLastScrolledConversationByProvider = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        };
        this.timestampOfLastConversationByProvider = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        };

        list.forEach(item => {
            const conversationType = item.conversationType;
            // Here we need to set LAST updated at of every type
            this.timestampOfLastScrolledConversationByProvider[conversationType] = item.updatedAt;

            // here we need to set FIRST, that's why we check if it not exists, if exists we don't need to update
            if (!this.timestampOfLastConversationByProvider[conversationType]) {
                this.timestampOfLastConversationByProvider[conversationType] = item.updatedAt;
            }
        });
    }

    @action.bound
    async getConversationsByDynamicFields(body) {
        const resp = await fetchService.authenticatedPost(`/conversations-query`, body);
        return resp.data;
    }

    @action.bound
    async getUnreadMessagesCount(body) {
        this.getRightStatus(body);
        const resp = await fetchService.authenticatedPost(`/conversations-count`, body);
        if (resp && resp.data && resp.data.data) {
            if (resp.data.data.totalLoggedUser == null) {
                resp.data.data.totalLoggedUser = this.totalNewMessagesCount.totalLoggedUser;
            }
            this.totalNewMessagesCount = resp.data.data;
            return resp.data.data;
        }
    }

    @action.bound
    async getRecruitersConversationsCount() {
        const resp = await fetchService.authenticatedGet('/conversations-count-recruiters');
        if (resp && resp.data && resp.data.data) {
            this.recruitersConversationsCount = resp.data.data;
            return resp.data.data;
        }
    }

    async getNewConversations(id) {
        const cTypes = messagingStore.conversationProviders.map(a => {
            return {
                id: a.id,
                timestamp: this.timestampOfLastConversationByProvider[a.id]
            };
        });

        const body = {
            limit: 80,
            cType: cTypes,
            id: id,
            readStatus: 1,
            isNew: true
        };

        this.getRightStatus(body);

        const resp = await fetchService.authenticatedPost(
            `/conversations`, body);
        let data = null;
        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
            data = resp.data.data;
            this.setLastConversationTimestamp(data, false);
            this.makeConversationsUpdate(data);
        }
        return data;
    }

    async getScrollingData() {
        const idOfUserForInbox = this.getCurrentUserIdOfInbox();
        const type = this.providersSelect;
        const timestamp = this.timestampOfLastScrolledConversationByProvider[type];

        const body = {
            id: idOfUserForInbox,
            limit: 80,
            timestamp: timestamp,
            cType: type
        };

        this.getRightStatus(body);

        if (!this.allConversationsLoaded) {
            const resp = await fetchService.authenticatedPost(
                `/conversations`, body);
            if (resp.data && resp.data.data.length > 0) {
                const data = resp.data.data;
                this.setLastConversationTimestamp(data, true);
                this.makeConversationsUpdate(data);
            } else {
                this.allConversationsLoadedByProvider[type] = true;
            }
        }
    }

    @action.bound
    makeConversationsUpdate(data, cType) {
        data.forEach(item => {
            this.conversationsListById[item._id] = item;
        });

        this.updateConversationUpdatedAt(null, null, false);
        if (this.conversationItemSelect) {
            this.setMessages(true);
        }
    }

    async getUpdates(id) {

        // we ask for new updates only if sort is last update
        if (this.conversationStatusSortSelect === 1 && this.updatesRequired) {
            const idOfUserForInbox = this.getCurrentUserIdOfInbox();
            const data = await this.getNewConversations(idOfUserForInbox);

            //if conversation changed, ask for counter
            if (data) {
                this.getUnreadMessagesCount({ id: id, idOfUserForInbox: idOfUserForInbox });
            }
        }
    }

    async getMessagesByConversationId(dontCheckExist = false) {
        const id = messagingStore.conversationItemSelect;
        if (!dontCheckExist && this.messagesByConversationId[id]) return;

        const resp = await fetchService.authenticatedGet(`/messages?conversationIds=${[id]}&last=0`);
        this.removeJunkFromMessages(resp.data.data, id);
        this.normalizeMessagesParents(resp.data.data, id);
    }

    async getMessagesByConversationIds(ids) {
        const resp = await fetchService.authenticatedGet(`/messages?conversationIds=${ids}&last=1`);
        resp.data && ids.forEach(id => {
            const relevantData = resp.data.data.find(item => id === item.conversationId);
            this.removeJunkFromMessages([relevantData], id);
            this.normalizeMessagesParents([relevantData], id);
        })
    }

    async conversationAction(data) {
        const resp = await fetchService.authenticatedPatch('/conversations-action', data);
        return resp.data;
    }

    async messageAction(data) {
        const resp = await fetchService.authenticatedPatch('/messages-action', data);
        return resp.data;
    }

    @action.bound
    selection(value, key) {
        this[key] = value;

        if (key === 'conversationItemSelect') {
            this.conversationItemSelectMagic();
        }

        if (key === 'providersSelect') {
            this.buildConversations();
        }
    }

    async MarkConversationAsRead() {

    }
    async conversationItemSelectMagic() {
        this.setMessages();
        const currentConversation = this.conversationsListById[this.conversationItemSelect];

        // if (currentConversation && currentConversation.targetParticipantProfileId) {
        //     await profileStore.getProfileById(currentConversation.targetParticipantProfileId, true);
        //     currentConversation.receiver = profileStore.profiles[currentConversation.targetParticipantProfileSlug];
        // }

        if (currentConversation && currentConversation.conversationType === 5) {
            await fetchService.authenticatedPost(`/mark-discussion-as-read`, { processSlug: currentConversation.metaData.processSlug });
        }

        if (!currentConversation || currentConversation.isRead) return;

        const conversationActionData = {
            actionType: 4,
            conversationId: this.conversationItemSelect
        };

        this.conversationAction(conversationActionData);
        currentConversation.readStatus = 2;
        this.buildConversations();
    }

    @action.bound
    async messageActionPerform(actionId, messageId) {
        const data = {
            actionType: actionId,
            messageId: messageId
        };

        const dataArr = this.profileOrCompanyMessages.map(mes => {
            if (mes._id === messageId) {
                if (mes.pinned) {
                    mes.pinned = false;
                    data.actionType = 2;
                } else {
                    mes.pinned = true;
                }
            }

            return mes;
        });
        this.profileOrCompanyMessages = dataArr.sort((x, y) => (x.pinned === y.pinned) ? 0 : x.pinned ? -1 : 1);
        this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessages;
        await messagingStore.messageAction(data);
    }

    @action.bound
    filterProfileOrCompanyMessages(filterType, data, dataType) {
        // userSelected: 'all_users',
        //     fromDate: null,
        //     toDate: null,
        //     text: ''

        this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessages;

        // PYTHON STYLE (tell me if you get the joke)
        if (filterType === 'userSelected') {
            this.profileOrCompanyMessagesFilterActions.userSelected = data.elId;
            if (data.elId !== 'all_users') {
                this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessagesFiltered.filter(mes => {
                    return mes.senderUserSlug === data.elId || mes.senderProfileSlug === data.elId;
                });
            }
        }

        if (filterType === 'date') {
            if (!Number.isNaN(data)) {
                this.profileOrCompanyMessagesFilterActions[dataType] = data;
                if (dataType === 'fromDate') {
                    this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessagesFiltered.filter(mes => {
                        return mes.createdAt >= data;
                    });
                }
                if (dataType === 'toDate') {
                    this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessagesFiltered.filter(mes => {
                        return mes.createdAt <= data;
                    });
                }
            } else {
                this.profileOrCompanyMessagesFilterActions[dataType] = null;
            }

        }

        if (filterType === 'text' && data) {
            this.profileOrCompanyMessagesFilterActions.text = data;
            this.profileOrCompanyMessagesFiltered = this.profileOrCompanyMessagesFiltered.filter(mes => {
                return mes.subject.toLowerCase().includes(data.toLowerCase());
            });
        }
    }

    @action.bound
    async conversationActionPerform(actionId, send_data, convoId) {
        const currentConversation = convoId ? this.conversationsListById[convoId] : this.conversationsListById[this.conversationItemSelect];
        if (!currentConversation) {
            return;
        }
        let makeRequest = true;
        const data = {
            actionType: actionId,
            conversationId: currentConversation._id,
            send_data: send_data
        };
        const selectedItem = convoId ? convoId : this.conversationItemSelect;

        switch (actionId) {
            // star
            case 1:
                data.actionType = currentConversation.isStared ? 5 : 1;
                currentConversation.isStared = !currentConversation.isStared;
                break;
            // Unread
            case 2:
                this.conversationsListById[this.conversationItemSelect].readStatus = 1;
                this.buildConversations();
                break;
            // Archive or unarchive
            case 3:
                if (currentConversation.status === 1) {
                    data.actionType = 3
                    this.moveConversationUIToArchive(convoId)
                }
                else {
                    data.actionType = 31
                    this.totalCountsHandle(currentConversation.conversationType, true);
                    this.totalRecruitersCountsHandle(currentConversation.assignedToUserIds[0], true)
                    if (convoId) {
                        if (this.conversationItemSelect === convoId) {
                            this.conversationItemSelect = 0;
                        }

                    } else {
                        this.conversationItemSelect = 0;
                    }

                    this.messagesByConversationId[selectedItem] = null;
                    delete this.conversationsListById[selectedItem];
                    this.buildConversations();
                }

                // move to inbox list when archive/unarchive on mobile
                this.activeInboxMode = this.inboxMode.LIST;
                break;
            // tag
            case 13:
                currentConversation.tag = send_data;
                break;
            // label
            case 14:
                currentConversation.label = send_data;
                currentConversation.tag = 1;
                this.activeInboxMode = this.inboxMode.LIST;
                break;
            // importance
            case 54:
                data.actionType = currentConversation.importance ? 55 : 54;
                currentConversation.importance = !currentConversation.importance;
                break;
            // Spam
            case 66:
                delete this.conversationsListById[selectedItem];
                this.buildConversations();
                break;
            // process attachment    
            case 560:
                currentConversation.metaData = send_data;
                break;

            // changed 10.1.20 - not used, 14.6.22 - startd using again, 13.10.22 - not use again
            case 9398:
                makeRequest = false;
                const receiver = await this.makeTalat(this.conversationItemSelect, null, send_data?.isAvailable);
                if (receiver) {
                    this.moveConversationUIToArchive(selectedItem)
                }
                break;

            default:
                break;
        }

        if (makeRequest) {
            await messagingStore.conversationAction(data);
        }
    }

    @action.bound
    async changeAssignee(slug) {
        const userToAssign = userStore.users[slug];
        const data = {
            conversationId: messagingStore.conversationItemSelect,
            userId: userToAssign._id,
            userSlug: userToAssign.slug
        };
        const oldAssignee = this.conversationsListById[messagingStore.conversationItemSelect]?.assignedToUserIds[0]
        const resp = await fetchService.authenticatedPatch(`/conversations-assignee`, data);
        const selectedItem = this.conversationItemSelect;
        this.conversationsListById[selectedItem] = resp.data.data;
        if (this.conversationInboxSelect !== []) {
            this.totalCountsHandle(this.conversationsListById[selectedItem]?.conversationType, false);
            this.totalRecruitersCountsHandle(userToAssign._id, true, oldAssignee)
            this.conversationItemSelect = 0;
            this.messagesByConversationId[selectedItem] = null;
            delete this.conversationsListById[selectedItem];
        }
        await this.buildConversations();
    }

    @action.bound
    async setMessages(dontCheckExist = false) {
        await this.getMessagesByConversationId(dontCheckExist);
        const conversation = this.conversationsListById[this.conversationItemSelect];
        if (conversation) {
            if (this.messagesByConversationId[this.conversationItemSelect]) {
                this.messagesToShow = this.messagesByConversationId[this.conversationItemSelect];
            }
        }
    }

    @action.bound
    async buildConversations() {
        const temp = [];
        const usersIdsSet = new Set();
        const profilesIdsSet = new Set();
        const companiesIdsSet = new Set();

        const conversationsList = Object.values(this.conversationsListById);

        conversationsList.forEach(conversation => {
            if (conversation.targetParticipantUserId) {
                usersIdsSet.add(conversation.targetParticipantUserId);
            }

            if (conversation.targetParticipantProfileId) {
                profilesIdsSet.add(conversation.targetParticipantProfileId);
            }

            if (conversation.assignedToUserIds && conversation.assignedToUserIds.length > 0) {
                conversation.assignedToUserIds.forEach(id => {
                    usersIdsSet.add(id);
                });
            }

            if (conversation.targetCompanyId) {
                companiesIdsSet.add(conversation.targetCompanyId);
            }

        });

        await Promise.all([
            // profileStore.getProfilesByIds(Array.from(profilesIdsSet), false),
            profileStore.searchProfiles(Array.from(profilesIdsSet)),
            userStore.getUsersByIds(Array.from(usersIdsSet)),
            companyStore.getCompaniesByIds(Array.from(companiesIdsSet))
        ]);

        conversationsList.forEach(conversation => {
            if (conversation.conversationType === this.providersSelect) {
                if (conversation.conversationType !== this.providersSelect) return;

                const { conversationsTags, conversationsLabels } = metaDataStore.categories;

                if (!conversation.tag) {
                    conversation.tag = conversationsTags[0];
                }

                if (!conversation.label) {
                    conversation.label = conversationsLabels[0];
                }

                conversation.date = dayjs(conversation.updatedAt).format('H:m');
                conversation.dateAgo = dayjs(conversation.updatedAt).format('MMM D YYYY, H:mm');
                conversation.isRead = conversation.readStatus === 2;

                if (conversation.targetParticipantUserId) {
                    const tempUser = userStore.users[conversation.targetParticipantUserSlug];
                    if (tempUser && tempUser.profile) {
                        conversation.receiver = tempUser.profile;
                    } else {
                        conversation.receiver = tempUser;
                    }
                } else if (conversation.targetParticipantProfileId) {
                    conversation.receiver = profileStore.profiles[conversation.targetParticipantProfileSlug];
                }
                else if (conversation.targetCompanyId) {
                    conversation.receiver = companyStore.companies[conversation.targetCompanySlug];
                }

                // last update response without filter, A-Z response with name
                if (!conversation.name) {
                    conversation.name = utilsService.getDisplayNameConversationScreen(conversation)
                }
                temp.push(conversation);
            }
        });

        this.currentConversationsListBuild = temp;
    }

    @action.bound
    normalizeMessagesParents(data, id) {
        if (data) {
            this.messagesByConversationId[id] = data;
        }
    }

    @action.bound
    async setConversationInboxSelect(userSlugs) {
        this.conversationItemSelect = 0;
        this.conversationInboxSelect = userSlugs;
        this.updatesRequired = true;
        this.conversationsListById = {};
        this.currentConversationsListBuild = [];
        this.allConversationsLoaded = false;
        this.timestampOfLastScrolledConversationByProvider = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        };
        this.timestampOfLastConversationByProvider = {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            5: 0
        };
        const idOfUserForInbox = this.getCurrentUserIdOfInbox();
        await Promise.all([
            this.getConversationsByAssignedId(),
            this.getUnreadMessagesCount({ id: userStore.user._id, idOfUserForInbox: idOfUserForInbox })
        ]);
        await this.buildConversations();
    }

    getReceiverType(id) {
        switch (id) {
            case 1:
                return 'Applicant';
            case 2:
                return 'Employer';
            case 3:
                return 'Recruiter';
            default:
                return '';
        }
    }

    @action.bound
    totalCountsHandle(conversationType, increase = true) {

        if (increase) {
            this.totalNewMessagesCount.total++;

            switch (conversationType) {
                case 1:
                    this.totalNewMessagesCount.emails++;
                    this.totalNewMessagesCount.totalLoggedUser++;
                    break;
                case 2:
                    this.totalNewMessagesCount.sms++;
                    this.totalNewMessagesCount.totalLoggedUser++;
                    break;
                case 3:
                    this.totalNewMessagesCount.whatsapp++;
                    this.totalNewMessagesCount.totalLoggedUser++;
                    break;

                default:
                    break;
            }
        } else {
            this.totalNewMessagesCount.total--;

            switch (conversationType) {
                case 1:
                    this.totalNewMessagesCount.emails--;
                    this.totalNewMessagesCount.totalLoggedUser--;
                    break;
                case 2:
                    this.totalNewMessagesCount.sms--;
                    this.totalNewMessagesCount.totalLoggedUser--;
                    break;
                case 3:
                    this.totalNewMessagesCount.whatsapp--;
                    this.totalNewMessagesCount.totalLoggedUser--;
                    break;

                default:
                    break;
            }
        }
    }

    @action.bound
    totalRecruitersCountsHandle(recruiterId, increase = true, oldRecruiterId) {
        if (increase) {
            if (!this.recruitersConversationsCount[recruiterId]) {
                this.recruitersConversationsCount[recruiterId] = 0;
            }
            this.recruitersConversationsCount[recruiterId]++;

            if (this.recruitersConversationsCount[oldRecruiterId] === 1) {
                this.recruitersConversationsCount[oldRecruiterId] = null;
            }
            else {
                this.recruitersConversationsCount[oldRecruiterId]--
            }

        }
        else {
            if (this.recruitersConversationsCount[recruiterId] === 1) {
                this.recruitersConversationsCount[recruiterId] = null;
            }
            else {
                this.recruitersConversationsCount[recruiterId]--;
            }
        }
    }


    @action.bound
    async getAttachment(id) {
        const resp = await fetchService.authenticatedGet(`/messages-attachment/${Base64.encode(id)}`);
        if (resp.data && resp.data[0]) {
            this.attachmentToShowUrl = resp.data[0];
        } else {
            this.attachmentToShowUrl = null;
        }
    }

    setLastTimestamps(conversation) {
        this.timestampOfLastScrolledConversationByProvider[conversation.conversationType] = conversation.updatedAt;
    }

    @action.bound
    fillCvEmailsList() {
        const jobs = searchStore.jobsSearchTo;
        const result = [];
        jobs.forEach(element => {
            const job = element;
            if (job.emailsForCv && job.emailsForCv.length > 0) {
                job.emailsForCv.forEach(email => {
                    result.push({
                        elId: email,
                        value: email
                    });
                });
            }
        });

        this.sendCVEmailList = result;
        this.sendCVEmailListSelected = result;
    }

    async sendCvs(data) {
        const response = await fetchService.authenticatedPost('/message/cvs', data);
        const processArray = [];

        if (response && response?.find?.(res => res.isError)) {
            return response;
        }

        response && response.data.forEach(item => {
            const data = item && item.data;
            if (data && data.process) {
                processArray.push(data.process);
            }
        });

        const normalizedData = utilsService.normalizeApiData(processArray);
        utilsService.mergeApiData(normalizedData, processStore.processes);
        utilsService.mergeApiData(normalizedData, processStore.allProcesses);
        return response && response.data;
    }

    @action.bound
    async createMessagingTemplate(data) {
        const resp = await fetchService.authenticatedPost(`/messages-templates`, data);
        this.messagesTemplateList.unshift(resp.data);
        this.filterMessagingTemplates(this.messagesTemplateType);
        return resp.data;
    }

    @action.bound
    async updateMessagingTemplate(data) {
        const resp = await fetchService.authenticatedPost(`/messages-templates`, data);
        if (resp.data) {
            let currentItem = this.messagesTemplateList.find(item => item._id === resp.data._id);
            Object.assign(currentItem, resp.data);
        }
        this.filterMessagingTemplates(this.messagesTemplateType);
        return resp.data;
    }



    @action.bound
    async getMessagingTemplates(body) {
        if (this.messagesTemplateList.length === 0) {
            const resp = await fetchService.authenticatedPost(`/messages-templates-query`, body);
            resp?.data?.forEach(ee => {

                // email
                if (ee.templateType === 1) {
                    ee.body = ee.body.split('\n').join('</br>');
                }
            });
            const filtered = resp?.data?.filter(ee => !ee.templateCategories || (ee.templateCategories && ee.templateCategories.length === 0));
            this.messagesTemplateList = filtered;
            this.messagesTemplateListFiltered = filtered;
        }
    }

    @action.bound
    async getMessagingTemplatesByFilters(body) {
        const resp = await fetchService.authenticatedPost(`/messages-templates-by-filters`, body);
        resp.data.forEach(ee => {
            
            // email
            if (ee.templateType === 1) {
                ee.body = ee.body.split('\n').join('</br>');
            }
        });
        const filtered = resp.data.filter(ee => !ee.templateCategories || (ee.templateCategories && ee.templateCategories.length === 0));
        this.messagesTemplateList = filtered;
        this.messagesTemplateListFiltered = filtered;
    }

    @action.bound
    async deleteMessagingTemplate(id) {
        const response = await fetchService.authenticatedDelete(`/messages-templates/${id}`);
        this.messagesTemplateList = this.messagesTemplateList.filter(q => q._id !== id);
        this.messagesTemplateListFiltered = this.messagesTemplateListFiltered.filter(q => q._id !== id);
        return response.data
    }

    @action.bound
    filterMessagingTemplates(type) {
        if (type) {
            this.messagesTemplateListFiltered = this.messagesTemplateList.filter(q => q.templateType === type);
        } else {
            this.messagesTemplateListFiltered = this.messagesTemplateList;
        }
    }

    @action.bound
    async moveConversationUIToArchive(conversationId) {
        if (!conversationId) {
            conversationId = this.conversationItemSelect;
        }
        this.totalCountsHandle(this.conversationsListById[conversationId]?.conversationType, false);
        this.totalRecruitersCountsHandle(this.conversationsListById[conversationId]?.assignedToUserIds[0], false)
        delete this.conversationsListById[conversationId];
        this.messagesByConversationId[conversationId] = null;
        this.conversationItemSelect = 0;
        await this.buildConversations();
    }

    getCurrentUserIdOfInbox() {
        let res = null;
        if (this.conversationInboxSelect === 0) {
            res = userStore.user._id;
        } else if (this.conversationInboxSelect) {
            if (this.conversationInboxSelect.length > 0) {
                res = [];
                this.conversationInboxSelect.forEach(slug => {
                    const user = userStore.users[slug];
                    res = [...res, user._id];
                });
            }
            else {
                res = null;
            }
        }
        return res;
    }

    // merge send CV and add recipient
    mergeEmailListSelected = (job) => {
        const sendCVEmailListSelected = messagingStore.sendCVEmailListSelected;
        let filteredSendCVEmailList = sendCVEmailListSelected.filter(item => job.emailsForCv.includes(item.value));
        filteredSendCVEmailList = filteredSendCVEmailList.map(item => item.value.toLowerCase());

        const jobsSearchTo = searchStore.jobsSearchTo;
        let jobItem = jobsSearchTo.filter(item => item.slug === job.slug);
        const companiesUsersSearchPersonsTo = searchStore.companiesUsersSearchPersonsTo;
        const filteredCompanyUsersSearchList = companiesUsersSearchPersonsTo.filter(item => jobItem[0].companyUsers && jobItem[0].companyUsers.includes(item._id));
        const filteredCompanyUsersEmailList = filteredCompanyUsersSearchList.map(item => item.email);
        const companyEmails = companiesUsersSearchPersonsTo.filter(item => item.companyEmail).map(item => item.companyEmail);
        return [...filteredSendCVEmailList, ...filteredCompanyUsersEmailList, ...companyEmails];
    };

    findTargetParticipant = async (job) => {
        const jobsSearchTo = searchStore.jobsSearchTo;
        let jobItem = jobsSearchTo.filter(item => item.slug === job.slug);
        const companiesUsersSearchPersonsTo = searchStore.companiesUsersSearchPersonsTo;
        const filteredCompanyUsersSearchList = companiesUsersSearchPersonsTo.filter(item => jobItem[0].companyUsers.includes(item._id));
        const filteredCompanyProfilesSearchList = filteredCompanyUsersSearchList.map(item => item.profile).filter(item => item);
        if (filteredCompanyProfilesSearchList && filteredCompanyProfilesSearchList.length > 0) {
            return filteredCompanyProfilesSearchList[0];
        }
        else {
            const sendCVEmailListSelected = messagingStore.sendCVEmailListSelected;
            let filteredSendCVEmailList = sendCVEmailListSelected.filter(item => job.emailsForCv.includes(item.value));
            filteredSendCVEmailList = filteredSendCVEmailList.map(item => item.value.toLowerCase());
            const profileEmails = await profileStore.getProfileEmails(filteredSendCVEmailList);
            if (profileEmails && profileEmails.length > 0) {
                return profileEmails[0];
            }
            else {
                return null;
            }
        }
    };

    getRightStatus(body) {
        const statusData = this.conversationStatusSelect;
        const statusFilterData = this.conversationStatusFilterSelect;
        const searchValue = this.conversationsSearchValue;
        switch (statusData) {

            case 130:
                body.status = null;
                body.label = null;
                break;

            // all
            case 131:
                body.status = 1;
                body.label = null;
                break;
            // job
            case 11:
                body.status = 1;
                body.label = 3;
                break;
            // profile
            case 12:
                body.status = 1;
                body.label = 1;
                break;
            // archived
            case 6:
                body.status = 2;
                break;

            default:
                body.status = 1;
                body.label = null;
                break;
        }

        switch (statusFilterData.id) {

            // stared
            case 22:
                body.isStared = true;
                break;

            // unread
            case 2:
                body.readStatus = 1;
                break;

            // attached files
            case 7657:
                body.hasAttachments = true;
                break;

            case 5555:
                switch (statusFilterData.subId) {

                    // source - ethosia
                    case 6001:
                        body.source = 1;
                        break;

                    // source - linkedIn   
                    case 6002:
                        body.source = 2;
                        break;

                    // source - AllJobs       
                    case 6003:
                        body.source = 3;
                        break;

                    // source - Runner  
                    case 6004:
                        body.source = 4;
                        break;

                    // source - Comeet      
                    case 6005:
                        body.source = 444;
                        break;

                    // source - web       
                    case 6006:
                        body.source = 5;
                        break;

                    // source - jobsnet       
                    case 6007:
                        body.source = 6;
                        break;

                    // source - employer       
                    case 6008:
                        body.source = 7;
                        break;

                    // source - brightsource       
                    case 6009:
                        body.source = 8;
                        break;

                    // source - drushim       
                    case 6010:
                        body.source = 9;
                        break;

                    default:
                        break;
                }

                break;

            case 5556:
                switch (statusFilterData.subId) {

                    // tag - Untitled
                    case 7001:
                        body.tag = 1;
                        break;

                    // tag - Applicant   
                    case 7002:
                        body.tag = 101;
                        break;

                    // tag - Candidate     
                    case 7003:
                        body.tag = 102;
                        break;

                    // tag - Request for details
                    case 7004:
                        body.tag = 201;
                        break;

                    // tag - Upload CV  
                    case 7005:
                        body.tag = 303;
                        break;

                    // tag - Sent CV     
                    case 7006:
                        // body.tag = 304;  
                        body.specialActions = [12];
                        break;

                    // tag - Exposed    
                    case 7007:
                        // body.tag = 6;  
                        body.specialActions = [34];
                        break;

                    // tag - Chat    
                    case 7008:
                        body.tag = 30;
                        break;

                    default:
                        break;
                }
                break;

            case 6666:
                body.jobCategoryId = statusFilterData.subId;
                break;

            default:
                break;
        }

        if (searchValue) {
            body.readStatus = null;
            body.isStared = null;
            body.status = null
            body.searchValue = searchValue;
        }

        body.filter = this.conversationStatusSortSelect

        // we send lastId in order to know if sort is A-Z
        // for example last name result was yosi cohen, then next chunk will start from 
        // yosi cohen index + 1
        if (this.conversationStatusSortSelect === 2) {
            const type = this.providersSelect;
            const arrayByType = Object.values(this.conversationsListById).filter(item => item.conversationType === type);
            if (arrayByType.length > 0) {
                body.lastId = arrayByType[arrayByType.length - 1]._id;
            }
        }


    }

    removeJunkFromMessages(data, id) {
        const conversation = this.conversationsListById[id];
        if (conversation) {
            const source = conversation.source;
            if (source && source.elId === 4) {
                data.forEach(mes => {
                    mes.attachments = mes.attachments.filter(dd => dd.mimeType !== 'image/png');
                });
            }
        }
    }

    async makeTalat(conversationId, profileSlug, isAvailable) {
        let conversation = null;
        let receiver = null;
        let profileId = null;

        if (conversationId) {
            conversation = this.conversationsListById[conversationId];
            receiver = profileStore.profiles[conversation.targetParticipantProfileSlug];
        } else if (profileSlug) {
            receiver = profileStore.profiles[profileSlug];
        }
        if (receiver) {
            await this.getMessagingTemplates({ field: 'userId', value: userStore.user._id });
            profileId = receiver._id;
            const sender = userStore.user;
            const template = this.messagesTemplateList.find(pw => pw.name === 'תל"ת');
            const textMessage = template.body;
            const messageData = {
                messageBody: stringTokenReplacerService.messageStringTokensReplacer(textMessage, receiver, sender, template),
                messageRawBody: stringTokenReplacerService.messageStringTokensReplacer(textMessage, receiver, sender, template),
                targetParticipantProfileId: receiver ? receiver._id : null,
                targetParticipantProfileSlug: receiver ? receiver.slug : null,
                targetParticipantUserId: null,
                targetParticipantUserSlug: null,
                senderUserId: sender._id,
                senderUserSlug: sender.slug,
                senderUserEmail: sender ? sender.email : null,
                subject: template.subject,
                firstName: receiver.firstName,
                lastName: receiver.lastName,
                action: 'to_talent_send_message',
                notificationType: 'mail',
                type: 'recruiterMail',
                conversationType: 1,
                targetParticipantType: 1,
                senderType: sender.userType,
                conversationId: conversationId
            };
            const response = await fetchService.authenticatedPost(`/conversations-talat`, {
                conversationId,
                message: messageData,
                profileId: profileId,
                isAvailable: isAvailable
            });

            if (response && response.data) {
                profileStore.updateLocalProfile(response.data.slug, response.data);
            }
        }

        return receiver
    }

    handleReplayMessage = async () => {
        this.timestampOfLastConversationByProvider[1] = 0;
        await this.getConversationsByAssignedId()
        this.buildConversations();
        await this.setMessages(true);
    }

}

const messagingStore = new MessagingStore();

export default messagingStore;
