import {action, observable} from 'mobx';
import appSettings from '../constants/appSettings';
import fetchService from '../services/fetch.service';
import {searchSettingsApi} from "../entities/settings/model/search-settings-api";
import notificationsStore from "./notifications.store";

class SettingsStore {
    @observable appSettings = {};
    @observable redirectRoute = null;
    @observable remoteConfig = {};
    @observable searchSettings = {
        @observable
        filters: {
            type: null,
        },
        @observable
        items: [],
        @observable
        isLoading: false,
        @observable
        isSubmitSearch: false,
        isSubmitting: false,
    };

    constructor() {
        this.getAppSettings();
    }

    @action.bound
    setAppSettings(key, value) {
        const localAppSettings = localStorage.getItem('appSettings');
        if (localAppSettings) {
            this.appSettings = JSON.parse(localAppSettings);
        } else {
            this.appSettings = appSettings;
        }

        this.appSettings[key] = value;
        this.appSettings = { ...this.appSettings };
        localStorage.setItem('appSettings', JSON.stringify(this.appSettings));
    }

    getAppSettings() {
        const localAppSettings = localStorage.getItem('appSettings');
        if (localAppSettings) {
            this.appSettings = JSON.parse(localAppSettings);
        } else {
            this.appSettings = appSettings;
        }
    }

    setRedirectRoute(route) {
        if (route === '' || route === '/login') {
            this.redirectRoute = '/inbox';
        } else {
            this.redirectRoute = route;
        }
    }

    async getRemoteConfig() {
        const response = await fetchService.authenticatedGet('/config');
        this.remoteConfig = response.data;
    }

    getFeature(feature) {
        let res = false;
        const featureObj = this.remoteConfig.parameters[feature];

        if (featureObj && featureObj.defaultValue) {
            res = featureObj.defaultValue.value;
        }
        return res;
    }

    @action.bound
    async getSearchSettings() {
        this.searchSettings.isLoading = true;
        const { filters } = this.searchSettings;
        const queries = {
            type: filters.type?.value,
            recruiterName: filters.recruiterName,
            updatedAt: filters.date
        };

        const { data } = await searchSettingsApi.getMany(queries);

        this.searchSettings.items = data?.data ?? [];
        this.searchSettings.isLoading = false;
        this.searchSettings.isSubmitSearch = false;
        return data;
    }

    @action.bound
    async saveSetting(setting) {
        this.searchSettings.isSubmitting = true;
        const { data } = await searchSettingsApi.createOne(setting);
        this.searchSettings.isSubmitting = false;

        if (data.error) {
            await notificationsStore.showToast('Save search settings failed. Please contact admin', 'failure');
            return;
        }

        await notificationsStore.showToast('Save search settings successfully', 'success');
    }

    @action.bound
    updateFilterSearchSettingFilter(key, item) {
        this.searchSettings.filters[key] = item;
        this.searchSettings.isSubmitSearch = true;
    }

    @action.bound
    async deleteSearchSettingById(id) {
        const { data } = await searchSettingsApi.deleteOne(id);

        if (403 === data.statusCode) {
            await notificationsStore.showToast('You are not author to remove this bill', 'failure');
            return;
        }

        if (data.error) {
            await notificationsStore.showToast('System is getting trouble', 'failure');
            return;
        }

        await notificationsStore.showToast('Delete successfully', 'success');
    }

    pushEventSearchSettings({ id, event, body }) {
        return searchSettingsApi.pushEvent(id, { ...event, body });
    }
}

const settingsStore = new SettingsStore();

export default settingsStore;
