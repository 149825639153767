import React, {Component} from 'react';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';
import data from '../../../constants/data';
import {CloseIcon} from '../svg';

class PauseProcessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            options: new Set()
        };
    }

    selectOptions = (e, option) => {
        const curOptions = this.state.options;

        if (curOptions.has(option)) {
            curOptions.delete(option);
        } else {
            curOptions.add(option);
        }

        this.setState({ options: curOptions });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ updating: true });
        const id = this.props.data.process._id;
        const body = {
            status: 6,
            profileId: this.props.data.process.profileId,
            comment: Array.from(this.state.options).join(', '),
            eventType: 'Hold',
            eventTypeId: 6,
            decisionMakerEmail: userStore.user.email,
            decisionMakerName: userStore.user.displayName
        };

        await processStore.changeStatus(id, body);
        this.setState({ updating: false });
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.successEvent();
        }
    };

    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    render() {
        return (
            <div className='action-process pause-process'>
                <header>
                    <h2> Hold
                        <span
                            className='capitalized'> {this.props.data.profile.firstName} {this.props.data.profile.lastName} </span>
                        process?
                    </h2>
                    {this.props.isModal && (<div onClick={this.handleCloseClick}
                                                 className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>)}
                    <h3>
                        Tell us why you decided to hold
                    </h3>
                </header>
                <section className="action-process-body">
                    {data.pauseProcess_options.map((item, idx) => {
                        return (
                            <label key={idx} className='customCheckbox-label'>
                                   <span className="customCheckbox with-text">
                                    <input
                                        onChange={(e) => {
                                            this.selectOptions(e, item);
                                        }}
                                        type='checkbox'/>
                                </span> {item}
                            </label>
                        );
                    })}
                </section>

                <div>
                    <button
                        onClick={this.handleCloseClick}
                        className='button gray'>
                        Cancel
                    </button>
                    <button
                        onClick={this.handleSubmit}
                        className={`button blue ${this.state.updating
                            ? 'loading'
                            : ''}`}>
                        Hold
                    </button>
                </div>
            </div>
        );
    }
}

export default PauseProcessComponent;
