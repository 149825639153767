import React from 'react';
import './operatorMessageNavigate.scss';

const OperatorMessageNavigate = (props) => {

    return (
        <div className={'operator-message-navigate-wrapper'}>
            <label> Include: </label>
            <button onClick={props.OperatorMessageNavigateClick}>
                {props.textOperator}
            </button>
        </div>
    )
};

export default OperatorMessageNavigate;
