import React, {Component} from 'react';
import ModalComponent from '../modal/Modal.component';
import {doPasswordUpdate, doSignInWithEmailAndPassword} from '../../../services/auth.service';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';

class ChangePasswordModal extends Component {

    handleSubmit = () => {
        const {
            oldPassword,
            password2,
            password
        } = this.state;

        const validatePass = utilsService.validatePasswords(password, password2);

        if (validatePass.valid && oldPassword) {
            this.setState({ updating: true });
            doSignInWithEmailAndPassword(userStore.user.email, oldPassword)
                .then(resp => {
                    this.doChangePassword(password);
                    console.log('bresp', resp);
                }).catch(error => {
                this.setState({ message: error.message, updating: false });
                console.log('errorb', error);
            });
        } else {
            this.setState({ message: validatePass.message });
        }
    };

    doChangePassword = (password) => {
        doPasswordUpdate(password)
            .then(() => {
                this.setState({ updating: false });
                this.props.closeEvent();
            })
            .catch(err => {
                this.setState({ message: err.message, updating: false });
                console.log('b', err);
            });
    };

    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            password: '',
            password2: '',
            updating: false,
            message: ''

        };
    }

    render() {
        return (
            <ModalComponent onClose={() => this.props.closeEvent()}>
                <div className='modal-box change-password-modal'>
                    <header>
                        <h2>Change Password</h2>
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <i className="fas fa-times-circle"/>
                        </div>
                    </header>

                    <section className="modal-body form">
                        <p className='error-message'>{this.state.message}</p>
                        <form>
                            <div className="form-item">
                                <label>Old Password</label>
                                <input
                                    value={this.state.oldPassword}
                                    type="password"
                                    onChange={(e) => this.setState({ oldPassword: e.target.value })}/>
                            </div>
                            <div className="form-item">
                                <label>New Password</label>
                                <input
                                    value={this.state.password}
                                    type="password"
                                    onChange={(e) => this.setState({ password: e.target.value })}/>
                            </div>
                            <div className="form-item">
                                <label>Confirm New Password</label>
                                <input
                                    value={this.state.password2}
                                    type="password"
                                    onChange={(e) => this.setState({ password2: e.target.value })}/>
                            </div>


                        </form>
                    </section>
                    <footer>
                        <button onClick={() => this.props.closeEvent()}
                                className='button gray min140'>
                            Cancel
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className={`button blue ${this.state.updating ? 'loading' : ''}`}
                        >
                            Change Password
                        </button>
                    </footer>
                </div>
            </ModalComponent>
        );
    }
}

export default ChangePasswordModal;
