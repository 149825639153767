import React from 'react';
import utilsService from '../../../services/utils.service';
import ModalComponent from "../modal/Modal.component";
import {CloseIcon} from '../svg';


const EmailMessageModal = props => {

    const { profiles } = props;


    const handleSubmit = async (e) => {
        props.closeEvent();

    };

    const getNames = () => {
        const filteredNames = profiles.filter(person => 
            (!person.firstNameHeb || person.firstNameHeb.length === 0) ||
            (!person.lastNameHeb || person.lastNameHeb.length === 0))
        const names = filteredNames.map(item => utilsService.getDisplayName(item));
        return names.join("\r\n");
    }
    

    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box  email-message'>
                <div className='action-process'>
                    <header>
                        <h2>{'Missing first name or last name in hebrew for:'} <br/> <br/> {getNames()}</h2>
                        <div onClick={() => props.closeEvent()}
                            className='circle-close modal-close'>
                            <CloseIcon />
                        </div>
                    </header>
                    <footer>
                        <button
                            onClick={(e) => handleSubmit(e)}
                            className={`button black handler`}>
                            Ok
                        </button>
                    </footer>
                </div>
            </div>
        </ModalComponent>
    );
};

export default EmailMessageModal;
