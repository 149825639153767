import React, { Component } from 'react';
import './search.scss';
import processStore from '../../../stores/process.store';
import { SearchCompanyIcon, SearchIcon3, SearchIconNew, SearchJobIcon, SearchRecruiterIcon } from '../svg';
import { Link, withRouter } from 'react-router-dom';
import searchStore from '../../../stores/search.store';
import RecruiterSearchResultsComponent from './RecruiterSearchResults.component';
import { observer } from 'mobx-react';
import utilsService from '../../../services/utils.service';
import { Button, Icon, Menu, MenuItem } from '@blueprintjs/core';
import { Classes, Popover2 } from "@blueprintjs/popover2"
import onClickOutside from "react-onclickoutside";


const SEARCH_TYPE = {
    ALL: '',
    JOB: 'jobs',
    COMPANY: 'companies',
    PROFILE: 'profiles',
}

@observer
class RecruiterSearchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: '',
            activeFocus: -1,
            available: false,
            type: null,
            valueAfterSubstring: "",
            searchType: SEARCH_TYPE.ALL,
            isSearching: false,
            isOpen: false,
        };
        this._mounted = false;
        this.input = React.createRef();
        this._searching = null;
        this.typingTimeout = 0;
    }

    searchHandle = async e => {
        const searchValue = e.target.value;
        this.setState({ searchValue: searchValue, activeFocus: -1 });

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(async () => {
            const firstChar = searchValue.charAt(0);
            let type = 'all';

            if (searchValue.length > 1 && !this._searching) {
                this._searching = true;
                let newValue = searchValue;
                if (this.state.searchType === SEARCH_TYPE.PROFILE || ( this.state.searchType ===  SEARCH_TYPE.ALL && firstChar === '$')) {
                    if (firstChar === '$') {
                        newValue = searchValue.substr(1);
                    }
                    type = 'profile';
                }
                else if (this.state.searchType === SEARCH_TYPE.JOB || ( this.state.searchType ===  SEARCH_TYPE.ALL && firstChar === '#')) {
                    if (firstChar === '#') {
                        newValue = searchValue.substr(1);
                    }
                    type = 'job';
                }
                else if (this.state.searchType === SEARCH_TYPE.COMPANY ||( this.state.searchType ===  SEARCH_TYPE.ALL && firstChar === '@')) {
                    if (firstChar === '@') {
                        newValue = searchValue.substr(1);
                    }
                    type = 'company';
                }
                else if (firstChar === '^') {
                    type = 'free-text';
                    newValue = searchValue.substr(1);
                }

                this.setState({ isSearching: true });
                await searchStore.recruiterFromNavigationSearch(type, newValue);

                this.setState({ isSearching: false });
                this._searching = false;
            }
            else {
                searchStore.initRecruiterFromNavigationSearch();
            }
        }, 300);
    };

    onArrowClick = async (e) => {
        const { activeFocus, searchValue } = this.state;
        const { history } = this.props
        // ENTER
        if (e.keyCode === 13) {

            if(searchValue.length > 1) {
                searchStore.searched = true;
                const firstChar = searchValue.charAt(0);
                let newValue = searchValue;

                // profile by default
                let advancedTopType = 0;
                let type = 'profile';
                let pathName = '/search-results2'

                if(utilsService.validateEmail(searchValue)) {
                    type = 'email';
                    advancedTopType = 3;
                }

                else if (this.state.searchType === SEARCH_TYPE.JOB) {
                    advancedTopType = 1;
                    type = 'job';
                }

                else if (this.state.searchType === SEARCH_TYPE.COMPANY) {
                    advancedTopType = 2;
                    type = 'company';
                    pathName = '/search-advanced-results';
                }
                
                else if (firstChar === '^') {
                    newValue = searchValue.substr(1);
                    type = 'free-text';
                }

                searchStore.changeAdvancedTop(advancedTopType);

                const activeSearchTypes = [0,1,2]

                if (activeSearchTypes.includes(advancedTopType)) {
                    const key = advancedTopType === 0 ? 'value' : 'name';
                    const url = `/search?searchType=${advancedTopType}&${key}=${newValue}`;
                    history.push(url)
                } else {
                    history.push({
                        pathname: pathName,
                        state: {
                            type: type,
                            value: newValue,
                            screen: 'top-search'
                        }
                    });
                }

                this.setState({searchValue: ''});
            }
        }

        // Arrow UP
        if (e.keyCode === 38) {
            const caret = e.target.selectionStart;
            const element = e.target;

            window.requestAnimationFrame(() => {
                element.selectionStart = caret;
                element.selectionEnd = caret;
            });
            if (activeFocus !== 0 && activeFocus !== -1) {
                this.setState({ activeFocus: activeFocus - 1 });
            }
        }

        // Arrow DOWN
        if (e.keyCode === 40) {
            if (processStore.searchResults.length - 1 > activeFocus) {
                this.setState({ activeFocus: activeFocus + 1 });
            }
        }

        if (e.keyCode === 27) {
            this.setState({ isOpen: false });
            this.input.current.blur();
        }
    };

    handleClickOutside = evt => {
        this.setState({ isOpen: false });
    };

    onSearchIconClick = () => {
        this.setState({
            available: true,
            showResults: true,
            isOpen: true
        }, () => {
            this.input.current.focus();
        });
    };

    componentDidMount() {
        this._mounted = true;
        this._searching = false;
    }

    componentWillUnmount() {
        this._mounted = false;
        clearTimeout(this.typingTimeout);
    }

    hideSearch = () => {
        const { searchValue } = this.state;
        setTimeout(() => {
            if (this._mounted) {
                if (!searchValue) {
                    this.setState({ available: false, showResults: false });
                } else {
                    this.setState({ showResults: false });
                }
            }
        }, 200);
    };

    onResultClick = () => {
        this.setState({
            searchValue: ''
        });
        this.props.onClose()
    };

    render() {
        const { activeFocus, searchValue, available, showResults } = this.state;

        const searchMenu = <Menu className={Classes.POPOVER2_DISMISS}>
            <MenuItem text='Profiles' label='Search for talents' icon={<SearchRecruiterIcon/>} onClick={() => this.setState({ searchType: SEARCH_TYPE.PROFILE })} />
            <MenuItem text='Jobs' label='Search for jobs' icon={<SearchJobIcon/>} onClick={() => this.setState({ searchType: SEARCH_TYPE.JOB })} />
            <MenuItem text='Companies' label='Search for companies' icon={<SearchCompanyIcon/>} onClick={() => this.setState({ searchType: SEARCH_TYPE.COMPANY })} />
            <MenuItem text='Search All' className='search-all' onClick={() => this.setState({ searchType: SEARCH_TYPE.ALL })} />
        </Menu>

        const searchTypeIcon = () => {
            if (this.state.searchType === SEARCH_TYPE.JOB) return <SearchJobIcon/>
            if (this.state.searchType === SEARCH_TYPE.COMPANY) return <SearchCompanyIcon/>
            if (this.state.searchType === SEARCH_TYPE.PROFILE) return <SearchRecruiterIcon/>

            return null
        }

        return (
            <div className='search recruiter'>
                <div
                    onClick={this.onSearchIconClick}
                    className={`search-area available ${!this.state.searchType ? 'search-all' : ''}`}>
                    {/* <SearchIcon3 /> */} 

                    <Popover2
                        className='search-type-dropdown'
                        popoverClassName='search-type-popover'
                        interactionKind={window.innerWidth < 768 ? 'click' : 'hover'}
                        placement= "bottom-start"
                        content={searchMenu}
                        modifiers={{
                            arrow: { enabled: false }
                        }}
                        renderTarget={({ isOpen, ref, ...props }) => (
                            <Button 
                                {...props} 
                                elementRef={ref} 
                                minimal 
                                icon='chevron-down' 
                                rightIcon={!this.state.isSearching && searchTypeIcon()} 
                                loading={this.state.isSearching}
                            />
                        )}
                    />

                    <input
                        // onBlur={this.hideSearch}
                        value={searchValue}
                        onChange={this.searchHandle}
                        onKeyDown={this.onArrowClick}
                        type="text"
                        className='border-radius'
                        placeholder={'Search ' + this.state.searchType}
                        ref={this.input}
                    />
                    <Link to="/search" className='button white oval'>
                        <span>Advanced</span>
                        <Icon icon="chevron-right" className='advanced-search-arrow'/>
                    </Link>
                </div>

                {this.state.isOpen && this.state.searchValue.length > 1 &&
                    <RecruiterSearchResultsComponent
                        data={searchStore.navigationBarRecruiterSearchResults}
                        clicked={this.onResultClick}
                        active={activeFocus}
                    />
                }
            </div>
        );
    }
}

export default withRouter(onClickOutside(RecruiterSearchComponent));
