import React, {Component} from 'react';
import ModalComponent from "../modal/Modal.component";
import {CloseIcon} from '../svg';
import jobsBoardsStore from '../../../stores/jobsBoards.store';
import utilsService from '../../../services/utils.service';
import { RadioGroup } from '@brightsource/brightsource-ui-lib';

class ShareModal extends Component {
    copyToClipboard = (e) => {
        e.preventDefault();
        this.input.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({copySuccess: 'Copied!'});
    };

    constructor(props) {
        super(props);
        this.state = {
            copySuccess: 'Copy to Clipboard',
            loading: false,
            typeOfPath: 'ethosia'
        }
    }

    async componentDidMount() {
        const {shareType, jobId} = this.props;

        if(shareType === "job") {
            this.setState({ loading: true });
            await jobsBoardsStore.getPublishedJob(jobId);
            this.setState({ loading: false });
        }
    }

    buildTypeOfPath() {
        const {shareType} = this.props;
        if (shareType == 'job') {
            return (
                <div>
                    <RadioGroup
                        name=''
                        items={[{label: "Ethosia URL", value: "ethosia"}, {label: "Brightsource URL", value: "brightsource"}]}
                        value={this.state.typeOfPath}
                        onChange={(event, value) => {
                            this.setState({typeOfPath: value})
                            this.setState({copySuccess: 'Copy to Clipboard'});
                        }}
                    />
                </div>
            )
        }
    }

    buildPath() {
        const {shareType, slug, processSlug, screenType, tabNumber, conversationId, claimToken} = this.props;
        if(shareType === 'profile') {
            return window.location.origin + '/profile/' + slug;
        }
        else if(shareType === 'job') {
            if (this.state.loading) return 'Loading...';

            const publishedJob = jobsBoardsStore.publishedJob;
            if (this.state.typeOfPath == 'ethosia') {
                if(publishedJob.link) {
                    return publishedJob.link;
                }
                else {
                    return 'Job is not published to Ethosia Web Site Yet.'
                }
            } else {
                return utilsService.getBrightsourceJobUrl(slug)
            }
            
        }
        else if(shareType === 'company') {
            return window.location.origin + '/company/' + slug;
        }
        else if(shareType === 'process') {
            return window.location.origin + `/${screenType}/` + slug + `?process=${processSlug}&tab=${tabNumber}`
        }
        else if(shareType === 'conversation') {
            return window.location.origin + `/inbox?id=${conversationId}&single=${true}`
        }
        else if(shareType === 'talent') {
            return utilsService.getTalentInviteUrl(claimToken)
        }
    }

    buildHeader() {
        const {isExposed, profileName, shareType, jobName, companyName, processName} = this.props;
        if(shareType === "profile") {
            return (
                <h2> Share 
                    <span
                        className="capitalized">{isExposed ? ` ${profileName}` : ''}
                    </span>
                    {` Profile`}
                </h2>
            )
        } else if(shareType === "job") {
            return (
                <h2> Share 
                    <span
                        className="capitalized">{` ${jobName}`}
                    </span>
                    {` Job`}
                </h2>
            )
        } else if(shareType === "company") {
            return (
                <h2> Share 
                    <span
                        className="capitalized">{` ${companyName}`}
                    </span>
                    {` Company`}
                </h2>
            )
        }
        else if(shareType === "process") {
            return (
                <h2> Share 
                    <span
                        className="capitalized">{` ${processName}`}
                    </span>
                    {` Process`}
                </h2>
            )
        }
        else if(shareType === "conversation") {
            return (
                <h2>Share conversation</h2>
            )
        }
        else if(shareType === "talent") {
            return (
                <h2> Share 
                    <span
                        className="capitalized">{` ${profileName}`}
                    </span>
                    {' Talent'}
                </h2>
            )
        }
    }

    render() {
        return (
            <ModalComponent onClose={() => this.props.closeEvent()} clickBodyToclose={false}>
                <div className='modal-box share-modal'>
                    <header>
                        {this.buildHeader()}
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                    </header>
                    <section className="modal-body">
                        {this.buildTypeOfPath()}
                        <form className="form">
                            <div className="form-item">
                                <input
                                    type='text'
                                    ref={(input) => this.input = input}
                                    value={this.buildPath()}
                                    readOnly
                                    className='profile-share'/>
                            </div>
                        </form>

                        {
                            document.queryCommandSupported('copy') &&
                            <div>
                                <button
                                    className="button blue"
                                    onClick={this.copyToClipboard}>
                                    {this.state.copySuccess}
                                </button>
                            </div>
                        }
                    </section>
                </div>
            </ModalComponent>
        );
    }
}

export default ShareModal;
