import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './searchResultsFilter.scss';
import { observer } from "mobx-react"
import metaDataStore from '../../../../stores/meta-data.store';
import ComplexMultiSelectComponent from '../../../components/forms/complexMultiSelect/ComplexMultiSelect.component';
import utilsService from '../../../../services/utils.service';
import searchStore from '../../../../stores/search.store';
import AdvancedSearchView from '../../advancedSearch/AdvancedSearch.view';
import userStore from '../../../../stores/user.store';
import companyStore from '../../../../stores/company.store';
import data from '../../../../constants/data';
import ComplexRangerChuck from '../../../components/forms/complexRangerChuck/ComplexRangerChuck.component';
import ComplexInput2 from '../../../components/forms/complexInput2/complexInput2.component';
import BaseSearchWithNetwork from '../../../components/forms/BaseSearchWithNetwork.Component';

const SearchResultsFilter = observer(React.forwardRef((props, ref) => {


    const [metadata, setMetadata] = useState({
        normalRoles: {},
        categories: [],
        multiSkills: [],
        normalLocations: [],
        normalDLocations: [],
        positionTypes: [],
        seniorityLevels: [],
        languages: [],
        sectors: [],
        degreeTypes: [],
        recruitersList: [],
        companyNames: [],
        industries: {},
        jobStatus: [],
        projectTypes: [],
        degreeNames: [],
        educationInstitutions: [],
        specialties: []
    });

    useEffect(() => {

        const bringMetaDataCategories = () => {
            const categories = [
                'categories',
                'roles',
                'subRoles',
                'skills',
                'topSkillsV2',
                'locations',
                'desiredLocations',
                'positionTypes',
                'seniorityLevels',
                'languages',
                'sectors',
                'degreeTypes',
                'industries',
                'jobStatus',
                'projectTypes',
                'degreeNames',
                'educationInstitutions',
                'specialties',
            ];
            return categories.map(cat => {
                return metaDataStore.getCategory(cat, true);
            });
        }

        const getMetadata = async () => {
            const promises = bringMetaDataCategories();
            await Promise.all(promises)

            const {
                categories,
                roles,
                subRoles,
                skills,
                topSkillsV2,
                locations,
                desiredLocations,
                positionTypes,
                seniorityLevels,
                languages,
                sectors,
                degreeTypes,
                industries,
                jobStatus,
                projectTypes,
                degreeNames,
                educationInstitutions,
                specialties
            } = metaDataStore.categories;

            const locationsFromDB = await metaDataStore.getLocations();
            setMetadata({
                ...metadata,
                categories: Object.values(categories),
                normalRoles: utilsService.normalizeRoles(roles, subRoles),
                multiSkills: Object.values(skills).sort((a, b) =>
                    a.value.toLowerCase().localeCompare(b.value.toLowerCase())),
                topSkillsV2: utilsService.sortTopSkillsV2(topSkillsV2),
                normalLocations: utilsService.normalizeMetaLocationsFromDB(locationsFromDB, true),
                normalDLocations: Object.values(desiredLocations),
                positionTypes: Object.values(positionTypes),
                seniorityLevels: utilsService.sortSeniorityLevels(seniorityLevels),
                languages: Object.values(languages),
                sectors: Object.values(sectors),
                degreeTypes: Object.values(degreeTypes),
                industries: AdvancedSearchView.formatIndustries(industries),
                jobStatus: Object.values(jobStatus),
                projectTypes: Object.values(projectTypes),
                degreeNames: Object.values(degreeNames),
                educationInstitutions: Object.values(educationInstitutions),
                specialties: Object.values(specialties),
            });

            props.metaDataReceived() && props.metaDataReceived();
        }

        getMetadata()

    }, [])

    const removeMultiItem = (item, type) => {
        const state = searchStore[searchStore.selectRightTop()];
        let currentState = [...state[type]];
        currentState = currentState.filter(objItem => objItem.elId !== item.elId);
        searchStore.setSearchValue(type, currentState);
        handleFilterChanged();
    };

    const selectMultiItem = (item, type) => {
        const state = searchStore[searchStore.selectRightTop()];
        let currentState = [...state[type]];

        if (!currentState.some(objItem => objItem.elId === item.elId)) {
            currentState.push(item);

        }
        else {
            currentState = currentState.filter(object => object.elId !== item.elId);
        }

        searchStore.setSearchValue(type, currentState);
        handleFilterChanged();

    };

    const multiItemClicked = (item, type) => {
        const state = searchStore[searchStore.selectRightTop()];
        let update = utilsService.doMeAFavor(state[type]);
        let tempItem = update.find(updateItem => updateItem.elId === item.elId);
        if (!tempItem.type || tempItem.type === -1) {
            tempItem.type = 2;
        }
        else {
            tempItem.type = -1;
        }
        searchStore.setSearchValue(type, update);
        handleFilterChanged();
    }

    const addRelevantSuggestion = (item, type) => {
        const state = searchStore[searchStore.selectRightTop()];
        let currentState = [...state[type]];
        currentState.push(item);
        searchStore.setSearchValue([type], currentState);
        handleFilterChanged();
    };

    const selectSingleItem = (item, type) => {
        if (type === 'schoolType') {
            const index = data.schoolTypeOptions.findIndex
                (element => element.elId === item.elId)
            searchStore.setSearchValue(type, index);
        }
        else if (type === 'summary') {
            const index = data.summary.findIndex
                (element => element.elId === item.elId)
            searchStore.setSearchValue(type, index);
        }
        else if (type === 'jobStatus') {
            searchStore.setSearchValue(type, item.elId);
        }
        else {
            searchStore.setSearchValue(type, item);
        }

        handleFilterChanged();
    };

    const singleItemClicked = (item, type) => {
        if (!item.type || item.type === -1) {
            item.type = 2;
        }
        else {
            item.type = -1;
        }
        searchStore.setSearchValue(type, item);
        handleFilterChanged();
    }

    const removeSingleItem = (type) => {
        if (type === 'selectedAvailability' || type === 'schoolType' || type === 'summary') {
            searchStore.setSearchValue(type, 0);
        }
        else if (type === 'jobStatus') {
            searchStore.setSearchValue(type, '-1');
        }
        else {
            searchStore.setSearchValue(type, '');
        }
        handleFilterChanged();
    };

    const handleFilterChanged = () => {
        props.filterDataChanged();
    }

    const buildRulesBasedOnCategory = (categories) => {
        let roles = [];
        for (let i = 0; i < categories.length; ++i) {
            const category = categories[i];
            const selectedRoles = metadata.normalRoles.parentRoles &&
                metadata.normalRoles.parentRoles[category.elId];
            roles = selectedRoles ? roles.concat(selectedRoles) : roles;
        }
        return roles;
    }

    const radioSelect = (index, type) => {
        searchStore.setSearchValue(type, index);
    };

    const slideEv = (value, type) => {
        searchStore.setSearchValue(type, value);
    };

    const handleRangeChuckClick = (type, value) => {
        handleFilterChanged();
    };

    const handleInputChanged = (type, value) => {
        const typeValue = searchStore[searchStore.selectRightTop()][type];
        if(typeValue !== value) {
            searchStore.setSearchValue(type, value);
            handleFilterChanged();
        }
    }

    const checkActions = (isCheck, type) => {
        let value = isCheck ? 'In' : 'All'
        searchStore.setSearchValue(type, value);
        handleFilterChanged();
    }

    const shouldRender = (type) => {
        const isPeopleSearch = searchStore.selectedTopAdvanced === 0;
        const isJobSearch = searchStore.selectedTopAdvanced === 1;

        switch (type) {
            case 'categories':
            case 'roles':
            case 'skills':
            case 'areas':
            case 'salary':
            case 'position types':
            case 'seniority levels':
            case 'languages':
            case 'recruiter in charge':
            case 'degrees':
            case 'school type':
            case 'sectors':
            case 'industries':
                return isPeopleSearch || isJobSearch;

            case 'key words':
            case 'summary':
            case 'current address':
            case 'availability':
            case 'age range':
            case 'current company':
            case 'previous company':
            case 'stage':
            case 'degree names':
            case 'education institutions':
            case 'product tags':
                return isPeopleSearch;

            case 'job status':
            case 'project type':
                return isJobSearch;


            default: {
                return;
            }
        }
    }
    const {
        keyWords, selectedCategories, selectedRoles, selectedSkills, selectedLocations, selectedDLocations,
        selectedSalary, selectedAvailability, selectedPositions, selectedSeniorities, selectedLanguages,
        recruiterInCharge, currentCompany, previousCompany, selectedDegreeTypes, schoolType, selectedDegreeNames,
        selectedEducationInstitutions, summary, sector, industries, operatorRoles, operatorSkills,
        jobStatus, selectedProjectTypes, selectedStages, tags
    } = searchStore[searchStore.selectRightTop()];

    const { skills } = metaDataStore.categories;
    const summaryOptions = utilsService.doMeAFavor(data.summary).slice(1);
    const schoolTypeOptions = utilsService.doMeAFavor(data.schoolTypeOptions).slice(1);
    const salaryMinMax = utilsService.getSalaryRanges(selectedSalary + 1);
    const slicedJobStatus = utilsService.doMeAFavor(metadata.jobStatus).slice(1);
    const jobStatusSelectedItems = jobStatus !== '-1' && metadata.jobStatus.length > 0 ?
        [metadata.jobStatus.find(item => item.elId === Number(jobStatus))] : [];

    return (
        <div className={"search-results-filter-wrapper " + (props.allNeededDataReady ? '' : 'not-ready')}>
            {shouldRender('key words') && <ComplexInput2
                label={'Key Words'}
                outerMultiItemClass='outer-multi-item'
                inputChanged={value => handleInputChanged('keyWords', value)}
                value={keyWords}
            />}
            {shouldRender('categories') && <ComplexMultiSelectComponent
                items={metadata.categories}
                selectedItems={selectedCategories}
                removeItem={item => removeMultiItem(item, 'selectedCategories')}
                selectItem={item => selectMultiItem(item, 'selectedCategories')}
                placeholder={'Categories'}
                singularPlaceHolder={'Category'}
                outerMultiItemClass='outer-multi-item'
                hasIncludeOperator={true}
                openOnKeyDown={false}
                includeOperatorCheckAction={(e) => checkActions(e.target.checked, 'operatorRoles')}
                includeOperatorChecked={operatorRoles === 'In'}
            />}

            {shouldRender('roles') && <ComplexMultiSelectComponent
                items={buildRulesBasedOnCategory(selectedCategories)}
                selectedItems={selectedRoles}
                removeItem={item => removeMultiItem(item, 'selectedRoles')}
                selectItem={item => selectMultiItem(item, 'selectedRoles')}
                itemClicked={item => multiItemClicked(item, 'selectedRoles')}
                supportSpecialTags={true}
                placeholder={'Roles'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('skills') && <ComplexMultiSelectComponent
                items={metadata.multiSkills}
                selectedItems={selectedSkills}
                removeItem={(item) => removeMultiItem(item, 'selectedSkills')}
                selectItem={(item) => selectMultiItem(item, 'selectedSkills')}
                itemClicked={item => multiItemClicked(item, 'selectedSkills')}
                supportSpecialTags={true}
                placeholder={'Skills'}
                singularPlaceHolder={'Skill'}
                numberOfCharsToPredicate={2}
                outerMultiItemClass='outer-multi-item'
                hasIncludeOperator={true}
                includeOperatorCheckAction={(e) => checkActions(e.target.checked, 'operatorSkills')}
                includeOperatorChecked={operatorSkills === 'In'}

                //suggested
                hasSuggested={true}
                selectedSuggested={selectedRoles.map(item => item.elId)}
                topSkills={metadata.topSkillsV2}
                skills={skills}
                addRelevantSuggestion={(item) => addRelevantSuggestion(item, 'selectedSkills')}
            />}

            {shouldRender('current address') && <ComplexMultiSelectComponent
                items={metadata.normalLocations}
                selectedItems={selectedLocations}
                removeItem={item => removeMultiItem(item, 'selectedLocations')}
                selectItem={item => selectMultiItem(item, 'selectedLocations')}
                itemClicked={item => multiItemClicked(item, 'selectedLocations')}
                supportSpecialTags={true}
                placeholder={'Current Address'}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('areas') && <ComplexMultiSelectComponent
                items={metadata.normalDLocations}
                selectedItems={selectedDLocations}
                removeItem={item => removeMultiItem(item, 'selectedDLocations')}
                selectItem={item => selectMultiItem(item, 'selectedDLocations')}
                itemClicked={item => multiItemClicked(item, 'selectedDLocations')}
                supportSpecialTags={true}
                placeholder={'Areas'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('salary') && <ComplexRangerChuck
                classes={'salary-range'}
                label={'Salary'}
                max={salaryMinMax.max}
                min={salaryMinMax.min}
                isSalary={true}
                type={'selectedSalary'}
                radioSelect={(index) => radioSelect(index, 'selectedSalary')}
                slideEv={(realValue) => slideEv(realValue, 'salaryRange')}
                buttonClick={handleRangeChuckClick}
                realValue={searchStore[searchStore.selectRightTop()]['salaryRange']}
                realSelectedRadioIndex={searchStore[searchStore.selectRightTop()]['selectedSalary']}
                outerMultiItemClass='outer-multi-item'

            />}

            {shouldRender('availability') && <ComplexMultiSelectComponent
                items={data.availabilityOptionsSearch}
                selectedItems={selectedAvailability}
                removeItem={(item) => removeMultiItem(item, 'selectedAvailability')}
                selectItem={item => selectMultiItem(item, 'selectedAvailability')}
                placeholder={'Availability'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('position types') && <ComplexMultiSelectComponent
                items={metadata.positionTypes}
                selectedItems={selectedPositions}
                removeItem={item => removeMultiItem(item, 'selectedPositions')}
                selectItem={item => selectMultiItem(item, 'selectedPositions')}
                itemClicked={item => multiItemClicked(item, 'selectedPositions')}
                supportSpecialTags={true}
                placeholder={'Position Types'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('stage') && <ComplexMultiSelectComponent
                items={data.stage}
                selectedItems={selectedStages}
                removeItem={item => removeMultiItem(item, 'selectedStages')}
                selectItem={item => selectMultiItem(item, 'selectedStages')}
                itemClicked={item => multiItemClicked(item, 'selectedStages')}
                supportSpecialTags={true}
                placeholder={'Stage'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('seniority levels') && <ComplexMultiSelectComponent
                items={metadata.seniorityLevels}
                selectedItems={selectedSeniorities}
                removeItem={item => removeMultiItem(item, 'selectedSeniorities')}
                selectItem={item => selectMultiItem(item, 'selectedSeniorities')}
                itemClicked={item => multiItemClicked(item, 'selectedSeniorities')}
                supportSpecialTags={true}
                placeholder={'Seniority Levels'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('languages') && <ComplexMultiSelectComponent
                items={metadata.languages}
                selectedItems={selectedLanguages}
                removeItem={item => removeMultiItem(item, 'selectedLanguages')}
                selectItem={item => selectMultiItem(item, 'selectedLanguages')}
                itemClicked={item => multiItemClicked(item, 'selectedLanguages')}
                supportSpecialTags={true}
                placeholder={'Languages'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('project type') && <ComplexMultiSelectComponent
                items={metadata.projectTypes}
                selectedItems={selectedProjectTypes}
                removeItem={item => removeMultiItem(item, 'selectedProjectTypes')}
                selectItem={item => selectMultiItem(item, 'selectedProjectTypes')}
                supportSpecialTags={true}
                placeholder={'Project Type'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('recruiter in charge') && <ComplexMultiSelectComponent
                items={AdvancedSearchView.normalizeRecruiters(userStore.recruitersList)}
                selectedItems={Object.values(recruiterInCharge).length > 0 ? [recruiterInCharge] : []}
                removeItem={() => removeSingleItem('recruiterInCharge')}
                selectItem={item => selectSingleItem(item, 'recruiterInCharge')}
                itemClicked={item => singleItemClicked(item, 'recruiterInCharge')}
                supportSpecialTags={true}
                placeholder={'Recruiter in charge'}
                isSingle={true}
                isLoading={!userStore.recruitersLoaded}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('age range') && <ComplexRangerChuck
                label={'Age Range'}
                max={90}
                min={0}
                type={'ageRange'}
                slideEv={(realValue) => slideEv(realValue, 'ageRange')}
                buttonClick={handleRangeChuckClick}
                realValue={searchStore[searchStore.selectRightTop()]['ageRange']}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('current company') && 
                <BaseSearchWithNetwork 
                    networkRequest={companyStore.searchCompaniesByPartialNameByText}
                    numberOfCharsToPredicate={2}>
                    <ComplexMultiSelectComponent
                        selectedItems={Object.values(currentCompany).length > 0 ? [currentCompany] : []}
                        removeItem={() => removeSingleItem('currentCompany')}
                        selectItem={item => selectSingleItem(item, 'currentCompany')}
                        itemClicked={item => singleItemClicked(item, 'currentCompany')}
                        supportSpecialTags={true}
                        placeholder={'Current Company'}
                        isSingle={true}
                        outerMultiItemClass='outer-multi-item'
                />
               </BaseSearchWithNetwork>     
            }

            {shouldRender('previous company') && 
                <BaseSearchWithNetwork 
                    networkRequest={companyStore.searchCompaniesByPartialNameByText}
                    numberOfCharsToPredicate={2}>
                    <ComplexMultiSelectComponent
                        selectedItems={Object.values(previousCompany).length > 0 ? [previousCompany] : []}
                        removeItem={() => removeSingleItem('previousCompany')}
                        selectItem={item => selectSingleItem(item, 'previousCompany')}
                        itemClicked={item => singleItemClicked(item, 'previousCompany')}
                        supportSpecialTags={true}
                        placeholder={'Previous Company'}
                        isSingle={true}
                        outerMultiItemClass='outer-multi-item'
                    />
                </BaseSearchWithNetwork>    
            }

            {shouldRender('job status') && <ComplexMultiSelectComponent
                items={slicedJobStatus}
                selectedItems={jobStatusSelectedItems}
                removeItem={() => removeSingleItem('jobStatus')}
                selectItem={item => selectSingleItem(item, 'jobStatus')}
                supportSpecialTags={true}
                placeholder={'Job Status'}
                isSingle={true}
                // isLoading={!userStore.recruitersLoaded}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('degrees') && <ComplexMultiSelectComponent
                items={metadata.degreeTypes}
                selectedItems={selectedDegreeTypes}
                removeItem={item => removeMultiItem(item, 'selectedDegreeTypes')}
                selectItem={item => selectMultiItem(item, 'selectedDegreeTypes')}
                itemClicked={item => multiItemClicked(item, 'selectedDegreeTypes')}
                supportSpecialTags={true}
                placeholder={'Degrees'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('school type') && <ComplexMultiSelectComponent
                items={schoolTypeOptions}
                selectedItems={schoolType !== 0 ?
                    [data.schoolTypeOptions[schoolType]] : []}
                removeItem={() => removeSingleItem('schoolType')}
                selectItem={item => selectSingleItem(item, 'schoolType')}
                placeholder={'School Type'}
                openOnKeyDown={false}
                isSingle={true}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('degree names') && <ComplexMultiSelectComponent
                items={metadata.degreeNames}
                selectedItems={selectedDegreeNames}
                removeItem={item => removeMultiItem(item, 'selectedDegreeNames')}
                selectItem={item => selectMultiItem(item, 'selectedDegreeNames')}
                itemClicked={item => multiItemClicked(item, 'selectedDegreeNames')}
                supportSpecialTags={true}
                placeholder={'Degree Names'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('education institutions') && <ComplexMultiSelectComponent
                items={metadata.educationInstitutions}
                selectedItems={selectedEducationInstitutions}
                removeItem={item => removeMultiItem(item, 'selectedEducationInstitutions')}
                selectItem={item => selectMultiItem(item, 'selectedEducationInstitutions')}
                itemClicked={item => multiItemClicked(item, 'selectedEducationInstitutions')}
                supportSpecialTags={true}
                placeholder={'Education Institutions'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('summary') && <ComplexMultiSelectComponent
                items={summaryOptions}
                selectedItems={summary !== 0 ? [data.summary[summary]] : []}
                removeItem={() => removeSingleItem('summary')}
                selectItem={item => selectSingleItem(item, 'summary')}
                placeholder={'Summary'}
                openOnKeyDown={false}
                isSingle={true}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('sectors') && <ComplexMultiSelectComponent
                items={metadata.sectors}
                selectedItems={Object.values(sector).length > 0 ? [sector] : []}
                removeItem={() => removeSingleItem('sector')}
                selectItem={item => selectSingleItem(item, 'sector')}
                itemClicked={item => singleItemClicked(item, 'sector')}
                placeholder={'Sectors'}
                openOnKeyDown={false}
                isSingle={true}
                outerMultiItemClass='outer-multi-item'
            />}

            {shouldRender('industries') && <ComplexMultiSelectComponent
                items={Object.values(sector).length > 0
                    && Object.values(metadata.industries).length > 0 ?
                    metadata.industries[sector.elId] : []}
                selectedItems={industries}
                removeItem={item => removeMultiItem(item, 'industries')}
                selectItem={item => selectMultiItem(item, 'industries')}
                itemClicked={item => multiItemClicked(item, 'industries')}
                supportSpecialTags={true}
                placeholder={'Industries'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
                isDisabled={Object.values(sector).length === 0}
            />}

            {shouldRender('product tags') && <ComplexMultiSelectComponent
                items={metadata.specialties}
                selectedItems={tags}
                removeItem={item => removeMultiItem(item, 'tags')}
                selectItem={item => selectMultiItem(item, 'tags')}
                itemClicked={item => multiItemClicked(item, 'tags')}
                supportSpecialTags={true}
                placeholder={'Product Tags'}
                openOnKeyDown={false}
                outerMultiItemClass='outer-multi-item'
            />}


        </div>

    )

}));

export default SearchResultsFilter;
