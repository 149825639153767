import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import {CloseIcon, TrashIcon} from '../svg';
import {InputWithPlaceHolderForm} from '../forms';
import utilsService from '../../../services/utils.service';
import userStore from '../../../stores/user.store';
import companyStore from '../../../stores/company.store';
import data from '../../../constants/data';
import StyledRadioButtonsComponent from '../styledRadioButttons/StyledRadioButtons.component';

class InviteMemberModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isInvitingLoader: false,
            invites: [
                {
                    value: '',
                    isValid: true,
                    role: { selectedIndex: -1, elId: -1, value: '' }
                }],
            isErrors: false
        };
    }

    clickHandle = (e, button, selectedIndex, rowIndex) => {
        const invites = [...this.state.invites];
        invites[rowIndex].role = {selectedIndex: selectedIndex, elId: button.elId, value: button.value}
        this.setState({ invites: invites });
    };

    buildEmail(emailPrefix) {
        return emailPrefix + '@' + userStore.userCompany.companyDomain;
    }
    getEmailPrefix(email) {
        const emailParts = email.split('@');
        return emailParts && emailParts.length > 0 ? emailParts[0] : '';
    }

    inputHandle = (e, id) => {
        const val = e.target.value;
        const invites = [...this.state.invites];

        const email = this.buildEmail(val);
        const isValid = val.length > 0 && utilsService.validateEmail(email);
        const item = invites[id];
        item.value = email;
        item.isValid = isValid;

        this.setState({ invites });
    };

    addInvitesHandle = () => {
        this.setState({
            invites: [
                ...this.state.invites, {
                    value: '',
                    isValid: true,
                    role: { selectedIndex: -1, elId: -1, value: '' }
                }]
        });
    };

    inviteHandle = async () => {
        const { invites } = this.state;
        const mails = [];

        this.setState({isErrors: false});

        let areAllEmailsValid = true;
        let areAllRoleSelected = true;
        invites.forEach(inv => {
            if(inv.role.selectedIndex == -1) {
                this.setState({isErrors: true});
                areAllRoleSelected = false;
            }

            inv.isValid = utilsService.validateEmail(inv.value);
            if(!inv.isValid) {
                areAllEmailsValid = false;
            }

            if (inv.isValid && !this.state.isErrors && inv.value) {
                mails.push(inv.value);
            }
        });

        if(!areAllEmailsValid || !areAllRoleSelected)
            return false;

        this.setState({isInvitingLoader: true});
        await companyStore.invitePersons(invites);
        this.setState({isInvitingLoader: false});

        this.props.closeEvent();
    };

    checkIfallValid(invites) {
        let res = true;

        if (invites.length === 1) {
            if (!invites[0].isValid) {
                res = false;
            }
        } else {
            invites.forEach(inv => {
                if (!inv.isValid && inv.value !== '') {
                    res = false;
                }
            });
        }

        return res;
    }

    onDeleteInviteRow = (e, rowIndex) => {
        const tmpInvites =  [...this.state.invites];
        tmpInvites.splice(rowIndex, 1);
        this.setState({invites: tmpInvites});
    }

    render() {
        const { invites } = this.state;
        const many = invites.length > 1;
        const buttons = data.employers_permissions;
        const companyDomain = userStore.userCompany.companyDomain;

        return (
            <ModalComponent onClose={() => this.props.closeEvent()}>
                <div className='modal-box send-invitation-modal'>
                    <header>
                        <h2>Invite people to join</h2>
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                    </header>

                    <section className="modal-body">
                        <div className="form">
                            {invites.map((inv, idx) => {
                                return (
                                    <div className="invite-line" key="{idx}">
                                        <InputWithPlaceHolderForm
                                            classes={inv.isValid ? 'valid' : 'invalid'}
                                            placeholderClasses={'placeHolder'}
                                            placeHolderText={'@' + companyDomain}
                                            label={'Email'}
                                            wrapperClasses={'email'}
                                            value={this.getEmailPrefix(inv.value)}
                                            type={'text'}
                                            inputChange={(e) => this.inputHandle(e, idx)}
                                        />
                                        <div></div>
                                        <div>
                                            <span className="invite-role">Role</span>
                                            <StyledRadioButtonsComponent
                                                buttons={buttons}
                                                activeIndex={invites[idx] ? invites[idx].role.selectedIndex : -1}
                                                clickHandle={(e, button, selectedIndex) => this.clickHandle(e, button, selectedIndex, idx)}
                                            />
                                            {this.state.isErrors && invites[idx].role.selectedIndex == -1 && (
                                                <span className="missing-role-selection">Role is missing</span>
                                            )}
                                        </div>
                                        {idx > 0 && (
                                            <div className="trash-icon" onClick={(e) => this.onDeleteInviteRow(e, idx)}>
                                                <TrashIcon></TrashIcon>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            <div></div>
                        </div>
                    </section>
                    <section className="add-more-container">
                        <label
                            onClick={this.addInvitesHandle}
                        >+ Add more</label>
                    </section>

                    <footer>
                        <button
                            onClick={() => this.props.closeEvent()}
                            className={'button gray ' + (this.state.isInvitingLoader ? 'disabled' : '')}>
                            Cancel
                        </button>
                        <button
                            onClick={this.inviteHandle}
                            className={'button bk-black ' + (this.state.isInvitingLoader ? 'loading' : '')}>
                            {many ? 'Send Invites' : 'Send Invite'}
                        </button>
                    </footer>
                </div>
            </ModalComponent>
        );
    }
}

export default InviteMemberModal;
