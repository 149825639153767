import React, {PureComponent} from 'react';
import MultiSelectComponent from './MultiSelect.component';
import SuggestComponent from './Suggest.component';
import FileUpload from './FileUpload.component';
import onClickOutside from 'react-onclickoutside';

const BaseInputForm = WrappedComponent => {
    return class extends PureComponent {

        handleClickOutside = evt => {
            this.props.onClickOutside && this.props.onClickOutside();
            this.setState({ opened: false });
        };

        render() {
            const wrapperClasses = this.props.wrapperClasses ? this.props.wrapperClasses : '';
            const classes = this.props.classes ? this.props.classes : '';
            const iconClass = this.props.icon ? 'with-icon' : '';
            return (
                <div className={'form-item ' + wrapperClasses}>
                    {this.props.label != null && (
                        <label className={iconClass}>
                            {this.props.icon && (
                                this.props.icon
                            )}
                            {this.props.label}
                        </label>
                    )}
                    {this.props.minilabel && (
                        <span className="mini-label">
                            {this.props.minilabel}
                        </span>
                    )}
                    <WrappedComponent
                        data={this.props}
                        value={this.props.value}
                        classes={classes}
                        inputChange={this.props.inputChange}
                        onBlurEvent={this.props.onBlurEvent ? this.props.onBlurEvent : null}
                        defaultValue={this.props.defaultValue}
                    />
                </div>
            );
        }
    };
};

const Input = props => {
    let classes = props.classes;
    if (props.data.valid === false) {
        classes += ' invalid';
    }
    return (
        <input
            autoComplete="off" 
            ref={props.data.reference}
            type={props.data.type}
            dir={props.data.dir ? props.data.dir : 'auto'}
            name={props.data.name}
            placeholder={props.data.placeHolder}
            min={props.data.min}
            max={props.data.max}
            required={props.data.required}
            value={props.data.value || ""}
            className={classes}
            disabled={props.data.disabled}
            onBlur={event => props.onBlurEvent ? props.onBlurEvent(event) : null}
            onChange={event => props.inputChange(event)}
            defaultValue={props.data.defaultValue}
        />
    );
};

const InputWithPlaceHolder = props => {
    let classes = props.classes;
    if (props.data.valid === false) {
        classes += ' invalid';
    }
    return (
        <div className={'wrapped-component-container ' + classes}>
            <input
                type={props.data.type}
                name={props.data.name}
                placeholder={props.data.placeHolder}
                min={props.data.min}
                max={props.data.max}
                required={props.data.required}
                value={props.data.value}
                className='borderless'
                disabled={props.data.disabled}
                onChange={event => props.inputChange(event)}
            />
            <div/>
            <span className={'place-holder ' + props.data.placeholderClasses}>{props.data.placeHolderText}</span>
        </div>
    );
};

const Select = props => {
    let { selectList, value, isLoading, hasPleaseSelect } = props.data
    if (hasPleaseSelect && !(selectList.find(item => item.elId === -1))) {
        selectList.unshift({value: 'Please select..', elId: -1})
    }
    return (
        <select
            value={value && value.elId !== undefined ? value.elId : value}
            onChange={event => props.inputChange(event)}
            name={props.data.value}>
            {props.data.defaultOption && (
                <option
                    key='8jd0d0d0003do0034'
                    value={0}>{props.data.defaultOption}</option>
            )}

            {selectList.map((option, idx) => {
                let val = option && option.elId;
                let text = option && option.value;
                if (typeof option === 'string' || typeof option === 'number') {
                    val = option;
                    text = option;
                }
            return (<option key={idx} value={val}>{text}</option>);
            })}
        </select>
    );
};

const TextArea = props => {
    return (
        <textarea
            style={props.data.valid ? {} : {'borderColor': 'red'}}
            dir={props.data.dir ? props.data.dir : 'auto'}
            placeholder={props.data.placeHolder}
            name={props.data.name}
            value={props.data.value || ""}
            className={props.classes}
            disabled={props.data.disabled}
            onChange={event => props.inputChange(event)}/>
    );
};

const DataList = props => {
    const { data, classes, inputChange } = props;
    const { type, name, value, list, selectList } = data;
    const currentValue = selectList[value] ? selectList[value].value : '';

    return (
        <React.Fragment>
            <input type={type} name={name} value={currentValue} className={classes}
                   onChange={event => inputChange(event)}
                   list={list}
            />
            <datalist id={list}>
                {selectList.map((option, idx) =>
                    <option key={idx} value={option.elId}>{option.value}</option>
                )}
            </datalist>
        </React.Fragment>
    );
};

const InputForm = onClickOutside(BaseInputForm(Input));
const InputWithPlaceHolderForm = BaseInputForm(InputWithPlaceHolder);
const selectForm = BaseInputForm(Select);
const TextAreaForm = BaseInputForm(TextArea);
const DataListForm = BaseInputForm(DataList);

const SelectForm = onClickOutside(selectForm)

export {
    InputForm,
    InputWithPlaceHolderForm,
    SelectForm,
    DataListForm,
    TextAreaForm,
    MultiSelectComponent,
    SuggestComponent,
    FileUpload
};
