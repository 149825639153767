import React from 'react';
import utilsService from '../../../../../services/utils.service';

const JobSummaryGeneral = props => {
    const { job } = props;

    const data = [
        {
            heading: 'BsId',
            value: job.bsId
        },
        {
            heading: 'Scope',
            value: utilsService.getObjValue(job.positionType),
            isLengthCheck: false
        },
        {
            heading: 'Location',
            value: job.locationData ? `${job.locationData.city}, ${job.locationData.adminName}, ${job.locationData.country}`: false,
            isLengthCheck: false
        },
        {
            heading: 'Industry',
            value: utilsService.joinByComma(job.industries, 'value'),
            isLengthCheck: true
        },
        {
            heading: 'Salary',
            value: job.salary,
            isLengthCheck: false,
            sign: '₪'
        },
        {
            heading: 'External',
            value: job.isExternal ? 'Yes' : 'No'
        }
    ];

    function releaseTheKraken(data) {
        const temp = [];

        data.forEach((item) => {
            let valid = false;

            if (item.value && item.isLengthCheck && item.value.length > 0) {
                valid = true;
            } else if (item.value && !item.isLengthCheck) {
                valid = true;
            }

            if (valid) {
                temp.push({ key: item.heading, value: item.value, sign: item.sign });
            }
        });

        return temp;
    }

    return (
        <div className="job-summery-general-container">
            {releaseTheKraken(data).map((item, idx) => {
                return (
                    <div
                        key={idx}
                        className="general-container">
                        <div className="key">{item.key}</div>
                        <div className="value">{item.sign ? `${item.sign} ${item.value}` : item.value}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default React.memo(JobSummaryGeneral);
