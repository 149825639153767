import React, {useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types'
import './searchResultsList.scss';
import SearchResultItem from './searchResultItem/SearchResultItem.component';
import SearchResultJobItem from './searchResultItem/SearchResultJobItem.component';
import searchStore from '../../../../stores/search.store';
import Loader from '../../../components/loader/Loader.component';
import { AutoSizer, List, WindowScroller, CellMeasurerCache, CellMeasurer } from 'react-virtualized';
import Cv2Component from '../../talent/components/Cv2.component';
import UseOutsideClick from '../../../components/OutsideClickHooks.component'

const SearchResultList = (props) => {

    const { results, resultsNumber } = props;

    const [cursor, setCursor] = useState(0);
    const [oldResultsNumber, setOldResultsNumber] = useState(0);
    const listRef = useRef();


    const outsideClickRef = useRef();

    const [profileToShowCV, setProfileToShowCV] = useState(null)

    UseOutsideClick(outsideClickRef, () => {
        if (profileToShowCV) {
            setProfileToShowCV(null);
        }
      });

      const [_cache, setCache] = useState(new CellMeasurerCache({
        fixedWidth: true,
    }))

    const handleItemClick = (result) => {
        props.itemSelected(result);
    }

    // changed to virtualize. Not in used since it's not working anymore. 
    // maybe should implement ArrowKeyStepper from virtualize
    const handleKeyDown = (e) => {

        //enter
        if (e && e.keyCode === 13) {
            const searchType = searchStore.selectedTopAdvanced
            let type;
            if (searchType === 0) {
                type = 'profile'
            }
            else if (searchType === 1) {
                type = 'jobs'
            }
            const result = results[cursor];
            window.open(`/${type}/${result.slug}`, "_blank");
        }


        //key up
        if (e && e.keyCode === 38) {
            if (cursor > 0) {
                handleKeyPressed(cursor - 1);
            }
        }


        //key down
        if (e && e.keyCode === 40) {
            if (cursor < results.length - 1) {
                handleKeyPressed(cursor + 1);
            }
        }
    }

    const handleKeyPressed = (newCursorPosition) => {
        const resultItem = results[newCursorPosition];
        props.itemSelected(resultItem);
        // handleClick(newCursorPosition);
        setCursor(newCursorPosition);
    }

    const handleRemoveItem = (e, removedItem) => {
        e.stopPropagation()
        props.removeItem(removedItem)
    }

    useEffect(() => {
        if(results && results.length > 0) {
            setTimeout(() => {
                for(let i = results.length - (results.length - oldResultsNumber); i < results.length; i++) {
                    _cache.clear(i, 0)
                }
                setOldResultsNumber(results.length)
            }, 1000)
        }

    }, [results])


    const handleScroll = async (params) => {

        const { clientHeight, scrollHeight, scrollTop } = params;

        if (resultsNumber.length === 0) {
            return;
        }

        if (scrollHeight > clientHeight && (scrollHeight - (scrollTop + clientHeight) < 50
            && resultsNumber < props.total && !props.isWaitingForMoreResults)) {
            props.scrollBottomHandler();
        }

    }


    const renderItem = ({ index, isScrolling, isVisible, key, parent, style }) => {

        const result = results[index];
        const searchType = searchStore.selectedTopAdvanced
        return (
            <CellMeasurer
                cache={_cache}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}>
                {({ measure, registerChild }) => (
                    <div ref={registerChild} className={index < results.length -1 ? 'search-item-main' : ''} style={style}>
                        {searchType === 0 && <SearchResultItem
                            profile={result}
                            itemSelected={item => handleItemClick(item)}
                            selectedItem={props.selectedItem}
                            checkActionChanged={props.checkActionChanged}
                            actionEventHandler={props.actionEventHandler}
                            removeItem={(e, removedItem) => handleRemoveItem(e, removedItem)}
                            index={index}
                            history={props.history}
                            showCVClick={(profile) => setProfileToShowCV(profile)}
                        />}
                        {searchType === 1 && <SearchResultJobItem
                            job={result}
                            itemSelected={item => handleItemClick(item)}
                            selectedItem={props.selectedItem}
                            checkActionChanged={props.checkActionChanged}
                            actionEventHandler={props.actionEventHandler}
                            removeItem={(e, removedItem) => handleRemoveItem(e, removedItem)}
                            index={index}
                            history={props.history}
                        />}
                    </div>
                )}
            </CellMeasurer>
        )
    }

    return (
        <div className='search-results-list-wrapper'>
            <div className="search-results-list">

                <WindowScroller>
                    {({ isScrolling, onChildScroll, scrollTop, height }) => {
                        return (
                            <AutoSizer disableHeight>{
                                ({ width }) => {
                                    return (
                                        <List
                                            onScroll={handleScroll}
                                            ref={listRef}
                                            className={'virtualized-grid-list custom-scroll'}
                                            height={height}
                                            deferredMeasurementCache={_cache}
                                            rowCount={results ? results.length : 0}
                                            rowHeight={_cache.rowHeight}
                                            rowRenderer={renderItem}
                                            width={width}
                                        />
                                    );
                                }
                            }
                            </AutoSizer>);
                    }}
                </WindowScroller>
                {/* } */}
                {profileToShowCV && <div ref={outsideClickRef }>
                        <Cv2Component  profile={profileToShowCV}/>
                </div>}
                {props.isWaitingForMoreResults && <Loader />}
            </div>
        </div>

    )
};

SearchResultList.propTypes = {
    // bla: PropTypes.string,
};

SearchResultList.defaultProps = {
    // bla: 'test',
    // list: PropTypes.instanceOf(Immutable.List).isRequired
};

export default SearchResultList;




