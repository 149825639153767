import React from 'react';
import Flag from 'react-world-flags'
import './showInfo.scss';
import { Link } from 'react-router-dom';
import utilsService from '../../../../../services/utils.service';

const ShowInfo = props => {
  // if phone number clean it before formating
  let comDevice = props.label;
  let countryCode;
  if (comDevice && comDevice.phone && !props.shouldHideMoreDetails) {
    // comDevice = utilsService.cleanUpThePhoneStr(comDevice);
    countryCode = comDevice.country || 'il';
    const callingCode = comDevice.callingCode || '972';
    const cleanedPhone = comDevice.phone?.substring(callingCode.length);
    comDevice = cleanedPhone.length <= 0 ? '' : callingCode == '972' ? '0' + cleanedPhone : '+' + comDevice.phone;
  }
  return (
    <div className="show-info-wrapper">
      {props.shouldHideMoreDetails && <button onClick={() => props.onClick()}>
        <span>+</span>
      </button>}
      {props.link ?
        <Link to={props.link} >
          <label className={"link " + (props.markLabel ? "mark-label" : "")}>
            {comDevice}
          </label>
        </Link>
        :
        <div className='phone-group'>
          <label>{typeof comDevice === 'string' ? comDevice : ''}</label>
          {
            comDevice?.length > 0 && countryCode && <Flag className='flag-icon' height="16" code={ countryCode } />
          }
        </div>
        
      }
    </div>
  )
};

export default ShowInfo;
