import { createPortal } from 'react-dom';
import Draggable from 'react-draggable';
import React from 'react';
import './modal.scss';

class ModalComponent extends React.Component {
    clickHandler = e => {
        if (e.target === e.currentTarget && this.props.clickBodyToclose) {
            this.props.onClose && this.props.onClose(e);
        }
    };

    handleEscape = e => {
        if (e.keyCode === 27) {
            this.props.onClose && this.props.onClose(e);
        }
    };

    componentDidMount() {
        document.querySelector('body').addEventListener('keyup', this.handleEscape);
        document.querySelector('#root').className = 'modal-overlay';
        document.querySelector('body').className = 'body-modal';
    }

    componentWillUnmount() {
        document.querySelector('body').removeEventListener('keyup', this.handleEscape);
        document.querySelector('#root').className = '';
        document.querySelector('body').className = '';
    }

    getCustomClasses(classes) {
        let res = 'modal ';
        if (classes) {
            res += classes;
        }

        return res;
    }

    getDropClasses(darkDrop) {
        let classes = 'drop';
        if (darkDrop) {
            classes += ' dark-drop';
        }

        return classes;
    }

    render() {
        const { darkDrop, isDraggable } = this.props;

        const dropClasses = this.getDropClasses(darkDrop);

        if (isDraggable) {
            return createPortal(
                <div className={this.getCustomClasses(this.props.classes)} id='modalId' onClick={this.clickHandler}>
                    <div className={dropClasses}/>
                    <Draggable
                    handle={'strong'}
                    >
                        <div className="modal-cont">
                            {this.props.children}
                        </div>
                    </Draggable>
                </div>,
                document.getElementById('portal')
            );
        }

        if (!isDraggable) {
            return createPortal(
                <div className={this.getCustomClasses(this.props.classes)} id='modalId' onClick={this.clickHandler}>
                    <div className={dropClasses}/>
                    <div className="modal-cont">
                        {this.props.children}
                    </div>
                </div>,
                document.getElementById('portal')
            );
        }
    }

}

export default ModalComponent;
