import React from 'react';
import ModalComponent from '../modal/Modal.component';
import { CloseIcon } from '../svg';

const AttachmentsModal = props => {
    const { attachment, closeEvent, attachmentUrl } = props;

    return (
        <ModalComponent
            onClose={() => closeEvent()}
            darkDrop={true}
        >
            <div className='modal-box inbox-attachment-modal'>
                <div onClick={() => closeEvent()}
                     className='circle-close modal-close'>
                    <CloseIcon
                        fill={'#fff'}
                    />
                </div>
                {getRelevantContent(attachmentTypeCheck(attachment.mimeType, attachment.fileFormat), attachmentUrl)}
            </div>
        </ModalComponent>
    );
};

export default AttachmentsModal;

const showImage = attUrl => {
    return (
        <img src={attUrl}/>
    );
};

const showDocument = attUrl => {
    const height = window.innerHeight || document.documentElement.clientHeight ||
        document.body.clientHeight;
    return (
        <iframe
            height={height - 100}
            // onLoad={this.loadedIframe}
            // ref={(ref) => this.frame = ref}
            title='inbox attachment'
            src={`https://docs.google.com/viewer?url=${encodeURIComponent(attUrl)}&pid=explorer&efh=false&a=v&embedded=true`}
            width='100%' frameBorder='0'>No CV found
        </iframe>
    );
};

function getRelevantContent(contentType, attachmentUrl) {
    switch (contentType) {
        case 'image':
            return showImage(attachmentUrl);
        case 'doc':
            return showDocument(attachmentUrl);
    }
}

function attachmentTypeCheck(mimeType, format) {
    let res = null;
    if (mimeType) {
        const splited = mimeType.split('/');
        if (splited[0] === 'image') {
            res = 'image';
        } else if (splited[0] === 'application') {
            res = 'doc';
        }
    }

    if (format) {
        switch (format) {
            case 'jpg':
            case 'png':
            case 'tiff':
            case 'tif':
            case 'jpeg':
                res = 'image';
                break;
            case 'doc':
            case 'docx':
            case 'pdf':
                res = 'doc';
                break;
        }
    }

    return res;
}
