import React, { useState } from 'react';
import ConversationTypeSelectorComponent from './ConversationTypeSelector.component';
import InboxMessagingAssignmentSelect
    from '../../assigmentSelect/InboxMessagingAssignmentSelect.component';
import ModernSelectComponent from '../../forms/ModernSelect.component';
import data from '../../../../constants/data';

const SendConversationHeaderComponent = props => {

    const [languageSelectId, setLanguageSelectId] = useState(2)

    const {
        conType,
        conTypeSelectHandle,
        assigmentSelectEvent,
        assigmentSelectSelectedSlug,
        tagSelectedId,
        tagSelectEvent,
        languageSelectEvent,
        operationType
    } = props;

    const selectEventHandle = (type, item) => {
        if (type === 'language') {
            setLanguageSelectId(item.elId)
            languageSelectEvent(item.elId)
        }
    }

    return (
        <div className='send-conversation-header'>
            <ConversationTypeSelectorComponent
                selectedId={conType}
                selectHandle={conTypeSelectHandle}
            />
            <InboxMessagingAssignmentSelect
                selectEvent={assigmentSelectEvent}
                selectedSlug={assigmentSelectSelectedSlug}
            />

            {conType !== 3 && <ModernSelectComponent
                customClasses='send-conversation-header-tags'
                list={data.tags}
                indexMark='elId'
                valueMark='value'
                selected={tagSelectedId}
                title={'Tag:'}
                selectEvent={tagSelectEvent}
            />}

            
            {operationType === 'expose' && <ModernSelectComponent
                customClasses='send-conversation-header-tags'
                list={data.conversationLanguage}
                indexMark='elId'
                valueMark='value'
                selected={languageSelectId}
                title={'Language:'}
                selectEvent={(item) => selectEventHandle('language', item)}
            />}
        </div>
    );
};

export default SendConversationHeaderComponent;
