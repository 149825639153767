import React, { Component } from 'react';
import { SelectForm } from '../forms';
import messagingStore from '../../../stores/messaging.store';
import { observer } from 'mobx-react';

@observer
class SelectMessagingTemplateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSelectorValue: 0
        }
    }

    templateSelect = e => {
        const id = e.target.value;
        const template = messagingStore.messagesTemplateList.find(q => q._id === id);

        this.props.chooseTemplateEvent(template);
    };

    componentDidMount() {
        const { conType } = this.props;
        messagingStore.filterMessagingTemplates(conType);
    }

    componentWillReceiveProps(nextProps) {
        const { conType } = this.props;
        if (conType !== nextProps.conType) {
            messagingStore.filterMessagingTemplates(nextProps.conType);
        }
    }

    render() {
        const {selectedEmailTemplate} = this.props;

        const list = messagingStore.messagesTemplateListFiltered;
        const selectorList = listOfOptions(list);
        let selectedEmailTemplateLocal;
        if(!selectedEmailTemplate || selectedEmailTemplate === 0) {
            selectedEmailTemplateLocal = 'Choose Template'
        }
        else {
            selectedEmailTemplateLocal = selectorList.find(item => item.elId === selectedEmailTemplate._id);
        }        


        return (
            <div className='select-messaging-template-component'>
                <SelectForm
                    name={'emailTemplate'}
                    value={selectedEmailTemplateLocal}
                    defaultOption={'Choose Template'}
                    selectList={selectorList}
                    wrapperClasses={'select-template'}
                    inputChange={this.templateSelect}
                />
            </div>
        );
    }
}

export default SelectMessagingTemplateComponent;

const listOfOptions = listOfTemplates => {
    return listOfTemplates.map(tmp => {
        return ({
            elId: tmp._id,
            value: tmp.name,
            isSystem: tmp.templateUserType === 2,
            language: tmp.language
        });
    });
};
