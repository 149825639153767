import React, { Fragment } from 'react';
import DefaultImageComponent from './DefaultImage.component';
import { Link } from 'react-router-dom';
import utilsService from '../../services/utils.service';
import './contactwithcoordinator.scss';

const ContactWithCoordinator = props => {
    const { name, user, image, type, profile, email, showPhoneNumber } = props;

    const getTitle = (type) => {
        switch(type) {
            case "contact":
                return "Contact";
            case "recruiter":
                return "Recruiter In Charge";
            case "hr":
                return "HR Manager";
            case "placementRecruiter":
                return "Placement Recruiter";
            case "firstRecruiter":
                return "First Recruiter";
            case "topRecruiter":
                return "Top Recruiter";
            case "companyPrimaryContactPerson":
                return "Primary Contact Person";
            default:
                return "recruiter";
        }
    }

    const getHrUrl = (profile) => {
        let slug = profile ? profile.slug : '';
        if(slug) {
            return `/profile/${slug}`;
        }
        return null;
    }
    const getProfilePhoneNumber = (profile) => {
        let phone = '';
        if(profile && profile.phones) {
            const tmp = profile.phones.find(phone => phone.isPrimary);
            if(tmp) {
                phone = tmp.phone;
            }
        }
        return utilsService.cleanUpThePhoneStr(phone);
    }

    return (
        <div className="coordinator main-details">
            <div className="coordinator-data">
                <div className="image">
                    <DefaultImageComponent
                        url={image}
                        alt={name}
                        profileName={name}
                        isExposed={true}
                    />
                </div>
                <div className="info">
                    <legend>{getTitle(type)}</legend>
                    {type === "hr" && (
                        <div className="contact-with-email"> 
                            {getHrUrl(user && user.profile) ?
                                <Link to={getHrUrl(user && user.profile)}>{utilsService.capitalizeString(name)}</Link>
                                :
                                <p>{utilsService.capitalizeString(name)}</p>
                            }
                            {email && 
                            <Fragment>
                                <span className="separator">|</span>
                                <span className="email">{email}</span>
                            </Fragment>}
                        </div>
                    )}                    
                    {type !== "hr" && (
                        getHrUrl(profile) ?
                            <Link to={getHrUrl(profile)}>{utilsService.capitalizeString(name)}</Link>
                            :
                            <p>{utilsService.capitalizeString(name)}</p>
                    )}                    
                    {type === "hr" && showPhoneNumber && (
                        <p>{getProfilePhoneNumber(user && user.profile ? user.profile : undefined)}</p>
                    )}
                    {type === "companyPrimaryContactPerson" && (
                        <p>{getProfilePhoneNumber(profile)}</p>
                    )}
                    {type === "contact" && (
                        <div className="contact-with-email"> 
                            <p>{name}</p> 
                            <span className="separator">|</span>
                            <span className="email">{email}</span>
                        </div>
                    )}
                    
                </div>
                {!email &&<button
                    onClick={props.contactMeModal}
                    className='button bk-black'>
                    Contact
                </button>}
            </div>
        </div>
    );
};

ContactWithCoordinator.defaultProps = {
    showPhoneNumber: true
};

export default React.memo(ContactWithCoordinator);
