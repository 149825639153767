import React from 'react';
import './progressBar.scss';

const ProgressBar = props => {
    const { fit, text } = props;

    function formatFit(fit) {
        let res = fit;
        if (Number.isInteger(fit)) {
            res = fit + '%';
        }
        return res;
    }

    const formatedFit = formatFit(fit);

    return (
        <div className='progress-bar'>
            <div className="progress-bar-progress" style={{ width: formatedFit }}/>
            {text && (
                <p className="progress-text">
                    {text}
                </p>
            )}
        </div>
    );
};

export default React.memo(ProgressBar);
