import React, { useState, useEffect, } from 'react';
import './dashboardActivitiesPeople.scss';
import { observer } from "mobx-react"
import { DotsIcon } from '../../../../../../components/svg';
import DashboardStatusItem from '../dashboardStatusItem/DashboardStatusItem.component';
import data from '../../../../../../../constants/data';
import messagingStore from '../../../../../../../stores/messaging.store';
import processStore from '../../../../../../../stores/process.store';
import utilsService from '../../../../../../../services/utils.service';

const DashboardActivitiesPeople = observer((props) => {

    const { selectedRecruiterSlugs } = props

    const [potentialMatches, setPotentialMatches] = useState(null);
    const [potentialCandidates, setPotentialCandidates] = useState(null);
    const [suggested, setSuggested] = useState(null);
    const [sourced, setSourced] = useState(null);


    const getPotentialUrl = (status) => {
        return `/candidates?recruiterId=${selectedRecruiterSlugs.join(',')}&PotentialMatches=1&Availability=${status}`
    }

    const getStatusUrl = (status) => {
        const stage = data.stage.find(item => item.value === status);
        const stageIds = stage ? stage.elId : '';

        return `/candidates?recruiterId=${selectedRecruiterSlugs.join(',')}&stages=${stageIds}`;
    }

    useEffect(() => {

        let unmounted = false;

        const getPotentialMatches = async () => {
            if(!unmounted) {
                setPotentialMatches(null);
            }
            const filter = {
                status: data.availabilityOptionsForCandidates.find
                (item => item.value === 'Available').elId
            }
            // const jobs = await searchStore.profileMatchesByRecruiter(selectedRecruiterSlugs._id, filter) // asi: Deleted until elastic search.
            const jobs = [];
            const relatedProfiles = Array.isArray(jobs) ? Array.from(new Set(jobs.map(item => item.relatedProfileId))) : [];
            if(!unmounted) {
                // setPotentialMatches(relatedProfiles.length)
                setPotentialMatches('-')
            }

            return () => {
                unmounted = true;
            }
        }

        const getPotentialCandidates = async () => {
            if(!unmounted) {
                setPotentialCandidates(null);
            }
            // const filter = {
            //     status: data.availabilityOptionsForCandidates.find
            //     (item => item.value === 'Maybe').elId
            // }
            //const jobs = await searchStore.profileMatchesByRecruiter(selectedRecruiterSlugs._id, filter) // asi: Deleted until elasticsearch.
            const jobs = [];
            const relatedProfiles = Array.isArray(jobs) ? Array.from(new Set(jobs.map(item => item.relatedProfileId))) : [];
            if(!unmounted) {
                // setPotentialCandidates(relatedProfiles.length)
                setPotentialCandidates('-')
            }
        }

        const getSourced = async () => {
            if(!unmounted) {
                setSourced(null);
            }

            let body = [
                {field: 'status', value: 1},
                {field: 'readStatus', value: 1},
                {field: 'tag', value: 101}
            ]

            if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
                body = [...body,  {field: 'assignedToUserIds', value: selectedRecruiterSlugs}];
            }

            const response = await messagingStore.getConversationsByDynamicFields(body);

            if(!unmounted) {
                setSourced(response.length)
            }
        }

        const getSuggested = async () => {
            if (!unmounted) {
                setSuggested(null);
            }
            const body = {
                type: 'profiles',
                stages: utilsService.getIdsArrayByStatus('Suggested'),
            }

            //if not all
            if (selectedRecruiterSlugs && selectedRecruiterSlugs.length > 0) {
                body.recruiterSlugs = selectedRecruiterSlugs;
            }
            
            const processes = await processStore.getProcessesByRecruiter(body);
            if (!unmounted && processes) {

                const filteredProcesses = [];
                processes.forEach(item => {
                    if (!filteredProcesses.some(filter => filter.profile === item.profile)) {
                        filteredProcesses.push(item);
                    }
                })
                setSuggested(filteredProcesses.length);
            }

        }

        if(selectedRecruiterSlugs) {
            getPotentialMatches();
            getPotentialCandidates();
            getSourced();
            getSuggested();
        }

        return () => {
            unmounted = true;
        }
        
    }, [selectedRecruiterSlugs])

    return (
        <div className='dashboard-activities-people-wrapper'>
            <div className="more-menu">
                <DotsIcon />

            </div>

            <h1 className="title">People</h1>
            <div className="content">
                <div className="top1">
                    <div>
                        <DashboardStatusItem number={suggested} name={'Suggestions'} 
                             linkTo={getStatusUrl('Suggested')} isClicked={true} />
                    </div>
                    <div>
                        <DashboardStatusItem number={potentialMatches} name={'Potential Matches'} 
                             linkTo={getPotentialUrl(data.availabilityOptionsForCandidates.find(item => item.elId === 0).elId)} 
                             isClicked={false} />   
                    </div>
                </div>
                <div className="bottom">
                    <div>
                        <DashboardStatusItem number={sourced} name={'Sourced'}
                             linkTo={getStatusUrl('Sourced')} isClicked={false} />
                    </div>
                    <div>
                        <DashboardStatusItem number={potentialCandidates} name={'Potential Candidates'} 
                             linkTo={getPotentialUrl(data.availabilityOptionsForCandidates.find(item => item.elId === 1).elId)} 
                             isClicked={false} />
                    </div>
                </div>
            </div>
        </div>

    )
});

DashboardActivitiesPeople.propTypes = {
    // bla: PropTypes.string,
};

DashboardActivitiesPeople.defaultProps = {
    // bla: 'test',
};

export default DashboardActivitiesPeople
