import React from 'react';
import ModalComponent from '../modal/Modal.component';
import PauseProcessComponent from '../actions/PauseProcess.component';

const PauseProcessModal = props => {
    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            <div className='modal-box pause-process-modal'>
                <PauseProcessComponent
                    isModal={true}
                    closeEvent={() => props.closeEvent()}
                    data={props.data}
                />
            </div>
        </ModalComponent>
    );
};

export default PauseProcessModal;
