import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import {CloseIcon} from '../svg';
import {TextAreaForm} from '../forms';
import DefaultImageComponent from '../DefaultImage.component';
import utilsService from '../../../services/utils.service';

class ContactMeModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            textArea: ''
        };
    }

    render() {
        const { textArea } = this.state;
        const { profile } = this.props.data;

        return (
            <ModalComponent
                onClose={() => this.props.closeEvent()}>
                <div className='modal-box contact-me-modal'>
                    <header>
                        <p>Send message to</p>
                        <div className="profile-image">
                            <DefaultImageComponent
                                url={utilsService.getRecruiterInChargeProfileImage(profile)}
                                alt={utilsService.getDisplayName(profile)}
                                profileName={utilsService.getDisplayName(profile)}
                            />
                        </div>
                        <h2>{utilsService.getRecruitersName(profile)}</h2>
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                    </header>

                    <section className="modal-body">
                        <TextAreaForm
                            label={false}
                            value={textArea}
                            inputChange={e => this.setState({ textArea: e.target.value })}
                        />
                    </section>

                    <footer>
                        <button className='button bk-black'>
                            Send
                        </button>
                    </footer>
                </div>
            </ModalComponent>
        );
    }
}

export default ContactMeModal;
