import React, { useState } from 'react';
import ModalComponent from '../modal/Modal.component';
import { CloseIcon } from '../svg';
import ProcessAlreadyExistsComponent from '../processAlradyExists/ProcessAlreadyExists.component';
import SuggestJobsToCandidatesComponent from '../suggest/SuggestJobsToCandidates.component';

const SuggestModal = props => {
    const { addToMatches, sendCandidates, profiles, jobsSlug, jobs, jobLimit, candidateLimit, isFromMatches } = props;

    const [showSomeExists, setShowSomeExists] = useState(false);
    const [someExistsData, setSomeExistsData] = useState(null);

    const callbackWithExistsDataHandle = (data, response) => {
        setShowSomeExists(true);
        setSomeExistsData(data);
    };

    const sentCallback = data => {
        props.requestFinished && props.requestFinished('send', data);
    };


    return (
        <ModalComponent onClose={() => props.closeEvent()}>
            {!showSomeExists && (
                <div className={'modal-box suggest-jobs-to-candidates-modal ' + props.classes}>
                    <div onClick={props.closeEvent}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>
                    <SuggestJobsToCandidatesComponent
                        profiles={profiles}
                        jobsSlug={jobsSlug}
                        jobs={jobs}
                        addToMatches={addToMatches}
                        sendCandidates={sendCandidates}
                        jobLimit={jobLimit}
                        candidateLimit={candidateLimit}
                        closeEvent={props.closeEvent}
                        someExists={callbackWithExistsDataHandle}
                        isFromMatches={isFromMatches}
                        {...{ ...props }}
                    />
                </div>
            )}
            {showSomeExists && (
                <div className={'modal-box show-exists-processes ' + props.classes}>
                    <div onClick={props.closeEvent}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>
                    <ProcessAlreadyExistsComponent
                        data={someExistsData}
                        closeEvent={props.closeEvent}
                        sentCallback={sentCallback}
                    />
                </div>
            )}
        </ModalComponent>
    );
};

export default SuggestModal;
