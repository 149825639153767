import React from 'react';
import { nanoid } from "nanoid";
import { LinkedinIcon3 } from "../../../../../components/svg";
import './LinkedinLinksSection.scss';
import { normalizeLinks, shouldShowIcon } from '../../../../../components/socialLinks/SocialLinks.component';

export function LinkedinLinksSection({ links }) {
  const normalLinks = normalizeLinks(links);
  return (<div className='social-links'>
    {normalLinks.map((link) => {
      const showIcon = shouldShowIcon(link.linkType);
      return showIcon && (
        <div key={link._id ?? nanoid()} className="link">
          <a target='_blank' rel='noopener noreferrer' href={link.link}>
            <LinkedinIcon3 className={link.isPrimary && 'primary-link'} />
          </a>
        </div>
      );
    })}
  </div>)
}
