import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import SendMessagesComponent from '../sendMessages/SendMessages.component';

class SendMessageModalComponent extends PureComponent {
    render() {
        const { items, closeEvent, removeSelection } = this.props;

        return (
            <ModalComponent onClose={closeEvent}>
                <div className='modal-box messaging-modal'>
                    <SendMessagesComponent
                        removeSelection={removeSelection}
                        closeEvent={closeEvent}
                        items={items}
                        isModal={true}
                    />
                </div>
            </ModalComponent>
        );
    }
}

export default SendMessageModalComponent;
