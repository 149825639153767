import React, {Component} from 'react'
import DefaultImageComponent from '../../../components/DefaultImage.component';
import { InterestedIcon, RejectIcon, CloseIcon } from '../../../components/svg';
import notificationsBellStore from '../../../../stores/notificationsAlerts.store';
import { observer } from 'mobx-react';
import utilsService from '../../../../services/utils.service';
import { Link } from 'react-router-dom';
import LoaderComponent from '../../../components/loader/Loader.component';
import './notificationitems.scss'

@observer
class NotificationItems extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            deletingLoader: false,
        }
    }

    getFilteredNotifications(notifications) {
        const {notificationFilter} = this.props;
        if(notificationFilter == 0) {
            return notifications;
        }

        const filteredNotifications = notifications.filter(notification => {

            //message blocked
            switch(notificationFilter) {

                //blocked message

                //chat
                case 61:
                    return notification.type === '6' && notification.tag === 30

                //Send CV
                case 62:
                    return notification.type === '6' && notification.specialActions && notification.specialActions.includes(12)

                //Exposed
                case 63:
                    return notification.type === '6' && notification.specialActions && notification.specialActions.includes(34)      

                default:
                    return notification.type === notificationFilter.toString()
            }
        })
        return filteredNotifications;
    }


    async componentDidUpdate(prevProps,) {
        const showLoader = this.props.showLoader;
        const prevShowLoader = prevProps.showLoader;

        if(prevShowLoader === false && showLoader === true ) {
            this.setState({loader: true});
        }
        else if(prevShowLoader === true && showLoader === false ) {
            this.setState({loader: false});
        }
    }

    async handleOnClick(e, index) {
        const notifications = this.getFilteredNotifications(notificationsBellStore.notifications);
        const ids = [];
        let clickedNotification = undefined;
        notifications.forEach((notification, i) => {
            if(index === i) {
                clickedNotification = notification;
                notification.isRead = true;
                ids.push(notification._id);
                return;
            }
        });

        if(ids && ids.length > 0) {
            notificationsBellStore.markNotificationsAsRead(ids);
        }

        if(clickedNotification && this.props.onSelectNotification) {
            await this.props.onSelectNotification(clickedNotification)
        }

        this.setState({notifications: notifications});
    }

    getCreatorName = (notification) => {
        if (!notification.creatorData) {
            return ""
        }
        return `${utilsService.capitalizeString(notification.creatorData.firstName)} ${utilsService.capitalizeString(notification.creatorData.lastName)}`;
    }
    getAssignToName = (notification) => {
        if(notification.assignToRecData) {
            return `${utilsService.capitalizeString(notification.assignToRecData.firstName)} ${utilsService.capitalizeString(notification.assignToRecData.lastName)}`;
        }
        else {
            return 'N/A';
        }
        
    }
    getProfileName = (notification) => {
        if(!notification.profileData)
            return '';
        return `${utilsService.capitalizeString(notification.profileData.firstName)} ${utilsService.capitalizeString(notification.profileData.lastName)}`;
    }
    getJobName = (notification) => {
        if(!notification.jobData)
            return '';
        return `${utilsService.capitalizeString(notification.jobData.title)}`;
    }
    getCompanyName = (notification) => {
        if(!notification.companyData)
            return '';
        return `${utilsService.capitalizeString(notification.companyData.name)}`;
    }

    getDescription = (notification) => {
        switch (Number(notification.type)) {
            case 1: 
                return ` Has Added To Approve - ${this.getProfileName(notification)} To ${this.getJobName(notification)} @ ${this.getCompanyName(notification)}`;
            default:
                return '';
        }
    }
    getCreateDate = (notification) => {
        return `${utilsService.getFormattedDate(notification.createTime)}, 
        ${utilsService.getFormattedDate(notification.createTime, 3)}`;
    }
    getIsShowActionButtons = (notification) => {
        return false;
    }
    getAction = (notification) => {
        switch (Number(notification.type)) {
            case 1: 
                return {text: `View To Approve`, link: `/jobs/${notification.jobData.slug}?tab=3`};
            default:
                return {text: ``, link: ''};
        }
    }
    getNotificationLine = (notification) => {

        switch (Number(notification.type)) {
            case 1:
                return (<div className="message-text">
                    <span>To Approve - </span>
                    <span className="message-bold">{this.getCreatorName(notification)}</span>
                    <span> Has Added </span>
                    <span className="message-bold">{this.getProfileName(notification)}</span>
                    <span> To </span>
                    <span className="message-bold">{utilsService.removeWrongCharsFromString(this.getJobName(notification))}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                </div>);
            case 2:
                return (<div className="message-text">
                    <span>Request For Details - </span>
                    <span className="message-bold">{this.getCreatorName(notification)}</span>
                    <span> Ask For Details Of </span>
                    <span className="message-bold">{this.getProfileName(notification)}</span>
                    <span> To </span>
                    <span className="message-bold">{this.getJobName(notification)}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                </div>);
            case 3:
                return (<div className="message-text">
                    <span>Uploaded CV To ATS - </span>
                    <span className="message-bold">{this.getProfileName(notification)}</span>
                    <span> To </span>
                    <span className="message-bold">{this.getJobName(notification)}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                    {notification.atsName && (
                        <React.Fragment>
                            <span> Into </span>
                            <span className="message-bold">{notification.atsName}</span>
                        </React.Fragment>
                    )}
                </div>);
            case 4:
                return (<div className="message-text">
                    <span>New Job - </span>
                    <span className="message-bold">{this.getCreatorName(notification)}</span>
                    <span> Created Job </span>
                    <span className="message-bold">{this.getJobName(notification)}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                    <span> Using </span>
                    <span className="message-bold">Employer App.</span>
                </div>);
            case 5:
                return (<div className="message-text">
                    <span>New Discussion - </span>
                    <span> From </span>
                    <span className="message-bold">{this.getCreatorName(notification)}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                    <span> Regarding candidate </span>
                    <span className="message-bold">{this.getProfileName(notification)}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getJobName(notification)}</span>
                </div>);
            case 6:
                const profileName = this.getProfileName(notification);
                const targetEmail = notification.targetEmail;
                return (<div className="message-text">
                    <span>Your email to </span>
                    <span 
                        title={targetEmail}
                        className="message-bold">{profileName ? profileName : targetEmail}
                    </span>
                    <span> got blocked</span>
                </div>);
            case 7:
                return (<div className="message-text">
                    <span>Check decline of - </span>
                    <span className="message-bold">{this.getProfileName(notification)}</span>
                    <span> after exposure to - </span>
                    <span className="message-bold">{utilsService.removeWrongCharsFromString(this.getJobName(notification))}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                </div>);
            case 8:
                const profileName2 = this.getProfileName(notification);
                const targetPhone = notification.telephoneNumber;
                return (<div className="message-text">
                    <span>Your whatsapp to </span>
                    <span 
                        title={targetPhone}
                        className="message-bold">{profileName2 ? profileName2 : targetPhone}
                    </span>
                    <span> failed</span>
                </div>); 
            case 9:
                return (<div className="message-text">
                    <span>Send Talent To Job - </span>
                    <span className="message-bold">{this.getProfileName(notification)}</span>
                    <span> has been sent to </span>
                    <span className="message-bold">{this.getJobName(notification)}</span>
                    <span> @ </span>
                    <span className="message-bold">{this.getCompanyName(notification)}</span>
                </div>);         
            default:
                return (<div></div>);
        }
    }

    getLinkData = (notification) => {
        switch (Number(notification.type)) {
            case 1:
                if (!notification.jobData) {
                    return null;
                }
                return {text: `View To Approve`, link: `/jobs/${notification.jobData.slug}?tab=3`};
            case 2:
                if (!notification.jobData) {
                    return null;
                }
                return {text: `View Request`, link: `/jobs/${notification.jobData.slug}?tab=2`};
            case 3:
                return undefined;
            case 4:
                if (!notification.jobData) {
                    return null;
                }
                return {text: `View New Job`, link: `/jobs/${notification.jobData.slug}?tab=2`};
            case 5:
                return {text: `View New Discussion`, link: `/inbox?id=${notification.conversationId}`};
            case 6:
                
                let object = {
                    text: 'View Email',
                    link: `/inbox?id=${notification.conversationId}`,
                    buttonsNumber : 1
                };

                //process
                if(notification.specialActions && notification.specialActions.some(item => item === 12 || item === 34 )) {
                    object.text2 = 'View Activities';
                    object.link2 = `/jobs/${notification.jobSlug}?process=${notification.processSlug}&tab=2`;
                    object.buttonsNumber = 2;
                }
                return object;
            case 7:
                if (notification.jobData) {
                    return null;
                }
                return {text: `View Activities`, 
                    link: `/jobs/${notification.jobData.slug}?process=${notification.processData.slug}&tab=2`};
            case 8:
                const type = notification.messageType === '2'? "Sms" : "Whatsapp"
                const object2 = {
                    text: `View ${type}`,
                    link: `/inbox?id=${notification.conversationId}`,
                    buttonsNumber : 1
                };

                return object2;                
            default:
                return '';
        }
    }

    isShowCloseButton = (notification) => {
        if(notification.type == 3 || notification.type == 4 || (notification.type >= 6 && notification.type <= 8))
            return true;

        return false;
    }

    handleCloseButton = async (notification) => {
        this.setState({deletingLoader: true});

        notificationsBellStore.deleteNotifications([notification._id]);
        await notificationsBellStore.getNotifications();

        this.setState({deletingLoader: false});
    }

    render() {

        const {loader, deletingLoader} = this.state;
        const notifications = this.getFilteredNotifications(notificationsBellStore.notifications);

        return (
            <div className="notification-item-container">

                <div className="notifications-container custom-scroll">
                    {!loader && notifications.length > 0 && notifications.map((notification, index) => {
                        const isShowActionButtons = this.getIsShowActionButtons(notification);                        
                        const linkData = this.getLinkData(notification);
                        const notificationLineHtml = this.getNotificationLine(notification);
                        const isShowCloseButton = this.isShowCloseButton(notification);
                        if (!notification.creatorData) {
                            return null;
                        }        
                        return (
                            <div className={`notification-container ${notification.isRead ? '' : 'selected'}`} onClick={(e) => this.handleOnClick(e, index)} key={index}>
                                <DefaultImageComponent
                                    url={utilsService.getUserImage({ emsId: notification.creatorData.emsId })}
                                    alt={this.getCreatorName(notification)}
                                    profileName={this.getCreatorName(notification)}
                                    isExposed={true}
                                    width={50}
                                />
                                <div className="message">
                                    {notificationLineHtml}
                                    <span className="assign-to"> {`(Assigned to: ${this.getAssignToName(notification)})`}</span>
                                </div>
                                <div className="date">{this.getCreateDate(notification)}</div>
                                    <div>
                                        {isShowActionButtons ? (
                                            <div className="button-container">
                                                <InterestedIcon />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    <div>
                                        {isShowActionButtons ? (
                                            <div className="button-container">
                                                <RejectIcon />
                                            </div>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                    {linkData ? (
                                            <div className="view-lbl">
                                                {linkData.buttonsNumber === 2 ?
                                                <div className='two-buttons'>
                                                    <Link to={linkData.link}>{linkData.text}</Link>
                                                    <Link to={linkData.link2}>{linkData.text2}</Link>
                                                </div> 
                                                :
                                                <Link to={linkData.link}>{linkData.text}</Link>}
                                            </div>
                                    ) : (
                                        <div></div>
                                    ) }
                                    {isShowCloseButton && (
                                        <React.Fragment>
                                            {deletingLoader ? (
                                                <div className="loading"></div>
                                            ) : (
                                                <div className='close-button-container' onClick={() => this.handleCloseButton(notification)}><CloseIcon /></div>
                                            )}
                                        </React.Fragment>
                                    )}
                            </div>
                        )
                    })}
                    {!loader && (!notifications || notifications.length === 0) && (
                        <div className="zero-notifications">Zero Notifications For Now...</div>
                    )}
                    {loader && (
                        <LoaderComponent />
                    )}
                </div>
            </div>
        )
    }
}

export default NotificationItems;