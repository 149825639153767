import React from 'react';
import './createComponent.scss';
import { InputForm, SelectForm, TextAreaForm } from '../../../../../../components/forms';
import ConversationTypeSelectorComponent
    from '../../../../../../components/sendMessages/sendConversation/ConversationTypeSelector.component';
import messagingStore from '../../../../../../../stores/messaging.store';
import userStore from '../../../../../../../stores/user.store';
import utilsService from '../../../../../../../services/utils.service';

class CreateTemplateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            body: '',
            conType: 1,
            subject: '',
            language: 1,
            loader: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { item } = this.props;

        if (item) {
            if (!prevProps.item) {
                this.setState({
                    name: item.name,
                    body: item.body,
                    conType: item.templateType,
                    subject: item.subject,
                    language: item.language,
                    loader: false
                });
            } else if (prevProps.item && prevProps.item._id !== item._id) {
                this.setState({
                    name: item.name,
                    body: item.body,
                    conType: item.templateType,
                    subject: item.subject,
                    language: item.language,
                    loader: false
                });
            }
        }
    }

    inputChange = (e, type, valid) => {
        if (valid) {
            this.setState({ [type]: e.target.value, [valid]: e.target.value.length > 1 });
        } else {
            this.setState({ [type]: e.target.value });
        }

    };

    conTypeSelectHandle = conType => {
        this.setState({
            conType: conType.elId
        });
    };

    clearAll = () => {
        this.setState({
            name: '',
            body: ''
        });
    };

    handleSubmit = async () => {
        const { name, body, conType, subject, language } = this.state;
        const user = userStore.user;
        this.setState({
            loader: true
        });
        await messagingStore.createMessagingTemplate({
            userId: user._id,
            userSlug: user.slug,
            templateType: conType,
            status: 1,
            subject,
            language: Number(language),
            name,
            body
        });

        this.setState({
            loader: false,
            name: '',
            body: '',
            subject: ''
        });
    };

    render() {

        const { name, body, conType, subject, language } = this.state;

        return (
            <div className='user-details-message-templates-create-template'>
                <h3>New Template</h3>

                <div className="user-details-message-templates-create-template-header">
                    <ConversationTypeSelectorComponent
                        selectedId={conType}
                        selectHandle={this.conTypeSelectHandle}
                    />
                </div>

                <SelectForm
                    label={'Language'}
                    name={'pewpew'}
                    value={language}
                    selectList={[{ elId: 1, value: 'English' }, { elId: 2, value: 'Hebrew' }]}
                    inputChange={e => this.inputChange(e, 'language')}
                />

                <InputForm
                    label={'Template Name'}
                    value={name}
                    dir={utilsService.getDir(name)}
                    type={'text'}
                    inputChange={e => this.inputChange(e, 'name')}
                />


                <InputForm
                    label={'Message subject (optional)'}
                    value={subject}
                    dir={utilsService.getDir(name)}
                    type={'text'}
                    inputChange={e => this.inputChange(e, 'subject')}
                />

                <TextAreaForm
                    label={'Message'}
                    value={body}
                    dir={utilsService.getDir(body)}
                    type={'text'}
                    inputChange={e => this.inputChange(e, 'body')}
                />

                <footer>
                    <div className="user-details-message-templates-create-template-add-variable">
                        <h5>Variables available</h5>
                        <ul>
                            <li>
                                #name# - receiver first and last name
                            </li>
                            <li>
                                #firstname# - receiver first name
                            </li>
                            <li>
                                #lastname# - receiver last name
                            </li>
                            <li>
                                #sendername# - your name
                            </li>
                            <li>
                                #senderfirstname# - your first name
                            </li>
                            <li>
                                #senderlastname# - your last name
                            </li>
                        </ul>
                    </div>
                    <p
                        onClick={this.clearAll}
                        className="user-details-message-templates-create-template-clear-all">
                        Clear All
                    </p>
                    <button
                        onClick={this.handleSubmit}
                        className="button bk-darkblue">
                        Add
                    </button>
                </footer>
            </div>
        );
    }
}

export default CreateTemplateComponent;
