import { Component, Children, cloneElement } from 'react';

class BaseSearchWithNetwork extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
        this.typingTimeout = 0;
    }

    query = (text) => {
        const { networkRequest, numberOfCharsToPredicate, moreParams } = this.props;
        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        if(text.length >= numberOfCharsToPredicate) {
            this.typingTimeout = setTimeout(async () => { 
                const data = await networkRequest(text, moreParams);
                this.setState({items: data});
            }, 300);
        }
        else {
            this.setState({items: []});
        }
    }

    render() {

        const { items } = this.state;
        const { children } = this.props
        return (
            Children.map(children, child => {
                return cloneElement(child, {
                    items: items,
                    query: this.query
                });
            })
            
        );
    }
}

BaseSearchWithNetwork.propTypes = {

};

BaseSearchWithNetwork.defaultProps = {
    numberOfCharsToPredicate: 1,
};

export default BaseSearchWithNetwork;

