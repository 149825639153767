import { debounce, useDebouncedState } from '@brightsource/brightsource-ui-lib';
import pick from 'lodash/pick';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import metaDataStore from '../stores/meta-data.store';
import fetchService from './fetch.service';
import utilsService from './utils.service';

export const useSearchMetadata = (categories = []) => {
  const [metadataList, setMetadataList] = useState(null);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const URL = `/meta-data?categories=${categories.join(',')}`;
        const { data: response } = await fetchService.authenticatedGet(URL);

        const data = Object.entries(response ?? {}).reduce(
          (allMetadata, [key, value]) => ({ ...allMetadata, [key]: Object.values(value) }),
          {}
        );

        setMetadataList(data);
      } catch (error) {
        console.log('Failed to fetch metadata', error);
      }

      setIsFetching(false);
    })();
  }, [categories]);

  return { metadataList, isFetching };
};

export const useMetadataLoader = (categories = []) => {
  const data = pick(metaDataStore.categoryOptions, categories);
  // const isLoading = metaDataStore.isLoading;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      await metaDataStore.getCategories(categories);

      setIsLoading(false);
    })();
  }, [categories]);

  return { data, isLoading };
};

export const useSearchCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const onSearchDebounce = debounce(async (searchValue = '') => {
    if (!searchValue) {
      setCompanies([]);
      return;
    }

    if (companies.some((company) => company.name === searchValue)) return;

    setIsSearching(true);

    const { data } = await fetchService.authenticatedPost('/companies-search-partial-name', {
      limit: 1000,
      select: '_id slug name',
      text: searchValue,
    });

    if (Array.isArray(data)) {
      setCompanies(data.map((company) => ({ ...company, elId: company._id, value: company.name })));
    } else {
      setCompanies([]);
    }

    setIsSearching(false);
  }, 500);

  return { companies, isSearching, onSearchDebounce };
};

export const useSchoolsByIds = (schoolIds = []) => {
  const [schools, setSchools] = useState([]);

  useEffect(() => {
    if (!schoolIds.length) return;

    (async () => {
      try {
        const { data } = await fetchService.authenticatedGet(
          `/companies?slugs=${schoolIds.join(',')}&select=name slug`
        );

        const metadataSchools = Array.isArray(data)
          ? data.map((school) => ({ ...school, elId: school.slug, name: school.name }))
          : [];

        setSchools(metadataSchools);
      } catch (error) {
        console.log('Failed to get schools by elIds', error);
      }
    })();
  }, [schoolIds]);

  return { schools };
};

export const useSearchSchools = () => {
  const [schools, setSchools] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const onSearchDebounce = debounce(async (searchValue = '') => {
    if (!searchValue) {
      setSchools([]);
      return;
    }

    if (schools.some((education) => education.name === searchValue)) return;

    setIsSearching(true);

    const { data } = await fetchService.authenticatedPost('/companies-search-partial-name', {
      limit: 1000,
      type: 2,
      select: '_id slug name',
      text: searchValue,
    });

    setSchools(Array.isArray(data) ? data : []);

    setIsSearching(false);
  }, 500);

  return { schools, isSearching, onSearchDebounce };
};

export const useCitiesByIds = (cityIds = []) => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (!cityIds.length) return;

    (async () => {
      try {
        const data = await metaDataStore.getWorldCities(`ids=${cityIds.join(',')}`);

        if (Array.isArray(data)) {
          setCities(data.map(utilsService.getWorldCityOption));
        } else {
          setCities([]);
        }
      } catch (error) {
        console.log('Failed to get cities by elIds', error);
      }
    })();
  }, [cityIds]);

  return { cities };
};

export const useSearchWorldCities = (country = '', filterCities = false) => {
  const [cities, setCities] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const onSearchDebounce = debounce(async (searchValue = '') => {
    if (!searchValue) {
      setCities([]);
      return;
    }

    if (cities.some((c) => utilsService.getWorldCityValue(c) === searchValue)) return;

    setIsSearching(true);

    let query = `city=${searchValue}&limit=100&filterCities=${filterCities}`;

    if (country) {
      query += `&country=${country}`;
    }

    const data = await metaDataStore.getWorldCities(query);

    if (Array.isArray(data)) {
      setCities(data.map(utilsService.getWorldCityOption));
    } else {
      setCities([]);
    }

    setIsSearching(false);
  }, 500);

  return { cities, isSearching, onSearchDebounce };
};

/**
 *
 * @param debouncedTime
 * @param {Function} dataLoader
 * @return {{isLoading: boolean, options: *[], updateSearch: (value: (((prevState: string) => string) | string)) => void}}
 */
export function useSearchDebounce({ debouncedTime = 500, dataLoader }) {
  const [, setSearch, searchText] = useDebouncedState('', debouncedTime);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function search() {
      if (searchText.length > 0) {
        setIsLoading(true);
        const data = await dataLoader(searchText);

        setOptions(data);
        setIsLoading(false);
      }
    }

    search();
  }, [searchText]);

  return {
    options,
    updateSearch: setSearch,
    isLoading,
  };
}

export const useSearchParams = (
  locationSearch = '',
  textKeys = [],
  numberKeys = [],
  textArrayKeys = []
) => {
  const searchParams = useMemo(() => {
    const search = qs.parse(locationSearch, { ignoreQueryPrefix: true, comma: true });

    return Object.entries(search ?? {}).reduce((data, [key, value]) => {
      if (textKeys.includes(key)) return { ...data, [key]: value };
      if (numberKeys.includes(key)) return { ...data, [key]: Number(value) };

      if (key === 'recruiterId') {
        return {
          ...data,
          [key]: value === 'all' ? [] : Array.isArray(value) ? value.map((x) => x) : [value],
        };
      }

      if (textArrayKeys.includes(key)) {
        return {
          ...data,
          [key]: Array.isArray(value) ? value.map((x) => x) : [value],
        };
      }

      return {
        ...data,
        [key]: Array.isArray(value) ? value.map((x) => Number(x)) : [Number(value)],
      };
    }, {});
  }, [locationSearch]);

  return searchParams;
};
