import React, {Component} from 'react';
import onClickOutside from 'react-onclickoutside';
import processStore from '../../../../stores/process.store';
import jobStore from '../../../../stores/job.store';
import {observer} from 'mobx-react';
import utilsService from '../../../../services/utils.service';
import userStore from '../../../../stores/user.store';

@observer
class CandidateForFilterComponent extends Component {

    handleMenuClick = (jobSlug, label, e) => {
        const { companySlug } = this.props
        const isRecruiter = utilsService.isRecruiter(userStore.user);
        e.preventDefault();
        if (jobSlug) {
            processStore.filterProcessesByJobSlug(jobSlug, companySlug, isRecruiter);
        } else {
            processStore.filterProcessesByJobSlug(null, companySlug, isRecruiter);
        }

        processStore.candidateForDefaultValue = label;
    };

    async componentDidMount() {
        if (!jobStore.jobsFetched) {
            await jobStore.getJobs();
        }

    }

    handleClickOutside = () => {
        this.props.clickOutside();
    };

    render() {
        let jobs = [];
        if (jobStore.jobsFetched) {
            jobs = Object.keys(jobStore.jobs).map(key => jobStore.jobs[key]).filter(item => item.status === 1);
        }

        return (
            <div className='hidden-menu shadow4 candidate-for-filter'>
                <div
                    onClick={(e) => this.handleMenuClick(null, 'Applied for', e)}
                    className="hidden-menu-item">Applied for
                </div>
                {jobs.map(item => {
                     return (
                        <div
                            title={item.title}
                            onClick={(e) => this.handleMenuClick(item.slug, item.title, e)}
                            className="hidden-menu-item" key={item.slug}>{item.title}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default onClickOutside(CandidateForFilterComponent);
