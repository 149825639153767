import React from 'react';
import Template from './Template.component';

const SuperInput = props => {
    const {
        profiles, removeSelection, isEmail,
        ccButtonsShowHide, bcc, cc
    } = props;

    let classes = 'email-messaging-input-template';
    if (isEmail) {
        classes += ' smses';
    }

    return (
        <div className={classes}>
            <div className="email-addresses-wrap">
                <label>To</label>
                <div className="email-addresses">
                    <div className="email-addresses-ind">
                        {profiles.map(item => {
                            return (
                                <Template
                                    key={item._id}
                                    item={item}
                                    isEmail={isEmail}
                                    removeSelection={removeSelection}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuperInput;
