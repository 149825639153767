import React, { Component } from 'react';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';
import { CloseIcon } from '../svg';
import utilsService from '../../../services/utils.service';
import profileStore from '../../../stores/profile.store';
import companyStore from '../../../stores/company.store';
// import data from '../../../constants/data';
import dataUtils from '../../../constants/data';

class PromoteProcessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            comment: ''
        };
    }

    getNextStatus = () => {
        switch (this.props.data.modalType) {
            case 'in-progress':
                return 3;
            case 'offer':
                return 9;
            case 'interview':
                return 2;
            case 'hire':
                return 7;
            default:
                break;
        }
    };

    getHeaderText = (str = '') => {
        switch (this.props.data.modalType) {
            case 'in-progress':
                return (`Update ${str} Status To In-Progress?`);
            case 'offer':
                return (`Update ${str} Status To Offer?`);
            case 'interview':
                return (`Update ${str} Status To Interview?`);
            case 'hire':
                return (`Update ${str} Status To Hire?`);
            default:
                break;
        }
    };

    getButtonText = () => {
        switch (this.props.data.modalType) {
            case 'in-progress':
            case 'interview':
            case 'offer':
            case 'hire':
                return ('Update');
            default:
                break;
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { closeEvent, isModal, data, isSingleJobView, successEvent, isSingleCompanyView } = this.props;
        const { process, modalType, profile, job } = data;
        const { comment } = this.state;

        this.setState({ updating: true });

        const nextStatus = this.getNextStatus();

        //hired
        if(nextStatus === 7) {

            //in order to close all processes regarding to candidate and change availability
            const processes = await processStore.getProcessesByProfileId(profile._id);

            const company = companyStore.companies[job.companySlug];
            let profileBody = {
                isAvailable: false,
                company: company.name,
                companyId: company._id,
                companySlug: company.slug,
                companyEmsId: company.emsId,
                followUpDate: Date.now(),
                user: userStore.user._id,
                userSlug: userStore.user.slug
            }

            const processesBody = []

            // close all relevant processes except hired
            processes.forEach(item => {
                const isDeclineOrWithdrawn = utilsService.checkIfDeclineOrWithdrawn(item);
                if (!isDeclineOrWithdrawn) {
                    let newStatus;
                    let commentToSend;
                    if (item.jobSlug === job.slug) {
                        const candidateOption = dataUtils.close_options_candidate_side.find(item => item.id === 7);
                        newStatus = candidateOption.id;
                        commentToSend = candidateOption.reason;
                    }
                    else {
                        const candidateOption = dataUtils.close_options_candidate_side.find(item => item.id === 363);
                        newStatus = candidateOption.id;
                        commentToSend = candidateOption.reason;
                    }
                    const body = {
                        id: item._id,
                        status: newStatus,
                        processSlug: item.slug,
                        profileId: item.profile,
                        comment: commentToSend,
                        eventType: 'Close',
                        eventTypeId: newStatus,
                        decisionMakerEmail: userStore.user.email,
                        decisionMakerName: userStore.user.displayName,
                        previousStatus: item.status,
                    };
                    processesBody.push(body)
                }
            });
    
            const promises = []
            promises.push(profileStore.updateProfile(profile._id, profileBody));
            if (processes && processes.length > 0) {
                promises.push(processStore.changeStatuses(processesBody, isSingleJobView, isSingleCompanyView, false));
            }
    
            await Promise.all(promises);
        }
        else {
            
            const id = process._id;
            const body = {
                status: this.getNextStatus(),
                processSlug: process.slug,
                profileId: process.profileId,
                comment: comment,
                eventType: modalType,
                eventTypeId: this.getNextStatus(),
                decisionMakerEmail: userStore.user.email,
                decisionMakerName: userStore.user.displayName
            };

            await processStore.changeStatus(id, body, isSingleJobView, isSingleCompanyView);
        }

        this.setState({ updating: false });
        if (isModal) {
            closeEvent();
        } else {
            successEvent();
        }
    };

    handleCloseClick = () => {
        const { cancelEvent, closeEvent, isModal } = this.props;
        if (isModal) {
            closeEvent();
        } else {
            cancelEvent();
        }
    };

    render() {
        const { data, isModal } = this.props;
        const { profile, job, company } = data;
        const { updating } = this.state;

        return (
            <div className='action-process promote-process'>
                <header>
                    <h2> {this.getHeaderText(profile.firstName)}</h2>
                    {isModal && (<div onClick={this.handleCloseClick}
                        className='circle-close modal-close'>
                        <CloseIcon />
                    </div>)}
                    <p className='capitalized'>
                        Position '{job.title}' at '{company.name}'
                    </p>
                </header>
                <footer>
                    <button onClick={this.handleCloseClick}
                        className='button gray'>
                        Cancel
                    </button>
                    <button
                        onClick={this.handleSubmit}
                        className={`button black handler ${updating ? 'loading' : ''}`}>
                        {this.getButtonText()}
                    </button>
                </footer>
            </div>
        );
    }
}

export default PromoteProcessComponent;
