import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import './dropdownWithSubDirectories.scss';
import { FilterIcon } from '../../svg';
import UseOutsideClick from "../../OutsideClickHooks.component"
import utilsService from '../../../../services/utils.service';


const DropdownWithSubDirectories = (props) => {
  const [isMainOpen, setIsMainOpen] = useState(false);
  const [selectedSubItem, setSelectedSubItem] = useState({elId: -1, isOpen: false });

  const outsideClickRef = useRef();

  UseOutsideClick(outsideClickRef, () => {
    if (isMainOpen) {
      setIsMainOpen(false);
      setSelectedSubItem({elId: -1, isOpen: false});
    }
  });

  const handleItemClicked = (item) => {
    const { isMultiDropdown } = props;
    if (!isMultiDropdown) {
      setTimeout(() => {
        setIsMainOpen(false)
        setSelectedSubItem({elId: -1, isOpen: false});
      }, 200);
    }

    props.titleFilterClick(props.column, item)

  }

  const SubDirectoryComponent = (props) => {

    const { item } = props;

    const handleSubItemClicked = (subItem) => {
      setIsMainOpen(false)
      setSelectedSubItem(-1);

      props.titleFilterClick(props.column, item, subItem)
    }

    const handleMainItemClicked = (item) => {
      if(item.elId === selectedSubItem.elId) {
        setSelectedSubItem({...selectedSubItem, isOpen: !selectedSubItem.isOpen});
      }
      else {
        setSelectedSubItem({elId: item.elId, isOpen: true});
      } 
    }

    return (
      <div className={"with-sub-directory " + props.class + ' ' +  item.class} onClick={() => handleMainItemClicked(item)}>
        {getItemIcon(item)}
        {getItemLabel(item)}
        <div className="button-wrapper">
          <div className='button selector close' />
        </div>

        {item.elId === selectedSubItem.elId && selectedSubItem.isOpen && 
          <div className="hidden-menu shadow4 sub-directory" >

            {item.subItems && item.subItems.map((subItem, key) => {

              return (
                <div
                  key={key}
                  onClick={() => handleSubItemClicked(subItem, item)}
                  className="hidden-menu-item">
                  <label className={subItem.isSelected ? (subItem.labelSelected ? subItem.labelSelected : 'label-selected') : ''}>{subItem.value}</label>
                </div>
              )
            })}
          </div>}
      </div>
    )
  }

  const getLeftTitleIcon = () => {
    return (
      <Fragment>
        {props.leftIcon && <div className={props.leftIcon} />}
        {props.leftIconImg && 
        <div className={props.leftIconImgClass} 
          onClick={() => props.leftIconImgClick && props.leftIconImgClick()}>
          {props.leftIconImg}
        </div>
        }
      </Fragment>
    )
  }

  const getRightTitleIcon = () => {
    return (
      <div className={"dropdown-icon " + (props.rightIcon === 'arrow' ? 'button arrow' : '')} 
        onClick={(e) =>  {
          e.stopPropagation();
          setIsMainOpen(!isMainOpen);
        }}>
        {props.rightIconImg ?? (props.rightIcon !== 'arrow' && <FilterIcon />)}
      </div>
    )
  }

  const getItemIcon = (item) => {
    return utilsService.getRightIcon(item.icon, (item.isSelected ? item.iconSelectedClass : ''));
  }

  const getItemLabel = (item) => {
    return <label className={item.isSelected ? (item.labelSelected ? item.labelSelected : 'label-selected') : ''} title={item.value}>
      {item.value}
    </label>
  }

  const getMainClasses = () => {
    let classes = 'dropdown-with-sub-directories';
    if(props.viewType === 'normal') {
      classes += ' shadow4 normal-view'
    }
    return classes;
  }

  return (
    <div 
      className={getMainClasses()} ref={outsideClickRef}>

      {getLeftTitleIcon()}

      {props.currentColumnName && <h2 title={props.column}>{props.currentColumnName}</h2>}

      {getRightTitleIcon()}

      {isMainOpen &&
        <div className="hidden-menu shadow4">

          {props.list && props.list.map((item, index) => {

            return (
              <div
                key={index}
                className={"hidden-menu-item " + (props.selectedItemIndex === index ? 'selected-item' : "")}>
                {item.subItems ?
                  <SubDirectoryComponent column={props.column} item={item} {...{ ...props }} />
                  :
                  <div className={item.class} onClick={(e) => {
                    e.stopPropagation();
                    handleItemClicked(item);
                  }}>
                    {getItemIcon(item)}
                    {getItemLabel(item)}
                  </div>}
              </div>
            )
          })}

        </div>
      }
    </div>
  )
}

DropdownWithSubDirectories.propTypes = {
  // bla: PropTypes.string,
};

DropdownWithSubDirectories.defaultProps = {
  // bla: 'test',
};

export default DropdownWithSubDirectories;
