import React, {Component} from 'react';
import processStore from '../../../stores/process.store';
import Applicants from '../../components/applicants/Applicants.component';
import NoResultsComponents from './components/NoResults.component';
import NavigationBar from '../../components/navigationBar/NavigationBar.component';
import './searchResults.scss';

class SearchResultsView extends Component {
    componentDidMount() {
        document.title = 'Search Results - Ethosia';
    }

    renderRelevantComponent = data => {
        if (data.length === 0) {
            return (
                <NoResultsComponents/>
            );
        } else {
            return (
                <div className="max-width main-content-space search-results-wrapper">
                    <h1>Search results</h1>
                    <Applicants
                        loader={false}
                        data={data}
                        searchResults={true}
                    />
                </div>
            );
        }
    };

    render() {

        const data = processStore.searchResults.slice();

        return (
            <div>
                <NavigationBar/>
                {this.renderRelevantComponent(data)}
            </div>
        );
    }
}

export default SearchResultsView;
