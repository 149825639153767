import { action, observable, spy } from 'mobx';
import fetchService from '../services/fetch.service';
import profileStore from './profile.store';
import jobStore from './job.store';
import companyStore from './company.store';
import utilsService from '../services/utils.service';
import userStore from './user.store';
import messagingStore from './messaging.store';
import processStore from './process.store';
import dayjs from 'dayjs';
import data from '../constants/data';

class SearchStore {

    advancedSearchRequestsCounter = 0;
    searchPersonsRequestCounter = 0;
    @observable advancedPeople = this.initAdvancedPeople();

    initAdvancedPeople() {
        return {
            keyWords: '',
            emsBsId: '',
            idNumber: '',
            category: 0,
            sector: 0,
            selectedCategories: [],
            selectedRoles: [],
            selectedSkills: [],
            hiddenSuggestedList: [],
            selectedLocations:[],
            selectedDLocations: [],
            selectedPositions: [],
            selectedSeniorities: [],
            selectedProjectTypes: [],
            selectedLanguages: [],
            industries: [],
            selectedDegreeTypes: [],
            salaryRange: {
                min: 0,
                max: 250000
            },
            ageRange: {
                min: 0,
                max: 90
            },
            selectedSalary: 0,
            followUpDateFrom: '',
            followUpDateTo: '',
            selectedAvailability: [],
            selectedLinkedin: 0,
            schoolType: 0,
            summary:0,
            recruiterInCharge: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            currentCompany: '',
            previousCompany: '',
            selectedStages: [],
            selectedDegreeNames: [],
            selectedEducationInstitutions: [],
            tags: [],
            operatorRoles: 'In',
            operatorSkills: 'In'
        }
    }

    @observable advancedJob = this.initAdvancedJob();

    initAdvancedJob() {
        return {
            emsBsId: '',
            category: 0,
            sector: 0,
            selectedCategories: [],
            selectedRoles: [],
            selectedSkills: [],
            hiddenSuggestedList: [],
            selectedDLocations: [],
            selectedLocation: 0,
            selectedPositions: [],
            selectedSeniorities: [],
            selectedProjectTypes: [],
            selectedLanguages: [],
            industries: [],
            followUpDateFrom: '',
            followUpDateTo: '',
            selectedDegreeTypes: [],
            salaryRange: {
                min: 0,
                max: 250000
            },
            selectedSalary: 0,
            schoolType: 0,
            recruiterInCharge: '',
            name: '',
            jobStatus: "1",
            operatorRoles: 'In',
            operatorSkills: 'In'
        }
    }


    @observable advancedCompany = this.initAdvancedCompany();

    initAdvancedCompany() {
        return {
            emsBsId: '',
            sector: 0,
            tags: [],
            companySizes: [],
            selectedLocation: 0,
            industries: [],
            selectedDLocations: [],
            recruiterInCharge: '',
            name: '',
            companyMarket: [],
            customerStatus: 0
        }
    }

    @observable selectedTopAdvanced = 0;
    @observable searched = false;
    @observable results = {
        advancedPeople: {results: [], total: 0},
        advancedJob: {results: [], total: 0},
        advancedCompany: {results: [], total: 0},
        email: {results: [], total: 0}
    };
    // super searches
    @observable profilesUsersSearchResults = [];
    @observable profilesUsersSearchPersonsTo = [];
    @observable jobsSearchResults = [];
    @observable jobsSearchTo = [];
    @observable companiesUsersSearchResults = [];
    @observable companiesUsersSearchPersonsTo = [];

    @observable navigationBarRecruiterSearchResults = {
        profiles: [],
        jobs: [],
        companies: []
    };
    @observable duplicateMergeSearchResults = {
        profiles: [],
        companies: []
    }
    @observable objectForReminderSearchResults = {
        profiles: [],
        jobs: [],
        companies: [],
    }
    
    @observable companyNavigationIndex = 0;

    // matches
    @observable jobsMatchesObjById = {};
    @observable profilesMatchesObjById = {};

    @observable jobsMatchesObjByRecId = {};
    @observable profilesMatchesObjByRecId = {};

    // candidates
    @observable candidatesList = {
        requestCounter: 0,
        profiles: [],
        isLoadedAllResults: false,
        counter: undefined
    };

    //jobs
    @observable jobList = {
        requestCounter: 0,
        jobs: [],
        isLoadedAllResults: false,
        counter: undefined
    };

    @observable mainFilterDetails = {};

    backFromResults = false;

    @observable
    advanceSearch = {
        records: [],
        total: 0
    }

    @action.bound
    setSearchValue(name, val) {
        this[this.selectRightTop()][name] = val;
    }

    @action.bound
    async doSearch(skip = 0) {
        this.searched = true;
        const data = this.prepareAdvancedSearchData();
        this.advancedSearchRequestsCounter++;
        data.requestNumber = this.advancedSearchRequestsCounter;
        data.skip = skip;
        const response = await fetchService.authenticatedPost(`/search-advanced`, data);

        //we want to update UI only for last result
        if(response?.data?.requestNumber === this.advancedSearchRequestsCounter) {

            // if skip > 0 then we want to concatenate new results to old
            let resultsList = this.results[this.selectRightTop(this.selectedTopAdvanced)];
            if(skip > 0) {
                resultsList.results = [...resultsList.results, ...response.data.results]
            }
            else {
                resultsList.results = response.data.results;
                resultsList.total = response.data.count;
            }

            //clean data
            const dataResults = Array.isArray(response.data.results) ? response.data.results : [];
            const results = dataResults.map(item => {
                delete item.processesCounter;
                return item;
            })
            this.appendToRelevantStored(results);
            return results;
        }
        else {
            return null;
        }
    }

    initAdvancedSearchRequestsCounter() {
        this.advancedSearchRequestsCounter = 0;
    }

    resetSearch() {
        this.results = {
            advancedPeople: [],
            advancedJob: [],
            advancedCompany: []
        };
    }

    appendToRelevantStored(data) {
        switch (this.selectedTopAdvanced) {
            case 0:
                profileStore.appendDataFromSearch(data);
                break;
            case 1:
                jobStore.appendDataFromSearch(data);
                break;
            case 2:
                companyStore.appendDataFromSearch(data);
                break;
            default:
                break;
        }
    }

    @action.bound
    setNavigationIndex(index) {
        this.companyNavigationIndex = index;
    }

    @action.bound
    async changeAdvancedTop(index) {
        this.selectedTopAdvanced = index;
    }

    selectRightTop() {
        switch (this.selectedTopAdvanced) {
            case 0:
                return 'advancedPeople';
            case 1:
                return 'advancedJob';
            case 2:
                return 'advancedCompany';
            case 3:
                return 'email';

            default:
                break;
        }
    }

    prepareAdvancedSearchData() {
        const isPeopleSearch = this.selectedTopAdvanced === 0;
        const isJobSearch = this.selectedTopAdvanced === 1;
        const isCompanySearch = this.selectedTopAdvanced === 2;
        const {
            keyWords, emsBsId, selectedCategories, selectedRoles, selectedSkills, selectedDLocations,
            selectedLocations, selectedPositions, selectedSeniorities,
            selectedProjectTypes, selectedLanguages,industries, selectedDegreeTypes,
            salaryRange, ageRange,selectedSalary, selectedAvailability, selectedLinkedin, schoolType,
            summary, recruiterInCharge, firstName, lastName, email, phone,
            currentCompany, previousCompany, name, selectedLocation,
            tags, companySizes, companyMarket, customerStatus, followUpDateFrom, followUpDateTo, jobStatus, idNumber,
            operatorRoles, operatorSkills, selectedStages, selectedDegreeNames,
            selectedEducationInstitutions, sector
        } = this[this.selectRightTop()];


        let instituteType = 0;
        if (schoolType === 1) {
            instituteType = 3;
        } else if (schoolType === 2) {
            instituteType = 7;
        }


        let tempData = {};
        if (isPeopleSearch || isJobSearch) {
            tempData = {
                emsBsId: emsBsId,
                categories: utilsService.getElIdsFromArray(selectedCategories),
                searchType: this.selectedTopAdvanced,
                degreeTypes: selectedDegreeTypes.map(t => t.elId),
                salaryRange: salaryRange,
                salaryType: selectedSalary + 1,
                instituteType: instituteType,
                followUpDateFrom: followUpDateFrom ? dayjs(followUpDateFrom).valueOf() + 86400000 : null,
                followUpDateTo: followUpDateTo ? dayjs(followUpDateTo).valueOf() + 86400000 : null
            };
        }


        if (isPeopleSearch) {
            let excludeBanned = true;
            if(email || phone){
                excludeBanned = false;
            }
            tempData.keyWords = keyWords;
            tempData.firstName = firstName;
            tempData.lastName = lastName;
            tempData.email = email;
            tempData.summary = data.summary[summary].elId;
            tempData.phone = phone;
            tempData.isAvailable = utilsService.getElIdsFromArray(selectedAvailability);
            tempData.linkedin = selectedLinkedin;
            tempData.ageRange = ageRange;
            tempData.excludeBanned = excludeBanned;

            tempData.desiredLocations = utilsService.filterSelectedTags(selectedDLocations).map(t => t.regionId);
            tempData.excludedDesiredLocations = utilsService.filterSelectedTags
                (selectedDLocations, false, true).map(t => t.regionId);

            tempData.skills = utilsService.getElIdsFromArray(
                utilsService.filterSelectedTags(selectedSkills).concat(
                utilsService.filterSelectedTags(selectedSkills, true))
            );
            tempData.excludedSkills = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedSkills, false, true))

            tempData.address = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedLocations));
            tempData.excludedAddress = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedLocations, false, true));

            tempData.roles = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedRoles));
            tempData.excludedRoles = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedRoles, false, true));

            tempData.industries =  utilsService.getElIdsFromArray(utilsService.filterSelectedTags(industries));
            tempData.excludedIndustries = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(industries, false, true));

            tempData.positionTypes = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedPositions));
            tempData.excludedPositionTypes = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedPositions, false, true));

            tempData.seniority = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedSeniorities));
            tempData.excludedSeniority = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedSeniorities, false, true));

            tempData.languages = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedLanguages));
            tempData.excludedLanguages = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedLanguages, false, true));

            tempData.recruiterInCharge = recruiterInCharge && recruiterInCharge.type !== 2 && recruiterInCharge.elId !== -1
                ? recruiterInCharge.elId : null
            tempData.excludedRecruiterInCharge = recruiterInCharge && recruiterInCharge.type === 2 && recruiterInCharge.elId !== -1
                ? recruiterInCharge.elId : null

            tempData.currentCompany = currentCompany && currentCompany.type !== 2 && currentCompany.elId !== -1 ? currentCompany.value : null;
            tempData.excludedCurrentCompany = currentCompany && currentCompany.type === 2 && currentCompany.elId !== -1
                ? currentCompany.value : null

            tempData.previousCompany = previousCompany && previousCompany.type !== 2 && previousCompany.elId !== -1 ? previousCompany.value : null;
            tempData.excludedPreviousCompany = previousCompany && previousCompany.type === 2 && previousCompany.elId !== -1
                ? previousCompany.value : null

            tempData.idNumber = idNumber;

            tempData.operatorRoles = operatorRoles.toLowerCase();

            tempData.operatorSkills = operatorSkills.toLowerCase();

            //Convert a 2D JavaScript array to a 1D array
            const selectedStagesArray = [].concat(...selectedStages);

            tempData.selectedStages =  [].concat(...((utilsService.filterSelectedTags(selectedStagesArray)).map(item => utilsService.getIdsArrayByStatus(item.value))));
            tempData.excludedSelectedStages =[].concat(...(utilsService.filterSelectedTags(selectedStagesArray, false, true)).map(item => utilsService.getIdsArrayByStatus(item.value)));

            tempData.degreeNames = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedDegreeNames));
            tempData.excludedDegreeNames = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedDegreeNames, false, true));

            tempData.educationInstitutions = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedEducationInstitutions));
            tempData.excludedEducationInstitutions = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedEducationInstitutions, false, true));

            tempData.tags = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(tags));
            tempData.excludedTags = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(tags, false, true));
        }

        if (isJobSearch) {
            tempData.name = name;
            tempData.desiredLocations = selectedDLocations.map(t => t.regionId);
            tempData.location = selectedLocation;
            tempData.jobStatus = Number(jobStatus);
            tempData.projectTypes = utilsService.getElIdsFromArray(selectedProjectTypes);
            tempData.mandatorySkills = utilsService.getElIdsFromArray(utilsService.filterSelectedTags(selectedSkills, true));
            tempData.roles = utilsService.getElIdsFromArray(selectedRoles);
            tempData.industries = utilsService.getElIdsFromArray(industries);
            tempData.positionTypes = selectedPositions.map(t => t.elId);
            tempData.skills = utilsService.getElIdsFromArray(selectedSkills);
            tempData.seniority = selectedSeniorities.map(t => t.elId);
            tempData.languages = selectedLanguages.map(t => t.elId);
            tempData.recruiterInCharge = recruiterInCharge ? recruiterInCharge.elId : null

            tempData.operatorRoles = operatorRoles.toLowerCase();

            tempData.operatorSkills = operatorSkills.toLowerCase()
        }

        if (isCompanySearch) {
            tempData = {
                emsBsId: emsBsId,
                desiredLocations: selectedDLocations.map(t => t.regionId),
                searchType: this.selectedTopAdvanced,
                tags: tags.map(t => t.elId),
                companySize: companySizes.map(t => t.elId),
                industries: utilsService.getElIdsFromArray(industries),
                recruiterInCharge: recruiterInCharge ? recruiterInCharge.elId : null,
                name: name,
                companyMarket: companyMarket.map(t => t.elId),
                customerStatus: Number(customerStatus)
            };
        }

        tempData.sectors = sector ? utilsService.getElIdsFromArray([sector]) : null;

        return tempData;
    }

    @action.bound
    async profilesUsersSearch(data) {
        this.searchPersonsRequestCounter++
        data.requestNumber = this.searchPersonsRequestCounter;
        const response = await fetchService.authenticatedPost(`/search-persons`, data);
        if(response?.data?.requestNumber === this.searchPersonsRequestCounter) {
            this.profilesUsersSearchResults = response.data.results;
        }
    }

    @action.bound
    async jobsSearch(data) {
        const response = await fetchService.authenticatedPost(`/search-jobs`, data);
        this.jobsSearchResults = response.data;
    }

    @action.bound
    profilesUsersSearchSetPersonsTo(items) {
        this.profilesUsersSearchPersonsTo = items;
    }

    @action.bound
    setCompaniesUsersSearchResults(items) {
        this.companiesUsersSearchResults = utilsService.doMeAFavor(items);
    }


    @action.bound
    companiesUsersSearchSetPersonsTo(items) {
        this.companiesUsersSearchPersonsTo = items;
    }

    @action.bound
    setCompaniesUsersSearchTo(jobsSearchItem) {
        let userIds = [];
        jobsSearchItem.forEach(element => {

            element.companyUsers.forEach(item => {
                userIds.push(item);
            })
        });
        this.companiesUsersSearchPersonsTo = this.companiesUsersSearchPersonsTo.filter(item => userIds.some(element => element === item._id));
    }

    @action.bound
    setJobsSearchTo(items) {
        this.jobsSearchTo = items;

        if (messagingStore._sendCVInProcess) {
            messagingStore.fillCvEmailsList();
        }
    }

    @action.bound
    async recruiterFromNavigationSearch(type = "all", value) {
        if (utilsService.isRecruiter(userStore.user)) {
            const response = await fetchService.authenticatedGet(`/search-name-change/${type}?value=${value}`);
            if (response && response.data) {
                this.navigationBarRecruiterSearchResults = response.data;
            }
        }
    }

    @action.bound
    async duplicateMergeSearch(type = 'name', value, isProfileMerge) {
        if (utilsService.isRecruiter(userStore.user)) {
            if (type === 'name') {
                const response = await fetchService.authenticatedGet(`/search-name-change/${isProfileMerge ? 'profile' : 'company'}?value=${value}`);
                if (response && response.data) {
                    this.duplicateMergeSearchResults.profiles = response.data.profiles?.map(i => ({ ...i, name: utilsService.getDisplayName(i) })) || [];
                    this.duplicateMergeSearchResults.companies = response.data.companies || [];
                }
            } else if (type === 'slug') {
                const response = await fetchService.authenticatedGet(`/${isProfileMerge ? 'profiles-slug' : 'companies-slug'}/${value}`);
                if(response && response.data) {
                    this.duplicateMergeSearchResults.profiles = [{ ...response.data, name: utilsService.getDisplayName(response.data) }];
                    this.duplicateMergeSearchResults.companies = [response.data];
                }
            }
        }
    }

    @action.bound
    async objectForReminderSearch(type = 'profile', value) {
        if (utilsService.isRecruiter(userStore.user)) {
            const response = await fetchService.authenticatedGet(`/search-name-change/${type}?value=${value}`);
            if (response && response.data) {
                this.objectForReminderSearchResults.profiles = response.data.profiles?.map(i => ({ ...i, name: utilsService.getDisplayName(i) })) || [];
                this.objectForReminderSearchResults.jobs = response.data.jobs || [];
                this.objectForReminderSearchResults.companies = response.data.companies || [];
            }
        }
    }

    @action.bound
    async recruiterFromNavigationSearchSubmit(type, value) {
        if (utilsService.isRecruiter(userStore.user)) {
            const response = await fetchService.authenticatedGet(`/search-top-recruiter-submit/${type}?value=${value}`);

            /* in case we search for email that can belongs to profile or company.
            If count not one, we treat it like profile search */
            if (type === 'email' && response.data.count !== 1) {
                this.changeAdvancedTop(0);
            }
            if (!response || !response.data || response.data.length === 0) {
                return;
            }
            this.results[this.selectRightTop(this.selectedTopAdvanced)].results = response.data.results;
            this.results[this.selectRightTop(this.selectedTopAdvanced)].total = response.data.count;
            this.results[this.selectRightTop(this.selectedTopAdvanced)].specialSearch = response.data.specialSearch;

            //for now relevant only from type email. Type can be profile or company
            this.results[this.selectRightTop(this.selectedTopAdvanced)].type = response.data.type;

            //clear data
            const results = response.data.results.map(item => {
                delete item.processesCounter;
                return item;
            })
            this.appendToRelevantStored(results);
        }
    }

    @action.bound
    initRecruiterFromNavigationSearch() {

        this.navigationBarRecruiterSearchResults = {
            profiles: [],
            jobs: [],
            companies: []
        };
    }

    @action.bound
    initDuplicateMergeSearch() {
        this.duplicateMergeSearchResults = {
            profiles: [],
            companies: []
        };
    }

    @action.bound
    async profileMatches(profileId) {
        const body = {
            id: profileId
        };
        const response = await fetchService.authenticatedPost(
            `/search/profile-matches`, body);
        this.profilesMatchesObjById[profileId] = Array.isArray(response.data?.list) && response.data.list;
        return response.data?.messageId;
    }

    @action.bound
    async jobMatches(body) {
        profileStore.initProfilesForJobsMatches();
        const response = await fetchService.authenticatedPost(`/search/job-matches`, body);
        if(response?.data?.list)  {
            this.jobsMatchesObjById[body.id] = Array.isArray(response.data.list) && response.data.list;
        }
        return response.data?.messageId;
    }

    @action.bound
    async jobMatchesCounter(body) {
        const response = await fetchService.authenticatedPost(`/search/job-matches-count`, body);
        return response?.data?.counter;
    }

    @action.bound
    async jobMatchesByRecruiter(recruiterId) {
        const body = {
            recruiterId: recruiterId
        };
        const response = await fetchService.authenticatedPost(
            `/search/job-matches-recruiter`, body);

        const data =  Array.isArray(response.data) && response.data.map(profile => profile && profile.relatedJobId);
        if(data && Array.isArray(data)) {
            this.jobsMatchesObjByRecId[recruiterId] = Array.from(new Set(data));
            profileStore.mergeData(data);
            return response.data
        }
    }

    @action.bound
    async profileMatchesByRecruiter(recruiterId, filter) {
        const body = {
            recruiterId: recruiterId,
            ...filter
        };
        const response = await fetchService.authenticatedPost(`/search/profile-matches-recruiter`, body);

        const data = Array.isArray(response.data) && response.data.map(job => job && job.relatedProfileId);
        if(data && Array.isArray(data))  {
            this.profilesMatchesObjByRecId[recruiterId]  = Array.from(new Set(data));
            jobStore.mergeData(response.data);
            return response.data
        }
    }

    @action.bound
    async getCandidatesQuery(recruiterSlugs, filter, skip = 0) {
        let body = {
            skip : skip,
            ...filter
        };
        if(recruiterSlugs !== 'all') {
            body.recruiterSlugs = recruiterSlugs;
        }
        this.candidatesList.requestCounter++;
        body.requestNumber = this.candidatesList.requestCounter

        const response = await fetchService.authenticatedPost(
            `/search/profiles-with-processes`, body);

        //we want to update UI only for last result
        if(response?.data?.requestNumber === this.candidatesList.requestCounter) {
            const profiles = Array.isArray(response.data.profiles) ? response.data.profiles : [];

            userStore.onlineRecruiters[userStore.user?._id] = 1;
            if(response.data.counter !== undefined) {
                this.candidatesList.counter =  response.data.counter;
            }

            // if skip > 0 then we want to concatenate new results to old
            if(skip > 0) {
                this.candidatesList = {...this.candidatesList, profiles:
                    [...this.candidatesList.profiles, ...profiles]};
            }
            else {
                this.candidatesList = {...this.candidatesList, profiles:
                    profiles};
            }

            this.candidatesList.isLoadedAllResults = this.candidatesList.profiles.length >= this.candidatesList.counter;

            return profiles;
        }
        else {
            return null;
        }
    }

    @action.bound
    async getJobsWithMatches(recruiterSlugs, filter) {
        this.initJobList();

        const body = {
            ...filter
        };
        if(recruiterSlugs !== 'all') {
            body.recruiterSlugs = recruiterSlugs;
        }

        this.jobList.requestCounter++;
        body.requestNumber = this.jobList.requestCounter

        const response = await fetchService.authenticatedPost(`/search/jobs-with-matches`, body);
        //we want to update UI only for last result
        if(response?.data?.requestNumber === this.jobList.requestCounter) {
            userStore.onlineRecruiters[userStore.user?._id] = 1;
            if(response.data.counter !== undefined) {
                this.jobList.counter =  response.data.counter;
            }
            this.jobList.jobsWithMatches = response.data.jobs;
            return this.jobList.jobsWithMatches;
        }
        else {
            return [];
        }
    }

    @action.bound
    async getJobsWithProcesses(skip = 0) {
        const partToSend = this.jobList.jobsWithMatches.slice(skip, skip + 50);
        const body = {
            jobsIds: partToSend.map(item => item._id).filter(item => item),
        }

        const processesIds = [].concat.apply([], partToSend.map(job => job.processes).filter(item => item))
        if (processesIds) {
            body.processesIds = processesIds;
        }

        const response = await fetchService.authenticatedPost(`/search/jobs-with-processes`, body);

        if(response?.data) {

            // merge processes
            const companiesIds = new Set();
            const recruiterIds = new Set();
            response.data.forEach(item => {

                companiesIds.add(item.companySlug);
                if (item.recruiterInChargeId) {
                    recruiterIds.add(item.recruiterInChargeId);
                }

            });

            const promises = [];
            if (companiesIds.size > 0) {
                promises.push(companyStore.getCompaniesBySlugs(Array.from(companiesIds)));
            }

            await Promise.all(promises);

            const jobsTemp = response.data.map(job => {
                const company = companyStore.companies[job.companySlug];
                if (company) {
                    job.companyName = company ? company.name : '';
                    job.companyImage = utilsService.getCompanyImage(company);
                    job.statuses = utilsService.getStatusesByProcesses(job.processes);
                }
                job.title = utilsService.removeWrongCharsFromString(job.title);
                const tempJob = this.jobList?.jobsWithMatches?.find(item => item._id === job._id);
                if (tempJob) {
                    job.matchesCounter = tempJob.matchesCounter;
                }
                return job;
            })


            //merge just jobs with without there processes
            const jobs = response.data.map(item => {
                delete item.processes;
                return item;
            });
            const normalizedData = utilsService.normalizeApiData(jobs);
            jobStore.jobs = utilsService.mergeApiData(normalizedData,
                jobStore.jobs);
            jobStore.allJobs = utilsService.mergeApiData(normalizedData,
                jobStore.allJobs);

            this.jobList = {...this.jobList, jobs: [...this.jobList.jobs, ...jobsTemp]};

            this.jobList.isLoadedAllResults = this.jobList.jobs?.length  === this.jobList.jobsWithMatches?.length

            return response.data;
        }

        return [];

    }


    initJobList = () => {
        const requestCounter = this.jobList.requestCounter;
        this.jobList = {
            jobsWithMatches: null,
            isLoadedAllResults: false,
            counter: undefined,
            requestCounter: requestCounter,
            jobs: [],
        };
    }

    initProfilesCandidates = () => {
        const requestCounter = this.candidatesList.requestCounter;
        this.candidatesList = {
            profiles: [],
            isLoadedAllResults: false,
            counter: undefined,
            requestCounter: requestCounter
        };
    }

    @action.bound
    async getMainFilterDetails(body) {
        const response = await fetchService.authenticatedPost(`/search-main-filter`, body );
        if(response && response.data) {
            this.mainFilterDetails = response.data;
            return response.data
        }

        return [];
    }

    initAllAdvancedSearch() {
        this.advancedPeople = this.initAdvancedPeople();
        this.advancedJob = this.initAdvancedJob();
        this.advancedCompany= this.initAdvancedCompany();
        this.selectedTopAdvanced = 0;
    }

    @action.bound
    saveAdvancedSearchData(data) {
        const { count, results } = data ?? {};

        this.advanceSearch = {
            records: results,
            total: count,
        }
    }
}

const searchStore = new SearchStore();

export default searchStore;
