import React, {Component} from 'react';
import './JobSummary.scss';
import JobSummaryGeneral from './JobSummaryGeneral.component';
import JobSummaryRequirement from './JobSummaryRequirement.component';
import MoreMenuActionComponent from '../../../../components/processActions/MoreMenuAction.component';
import utilsService from '../../../../../services/utils.service';
import DefaultImageComponent from '../../../../components/DefaultImage.component';
import {Link} from 'react-router-dom';
import { AddToMatchesModal, ShareModal } from '../../../../components/modalComponents';
import ContactWithCoordinatorComponent from '../../../../components/ContactWithCoordinator.component';
import userStore from '../../../../../stores/user.store';
import { EditIcon, MarkIcon } from '../../../../components/svg';
import PrimaryNote from '../../../../components/primarynote/PrimaryNote.component';
import notesStore from '../../../../../stores/notes.store';
import { observer } from 'mobx-react';
import companyStore from '../../../../../stores/company.store';
import CompanyOverviewComponent from '../../../company/components/CompanyOverview.component';
import notificationsStore from '../../../../../stores/notifications.store';
import jobStore from '../../../../../stores/job.store';

@observer
class JobSummaryComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalOpen_SendToCompany: false,
            isModalOpen_Share: false,
            jobSlugForModal: null,
            placementContactPersonData: null,
            recruiters: undefined,
            hrManagers: undefined,
            recipients: null,
            isUsingRecruitmentSystem: false
        };

        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        
        const {job} = this.props;

        this.updateHrs(job);
        this.updateCoordinateContacts(job);

        if(this._isMounted) {
            this.setState({recruiters: userStore.recruitersList});
        }

        if(job) {
            await notesStore.getNotes(job._id, 'job');
            notesStore.buildData(job._id, 'job');
        }

        let recipients = null;
        if(job.emailsForCvContacts && job.emailsForCvContacts.length > 0) {
            await userStore.getUsersByIds(job.emailsForCvContacts);
            const users = userStore.findUsersByIds(job.emailsForCvContacts);
            recipients = users.map(u => u.email);
            this.setState({recipients: recipients});
        }

        const companyId = job.company;
        companyStore.getCompanyManagement(companyId).then(companyM => {
            this.setState({isUsingRecruitmentSystem: companyM.isUsingSystem});
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidUpdate(prevProps, prevState) {
        const currentJob = this.props.job;
        const prevJob = prevProps.job;
        if(currentJob._id === prevJob._id || !currentJob)
            return;

        this.updateHrs(currentJob);
        this.updateCoordinateContacts(currentJob);

        await notesStore.getNotes(currentJob._id, 'job');
        notesStore.buildData(currentJob._id, 'job');
    }

    updateHrs(job) {
        utilsService.getHrManager(job).then(hrManagers => {
            this.setState({hrManagers: hrManagers});
        });
    }

    async updateCoordinateContacts(job) {
        await companyStore.getCompanyBySlug(job.companySlug);
        const placementContactPerson = companyStore.companyToShow && companyStore.companyToShow.placementContactPerson
        userStore.getRecruiters().then(() => {
            if(placementContactPerson) {
                const placementContactPersonData = userStore.recruitersList.find(rec => rec._id === placementContactPerson);
                this.setState({placementContactPerson: placementContactPersonData});
            }
        });
    }

    getDataForMoreMenu = (job, isRecruiter) => {
        const linkTo = `/edit-job/${job.slug}`;
        let menu = [];
        if (isRecruiter) {
            menu = this.getRecruiterMenu(job);
        } else {
            menu = [{ text: 'Edit', linkTo: linkTo, isLink: true }];
        }

        return menu;
    };

    getRecruiterMenu = (job) => {
        const editJobLink = `/edit-job/${job.slug}`;
        const duplicateJobLink = `/duplicate-job/${job.slug}`;
        const addToMatches = `/search?tabIndex=0&hideTabs=1&forJob=${job.slug}`;

        const handleJobStatusChange = () => {
            jobStore.updateJob(job._id, { isExternal: !job.isExternal }).then((response) => {
              if (response.isError) {
                notificationsStore.showToast('Failed to update job source type', 'failure');
              } else {
                notificationsStore.showToast('Update job source type successfully', 'success');
              }
            });
        };

        if(utilsService.getJobStatus(job.status) === 'close') {
            return [
                {
                    text: 'Duplicate',
                    linkTo: duplicateJobLink,
                    isLink: true,
                    classes: 'jobs-icons'
                }
            ];
        }
        else if(utilsService.getJobStatus(job.status) === 'hold') {
            return [
                {
                    text: 'Edit',
                    linkTo: editJobLink,
                    isLink: true,
                    classes: 'jobs-icons'
                },
                {
                    text: 'Duplicate',
                    linkTo: duplicateJobLink,
                    isLink: true,
                    classes: 'jobs-icons'
                },
                {
                    text: 'Close',
                    isFunc: true,
                    func: () => {},
                    classes: 'jobs-icons'
                },
                {
                    text: 'Print',
                    isFunc: true,
                    func: () => {},
                    classes: 'jobs-icons'
                },
                {
                    text: job.isExternal ? 'Mark as Internal' : 'Mark as External',
                    isFunc: true,
                    func: handleJobStatusChange
                }
            ];
        }
        else {
            return [
                {
                    text: 'Edit',
                    linkTo: editJobLink,
                    isLink: true,
                    classes: 'jobs-icons'
                },
                {
                    text: 'Add to Matches',
                    // linkTo: addToMatches,
                    // isLink: true,
                    modal: 'isModalOpen_AddToMatches'
                },
                {
                    text: 'Send Cv',
                    classes: 'comp',
                    modal: 'isModalOpen_SendToCompany'
                },
                {
                    text: 'Duplicate',
                    linkTo: duplicateJobLink,
                    isLink: true,
                    classes: 'jobs-icons'
                },
                {
                    text: 'Share',
                    classes: 'jobs-icons',
                    modal: 'isModalOpen_Share'
                },
                {
                    text: 'Print',
                    linkTo: editJobLink,
                    isFunc: true,
                    func: () => {}
                },
                {
                    text: job.isExternal ? 'Mark as Internal' : 'Mark as External',
                    isFunc: true,
                    func: handleJobStatusChange,
                }
            ];        
        }
    }

    actionEventHandler = (job, modal) => {
        this.setState({
            [modal]: true,
            jobSlugForModal: job.slug
        });
    };

    closeAddToMatchesModal = () => {
        this.setState({
            isModalOpen_SendToCompany: false
        });
    };

    getRecruiterInCharge =  () => {
        const { job } = this.props;
        if (job && job.recruiterInChargeSlug) {
            const user = userStore.users[job.recruiterInChargeSlug];
            if(user && user.profile) {
                return user.profile;
            }
        }
        return null;
    }

    render() {
        const { job, company } = this.props;
        const { isModalOpen_SendToCompany, isModalOpen_Share, isModalOpen_AddToMatches, jobSlugForModal, hrManagers,
            placementContactPerson, recruiters, recipients, isUsingRecruitmentSystem } = this.state;

        const isRecruiter = utilsService.isRecruiter();
        const companyEmails = job.emailsForCv?.map(email => {
            return email;
        }).join(', ');

        const notes = notesStore.notesToShow;
        const primaryNote = notes.find(note => note.eventTypeId === 1);
        const recruiterInCharge = this.getRecruiterInCharge()
        return (
            <div>
                <main className='shadow4 job-summery-container custom-scroll'>
                    <MoreMenuActionComponent
                        process={job}
                        menu={this.getDataForMoreMenu(job, isRecruiter)}
                        icons={true}
                        actionEvent={(process, modal, e) => this.actionEventHandler(job, modal, null, e)}
                    />

                    <Link to={`/edit-job/${job.slug}`} className='edit-item'>
                        <EditIcon />
                    </Link>

                    {isRecruiter && company && (
                        <div className="job-summery-container-info">
                            <DefaultImageComponent
                                url={utilsService.getCompanyImage(company)}
                                alt={'Company'}
                                company={true}
                                width={'62'}
                            />
                            <div className='job-summery-container-info-wrap'>
                                <h3>
                                    {utilsService.removeWrongCharsFromString(job.title)}
                                </h3>
                                {isRecruiter && (
                                    <p><Link to={`/company/${company.slug}`}>{company.name}</Link></p>
                                )}

                                {!isRecruiter && (
                                    <p>{company.name}</p>
                                )}
                            </div>
                            <div className="is-discrete">
                                {job.discretePosition && (
                                    <>
                                        <MarkIcon/>
                                        <label>Discreet Job</label>
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <JobSummaryGeneral
                        job={job}
                    />
                    <JobSummaryRequirement
                        job={job}
                        company={company}
                    />
                    <PrimaryNote 
                        wrapperClass={'notes-wrapper-class'}
                        note={primaryNote}
                    />
                    {placementContactPerson && (
                        <div className="background-info">
                            <ContactWithCoordinatorComponent 
                                type={"placementRecruiter"} 
                                name={CompanyOverviewComponent.getRecruiterName(placementContactPerson)}
                                image={utilsService.getProfileImage(placementContactPerson)} 
                                profile={placementContactPerson.profile}
                            />
                        </div>
                    )}
                    {recruiterInCharge && (
                        <div className="background-info no-margin">
                            <ContactWithCoordinatorComponent type={"recruiter"} 
                                name={utilsService.getRecruitersName(job)} 
                                image={utilsService.getRecruiterInChargeProfileImage(job)}
                                profile={recruiterInCharge} />
                        </div>
                    )}
                    {hrManagers && hrManagers.map((hr, index) => {
                        return (
                            <div className="background-info no-margin" key={index}>
                                <ContactWithCoordinatorComponent 
                                    type={"hr"} 
                                    user={hr} 
                                    name={utilsService.getDisplayName(hr)} 
                                    image={utilsService.getProfileImage(hr)} />
                            </div>
                        )
                    })}
                    {companyEmails && (
                        <div className="background-info">
                            <span>{utilsService.renderBackgroundData([{legend: 'Recruitement System', value: isUsingRecruitmentSystem ? 'true' : 'false'}])}</span>
                            <span>{utilsService.renderBackgroundData([{legend: 'Recipients', value: recipients}])}</span>
                            <span>{utilsService.renderBackgroundData([{legend: 'Company Emails', value: companyEmails}])}</span>
                        </div>
                    )}
                </main>

                {isModalOpen_SendToCompany && (
                    <AddToMatchesModal
                        sendCandidates={true}
                        jobsSlug={[jobSlugForModal]}
                        jobLimit={1}
                        closeEvent={this.closeAddToMatchesModal}
                    />
                )}

                {isModalOpen_AddToMatches && (
                    <AddToMatchesModal
                        addToMatches={true}
                        jobsSlug={[jobSlugForModal]}
                        jobLimit={1}
                        closeEvent={() => this.setState({ isModalOpen_AddToMatches: false })}
                    />
                )}

                {isModalOpen_Share && (
                    <ShareModal
                        shareType={'job'}
                        jobId={job._id}
                        isExposed={utilsService.checkIfExposed(process)}
                        jobName={job.title}
                        closeEvent={() => this.setState({ isModalOpen_Share: false })}
                        slug={jobSlugForModal}
                    />
                )}

            </div>
        );
    }
}

export default JobSummaryComponent;
