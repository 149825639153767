import React, {Component} from 'react';
import userStore from '../../../stores/user.store';
import processStore from '../../../stores/process.store';
import utilsService from '../../../services/utils.service';

class InterestedProcessComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updating: false,
            message: 'We will send a message to the candidate requesting to reveal contact information',
            exposed: false
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ updating: true });
        const { process } = this.props.data;
        const id = process._id;

        const body = {
            status: 70,
            profileId: process.profileId ? process.profileId : process.profile,
            comment: '',
            eventTypeId: 70,
            eventType: 'Interested',
            decisionMakerEmail: userStore.user.email,
            decisionMakerName: userStore.user.displayName
        };

        await processStore.changeStatus(id, body, this.props.isSingleJobView, this.props.isSingleCompanyView);
        this.handleCloseClick();
        // this.setState(
        //     { updating: false, exposed: true, message: 'The request needs candidate allowance, will be updated shortly' });
    };

    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    handleSuccessClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.successEvent();
        }
    };

    render() {
        const { profile } = this.props.data;
        return (
            <div className={this.state.exposed ? 'action-process expose-component exposed' : 'action-process expose-component'}>
                <div>
                    <h3>Are you interested in {utilsService.getDisplayName(profile)}?</h3>
                </div>

                {this.state.exposed && (<footer className='center'>
                    <button
                        onClick={this.handleSuccessClick}
                        className={`button blue okbutton`}
                    >
                        Ok
                    </button>
                </footer>)}

                {!this.state.exposed && (<footer>
                    <button
                        onClick={this.handleCloseClick}
                        className='button gray'>
                        Cancel
                    </button>
                    <button
                        onClick={this.handleSubmit}
                        className={`button blue handler ${this.state.updating ? 'loading' : ''}`}
                    >
                        Send
                    </button>
                </footer>)}

            </div>
        );
    }
}

export default InterestedProcessComponent;
