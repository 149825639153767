import React, {PureComponent} from 'react';
import MoreMenuActionComponent from '../../../components/processActions/MoreMenuAction.component';
import DefaultImageComponent from '../../../components/DefaultImage.component';
import utilsService from '../../../../services/utils.service';
import SocialLinks from '../../../components/socialLinks/SocialLinks.component';
import { ShareModal } from '../../../components/modalComponents';
import ContactWithCoordinatorComponent from '../../../components/ContactWithCoordinator.component';
import userStore from '../../../../stores/user.store';
import _url from '../../../../constants/url';
import profileStore from '../../../../stores/profile.store';
import notesStore from '../../../../stores/notes.store';
import PrimaryNote from '../../../components/primarynote/PrimaryNote.component';
import { observer } from 'mobx-react';
import companyStore from '../../../../stores/company.store';
import { Link } from 'react-router-dom';
import Transportations from '../../../components/transportations/Transportations.component';
import { Icon } from '@blueprintjs/core';
import messagingStore from '../../../../stores/messaging.store';
import { ReminderIcon } from '../../../components/svg';
import AddEditReminderModalComponent from '../../../components/modalComponents/AddReminderModal.component';

@observer
class CompanyOverviewComponent extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen_Share: false,
            placementContactPerson: undefined,
            firstContactPerson: undefined,
            topContactPerson: undefined,
            primaryContactPerson: undefined,
            isUsingRecruitmentSystem: false,
            isModelOpen_createReminder: false,
        }
    }

    async componentDidMount() {
        const {company} = this.props;

        this.getRecruiters();

        if(company) {
            await notesStore.getNotes(company._id, 'company');
            notesStore.buildData(company._id, 'company');
        }

        const companyId = company._id;
        companyStore.getCompanyManagement(companyId).then(companyM => {
            this.setState({isUsingRecruitmentSystem: companyM.isUsingSystem});
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        const currentCompany = this.props.company;
        const prevCompany = prevProps.company;
        if(currentCompany._id === prevCompany._id || !currentCompany)
            return;

        this.getRecruiters();
        await notesStore.getNotes(currentCompany._id, 'company');
        notesStore.buildData(currentCompany._id, 'company');
    }

    generateSecondaryData(item) {
        if(item.specialties && item.specialties.length > 0) {
            const technologies = item.specialties;

            return [
                { legend: 'Products', value: '' },
                { legend: 'Technology', value: utilsService.getSkills(technologies) }
            ];
        }
        else {
            return [];
        }
    }

    getSocialLinks(socialLinks) {
        return (<SocialLinks
            links={socialLinks}
        />);
    }

    getIndustryDistrict(industryDistrict) {
        return (<Transportations
            industryDistrict={industryDistrict}
        />);
    }

    generateInfoData(item) {
        const dataInfo = [
            { legend: 'Website', value: item ? item.website : '' },
            {
                legend: 'Social',
                value: item ? this.getSocialLinks(item.socialLinks) : ''
            }
        ];

        if(item && item.industryDistrict) {
            dataInfo.push({ legend: 'Transportation', value: item ?  this.getIndustryDistrict(item.industryDistrict) : '' },)
        }

        return dataInfo;
    }

    generateGeneralData(item) {
        const checkLocation = item.linkedinData?.locations?.length == 1 ? item.linkedinData?.locations[0] : item.linkedinData?.locations?.find(loc => loc.isPrimary)
        return [
            { legend: 'BsId', value: item.bsId },
            { legend: 'Headquarter', value: (item.linkedinData && checkLocation) ? (checkLocation ? `${checkLocation?.city || item?.linkedinData?.details?.find(item => item.key == "headquarters")?.value?.split(",") || ""}, ${checkLocation?.country || ""}` :  item?.linkedinData?.details?.find(item => item.key == "headquarters")?.value) : utilsService.getObjValue(item.address, 'city') },
            {
                legend: 'Sector',
                value: utilsService.getValueFromArray(item.sectors, 0)
            },
            { legend: 'Founded', value: item?.linkedinData?.details?.find(item => item.key == "founded")?.value || item.founded },
            { legend: 'Organization Size', value: item?.linkedinData?.details?.find(item => item.key == "company_size")?.value?.split(" ")?.[0] || utilsService.getObjValue(item.size), classes: 'org-size' },
            { legend: 'Operational status', value: 'Active' },
            { legend: 'Business model', value: utilsService.joinByComma(item.businessModel, 'value') },
            { legend: 'Lifecycle stage', value: utilsService.getObjValue(item.companyType) },
            { legend: 'Type', value: utilsService.joinByComma(item.companyMarket, 'value') },
            { legend: 'Business stage', value: utilsService.getObjValue(item.companyBusinessStage) },
            { legend: 'Company Ticker', value: item.companyTicker }
        ];
    }

    actionEventHandler = async (process, currentModal, type) => {
        this.setState({ [currentModal]: true })
    };

    getRecruiters() {
        const {company} = this.props;
        const {placementContactPerson, firstContactPerson, topContactPerson} = company;
        let placementContactPersonData, firstContactPersonData, topContactPersonData;
        userStore.getRecruiters().then(() => {
            if(placementContactPerson) {
                placementContactPersonData = userStore.recruitersList.find(rec => rec._id === placementContactPerson);
            }
            // if(firstContactPerson) {
            //     firstContactPersonData = userStore.recruitersList.find(rec => rec._id === firstContactPerson);
            // }
            // if(topContactPerson) {
            //     topContactPersonData = userStore.recruitersList.find(rec => rec._id === topContactPerson);
            // }

            this.setState({placementContactPerson: placementContactPersonData, firstContactPerson: firstContactPersonData, topContactPerson: topContactPersonData});
        });

        // if(company.primaryContactPerson){
        //     profileStore.getProfileById(company.primaryContactPerson).then(() => {
        //         this.setState({primaryContactPerson: profileStore.profileToShow});
        //     });
        // }
    }

    static getRecruiterImage(recruiter) {
        let res = '';
        if (recruiter && recruiter.profileImageId) {
            res = `${_url.profile_image + recruiter.profileImageId}.jpg`;
        }

        return res;
    }
    static getRecruiterName(recruiter) {
        return recruiter ? utilsService.capitalizeString(`${recruiter.firstName} ${recruiter.lastName}`) : '';
    }

    render() {
        const {isModalOpen_Share,
            // primaryContactPerson,
            placementContactPerson, isUsingRecruitmentSystem} = this.state;
        const { company, companyNameClickable, withScroll } = this.props;
        const infoData = this.generateInfoData(company);
        const generalData = this.generateGeneralData(company);
        const secondaryData = this.generateSecondaryData(company);
        const imgUrl = utilsService.getCompanyImage(company);

        const notes = notesStore.notesToShow;
        const primaryNote = notes.find(note => note.eventTypeId === 1);

        return (
            <div className='overviewWrapper custom-scroll'>
                <div className="overviewContainer shadow4 company">
                    <MoreMenuActionComponent
                        process={{}}
                        menu={[
                            { isLink: true, linkTo: `/company/${company.slug}`, context: this, text: 'Edit' },
                            { isLink: true, linkTo: `/new-job/${company.slug}`, context: this, text: 'New Job' },
                            { modal: 'isModalOpen_Share', text: 'Share' },
                        ]}
                        actionEvent={(process, modal, e) => this.actionEventHandler(process, modal, null, e)}
                    />

                    <div className='reminder-item company' onClick={()=>{this.setState({isModelOpen_createReminder: true})}}>
                        <ReminderIcon w={20} h={20} />
                    </div>

                    <Icon
                        icon='chevron-left'
                        size={20}
                        color='#007dff'
                        className='back-icon'
                        onClick={() => messagingStore.activeInboxMode = messagingStore.inboxMode.MAIN}
                    />

                    <div className="company-info">
                        <DefaultImageComponent
                            url={imgUrl}
                            alt={company.name}
                            profileName={company.name}
                        />
                        <div className="info">
                            {companyNameClickable ?
                                <Link className='black-link'
                                    to={`/company/${company.slug}`}>
                                    {company.name}
                                </Link>
                                :
                                <h3>{company.name}</h3>
                            }
                            <h3>{company.nameHeb}</h3>
                            {company && company.buyer && (
                                <p>{company.buyer.name}</p>
                            )}
                        </div>
                    </div>

                    <div className={withScroll ? 'company-info-wrapper custom-scroll' : '' }>

                        <div className="background-info borderless">
                            {utilsService.renderBackgroundData(infoData)}
                        </div>

                        <div className="background-info">
                            {utilsService.renderBackgroundData(generalData)}
                        </div>

                        {secondaryData.length > 0 && (
                            <div className="background-info">
                                {utilsService.renderBackgroundData(secondaryData)}
                            </div>
                        )}

                        <PrimaryNote
                            wrapperClass={'notes-wrapper-class'}
                            note={primaryNote}
                        />

                        {placementContactPerson && (
                            <div className="background-info">
                                <ContactWithCoordinatorComponent
                                    type={"placementRecruiter"}
                                    name={CompanyOverviewComponent.getRecruiterName(placementContactPerson)}
                                    image={utilsService.getProfileImage(placementContactPerson)}
                                    profile={placementContactPerson.profile}
                                />
                            </div>
                        )}
                        {/*{firstContactPerson && (*/}
                        {/*    <div className="background-info">*/}
                        {/*        <ContactWithCoordinatorComponent*/}
                        {/*            type={"firstRecruiter"}*/}
                        {/*            name={CompanyOverviewComponent.getRecruiterName(firstContactPerson)}*/}
                        {/*            image={utilsService.getProfileImage(firstContactPerson)}*/}
                        {/*            profile={firstContactPerson.profile}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{topContactPerson && (*/}
                        {/*    <div className="background-info">*/}
                        {/*        <ContactWithCoordinatorComponent*/}
                        {/*            type={"topRecruiter"}*/}
                        {/*            name={CompanyOverviewComponent.getRecruiterName(topContactPerson)}*/}
                        {/*            image={utilsService.getProfileImage(topContactPerson)}*/}
                        {/*            profile={topContactPerson.profile}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {/*{primaryContactPerson && (*/}
                        {/*    <div className="background-info">*/}
                        {/*        <ContactWithCoordinatorComponent*/}
                        {/*            type={"companyPrimaryContactPerson"}*/}
                        {/*            name={CompanyOverviewComponent.getRecruiterName(primaryContactPerson)}*/}
                        {/*            image={utilsService.getProfileImage(primaryContactPerson)}*/}
                        {/*            profile={primaryContactPerson}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        {company.email && company.email !== '' && (
                            <div className="background-info">
                                <div>{utilsService.renderBackgroundData([{legend: 'Recruitement System', value: isUsingRecruitmentSystem ? 'true' : 'false'}])}</div>
                                <div>{utilsService.renderBackgroundData([{legend: 'Send CV Email', value: company.email}])}</div>
                            </div>
                        )}
                    </div>
                </div>


                {isModalOpen_Share && (
                    <ShareModal
                        shareType={'company'}
                        companyName={company.name}
                        closeEvent={() => this.setState({ isModalOpen_Share: false })}
                        slug={company.slug}
                    />
                )}

                {this.state.isModelOpen_createReminder && (
                    <AddEditReminderModalComponent
                        buttonText="YES"
                        cancelButtonText="NO"
                        closeEvent={() => this.setState({ isModelOpen_createReminder: false })}
                        refresh={() => this.setState({ isModelOpen_createReminder: false })}
                        data={{
                            entityType: 'company',
                            entitySlug: company.slug,
                            entityData: {
                                companyName: company.name
                            }
                        }}
                    />
                )}

            </div>
        );
    }
}

export default CompanyOverviewComponent;
