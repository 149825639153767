import React, {Component, PureComponent} from 'react';
import './toast.scss';
import {observer} from 'mobx-react';
import notificationsStore from '../../../stores/notifications.store';
import {VeeeIcon} from '../svg';

@observer
class Toast extends Component {
    removeFromList = id => {
        notificationsStore.removeToast(id);
    };

    render() {
        const toastsList = notificationsStore.toasts;
        return (
            <div className='toasts'>
                {toastsList.map((toast, idx) => {
                    return (
                        <ToastItem
                            key={toast.id}
                            idx={idx}
                            toast={toast}
                            removeFromList={this.removeFromList}
                        />
                    );
                })}
            </div>
        );
    }
}

export default Toast;

class ToastItem extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    componentDidMount() {
        const { toast, removeFromList } = this.props;
        setTimeout(() => {
            removeFromList(toast.id);
        }, toast.timeoutMilliSec);

        setTimeout(() => {
            this.setState({ show: true });
        }, 350);

        setTimeout(() => {
            this.setState({ show: false });
        }, toast.timeoutMilliSec - 500);
    }

    render() {
        const { toast, idx } = this.props;
        const { show } = this.state;
        const stylesClasses = `toast-container ${toast.type} ${show ? 'show' : ''}`;

        const top = 44 * idx;

        return (
            <div
                style={{ 'top': top }}
                className={stylesClasses}>
                <div className="toast-sign">
                    <VeeeIcon/>
                </div>
                <div className='toast-text'>
                    {this.props.toast.message}
                </div>
            </div>
        );
    }
}
