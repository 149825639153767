import React from 'react';
import {FacebookIcon3, LinkedinIcon3, TwitterIcon3} from '../svg';
import classNames from "classnames";

const SocialLinks = props => {
    const { links } = props;
    const normalLinks = normalizeLinks(links);

    return (
        <div className={classNames('social-links', props.className)}>
            {normalLinks.map((link, idx) => {
                const showIcon = shouldShowIcon(link.linkType);
                return (
                    showIcon && <div
                        key={link._id ? link._id : idx}
                        className="link">
                        <a target='_blank' rel='noopener noreferrer' href={link.link}>
                            {getRightIcon(link.linkType)}
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export function normalizeLinks(links) {
    const temp = {};
    links && links.forEach(link => {
        if(!temp[link.linkType]) {
            temp[link.linkType] = link;
        }
    });
    return Object.values(temp);
}

export function shouldShowIcon(linkType) {
    switch(linkType) {
        case 47:
        case 43:
        case 51:
        case 41:
        case 42:
        case 52:
        return true;

        default:
            return false;
    }
}

function getRightIcon(type) {
    switch (type) {
        case 47:
        case 43:
            return <LinkedinIcon3/>;
        case 51:
            return <TwitterIcon3/>;
        case 41:
        case 52:
            return <FacebookIcon3/>;
        case 50:
            return '';
        default:
            return '';
    }
}

export default React.memo(SocialLinks);
