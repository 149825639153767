import {Card} from '../../../../../app/components/card/Card';
import {Autocomplete, DateInput, TextField} from '@brightsource/brightsource-ui-lib';
import {autcompleteComparator, renderOptionByValue} from '../../../../../shared/utils/form.utils';
import React, { useCallback, useState } from 'react';
import './ProcessFilterBar.scss';
import {observer} from 'mobx-react';
import {processReportStore} from '../../report.store';
import {formatFromDate, formatToDate} from '../../../../../shared/models';
import { debounce } from 'lodash';
import { Button } from '@blueprintjs/core';

export const ProcessFilterBar = observer(() => {
  const { interviewer, companyHandler, jobHandler, sender, lastUpdate, jobStatus, candidateAvailability, stage } = processReportStore.properties;
  const {
    companyHandler: recruiterNameOptions = [],
    jobStatus: jobStatusOptions = [],
    candidateAvailability: candidateAvailabilityOptions = [],
    stage: stageOptions = []
  } = processReportStore.source;
  const [searchCandidateName, setSearchCandidateName] = useState('');

  const handleCandidateNameChange = useCallback(debounce((value) => {
    processReportStore.loadByFilter('candidateName', value);
  }, 500),[]);

  const handleClearFilter = () => {
    processReportStore.resetFilters();
    setSearchCandidateName('');
  }

  return (<Card>
  <div className='clear-filter'>
    <Button minimal intent='primary' onClick={handleClearFilter}>
      Clear All
    </Button>
  </div>
  <div className={'process-filter-bar'}>
    <Autocomplete
      label="Interviewer"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('interviewer', value)}
      value={interviewer ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Company handler"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('companyHandler', value)}
      value={companyHandler ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Job handler"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('jobHandler', value)}
      value={jobHandler ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Sender"
      options={recruiterNameOptions}
      onChange={(_,  value) => processReportStore.loadByFilter('sender', value)}
      value={sender ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label='Stage'
      options={stageOptions}
      onChange={(_, value) => processReportStore.loadByFilter('stage', value)}
      value={stage ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <TextField
      label="Candidate Name"
      value={searchCandidateName}
      onChange={(e)=> {
        setSearchCandidateName(e.target.value)
        handleCandidateNameChange(e.target.value)
      }}
      placeholder='Search'
    />

    <Autocomplete
      label="Job status"
      options={jobStatusOptions}
      onChange={(_, value) => processReportStore.loadByFilter('jobStatus', value)}
      value={jobStatus ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <Autocomplete
      label="Availibility"
      options={candidateAvailabilityOptions}
      onChange={(_, value) => processReportStore.loadByFilter('candidateAvailability', value)}
      value={candidateAvailability ?? null}
      getOptionLabel={renderOptionByValue}
      isOptionEqualToValue={autcompleteComparator}
    />

    <DateInput
      label="Last update"
      maxDate={new Date()}
      value={lastUpdate}
      onChange={(date) => processReportStore.loadByFilter('lastUpdate', formatFromDate(date).toDate())}
    />
  </div>
  </Card>)
})
