import React, {Component} from 'react';
import {CloseIcon} from '../svg';
import utilsService from '../../../services/utils.service';
import {getSafeLatestInterview} from '../../../entities/profiles';

class InterviewInfoComponent extends Component {
    handleCloseClick = () => {
        if (this.props.isModal) {
            this.props.closeEvent();
        } else {
            this.props.cancelEvent();
        }
    };

    render() {
        const { data } = this.props;
        const summary = getSafeLatestInterview(data);

        const dateCreated = utilsService.getFormattedDate(summary.createDate);

        return (
            <div className="interview-info">
                <header>
                    <h2><span
                        className='capitalized'>Interview</span>
                    </h2>
                    {this.props.isModal && (
                        <div onClick={this.handleCloseClick
                        }
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>)}

                </header>

                <section className="main-data" dir='auto'>
                    <div
                        dangerouslySetInnerHTML={{ __html: summary.summary }}
                        className="content"/>
                    <p className="date-time">
                        <time>
                            {dateCreated}
                        </time>
                    </p>
                </section>
            </div>
        );
    }
}

export default InterviewInfoComponent;
