import React, { PureComponent } from 'react';
import { FileUpload, InputForm } from '../../forms';
import SuperInput from './SuperInput.component';
import SuperSearchUsersProfilesInputComponent from '../../forms/multiSelects/SuperSearchUsersProfilesInputComponent';
import CcBccIcons from '../../../scenes/inbox/components/inboxComponent/components/CcBccIcons.component';
import utilsService from '../../../../services/utils.service';
import PredefinedInboxMessagesComponent from '../sendConversation/PredefinedInboxMessages.component';
import SelectMessagingTemplateComponent from '../../selectMessagingTemplate/SelectMessagingTemplate.component';
import QuillBS from '../../quillBS/QuillBS.component';
import FancySwitchButtonComponent from '../../fancySwitchButton/fancySwitchButton.component';

class EmailMessaging extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ccShow: false, 
            bccShow: false,
        };
    }

    ccBccSelect = id => {
        if (id) {
            this.setState({
                bccShow: !this.state.bccShow
            });
        } else {
            this.setState({
                ccShow: !this.state.ccShow
            });
        }
    };

    getEditorClasses(ccShow, bccShow) {
        let classes = 'editor';

        if (ccShow && bccShow) {
            classes += ' short-2';
        } else if (ccShow || bccShow) {
            classes += ' short';
        }
        return classes;
    }

    getReplyToData(replyTo, items, type) {

        const temp = {};

        if (replyTo) {
            const item = items[0];

             if(type === 'company') {
                 if(replyTo.isInbound) {
                    temp.name = item.name;
                 }
                 else {
                    temp.name = replyTo.name;
                 }

                 temp.email = replyTo.email
                 if(replyTo.emailNotActivated) {
                    temp.email += ' - Not Activated'
                 }
                 temp.phone = item.phone;
             }
             else {
                temp.name = utilsService.getDisplayName(item);
                temp.email = utilsService.getPrimaryValue(item.emails, 'email');
                temp.phone = utilsService.getPrimaryValue(item.phones, 'phone');
             }
        }

        return temp;
    }

    render() {
        const {
            chooseTemplateEvent, cc, bcc, inputChange,
            subject, emailBody, onEditorStateChange, onSignatureEditorStateChange,
            ccButtonsShowHide, items, removeSelection, searchTo,
            selectedPersons, hideTemplate, title, hideSubject, fileHandle, replyTo,
            preDefinedTextHandle, type
        } = this.props;

        const {
            ccShow, bccShow
        } = this.state;

        const showCcBcc = ccShow || bccShow;

        const replyToData = this.getReplyToData(replyTo, items, type);
        let company;  
        if(items && items.length === 1 && type === 'company') {
            company = items[0];
        }

        return (
            <div className='new-message-super-search-input-fancy'>
                {searchTo && !replyTo && (
                    <div className="new-message-super-search-input-fancy-wrapper">
                        <CcBccIcons
                            select={this.ccBccSelect}
                            ccData={cc}
                            bccData={bcc}
                        />
                        <SuperSearchUsersProfilesInputComponent
                            allowCreate={true}
                            selectedPersons={selectedPersons}
                            titleType='email'
                            isCVNeeded={false}
                        />
                    </div>
                )}

                {!searchTo && !replyTo && !company && (
                    <SuperInput
                        profiles={items}
                        removeSelection={removeSelection}
                        isEmail={true}
                        ccButtonsShowHide={ccButtonsShowHide}
                        cc={cc}
                        bcc={bcc}
                    />
                )}

                {(replyTo) && (
                    <div className="new-message-reply-to-static">
                        To: <span>{replyToData.name} {replyToData.name ? `(${replyToData.email})` : replyToData.email}</span>
                    </div>
                )}

                {!replyTo && company && (
                    <div className="new-message-reply-to-static">
                        To: <span>{company.name} {company.name ? `(${company.email})` : company.email}</span>
                    </div>
                )}

                {showCcBcc && (
                    <div className="cc-bcc-wrapper">
                        {ccShow && (
                            <div className="cc-bcc">
                                <InputForm
                                    placeHolder={'Cc'}
                                    value={cc}
                                    type={'email'}
                                    inputChange={e => inputChange(e, 'cc')}
                                />
                            </div>
                        )}

                        {bccShow && (
                            <div className="cc-bcc">
                                <InputForm
                                    placeHolder={'Bcc'}
                                    value={bcc}
                                    type={'email'}
                                    inputChange={e => inputChange(e, 'bcc')}
                                />
                            </div>
                        )}

                    </div>
                )}

                {!hideSubject && (
                    <InputForm
                        placeHolder={'Subject'}
                        value={subject}
                        type={'text'}
                        wrapperClasses={'subject'}
                        inputChange={e => inputChange(e, 'subject')}
                    />
                )}

                {!hideTemplate && (
                    <div className="new-message-template-attachments">
                        <SelectMessagingTemplateComponent
                            chooseTemplateEvent={chooseTemplateEvent}
                            conType={1}
                        />

                        <PredefinedInboxMessagesComponent
                            preDefinedTextHandle={preDefinedTextHandle}
                        />

                        <div className="new-message-attachments-smiles">
                            <FileUpload
                                fileHandle={fileHandle}
                            />
                        </div>
                    </div>
                )}


                <div className={this.getEditorClasses(ccShow, bccShow)}>
                    <QuillBS
                        classes={'inbox'}
                        html={emailBody}
                        isInEditMode={true}
                        onChange={onEditorStateChange}
                        onSignatureChange={onSignatureEditorStateChange}
                    />
                </div>


            </div>
        );
    }
}

export default EmailMessaging;
