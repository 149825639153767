import React from 'react';
import RoundImage from '../RoundImage.component';
import './quickDetails.scss';
import utilsService from '../../../services/utils.service';
import {MapPointerIcon} from '../svg';
import ProgressBar from '../progressBar/ProgressBar.component';

const QuickDetailsTalentComponent = props => {
    const { action, data } = props;
    const isNotExposed = action === 'interested';
    return (
        <div className={`quick-details ${props.noShadow ? '' : 'shadow4'}`}>

            {!isNotExposed && (
                <RoundImage
                    alt='candidate profile picture'
                    imageUrl={utilsService.getProfileImage(data)}
                    profileName={utilsService.getDisplayName(data)}
                    height='50'
                />
            )}

            {isNotExposed && (
                <div className="fit">
                    <div className="fit-data">
                        {data.fit}% <span>fit</span>
                    </div>
                    <ProgressBar
                        fit={data.fit}
                    />
                </div>
            )}

            {!isNotExposed && (
                <h3 className='capitalized'>
                    {data.name}
                </h3>
            )}

            <p className='location'>{<MapPointerIcon/>}
                {data.location}</p>


            <div className="current">
                <p>{data.currentPosition}</p>
                <p>{data.currentCompany}</p>
            </div>


            {(data.previousPosition != '' || data.previousCompany != '') && (
                <div>
                    <legend>Past</legend>
                    <div className="past">
                        <p>{data.previousPosition}</p>
                        <p>{data.previousCompany}</p>
                    </div>
                </div>
            )}

            {(data.educationDegree != '' || data.educationSchoolName != '') && (
            <div>
                <legend>Education</legend>
                <div className="edu">
                    <p>{data.educationDegree}</p>
                    <p>{data.educationSchoolName}</p>
                </div>
            </div>
            )}
        </div>
    );
};

export default QuickDetailsTalentComponent;
