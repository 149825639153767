import React from 'react';
import NavigationBar
    from '../../components/navigationBar/NavigationBar.component';
import {DoorIcon} from '../../components/svg';
import './common-scenes.scss';

const PermissionRequiredView = () => {
    return (
        <div>
            <NavigationBar/>
            <div className="max-width page-not-found">
                <DoorIcon/>
                <h1>Sorry</h1>
                <p>you don't have permission to view this page</p>
            </div>
        </div>
    );
};

export default PermissionRequiredView;
