import React from 'react';
import packageJson from '../../../package.json';

const VersionShowComponent = () => {
  return (
    <div className='version-show'>
      <p>{packageJson.version}</p>
    </div>
  );
};

export default VersionShowComponent;
