import utilsService from "../../../services/utils.service";

export function mapUsersToHandlers(usersOrUser) {
  const users = Array.isArray(usersOrUser) ? usersOrUser : [usersOrUser];

  return users
    .filter(item => item.isActivated && item.canSignIn)
    .map(item => {
      return {
        elId: item._id,
        value: utilsService.getDisplayName(item),
        slug: item.slug,
        bsId: item.bsId,
        profileImage: utilsService.getProfileImage(item),
        companyName: item?.profile?.company,
        ...item,
      };
    })
}
