import React, { PureComponent } from 'react';
import userStore from '../../stores/user.store';
import utilsService from '../../services/utils.service';
import * as iconsList from '../components/svg';


class IconsListView extends PureComponent {
    async componentDidMount() {
        if (!utilsService.isRecruiter(userStore.user)) {
            this.props.history.push('/pipelines');
            return;
        }
    }


    render() {
        const keys = Object.keys(iconsList);

        return (
            <div
                style={{
                    display: 'grid',
                    padding: "20px",
                    gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 168px))'
                }}
                className='icons-list'>
                {keys.map((icon, idx) => {
                    const component = iconsList[icon];
                    return (
                        <div
                            style={{
                                minHeight: '100px',
                                border: '1px solid #3a3333',
                                alignContent: 'center',
                                display: 'grid',
                                justifyContent: 'center',
                                position: 'relative',
                                background: "rgb(134, 89, 89)"
                            }}
                            key={idx}
                            className="icons-list-item">
                            <span
                                style={{
                                    position: 'absolute',
                                    width: "100%",
                                    textAlign: 'center',
                                    color: '#000',
                                    fontSize: '14px',
                                    fontWeight:'bold'
                                }}

                            >{icon}</span>
                            {React.createElement(component, { key: idx })}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default IconsListView;
