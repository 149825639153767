import React, {useEffect, useRef, useState} from 'react';
import {PipelineReportDashboard} from './pipeline/PipelineReportDashboard';
import {autcompleteComparator, renderOptionByValue} from '../../../../../shared/utils/form.utils';
import {Autocomplete, Card} from '@brightsource/brightsource-ui-lib';
import './AnalyticContainer.scss';
import {ProcessReportDashboard} from './process/ProcessReportDashboard';
import {RefetchButton} from '../../../../../shared/ui/Button/RefetchButton/RefetchButton';
import {Label} from '@blueprintjs/core';
// import {BonusReportDashboard} from './bonus/BonusReportDashboard';

const REPORT_TYPES = {
  PIPELINE: {
    elId: 'PIPELINE',
    value: 'Pipeline',
  },
  PROCESS: {
    elId: 'PROCESSES',
    value: 'Processes',
  },
  // BONUS: {
  //   elId: 'BONUS',
  //   value: 'Bonus',
  // },
};
const REPORT_OPTIONS = Object.values(REPORT_TYPES);

export function AnalyticContainer() {
  const [reportType, setReportType] = useState(REPORT_TYPES.PIPELINE);
  const currentViewRef = useRef(null);

  function renderReportView() {
    return {
      [REPORT_TYPES.PIPELINE.elId]: <PipelineReportDashboard ref={currentViewRef}/>,
      [REPORT_TYPES.PROCESS.elId]: <ProcessReportDashboard ref={currentViewRef} />,
      // [REPORT_TYPES.BONUS.elId]: <BonusReportDashboard />,
    }[reportType.elId];
  }

  return <>
    <Card className="analytic-selection">
      <Label>
        Report type
      </Label>
      <div className='content'>
        <Autocomplete
          options={REPORT_OPTIONS}
          onChange={(_, value) => setReportType(value ?? REPORT_TYPES.PIPELINE)}
          value={reportType}
          getOptionLabel={renderOptionByValue}
          isOptionEqualToValue={autcompleteComparator}
        />

        <RefetchButton onClick={() => currentViewRef.current.refetch()} />
      </div>
    </Card>

    {renderReportView()}
  </>
}
