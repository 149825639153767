import React, { memo } from 'react';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import EmployerNavigationBar from './EmployerNavigationBar.component'
import RecruiterNavigationBar from './RecruiterNavigationBar.component'

function NavigationBar() {
    const isUserRecruiter = utilsService.isRecruiter(userStore.user);

    return (
      <>
          {isUserRecruiter
            ? <RecruiterNavigationBar />
            : <EmployerNavigationBar /> }
      </>
    );
}

export default memo(NavigationBar);
