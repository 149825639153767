import React, { useState, useRef } from 'react';
import './searchResultItem.scss';
import CustomCheckboxComponent from '../../../../components/customCheckbox/CustomCheckbox.component';
import utilsService from '../../../../../services/utils.service';
import DefaultImageComponent from '../../../../components/DefaultImage.component';
import {
    MailIcon2, TimeIcon, StarIcon3, ExperienceIcon, ProjectIcon,
    EducationIcon3, AddToMatchesIcon, SendCvBlueIcon,
    ProcessesBlueIcon, SendToSuggestBlueIcon, ProjectBlueIcon,
    AddToMatchesBlueIcon, CorrespondenceBlueIcon, NoteIcon, SendToSuggestIcon, SendCvIcon, TrashIcon, ListIcon, Bookmark
} from '../../../../components/svg';
import DropdownWithSubDirectories from '../../../../components/dropdown/dropdownWithSubDirectories/DropdownWithSubDirectories.component';
import MoreMenuActionComponent from '../../../../components/processActions/MoreMenuAction.component';
import StatusBarComponent from '../../../../components/statusBar/StatusBar.component';
import { Link } from 'react-router-dom';
import InterviewInfoComponent from '../../../../components/actions/InterviewInfo.component';
import PrimaryNote from '../../../../components/primarynote/PrimaryNote.component';
import notesStore from '../../../../../stores/notes.store';
import Cv2Component from '../../../talent/components/Cv2.component';
import UseOutsideClick from '../../../../components/OutsideClickHooks.component'
import data from '../../../../../constants/data';
import { observer } from "mobx-react"


const SearchResultItem = observer(React.forwardRef((props, ref) => {

    const [experienceOpen, setExperienceOpen] = useState(false)
    const [educationOpen, setEducationOpen] = useState(false)
    const [isCvVisible, setIsCvVisible] = useState(false)

    const { profile } = props;
    const profileName = utilsService.getDisplayName(profile);

    const outsideClickRef = useRef();

    UseOutsideClick(outsideClickRef, () => {
        if (isCvVisible) {
            setIsCvVisible(false);
        }
      });


    const checkAction = (e) => {
        props.checkActionChanged(profile);
    }

    const handleItemSelected = (e) => {

        props.itemSelected(profile)
    }

    const handleTitleFilterClick = (column, item) => {

    }

    const getDataForMoreMenu = () => {
        let temp = [];
        temp = [
            { modal: 'sendCV', text: 'Send CV', icon: 'sendCV' },
            { modal: 'isModalOpen_mark_as_withdrawn', text: 'Add to Project', icon: 'project' },
            { modal: 'sendSuggest', text: 'Send Suggest', icon: 'sendSuggest' },
            { modal: 'addToMatches', text: 'Add to Matches', icon: 'addToMatches' },
        ];
        return temp;
    }

    const createList = (column) => {
        if (column === 'projects') {
            return data.projectsOptions;
        }
    }

    const getIndustries = (industries = []) => {
        const count = industries.length;
        return industries.map((industry, index) =>
            utilsService.getObjValue(industry) + `${count - 1 !== index ? ', ' : ''}`);
    }

    const buildSkills = () => {
        const skills = utilsService.filterSkillsFromMandatorySkills(profile.skills, profile.primarySkills);
        const primarySkillsCss = utilsService.getSkills(profile.primarySkills, true);
        const skillsCss = utilsService.getSkills(skills, false);
        const res = primarySkillsCss.concat(skillsCss);
        return res;
    }

    const handleMoreMenuClick = (currentModal, e) => {
        e.stopPropagation();
        props.actionEventHandler(profile, currentModal, null, e);
    }

    const getLastUpdate = () => {
        let lastUpdate = 'Last update: ';

        // the requirment changed from last note update to lastUpdate flag
        // if(profile.lastNoteItemUpdated) {
        //     lastUpdate += utilsService.getFormattedDate(profile.lastNoteItemUpdated, 2);
        // }

        if(profile.lastUpdate) {
            lastUpdate += utilsService.getFormattedDate(profile.followUpDate, 2);
        }
        else {
            lastUpdate += 'N/A'
        }
        return lastUpdate;
    }


    return (
        <div className={'search-results-item-wrapper ' + (profile.slug === props.selectedItem.slug ? 'selected' : '')}>
            <div className="content-wrapper" onClick={(e) => handleItemSelected(e)}>
                <CustomCheckboxComponent
                    checkAction={(e) => checkAction(e)}
                    checked={profile.check} />
                <div className="content">
                    <div className="row">
                        <div className="profile-display">
                            <DefaultImageComponent
                                url={utilsService.getProfileImage(profile)}
                                alt={profileName}
                                profileName={profileName}
                            />
                            <div className="interview-tooltip">
                                <InterviewInfoComponent
                                    data={profile}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="row1">
                                <div className="left-part">
                                    <h1 className="name">
                                        <Link to={`/profile/${profile.slug}`}>
                                            {`${utilsService.getDisplayName(profile)}`}
                                        </Link>
                                    </h1>
                                    {profile.locationData && <h2 className="city">{`${profile.locationData.city}, ${profile.locationData.country}`}</h2>}
                                    {profile.positionTypes && profile.positionTypes.length > 0 && <div className="time">
                                        <TimeIcon />
                                        <h3 className="time-label">{profile.positionTypes[0].value}</h3>
                                    </div>}
                                    <div className="availability">
                                        <StatusBarComponent  text={utilsService.getCandidateAvailabilityLabel
                                            (profile)} />
                                    </div>
                                    {profile.rankingFromRecruiter !== undefined && profile.rankingFromRecruiter !== -1 &&
                                        profile.rankingFromRecruiter !== 0 && 
                                        <div className="stars">
                                            <StarIcon3 />
                                            <h2>{profile.rankingFromRecruiter}</h2>
                                        </div>}
                                </div>
                                <div className="right-part">
                                    <div className="activity-part">
                                        <div className="remove"
                                            onClick={(e) => props.removeItem(e, [profile])}
                                            title={'Remove'}>
                                            <TrashIcon />
                                        </div>
                                        <div className="send-cv"
                                            onClick={(e) => props.actionEventHandler(null, 'sendCV', null, e)}
                                            title={'Send CV'}>
                                            <SendCvIcon />
                                        </div>
                                        <div className="add-to-matches"
                                            onClick={(e) => props.actionEventHandler(profile, 'addToMatches', null, e)}
                                            title={'Add To Matches'}>
                                            <AddToMatchesIcon />
                                            <Bookmark />
                                        </div>
                                        <div className="send-to-suggest"
                                             onClick={(e) => props.actionEventHandler(profile, 'sendSuggest', null, e)}
                                             title={'Send To Suggest'}>
                                            <SendToSuggestIcon />
                                        </div>
                                        <div className="add-note-icon"
                                            onClick={(e) => props.actionEventHandler(profile, 'addNote', null, e)}
                                            title={'Add Note'}>
                                            <NoteIcon />
                                        </div>
                                    </div>
                                    <div className="projects-icon"  title={'Add To Project'}>
                                        <DropdownWithSubDirectories list={createList('projects')}
                                            titleFilterClick={(column, item) => handleTitleFilterClick(column, item)}
                                            leftIconImg={<ProjectIcon />} rightIcon='arrow'
                                        />
                                    </div>
                                    <div className="mail-icon"
                                        onClick={(e) => props.actionEventHandler(profile, 'sendMessage', null, e)}
                                        title={'Send Message'}>
                                        <MailIcon2 />
                                    </div>
                                    <div className="more-menu">
                                        <MoreMenuActionComponent
                                            process={null}
                                            menu={getDataForMoreMenu()}
                                            actionEvent={(process, modal, e) => handleMoreMenuClick(modal, e)}
                                            icons={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="inner-row row2">
                                <div className="row experience">
                                    <h4>Experience</h4>
                                    <h5>N/A</h5>
                                </div>
                                <div className="row seniority">
                                    <h4>Seniority</h4>
                                    <h5>{utilsService.getObjValue(profile.seniority)}</h5>
                                </div>
                                <div className="row salary">
                                    <h4>Salary</h4>
                                    <h5>{profile.desiredSalary ? `₪${profile.desiredSalary}` : `N/A`}</h5>
                                </div>
                                {profile.industries && profile.industries.length > 0 && <div className="row industry">
                                    <h4>Industry</h4>
                                    <h5>{getIndustries(profile.industries)}</h5>
                                </div>}
                            </div>
                        </div>
                    </div>
                    {profile.employment && profile.employment.length > 0 &&
                        <div className="row experience-list">
                            <button className={'button arrow ' + (experienceOpen ? 'close ' : '') +
                                (profile.employment.length <= 3 ? 'hidden ' : ' show')}
                                onClick={() => setExperienceOpen(!experienceOpen)} />
                            <div className="experience-icon">
                                <ExperienceIcon />
                            </div>
                            <div className="experience-item">
                                {profile.employment.map((item, index) => {
                                    if ((!experienceOpen && index < 3) || experienceOpen) {
                                        return (
                                            <div className="row" key={index}>
                                                <h4 className={"role " + (index === 0 ? '' : 'gray')}>{`${item.position ? item.position : 'N/A'} |`}</h4>
                                                <h4 className={"company-name " + (index === 0 ? '' : 'gray')}>{`${item.companyName}`}</h4>
                                                {item.location && <h2 className="company-city">{`${item.location.city}, ${item.location.country}`}</h2>}
                                                <h2 className="date">{utilsService.getDates(item.startDate, item.endDate)}</h2>
                                            </div>
                                        )
                                    }
                                    return false;
                                })}
                            </div>
                        </div>}
                    {profile.linkedInData?.educationList?.length > 0 &&
                        <div className="row education-list">
                            <button className={'button arrow ' + (educationOpen ? 'close ' : '') +
                                (profile.linkedInData.educationList.length <= 2 ? 'hidden ' : ' show')}
                                onClick={() => setEducationOpen(!educationOpen)}
                            />
                            <div className="education-icon">
                                <EducationIcon3 />
                            </div>
                            <div className="education-item">
                                {profile.linkedInData.educationList.map((item, index) => {
                                    if ((!educationOpen && index < 2) || educationOpen) {
                                        return (
                                            <div className="row" key={index}>
                                                <h4 className={"degree-type " + (index === 0 ? '' : 'gray')}>
                                                    {`${item.degreeType ? item.degreeType : 'N/A'},`}</h4>
                                                <h4 className={"degree-name " + (index === 0 ? '' : 'gray')}>
                                                    {`${item.degreeName ? item.degreeName : 'N/A'} |`}</h4>
                                                <h4 className={"institute-name " + (index === 0 ? '' : 'gray')}>{`${item.educationInstitution},`}</h4>
                                                <h6 className="degree-city">{'N/A'}</h6>
                                            </div>
                                        )
                                    }
                                    return false;

                                })}
                            </div>
                        </div>}
                    <div className="skills">
                        {buildSkills()}
                    </div>
                    <div className="row recruiter">
                        <h2>Recruiter:</h2>
                        <DefaultImageComponent
                            url={utilsService.getRecruiterInChargeProfileImage(profile)}
                            alt={utilsService.getRecruitersName(profile)}
                            profileName={utilsService.getRecruitersName(profile)}
                            isExposed={true}
                        />
                    </div>
                    <div className="update-details">
                        <div className="activities-counter">
                            <div className="send-cv" onClick={() => props.showCVClick(profile)}>
                                <ActivitiesCounter img={<SendCvBlueIcon />} number={'-'} />
                            </div>
                            <ActivitiesCounter img={<ProcessesBlueIcon />} number={'-'} />
                            <ActivitiesCounter img={<SendToSuggestBlueIcon />} number={'-'}
                                imgClass={'send-to-suggestion-icon'}
                                numberClass={'send-to-suggestion-label'} />
                            <ActivitiesCounter img={<MailIcon2 />} number={'-'}
                                imgClass={"mail-icon"} numberClass={'mail-label'} />
                            <ActivitiesCounter img={<ProjectBlueIcon />} number={'-'}
                                numberClass={'project-label'} />
                            <ActivitiesCounter img={<AddToMatchesBlueIcon />} number={'-'}
                                numberClass={'add-to-matches-label'} />  
                            <div className="notes-counter">
                                <ActivitiesCounter img={<CorrespondenceBlueIcon />} number={'-'} />
                            </div>
                            <div className="notes-tooltip">
                                <PrimaryNote
                                    isAlwaysOpen={true}
                                    wrapperClass={'notes-details'}
                                    note={notesStore.profileNotes[profile._id]}
                                />
                            </div>
                        </div>
                        <div className="last-update">
                            <h2 className="last-update-label">{getLastUpdate()}</h2>
                            <h2 className="separator">|</h2>
                            <h2>{'Source: N/A'}</h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}))

const ActivitiesCounter = props => {

    return (
        <div className="row">
            <div className={props.imgClass}>
                {props.img}
            </div>
            <label className={props.numberClass}>{props.number}</label>
        </div>
    )
};



SearchResultItem.propTypes = {
    // bla: PropTypes.string,
};

SearchResultItem.defaultProps = {
    // bla: 'test',
};

export default SearchResultItem;




