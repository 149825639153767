import React from 'react';
import { StarIcon } from '../svg';
import './stars.scss';

const StarsComponent = props => {
    const { rank, changeRankEvent, canEdit } = props;
    const rating = rank ? rank : 0;
    const arr = [0, 0, 0, 0, 0];

    let classes = 'stars-component';

    if (canEdit) {
        classes += ' editable';
    }

    const getClassName = (idx, rating) => {
        if(idx + 1 === 1 && rating === 1) {
            return 'star-rating filled not-touch';
        }
        else if(idx + 1 <= rating) {
            return 'star-rating filled';
        }
        else {
            return 'star-rating';
        }
    }

    return (
        <div className={classes}>
            {arr.map((s, idx) => {
                return (
                    <div
                        key={idx}
                        onClick={canEdit ? () => changeRankEvent(idx + 1) : null}
                        className={getClassName(idx, rating)}>
                        <StarIcon/>
                    </div>
                );
            })}
        </div>
    );
};

export default StarsComponent;
