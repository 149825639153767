import React, { PureComponent } from 'react';
import { InputForm, SuggestComponent } from '../../../components/forms';
import { TrashIcon } from '../../../components/svg';

class LocationsComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            city: {},
            address: '',
            sitename: '',
            postalcode: null,
            cityError: false
        };
    }

    addHandle = () => {
        const { addBranch } = this.props;
        const { address, city, sitename, postalcode } = this.state;
        if (city._id) {
            addBranch(city, address, sitename, postalcode);
            this.setState({
                city: {},
                address: '',
                sitename: '',
                postalcode: null
            })
        } else {
            this.setState({cityError: true})
        }
    };

    removeHandle = (idx) => {
        const { removeBranch } = this.props;
        removeBranch(idx);
    };

    render() {
        const { list, getWorldCities, locations } = this.props;
        const { address, city, sitename, postalcode, cityError } = this.state;

        return (
            <div className='branches-locations'>
                <div className="entry">
                    <div className="form-item">
                        <label>City</label>
                        <SuggestComponent
                            items={locations}
                            selectedItem={city}
                            removeItem={() => this.setState({ city: 0 })}
                            selectItem={item => this.setState({ city: item })}
                            query={getWorldCities}
                            numberOfCharsToPredicate={2}
                            showListCompanyLocations={true}
                        />
                    </div>
                    <InputForm
                        label={'Street'}
                        value={address}
                        type={'text'}
                        inputChange={e => this.setState({ address: e.target.value })}
                    />
                    <InputForm
                        label={'Site name'}
                        value={sitename}
                        type={'text'}
                        inputChange={e => this.setState({ sitename: e.target.value })}
                    />

                    <InputForm
                        label={'Postal code'}
                        value={postalcode}
                        type={'number'}
                        inputChange={e => this.setState({ postalcode: e.target.value })}
                    />

                </div>

                {cityError && <div className='error-message'>
                    City is missing!
                </div>}
                <button
                        onClick={this.addHandle}
                        className="button black more-cities">
                        Add
                </button>

                {list && list.length > 0 && (
                    <div className="branches-list border-radius">
                        {
                            list.map((item, idx) => {

                                const city = item.city ? `${item.city}, ${item.adminName}, ${item.country}` : '';
                                return (
                                    <div
                                        key={idx}
                                        className="branch"
                                    >
                                        <div>
                                            <strong>{city}</strong>
                                            <p>{item.siteName}</p>
                                            {item.name && (
                                                <p>{item.name}</p>
                                            )}
                                            {item.postalCode && (
                                                <p>{item.postalCode}</p>
                                            )}
                                        </div>

                                        <div>
                                            <div
                                                onClick={() => this.removeHandle(idx)}
                                                className="remove-icon">
                                                <TrashIcon />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                )}
            </div>
        );
    }
}

export default LocationsComponent;
