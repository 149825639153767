import utilsService from '../../../services/utils.service'
import companyStore from '../../../stores/company.store';
import metaDataStore from '../../../stores/meta-data.store';
import searchStore from '../../../stores/search.store';
import data from '../../../constants/data';
import userStore from '../../../stores/user.store';
import AdvancedSearchView from '../advancedSearch/AdvancedSearch.view';


export const updateData = async (search) => {
    
    const metaData = metaDataStore.categories

    const params = new URLSearchParams(search);
    
    const type = params.get('type');
    if(type) {
        searchStore.changeAdvancedTop(Number(type));
    }

    const keyWords = params.get('keyWords');
    if(keyWords) {
        searchStore.setSearchValue('keyWords', keyWords);
    }

    setData(search, 'categories', 'selectedCategories', Object.values(metaData.categories));

    setData(search, 'roles', 'selectedRoles', Object.values(metaData.roles));

    setData(search, 'rolesExc', 'selectedRoles', Object.values(metaData.roles, true));

    const rolesOperator = params.get('rolesOp');
    if(rolesOperator) {
        searchStore.setSearchValue('operatorRoles', 
            rolesOperator.charAt(0).toUpperCase() + rolesOperator.slice(1));
    }

    setData(search, 'skills', 'selectedSkills', Object.values(metaData.skills));

    setData(search, 'skillsExc', 'selectedSkills', Object.values(metaData.skills, true));

    const skillsOperator = params.get('skillsOp');
    if(skillsOperator) {
        searchStore.setSearchValue('operatorSkills',
            skillsOperator.charAt(0).toUpperCase() + skillsOperator.slice(1));
    }

    setData(search, 'address', 'selectedLocations', metaDataStore.locations);

    setData(search, 'addressExc', 'selectedLocations', metaDataStore.locations, true);

    setData(search, 'area', 'selectedDLocations', Object.values(metaData.desiredLocations));

    setData(search, 'areaExc', 'selectedDLocations', Object.values(metaData.desiredLocations), true);
    
    setData(search, 'salary', 'salaryRange', null);

    setData(search, 'salaryExc', 'salaryRange', null, true);

    const salaryType = params.get('salaryType');
    if(salaryType) {
        searchStore.setSearchValue('selectedSalary', Number(salaryType));
    }
    
    setData(search, 'availability', 'selectedAvailability', data.availabilityOptionsSearch);

    setData(search, 'posTypes', 'selectedPositions', Object.values(metaData.positionTypes));

    setData(search, 'posTypesExc', 'selectedPositions', Object.values(metaData.positionTypes), true);

    setData(search, 'stages', 'selectedStages', data.stage);

    setData(search, 'stagesExc', 'selectedStages', data.stage, true);

    setData(search, 'seniority', 'selectedSeniorities', Object.values(metaData.seniorityLevels));

    setData(search, 'seniorityExc', 'selectedSeniorities', Object.values(metaData.seniorityLevels), true);

    setData(search, 'languages', 'selectedLanguages', Object.values(metaData.languages));

    setData(search, 'languagesExc', 'selectedLanguages', Object.values(metaData.languages), true);

    setData(search, 'projectTypes', 'selectedProjectTypes', Object.values(metaData.projectTypes));

    setData(search, 'recruiter', 'recruiterInCharge', AdvancedSearchView.normalizeRecruiters(userStore.recruitersList));

    setData(search, 'recruiterExc', 'recruiterInCharge', AdvancedSearchView.normalizeRecruiters(userStore.recruitersList), true);

    setData(search, 'jobStatus', 'jobStatus', Object.values(metaData.jobStatus));

    setData(search, 'age', 'ageRange', null);

    setData(search, 'ageExc', 'ageRange', null, true);

    await setData(search, 'company', 'currentCompany', null);

    await setData(search, 'companyExc', 'currentCompany', null, true);

    await setData(search, 'prevCompany', 'previousCompany', null);

    await setData(search, 'prevCompanyExc', 'previousCompany', null, true);

    setData(search, 'degree', 'selectedDegreeTypes', Object.values(metaData.degreeTypes));

    setData(search, 'degreeExc', 'selectedDegreeTypes', Object.values(metaData.degreeTypes), true);

    setData(search, 'school', 'schoolType');
    
    setData(search, 'degreeName', 'selectedDegreeNames', Object.values(metaData.degreeNames));

    setData(search, 'degreeNameExc', 'selectedDegreeNames', Object.values(metaData.degreeNames), true);

    setData(search, 'educationIns', 'selectedEducationInstitutions', Object.values(metaData.educationInstitutions));

    setData(search, 'educationInsExc', 'selectedEducationInstitutions', Object.values(metaData.educationInstitutions), true);

    setData(search, 'summary', 'summary', data.summary);

    setData(search, 'sectors', 'sector', Object.values(metaData.sectors));

    setData(search, 'industries', 'industries', Object.values(metaData.industries));

    setData(search, 'industriesExc', 'industries', Object.values(metaData.industries), true);

    setData(search, 'productTags', 'tags', Object.values(metaData.specialties));

    setData(search, 'productTagsExc', 'tags', Object.values(metaData.specialties), true);

}

const setData = async (search, queryName, name, data, isExcluded) => {
    const params = new URLSearchParams(search);
    const datas = params.get(queryName);
    if(datas) {
        const dataFromURLArray = datas.split(',')
        if(dataFromURLArray) {
            let dataNew = null;
            if(name === 'selectedLocations') {
                const formattedData = utilsService.normalizeMetaLocationsFromDB(data, true)
                dataNew = formattedData.filter(item => dataFromURLArray.includes(item.elId.toString()));
                dataNew = utilsService.extractDataByElIdAndValue(dataNew);
            }
            else if(name === 'ageRange' || name === 'salaryRange') {
                dataNew = { min: Number(dataFromURLArray[0]), max: Number(dataFromURLArray[1]) };
                if(isExcluded) {
                    dataNew.isExclude = true;
                }
            }
            else if(name === 'currentCompany' || name === 'previousCompany') {
                const companyName = dataFromURLArray.length > 0 ? dataFromURLArray[0]: null;
                if(companyName) {
                    const companies = await companyStore.searchCompaniesByPartialNameByText(companyName)
                    if(companies && companies.length > 0) {
                        const company = companies[0];
                        dataNew = {
                            value: company.name,
                            elId: company._id
                        }
                    }
                }
            }
            else if(name === 'selectedStages') {
                dataNew = data.filter(item => dataFromURLArray.some(element => {
                    return utilsService.getStatusByEnum(element, true) === item.value;
                }))
            }
            else if(name === 'schoolType') {
                dataNew = 0;
                if(dataFromURLArray[0] === "3") {
                    dataNew = 1;
                }
                else if(dataFromURLArray[0] === "7") {
                    dataNew = 2;
                }
            }
            else if(name === 'summary') {
                dataNew = data.findIndex(item => Number(dataFromURLArray[0]) === item.elId);
            }
            else {
                dataNew = data.filter(item => dataFromURLArray.includes(item.elId.toString()));
                if(name === 'sector' || name === 'recruiterInCharge') {   
                    dataNew = dataNew[0];
                }
                else if(name === 'jobStatus') {
                    dataNew = dataNew[0].elId;
                }
                else {
                    if(name !== 'selectedDLocations') {    
                        dataNew = utilsService.extractDataByElIdAndValue(dataNew);
                    }
                }
            }


            if(isExcluded) {
                if(Array.isArray(dataNew)) {
                    dataNew.forEach(item => {
                        item.type = 2;
                    })                     
                    const rightTop = searchStore.selectRightTop();
                    let normalData = utilsService.doMeAFavor(searchStore[rightTop][name]);
                    searchStore.setSearchValue(name, [...normalData, ...dataNew]);
                }
                else {
                    dataNew.type = 2;
                    searchStore.setSearchValue(name, dataNew);
                }
            }
            else {
                searchStore.setSearchValue(name, dataNew);

            }
        }
    }
}

export const buildQuery = () => {
    const queryArray = [];

    const searchType = searchStore.selectedTopAdvanced;
    queryArray.push(`type=${searchType}`);

    const data = searchStore.prepareAdvancedSearchData();

    if(data.keyWords) {
        queryArray.push(`keyWords=${data.keyWords}`);
    }

    let hasCategories = false;
    if(data.categories && data.categories.length > 0) {
        queryArray.push(`categories=${data.categories}`);
        hasCategories = true;
    }
  
    if(data.roles && data.roles.length > 0) {
        queryArray.push(`roles=${data.roles}`);
    }

    if(data.excludedRoles && data.excludedRoles.length > 0) {
        queryArray.push(`rolesExc=${data.excludedRoles}`);
    }

    if(hasCategories && data.operatorRoles && data.operatorRoles.length > 0) {
        queryArray.push(`rolesOp=${data.operatorRoles}`);
    }

    let hasSkills = false;
    if(data.skills && data.skills.length > 0) {
        queryArray.push(`skills=${data.skills}`);
        hasSkills = true;
    }

    if(data.excludedSkills && data.excludedSkills.length > 0) {
        queryArray.push(`skillsExc=${data.excludedSkills}`);
        hasSkills = true;
    }

    if(hasSkills && data.operatorSkills && data.operatorSkills.length > 0) {
        queryArray.push(`skillsOp=${data.operatorSkills}`);
    }

    if(data.address && data.address.length > 0) {
        queryArray.push(`address=${data.address}`);
    }

    if(data.excludedAddress && data.excludedAddress.length > 0) {
        queryArray.push(`addressExc=${data.excludedAddress}`);
    }
    
    if(data.desiredLocations && data.desiredLocations.length > 0) {
        queryArray.push(`area=${data.desiredLocations}`);
    }

    if(data.excludedDesiredLocations && data.excludedDesiredLocations.length > 0) {
        queryArray.push(`areaExc=${data.excludedDesiredLocations}`);
    }

    if(data.salaryRange) {
        const excluded = data.salaryRange.isExclude ? 'Exc' : '';
        queryArray.push(`salary${excluded}=${data.salaryRange.min},${data.salaryRange.max}`);
    }

    if(data.salaryType) {
        queryArray.push(`salaryType=${data.salaryType - 1}`);
    }


    if(data.isAvailable && data.isAvailable.length > 0) {
        queryArray.push(`availability=${data.isAvailable}`);
    }

    if(data.positionTypes && data.positionTypes.length > 0) {
        queryArray.push(`posTypes=${data.positionTypes}`);
    }

    if(data.excludedPositionTypes && data.excludedPositionTypes.length > 0) {
        queryArray.push(`posTypesExc=${data.excludedPositionTypes}`);
    }

    if(data.selectedStages && data.selectedStages.length > 0) {
        queryArray.push(`stages=${data.selectedStages}`);
    }

    if(data.excludedSelectedStages && data.excludedSelectedStages.length > 0) {
        queryArray.push(`stagesExc=${data.excludedSelectedStages}`);
    }

    if(data.seniority && data.seniority.length > 0) {
        queryArray.push(`seniority=${data.seniority}`);
    }

    if(data.excludedSeniority && data.excludedSeniority.length > 0) {
        queryArray.push(`seniorityExc=${data.excludedSeniority}`);
    }

    if(data.languages && data.languages.length > 0) {
        queryArray.push(`languages=${data.languages}`);
    }

    if(data.excludedLanguages && data.excludedLanguages.length > 0) {
        queryArray.push(`languagesExc=${data.excludedLanguages}`);
    }

    if(data.projectTypes && data.projectTypes.length > 0) {
        queryArray.push(`projectTypes=${data.projectTypes}`);
    }

    if(data.recruiterInCharge && data.recruiterInCharge.length > 0) {
        queryArray.push(`recruiter=${data.recruiterInCharge}`);
    }

    if(data.excludedRecruiterInCharge && data.excludedRecruiterInCharge.length > 0) {
        queryArray.push(`recruiterExc=${data.excludedRecruiterInCharge}`);
    }

    if(data.type === 1 && data.jobStatus !== null && data.jobStatus !== -1 ) {
        queryArray.push(`jobStatus=${data.jobStatus}`);
    }

    if(data.ageRange) {
        const excluded = data.ageRange.isExclude ? 'Exc' : '';
        queryArray.push(`age${excluded}=${data.ageRange.min},${data.ageRange.max}`);
    }

    if(data.currentCompany && data.currentCompany.length > 0) {
        queryArray.push(`company=${data.currentCompany}`);
    }

    if(data.excludedCurrentCompany && data.excludedCurrentCompany.length > 0) {
        queryArray.push(`companyExc=${data.excludedCurrentCompany}`);
    }

    if(data.previousCompany && data.previousCompany.length > 0) {
        queryArray.push(`prevCompany=${data.previousCompany}`);
    }

    if(data.excludedPreviousCompany && data.excludedPreviousCompany.length > 0) {
        queryArray.push(`prevCompanyExc=${data.excludedPreviousCompany}`);
    }

    if(data.degreeTypes && data.degreeTypes.length > 0) {
        queryArray.push(`degree=${data.degreeTypes}`);
    }

    if(data.instituteType) {
        queryArray.push(`school=${data.instituteType}`);
    }

    if(data.degreeNames && data.degreeNames.length > 0) {
        queryArray.push(`degreeName=${data.degreeNames}`);
    }

    if(data.excludedDegreeNames && data.excludedDegreeNames.length > 0) {
        queryArray.push(`degreeNameExc=${data.excludedDegreeNames}`);
    }

    if(data.educationInstitutions && data.educationInstitutions.length > 0) {
        queryArray.push(`educationIns=${data.educationInstitutions}`);
    }

    if(data.excludedEducationInstitutions && data.excludedEducationInstitutions.length > 0) {
        queryArray.push(`educationInsExc=${data.excludedEducationInstitutions}`);
    }

    if(data && data.type === 0 && data.summary !== -1) {
        queryArray.push(`summary=${data.summary}`);
    }

    if(data.sectors && data.sectors.length > 0) {
        queryArray.push(`sectors=${data.sectors}`);
    }

    if(data.industries && data.industries.length > 0) {
        queryArray.push(`industries=${data.industries}`);
    }

    if(data.excludedIndustries && data.excludedIndustries.length > 0) {
        queryArray.push(`industriesExc=${data.excludedIndustries}`);
    }

    if(data.tags && data.tags.length > 0) {
        queryArray.push(`productTags=${data.tags}`);
    }

    if(data.excludedTags && data.excludedTags.length > 0) {
        queryArray.push(`productTagsExc=${data.excludedTags}`);
    }

    const query = utilsService.buildQuery(queryArray);
    return query
}


export const hasRecruiterParam = (search) => {
    const params = new URLSearchParams(search);
    const recruiter = params.get('recruiter');
    const recruiterExc = params.get('recruiterExc');
    return !!recruiter || !!recruiterExc;
}
