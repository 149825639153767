import React, {Component} from 'react';
import {AnonAvatar, CompanyAvatarIcon} from './svg';
import utilsService from '../../services/utils.service';

class DefaultImageComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false
        };
    }

    getDefaultFrame = () => {
        const { width, profileName } = this.props;
        let initials = '';
        if (profileName) {
            const splited = profileName.split(' ');
            if (splited[0] && splited[1]) {
                initials = `${splited[0].charAt(0)}${splited[1].charAt(0)}`;
            } else if (splited[0]) {
                initials = `${splited[0].charAt(0)}${splited[0].charAt(1)}`;
            }
        }
        return (
            <div className='default-pic-frame'
                 style={{ backgroundColor: utilsService.getRandomBackgroundColor(initials), width: width, height: width }}>
                {initials}
            </div>
        );
    };

    getSvg(isCompany) {
        if (isCompany) {
            return (<CompanyAvatarIcon/>);
        }

        return (this.getDefaultFrame(this.props.profileName));
    };

    setDefault = () => {
        const { imageError } = this.props;
        imageError && imageError();
        this.setState({ isError: true });
    };

    getAnonAvatar() {
        return (
            <div className="default-pic-frame anon">
                <AnonAvatar/>
            </div>
        );
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.url !== this.props.url) {
            this.setState({ isError: false });
        }
    }

    render() {
        const { url, alt, company, width, height, imageClick, classes, updatedAt } = this.props;
        const updatedAtMs = new Date(updatedAt).getTime();

        return (
            <div  
                className={'default-image-component ' + classes}
                onClick={(e) =>  imageClick && imageClick()}
                style={{ cursor: imageClick && 'pointer' }}
            >
                
                {!this.state.isError && url && (
                    <img
                        src={updatedAt ? `${url}?t=${updatedAtMs}` : url}
                        alt={alt}
                        width={width}
                        height={height}
                        onError={() => this.setDefault()}
                    />
                )}

                {this.state.isError && (
                    this.getSvg(company)
                )}

                {!this.state.isError && !url && (
                    this.getSvg(company)
                )}
            </div>
        );
    }
}

DefaultImageComponent.defaultProps = {
    classes: '',
}
  

export default DefaultImageComponent;
