import { Button, Icon } from '@blueprintjs/core';
import { Avatar, Menu, useIsMobile } from '@brightsource/brightsource-ui-lib';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import data from '../../../constants/data';
import { ResponsiveDevice } from '../../../constants/styles';
import utilsService from '../../../services/utils.service';
import notificationsStore from '../../../stores/notifications.store';
import ConfirmationModal from '../modalComponents/ConfirmationModal.component';
import { EditRoundedIcon, PinIcon, PinnedIcon, TrashRoundedIcon } from '../svg';

export function Note({ note, onSave, onCancel, onDelete }) {
  const isMobile = useIsMobile();
  const noteId = note._id;

  const noteTag = data.noteTags.find((tag) => tag.elId === note.eventTypeId);
  const noteUpdated = utilsService.getFormattedDate(note.dateUpdated ?? Date.now(), 6);
  const creatorName = utilsService.getDisplayName(note.creatorUser, true);

  const [isEdit, setIsEdit] = useState(!noteId);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [selectedTag, setSelectedTag] = useState(noteTag);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleSave = () => {
    if (!onSave) return;

    if (!editorState.getCurrentContent().hasText()) {
      notificationsStore.showToast('Missing note content', 'failure');
      return;
    }

    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    const updatedData = {
      text,
      eventTypeId: selectedTag?.elId,
      isTackOn: selectedTag?.elId === 1,
    };

    if (noteId) {
      onSave(noteId, updatedData);
    } else {
      onSave(updatedData);
    }

    onCancel ? onCancel() : setIsEdit(false);
  };

  const handlePin = () => {
    onSave && onSave(noteId, { isTackOn: true });
  };

  const handleUnpin = () => {
    onSave && onSave(noteId, { isTackOn: false });
  };

  const handleDelete = () => {
    onDelete && onDelete(noteId);
  };

  const handleCancel = () => {
    setSelectedTag(noteTag);
    onCancel ? onCancel() : setIsEdit(false);
  };

  useEffect(() => {
    if (!note) return;

    const contentBlock = htmlToDraft(note.text || '');

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  }, [note]);

  const tagMenu = (
    <Menu
      items={data.noteTags.map((tag) => ({ text: tag.value, onClick: () => setSelectedTag(tag) }))}
      minWidth={160}
      endIcon={<Icon icon="chevron-down" />}
      buttonProps={{
        sx: { minWidth: 160, justifyContent: 'space-between' },
      }}
    >
      {selectedTag?.value}
    </Menu>
  );

  return (
    <Wrapper className={isEdit ? 'isEdit' : ''}>
      {isEdit && isMobile && tagMenu}
      {!isEdit && (
        <Header>
          {noteTag && <Tag className={noteTag.elId === 1 ? 'isPrimary' : ''}>{noteTag.value}</Tag>}

          <Actions>
            <EditRoundedIcon
              onClick={() => {
                setIsEdit(true);
                setSelectedTag(noteTag);
              }}
            />

            {note.isTackOn ? <PinnedIcon onClick={handleUnpin} /> : <PinIcon onClick={handlePin} />}

            <TrashRoundedIcon onClick={() => setShowDeleteModal(true)} />
          </Actions>
        </Header>
      )}
      <Editor
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbar={{
          options: ['blockType', 'inline', 'list', 'link', 'image', 'textAlign'],
        }}
        readOnly={!isEdit}
        toolbarHidden={!isEdit}
        editorStyle={{
          minHeight: isEdit ? 200 : 0,
          borderWidth: isEdit ? 1 : 0,
          padding: isEdit ? '0 20px' : 0,
          color: '#404040',
          height: '100%',
        }}
      />
      <Footer>
        <Avatar size={30} src={utilsService.getProfileImage(note.creatorUser)} name={creatorName} />

        <span>
          {creatorName}, {noteUpdated}
        </span>

        {isEdit && (
          <Actions>
            {!isMobile && tagMenu}

            <Button minimal className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>

            <Button minimal className="add-btn" onClick={handleSave}>
              Save
            </Button>
          </Actions>
        )}
      </Footer>

      {showDeleteModal && (
        <ConfirmationModal
          title="You are about to delete note"
          subtitle="Are you sure?"
          buttonText="Delete"
          closeEvent={() => setShowDeleteModal(false)}
          confirmEvent={handleDelete}
          // isLoading={deleteLoader}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 12px;
  border: 1px solid #efefef;
  background-color: #f8f8f8;

  &.isEdit {
    border-color: #307ff6;
    background-color: #fff;
  }

  .bs-menu {
    margin-left: auto;
  }

  ${ResponsiveDevice.TABLET} {
    padding: 16px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;

  & > svg {
    cursor: pointer;
  }

  .bp3-button {
    padding: 8px 20px;
    font-size: 16px;
    font-weight: 400;
    color: #a4a4a4;

    &.add-btn {
      color: #fff;
      background-color: #307ff6;
      border-radius: 60px;
    }

    &.cancel-btn {
      background-color: transparent;
    }
  }
`;

const Tag = styled.span`
  display: flex;
  align-items: center;

  min-height: 30px;
  padding: 0 24px;
  border-radius: 60px;
  border: 1px solid #a4a4a4;

  font-size: 14px;
  font-weight: 500;
  color: #a4a4a4;

  &.isPrimary {
    color: #fff;
    background-color: #307ff6;
    border-color: #307ff6;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  & > span {
    font-size: 14px;
    font-weight: 400;
    color: #8a8a8a;
  }

  .MuiAvatar-root {
    font-size: 16px;
  }

  ${ResponsiveDevice.TABLET} {
    flex-wrap: wrap;
  }
`;
