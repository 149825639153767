import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import './ProcessReportDashboard.scss';
import {ProcessReportTable} from '../../../../../../entities/reports/model/process/ProcessReportTable/ProcessReportTable';
import {ProcessFilterBar} from '../../../../../../entities/reports/model/process/ProcessFilterBar/ProcessFilterBar';
import {processReportStore} from '../../../../../../entities/reports/model/report.store';

export const ProcessReportDashboard = forwardRef(function ProcessReportDashboard(props, ref) {
  useImperativeHandle(ref, () => ({
    refetch: processReportStore.refetch,
  }));
  useEffect(() => {
    processReportStore.onInit();
  }, []);

  return <div {...props} className='process-report-container' ref={ref}>
    <ProcessFilterBar />
    <ProcessReportTable />
  </div>
})
