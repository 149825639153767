import React, {Component} from 'react';
import {observer} from 'mobx-react';
import NavigationBar from '../../../components/navigationBar/NavigationBar.component';
import Loader from '../../../components/loader/Loader.component';
import userStore from '../../../../stores/user.store';
import {InputForm, MultiSelectComponent, SelectForm, SuggestComponent, TextAreaForm} from '../../../components/forms';
import utilsService from '../../../../services/utils.service';
import DefaultImageComponent from '../../../components/DefaultImage.component';
import LocationsComponent from './Locations.component';
import metaDataStore from '../../../../stores/meta-data.store';
import companyStore from '../../../../stores/company.store';
import notificationsStore from '../../../../stores/notifications.store';
import TopEditCompany from './Top.component';
import data from '../../../../constants/data';
import CustomCheckboxComponent from '../../../components/customCheckbox/CustomCheckbox.component';
import FromTheNews from './FromTheNews.component';
import {FacebookIcon2, LinkedInIcon2, TwitterIcon2} from '../../../components/svg';
import { withRouter } from 'react-router-dom';
import './editCompany.scss';
import SuggestWithNetworkComponent from '../../../components/forms/SuggestWithNetwork.component';
import 'react-phone-number-input/style.css'
import { Autocomplete, PhoneInput } from '@brightsource/brightsource-ui-lib';
import {formatPhoneNumberIntl, isValidPhoneNumber} from 'react-phone-number-input'
import qs from 'qs';

@observer
class EditCompanyComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyNamesLoader: true,
            loader: true,
            companyName: '',
            companyNameHebrew: '',
            founded: 0,
            listLocations: [],
            selectedCity: {},
            selectedStreet: '',
            selectedSiteName: '',
            selectedPostalcode: '',
            organizationSize: 0,
            selectedSectors: [],
            orgStatus: 0,
            nameOfBuyer: '',
            selectedBusinessModel: [],
            selectedLifeCycleStage: 0,
            selectedType: 0,
            selectedBusinessStage: 0,
            companyTicker: '',
            aboutUs: '',
            selectedSpecialties: [], // products
            selectedIndustries: [],
            branches: [],
            videoLink: '',
            parent: 0,
            parentRelationshipType: 0,
            selectedBenefits: [],
            contactEmail: '',
            contactPhone: {},
            website: '',
            linkedIn: '',
            twitter: '',
            facebook: '',

            fromTheNewsList: [],

            logoImageFile: null,
            logoPreviewUrl: null,
            logoValid: true,

            companyNameValid: false,

            showError: false
        };
        this._selectYears = utilsService.getListOfYears();
        this._normalLocations = [];
        this._products = [];
        this._companyType = [];
        this._businessModel = [];
        this._companyBusinessStage = [];
        this._companyMarket = [];
        this._industries = [];
        this.__logoUpload = null;
        this._industryDistrict = [];
        this._countryCodes = [];
    }

    bringMetaDataCategories() {
        const categories = [
            'specialties',
            'companyType',
            'businessModel',
            'companyBusinessStage',
            'companyMarket',
            'industries',
            'companySizes',
            'locations',
            'industryDistrict',
            'countryCodes'
        ];
        return categories.map(cat => {
            return metaDataStore.getCategory(cat, true);
        });
    }

    inputChange = (e, type, valid) => {

        if (valid) {
            this.setState({ [type]: e.target.value, [valid]: e.target.value.length > 1 });
        } else {
            if(type==='linkedIn'){
                e.target.value = e.target.value.split("/about")[0];
            }
            if(type === 'contactPhone') {
                const maxLength = 20
                if (e?.length > maxLength) {
                    e = e.slice(0, maxLength)
                }
                const contactPhone = {
                    ...this.state.contactPhone,
                    phone: e?.replace(/[^0-9\-]/g, ''),
                    country: this.state.contactPhone?.country || 'il',
                    callingCode: this.state.contactPhone?.callingCode || '972'
                }
                this.setState({ contactPhone });
            } else this.setState({ [type]: e.target.value });
        }
    };

    getPhone = () => {
        return this.state.contactPhone.phone || ''
    }

    getCountryCode = () => {
        return this.state.contactPhone?.country || "il"
    }
    
    countryCodeChange = (e) => {
        const callingCode = this._countryCodes?.find( i => i.value == e)?.dialCode || '972'
        const contactPhone = {
            ...this.state.contactPhone,
            country: e,
            callingCode: callingCode,
            phone: ''
        }
        this.setState({ contactPhone });
    }

    removeMultiItem = (text, type) => {
        const update = this.state[type].filter((objItem) => objItem.value !== text);
        this.setState({ [type]: update });

    };

    selectMultiItem = (item, type) => {
        let update = null;

        if (this.state[type].some(objItem => objItem.elId === item.elId)) {
            update = this.state[type].filter((objItem) => objItem.elId !== item.elId);
        } else {
            update = [...this.state[type], item];
        }

        this.setState({
            [type]: update
        });
    };

    selectSuggestItem = (item, type) => {
        this.setState({ [type]: item });
    };

    getWorldCities = async(value) => {
        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        if(value.length >= 2 && !this.state['showListCompanyLocations']) {
            const payload = {
                city: value
            }
            this.typingTimeout = setTimeout(async () => { 
                const data = await metaDataStore.getWorldCities(qs.stringify(payload))
                if (data?.length > 0) {
                    this.setState({listLocations: data.map(item => ({...item, elId: item._id, value: `${item.cityAscii}, ${item.adminNameAscii}, ${item.country}`}))})
                }
            }, 300);
        }
    }

    addBranch = (city, street, sitename, postalcode) => {
        const data = {
            name: street,
            city: city.cityAscii,
            cityId: city ? city.elId : -1,
            country: city.country,
            adminName: city.adminName,
            adminCode: city.adminCode,
            adminType: city.adminType,
            latitude: city.latitude,
            longitude: city.longitude,
            timezone: city.timezone,
            postalCode: postalcode,
            siteName: sitename,
            isHeadQuarter: false,
            _id: city._id
        };
        this.setState({ branches: [...this.state.branches, data] });
    };

    removeBranch = (idx) => {
        const current = [...this.state.branches];
        current.splice(idx, 1);
        this.setState({ branches: current });
    };

    addLink = link => {
        this.setState({ fromTheNewsList: [...this.state.fromTheNewsList, link] });
    };

    removeLink = (idx) => {
        const current = [...this.state.fromTheNewsList];
        current.splice(idx, 1);
        this.setState({ fromTheNewsList: current });
    };

    normalaizeCompanyNames(arr) {
        return arr.map(val => {
            return ({
                elId: val.emsId,
                value: val.name
            });
        });
    }

    async componentDidMount() {
        document.title = 'Edit Company Info - Ethosia';
        const { id } = !this.props.isNewCompany ? this.props.match.params : '';

        if (!utilsService.isRecruiter(userStore.user)) {
            this.props.history.push('/pipelines');
            return;
        }

        const promises = this.bringMetaDataCategories();

        if (id) {
            promises.push(companyStore.getCompanyBySlug(id));
        }

        await Promise.all(promises);
        let company = this.getCurrentCompany();
        let parent = null;
        if(company.parent && Object.keys(company.parent).length > 0) {
            parent =  await companyStore.getCompanyBySlug(company.parent.parentSlug)
        }

        this.setState({
            companyNamesLoader: false,
            parent: parent ? utilsService.normalizeCompanyObject(parent._id): 0
        });

        const {
            specialties,
            companyType,
            businessModel,
            companyBusinessStage,
            companyMarket,
            industries,
            locations,
            companySizes,
            industryDistrict,
            countryCodes
        } = metaDataStore.categories;

        //this._normalLocations = utilsService.normalizeMetaLocations(locations);
        const locationsFromDB = await metaDataStore.getLocations();
        this._normalLocations = utilsService.normalizeMetaLocationsFromDB(locationsFromDB); 

        this._specialties = Object.values(specialties);
        this._companyType = Object.values(companyType);
        this._businessModel = Object.values(businessModel);
        this._companyBusinessStage = Object.values(companyBusinessStage);
        this._companyMarket = Object.values(companyMarket);
        this._industries = Object.values(industries);
        this._companySizes = Object.values(companySizes).sort((a, b) => (a.order > b.order) ? 1 : -1);
        this._industryDistrict = utilsService.normalizeIndustryDistrictArray(industryDistrict);
        this._countryCodes = Object.values(countryCodes)

        if(this.props.isNewCompany) {
            this.setState({loader: false});
            return;
        }

        const selectedLocation = utilsService.getCompanyLocation(locations, company);

        let getType = 0;
        if (company.companyMarket && Array.isArray(company.companyMarket.slice())) {
            getType = this.getElIdOrZero(company.companyMarket[0]);
        }

        let linkedIn, twitter, facebook = '';
        company.socialLinks && company.socialLinks.forEach(link => {
            switch (link.linkType) {
                case 47:
                    linkedIn = link.link;
                    break;
                case 51:
                    twitter = link.link;
                    break;
                case 52:
                    facebook = link.link;
                    break;
                default:
                    break;
            }
        });

        const branches = company.branches && company.branches.map(br => {
            return {
                branchName: br.branchName,
                cityId: br.city ? br.city.elId : 0,
                city: br.city ? br.city.city : '',
                industryDistrictId: br.industryDistrictId,
                industryDistrict: br.industryDistrict,
                address: br.address,
                building: br.building
            }
        })

        this.setState({
            loader: false,
            listLocations: [],
            selectedCity: selectedLocation.city,
            selectedStreet: utilsService.removeWrongCharsFromString(selectedLocation.street),
            selectedBuilding: selectedLocation.building && selectedLocation.building !== "null" ? selectedLocation.building : '',
            selectedDistrictArea: utilsService.normalizeIndustryDistrict(selectedLocation),
            companyName: company.name,
            founded: company.founded ? company.founded : 0,
            organizationSize: this.getElIdOrZero(company.size),
            selectedSectors: company.sectors ? company.sectors : [],
            orgStatus: this.getElIdOrZero(company.companyStatus),
            nameOfBuyer: company.buyer ? company.buyer.name : '',
            selectedBusinessModel: company.businessModel ? company.businessModel : [],
            selectedLifeCycleStage: this.getElIdOrZero(company.companyType),
            selectedType: getType,
            selectedBusinessStage: this.getElIdOrZero(company.companyBusinessStage),
            companyTicker: company.companyTicker ? company.companyTicker : '',
            aboutUs: company.profile ? company.profile : '',
            selectedSpecialties: company.specialties ? company.specialties : [],
            selectedIndustries: company.industries ? company.industries : [],
            branches: branches,
            videoLink: company.videoPromoLink ? company.videoPromoLink : '',
            companyNameHebrew: company.nameHeb ? company.nameHeb : '',
            parentRelationshipType: company.parent ? company.parent.relationShip : 0,
            selectedBenefits: company.benefits && company.benefits.length > 0
                ? company.benefits.map(d => data.benefits.find(a => a.elId === d)).filter(benefit => Boolean(benefit))
                : [],
            contactEmail: company.email ? company.email : '',
            contactPhone: company.phones?.[0]
            ? {
                ...company.phones?.[0],
                country: company.phones?.[0].country || 'il',
                callingCode: company.phones?.[0].callingCode || '972',
                phone: company.phones?.[0].phone.startsWith('0') 
                    ? company.phones?.[0].phone 
                    : "0" + company.phones?.[0].phone?.slice(company.phones?.[0].callingCode?.replace(/\D/g, "").length),
              }
            : {},
            website: company.website ? company.website : '',
            linkedIn: linkedIn,
            twitter: twitter,
            facebook: facebook,
            fromTheNewsList: company.links ? company.links.map(a => a.link) : []
        });
    }

    getCurrentCompany = () => {
        const { id } = this.props.match ? this.props.match.params : '';
        let company = null;
        if (id) {
            company = companyStore.companies[id];
        } else {
            company = userStore.userCompany;
        }
        return company;
    }

    checkBenefits = lang => {
        let currentState = [...this.state.selectedBenefits];

        if (currentState.length > 0 && currentState.some(q => q.elId === lang.elId)) {
            currentState = currentState.filter(b => b.elId !== lang.elId);
        } else {
            currentState.push(lang);
        }

        this.setState({
            selectedBenefits: currentState
        });
    };

    submitForm = async () => {
        const company = this.getCurrentCompany();
        const {
            companyName,
            founded,
            selectedCity,
            selectedStreet,
            selectedSiteName,
            selectedPostalcode,
            organizationSize,
            selectedSectors,
            orgStatus,
            nameOfBuyer,
            selectedBusinessModel,
            selectedLifeCycleStage,
            selectedType,
            selectedBusinessStage,
            companyTicker,
            aboutUs,
            selectedSpecialties,
            selectedIndustries,
            branches,
            videoLink,
            companyNameHebrew,
            parent,
            parentRelationshipType,
            selectedBenefits,
            contactEmail,
            contactPhone,
            website,

            fromTheNewsList,

            logoPreviewUrl,
            logoValid,

            companyNameValid
        } = this.state;
        const companyHebrewValid = utilsService.isHebrewCharsValid(companyNameHebrew)
        if (companyNameValid && companyHebrewValid && logoValid) {
            this.setState({
                showError: false, formLoader: true
            });

            const buyer = {
                name: nameOfBuyer ? nameOfBuyer : null
            };

            let branchesToSend = [];
            if (branches && branches.length > 0) {
                branchesToSend = branches.map(bra => {
                    return ({
                        branchName: bra.branchName,
                        cityId: bra.cityId ? bra.cityId : 0,
                        industryDistrictId: bra.industryDistrictId,
                        industryDistrict: bra.industryDistrict,
                        address: bra.address,
                        building: bra.building ? bra.building : ''
                    });
                });
            }

            let companyMarketHandle = [0];
            if (selectedType && selectedType[0]) {
                companyMarketHandle = this.getNumberOrZero(selectedType);
            } else if (selectedType) {
                companyMarketHandle = [Number(selectedType)];
            }

            const parentData = {};
            if (parent && parent.elId !== -1) {
                await companyStore.getCompaniesByIds([parent.elId]);
                const parentMoreData = companyStore.getCompanyById(parent.elId);
                if (parentMoreData) {
                    parentData.parentId = parentMoreData._id;
                    parentData.parentSlug = parentMoreData.slug;
                    parentData.parentEmsId = parentMoreData.emsId;
                    parentData.relationShip = Number(parentRelationshipType);
                }
            }

            const getPhones = () => {
                if (!contactPhone || !contactPhone.phone) return [];

                const country = contactPhone.country || 'il';
                const callingCode = contactPhone.callingCode?.replace(/\D/g, "") || '972';
                const phone = contactPhone.phone ? contactPhone.phone.replace(/^0+/, '').replace(/\D/g, '') : '';

                return [{...contactPhone, country, callingCode, phone: callingCode + phone}];
            }
            const specialtiesToSend = selectedSpecialties.map(i => ({elId: i.elId, value: i.value}))

            const locationsToSend = [
                {
                    name: selectedStreet,
                    city: selectedCity.cityAscii,
                    cityId: selectedCity ? selectedCity.elId : -1,
                    country: selectedCity.country,
                    adminName: selectedCity.adminName,
                    adminCode: selectedCity.adminCode,
                    adminType: selectedCity.adminType,
                    latitude: selectedCity.latitude,
                    longitude: selectedCity.longitude,
                    timezone: selectedCity.timezone,
                    postalCode: selectedPostalcode,
                    siteName: selectedSiteName,
                    isHeadQuarter: true,
                    _id: selectedCity._id
                },
                ...branches
            ]

            const dataToSend = {
                name: companyName,
                nameLC: companyName.toLowerCase(),
                nameHeb: companyNameHebrew,
                email: contactEmail,
                phones: getPhones(),
                locations: locationsToSend,
                // location: {
                //     cityId: this.getElIdOrZero(selectedCity),
                //     street: selectedStreet,
                //     building: selectedBuilding,
                //     industryDistrictId: selectedDistrictArea.elId,
                //     industryDistrict: selectedDistrictArea.value,
                // },
                companyStatus: this.getNumberOrZero(orgStatus),
                companyBusinessStage: this.getNumberOrZero(selectedBusinessStage),
                companyMarket: companyMarketHandle,
                businessModel: this.getElIdsOrEmpty(selectedBusinessModel),
                sizeId: this.getNumberOrZero(organizationSize),
                companyType: this.getNumberOrZero(selectedLifeCycleStage),
                founded: this.getNumberOrZero(founded),
                profile: aboutUs,
                industries: this.getElIdsOrEmpty(selectedIndustries),
                specialties: specialtiesToSend,
                companyTicker: companyTicker,
                buyer: buyer,
                videoPromoLink: videoLink,
                // branches: branchesToSend,
                sectors: this.getElIdsOrEmpty(selectedSectors),
                companyId: this.props.isNewCompany ? undefined : company._id,
                file: logoPreviewUrl,
                previousFileName: company && company.profileImageUrl,
                parent: parentData,
                website: website,
                links: fromTheNewsList.map(link => {return ({ link: link });}),
                socialLinks: this.normalazeSocialLinks(this.state),
                benefits: selectedBenefits.map(ben => ben.elId)

            };

            const resp = await companyStore.updateCompany(dataToSend);

            if (resp.isError) {
                const error = {
                    isError: true,
                    message: resp.isError,
                    hidden: false
                };

                this.setState(
                    {
                        backEndMessage: error,
                        formLoader: false
                    }
                );
            } else {
                const isRecruiter = utilsService.isRecruiter();

                let toUrl = '/user/company-details';

                if (isRecruiter) {
                    toUrl = company && `/company/${company.slug}`;
                }

                if (this.props.isNewCompany) {
                    // toUrl = isRecruiter ? `/company/${resp.companySlug}` : '/user/company-details';
                    toUrl = isRecruiter ? `/company/${resp.companySlug}` : '/settings';
                }
                
                notificationsStore.showToast('Company info updated... Redirecting', 'success');
                setTimeout(() => {
                    this.props.history.push(toUrl);
                }, 3000);
            }

        } else {
            if (!logoValid) {
                notificationsStore.showToast('Invalid company logo', 'failure');
            }
            
            if (!companyNameValid || !companyHebrewValid) {
                notificationsStore.showToast('Invalid company name', 'failure');
            }

            this.setState({
                showError: true
            });
        }

    };

    getElIdOrZero(obj) {
        let res = 0;
        if (obj && obj.elId) {
            res = obj.elId;
        }

        return res;
    }

    getNumberOrZero(str) {
        let res = 0;

        if (str) {
            res = Number(str);
        }
        return res;
    }

    getElIdsOrEmpty(arr) {
        let res = [];
        if (arr && arr.length > 0) {
            res = arr.map(item => item.elId);
        }

        return res;
    }

    handleLogoClick = () => {
        this.__logoUpload.click();
    };

    handleLogoChange = e => {
        const reader = new FileReader();
        const file = e.target.files[0];
        const image = new Image();
        try {
            reader.onloadend = () => {
                image.src = reader.result;

                image.onload = () => {
                    this.setState({
                        logoImageFile: file,
                        logoPreviewUrl: reader.result,
                        logoValid: image.width >= 100 && image.width <= 130
                    });
                };
            };

            reader.readAsDataURL(file);
        } catch (e) {
            console.log('something wrong');
        }
    };

    normalazeSocialLinks(state) {
        const temp = [];

        const {
            linkedIn,
            twitter,
            facebook
        } = state;

        if (linkedIn) {
            temp.push({ link: linkedIn, linkType: 47 });
        }

        if (twitter) {
            temp.push({ link: twitter, linkType: 51 });
        }

        if (facebook) {
            temp.push({ link: facebook, linkType: 52 });
        }

        return temp;
    }

    render() { 
        const {
            companyNamesLoader,
            loader,
            companyName,
            founded,
            listLocations,
            selectedCity,
            selectedStreet,
            selectedSiteName,
            selectedPostalcode,
            organizationSize,
            selectedBusinessModel,
            selectedLifeCycleStage,
            selectedType,
            selectedBusinessStage,
            companyTicker,
            aboutUs,
            selectedSpecialties,
            selectedIndustries,
            branches,
            videoLink,
            companyNameHebrew,
            parentRelationshipType,
            parent,
            selectedBenefits,
            contactEmail,
            contactPhone,
            website,
            linkedIn,
            twitter,
            facebook,
            fromTheNewsList,

            companyNameValid,

            showError,
            formLoader,

            logoPreviewUrl,
            logoValid,
            street,
            building
        } = this.state;

        const {
            _selectYears, _companySizes, _normalLocations,
            _specialties,
            _companyType, _businessModel, _companyBusinessStage,
            _companyMarket, _industries, _industryDistrict
        } = this;

        const company = this.getCurrentCompany();

        const { id } = !this.props.isNewCompany ? this.props.match.params : '';

        return (
            <div>
                <NavigationBar/>
                {loader && (
                    <Loader/>
                )}
                <TopEditCompany
                    id={id}
                    save={this.submitForm}
                    formLoader={formLoader}
                    isNewCompany={this.props.isNewCompany}
                />
                <main className="max-width company-edit-wrapper">

                    {!loader && (
                        <div className="main-container form">
                            <div className="main-details shadow4 white-box border-radius">
                                <div className="logo space">
                                    <div>
                                        <strong>Company Logo</strong>
                                        <p>120x120 pix</p>
                                        {!logoValid && (
                                            <span className="error-logo">
                                                Logo must be 120px
                                            </span>
                                        )}
                                    </div>
                                    <div
                                        onClick={this.handleLogoClick}
                                        className="logo-image">
                                        <input
                                            accept="image/*"
                                            ref={input => this.__logoUpload = input}
                                            onChange={e => this.handleLogoChange(e)}
                                            type="file" style={{ display: 'none' }}/>
                                        {logoPreviewUrl && (
                                            <img src={logoPreviewUrl} alt=""
                                                 className={logoValid ? 'logoPreview' : 'logoPreview invalid'}/>
                                        )}
                                        {!logoPreviewUrl && (
                                            <DefaultImageComponent
                                                url={utilsService.getCompanyImage(company)}
                                                alt={'Company'}
                                                company={true}
                                                width={120}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="item grid1x1">
                                    <InputForm
                                        label={'Company Name'}
                                        value={companyName}
                                        type={'text'}
                                        valid={companyNameValid}
                                        inputChange={e => this.inputChange(e, 'companyName', 'companyNameValid')}
                                    />

                                    <InputForm
                                        label={'Company Name Hebrew'}
                                        value={companyNameHebrew}
                                        type={'text'}
                                        valid={utilsService.isHebrewCharsValid(companyNameHebrew)}
                                        inputChange={e => this.inputChange(e, 'companyNameHebrew')}
                                    />
                                </div>

                                <div className="item grid1x3">
                                    <SelectForm
                                        label={'Founded'}
                                        name={'founded'}
                                        value={founded}
                                        defaultOption={'Select'}
                                        selectList={_selectYears}
                                        inputChange={e => this.inputChange(e, 'founded')}
                                    />

                                    <SelectForm
                                        label={'Organization Size'}
                                        name={'organizationSize'}
                                        value={organizationSize}
                                        defaultOption={'Select'}
                                        selectList={_companySizes}
                                        inputChange={e => this.inputChange(e, 'organizationSize')}
                                    />

                                    <div className="form-item">
                                        <label>Headquarters</label>
                                        <SuggestComponent
                                            valid={selectedCity && selectedCity.elId && selectedCity.elId != -1 && selectedCity != {}}
                                            validation={true}
                                            items={listLocations}
                                            selectedItem={selectedCity}
                                            selectItem={item => this.selectSuggestItem(item, 'selectedCity')}
                                            query={this.getWorldCities}
                                            numberOfCharsToPredicate={2}
                                            showListCompanyLocations={true}
                                        />
                                    </div>

                                    {/* <InputForm
                                        label={'Company ID'}
                                        value={companyId}
                                        type={'number'}
                                        inputChange={e => this.inputChange(e, 'companyId')}
                                        contenteditable={false}
                                    /> */}

                                </div>

                                <div className="item grid1x3 margin-bottom">
                                    <div className="form-item1">
                                        <InputForm
                                            label={'Site name'}
                                            value={selectedSiteName}
                                            type={'text'}
                                            inputChange={e => this.setState({ selectedSiteName: e.target.value })}
                                        />
                                    </div>

                                    <div className="form-item1">
                                        <InputForm
                                            label={'Street'}
                                            value={selectedStreet}
                                            type={'text'}
                                            inputChange={e => this.setState({ selectedStreet: e.target.value })}
                                        />
                                    </div>

                                    <div className="form-item1">
                                        <InputForm
                                            label={'Postal code'}
                                            value={selectedPostalcode}
                                            type={'number'}
                                            inputChange={e => this.setState({ selectedPostalcode: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="item margin-bottom">
                                    <label>Industries</label>
                                    <MultiSelectComponent
                                        items={_industries}
                                        selectedItems={selectedIndustries}
                                        removeItem={item => this.removeMultiItem(item, 'selectedIndustries')}
                                        selectItem={item => this.selectMultiItem(item, 'selectedIndustries')}
                                    />
                                </div>


                                <div className="item grid1x1">
                                    <div className="form-item">
                                        <label>Parent Organization</label>
                                        <SuggestWithNetworkComponent
                                            defaultValue={'---None---'}
                                            isLoading={companyNamesLoader}
                                            selectedItem={parent}
                                            removeItem={item => this.selectSuggestItem(item, 'parent')}
                                            selectItem={item => this.selectSuggestItem(item, 'parent')}
                                            networkRequest={companyStore.searchCompaniesByPartialNameByText}
                                        />
                                    </div>

                                    <SelectForm
                                        label={'Relationship Type'}
                                        name={'rel_type'}
                                        value={parentRelationshipType}
                                        defaultOption={'Select'}
                                        selectList={[
                                            {
                                                elId: 1,
                                                value: 'Acquisition'
                                            }, {
                                                elId: 2,
                                                value: 'Subsidiary'
                                            }
                                        ]}
                                        inputChange={e => this.inputChange(e, 'parentRelationshipType')}
                                    />


                                </div>

                                <hr/>

                                <div className="item margin-bottom">
                                    <label>Business Model</label>
                                    <MultiSelectComponent
                                        items={_businessModel}
                                        selectedItems={selectedBusinessModel}
                                        removeItem={item => this.removeMultiItem(item, 'selectedBusinessModel')}
                                        selectItem={item => this.selectMultiItem(item, 'selectedBusinessModel')}
                                    />
                                </div>


                                <div className="item grid1x1">
                                    <SelectForm
                                        label={'Lifecycle Stage'}
                                        name={'selectedLifeCycleStage'}
                                        value={selectedLifeCycleStage}
                                        defaultOption={'Select'}
                                        selectList={_companyType}
                                        inputChange={e => this.inputChange(e, 'selectedLifeCycleStage')}
                                    />

                                </div>

                                <div className="item grid1x1">
                                    <SelectForm
                                        label={'Market Type'}
                                        name={'selectedType'}
                                        value={selectedType}
                                        defaultOption={'Select'}
                                        selectList={_companyMarket}
                                        inputChange={e => this.inputChange(e, 'selectedType')}
                                    />
                                </div>


                                <div className="item grid1x1">
                                    <SelectForm
                                        label={'Business Stage'}
                                        name={'selectedBusinessStage'}
                                        value={selectedBusinessStage}
                                        defaultOption={'Select'}
                                        selectList={_companyBusinessStage}
                                        inputChange={e => this.inputChange(e, 'selectedBusinessStage')}
                                    />

                                    {Number(selectedBusinessStage) === 10 && (
                                        <InputForm
                                            label={'Company Ticker'}
                                            value={companyTicker}
                                            type={'text'}
                                            disabled={Number(selectedBusinessStage) !== 10}
                                            inputChange={e => this.inputChange(e, 'companyTicker')}
                                        />
                                    )}

                                </div>

                                <hr/>

                                <div className="item">
                                    <TextAreaForm
                                        label={'About Us'}
                                        value={aboutUs}
                                        wrapperClasses='about-us'
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'aboutUs')}
                                    />
                                </div>

                                <div className="item space2">
                                    <label>Specialties</label>

                                    <Autocomplete
                                        multiple
                                        value={selectedSpecialties}
                                        onChange={(e, value) => this.setState({ selectedSpecialties: value })}
                                        options={_specialties}
                                        getOptionLabel={option => option.value}
                                        isOptionEqualToValue={(option, value) => option.elId === value.elId}
                                        limitTags={10}
                                        limitItems={1000}
                                    />
                                </div>

                                <div className="item benefits">
                                    <label>Benefits</label>
                                    <div className="benefit-items">
                                        {
                                            data.benefits.map(ben => {
                                                return (
                                                    <label
                                                        key={ben.elId}
                                                        className='customCheckbox-label'>
                                                        <CustomCheckboxComponent
                                                            checkAction={() => this.checkBenefits(ben)}
                                                            checked={selectedBenefits.some(s => s.elId === ben.elId)}
                                                        />
                                                        {ben.value}
                                                    </label>
                                                );
                                            })
                                        }
                                    </div>
                                </div>

                                <hr/>


                                <h3 className='contact-info'>Contact Info</h3>
                                <div className="item grid1x1">
                                    <InputForm
                                        label={'Email'}
                                        value={contactEmail}
                                        type={'email'}
                                        inputChange={e => this.inputChange(e, 'contactEmail')}
                                    />

                                    <div>
                                        <label>Phone</label>
                                        <PhoneInput
                                            className="phone-input-field"
                                            phoneNumber={this.getPhone()}
                                            label=''
                                            onPhoneNumberChange={(e) => this.inputChange(e, 'contactPhone')}
                                            countryCode={this.getCountryCode()}
                                            onCountryCodeChange={(e) => this.countryCodeChange(e)}
                                        />
                                    </div>
                                </div>

                                <div className="item grid1x1">
                                    <InputForm
                                        label={'Website'}
                                        value={website}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'website')}
                                    />

                                    <InputForm
                                        label={'Linkedin'}
                                        value={linkedIn}
                                        icon={<LinkedInIcon2/>}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'linkedIn')}
                                    />
                                </div>

                                <div className="item grid1x1">
                                    <InputForm
                                        label={'Twitter'}
                                        icon={<TwitterIcon2/>}
                                        value={twitter}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'twitter')}
                                    />

                                    <InputForm
                                        label={'Facebook'}
                                        icon={<FacebookIcon2/>}
                                        value={facebook}
                                        type={'text'}
                                        inputChange={e => this.inputChange(e, 'facebook')}
                                    />
                                </div>


                                <div className="item more-sites">
                                    <h3>More Sites</h3>
                                    <LocationsComponent
                                        list={branches}
                                        locations={listLocations}
                                        getWorldCities={this.getWorldCities}
                                        addBranch={this.addBranch}
                                        removeBranch={this.removeBranch}
                                    />

                                </div>

                                <div className="item">
                                    <h3>From The News</h3>
                                    <FromTheNews
                                        list={fromTheNewsList}
                                        addLink={this.addLink}
                                        removeLink={this.removeLink}
                                    />
                                </div>

                                <div className="item promo-video">
                                    <InputForm
                                        label={'Promotional Video URL'}
                                        value={videoLink}
                                        type={'url'}
                                        inputChange={e => this.inputChange(e, 'videoLink')}
                                    />
                                </div>

                            </div>
                        </div>
                    )}

                </main>
            </div>
        );
    }
}

export default withRouter(EditCompanyComponent);
