import React, { PureComponent } from 'react';

import { CloseIcon, SendLightIcon } from '../svg';
import userStore from '../../../stores/user.store';
import messagingStore from '../../../stores/messaging.store';
import notificationsStore from '../../../stores/notifications.store';
import EmailMessaging from './components/EmailsMessaging.component';
import SmsMessaging from './components/SmsMessaging.component';
import searchStore from '../../../stores/search.store';
import { observer } from 'mobx-react';
import stringTokenReplacerService from '../../../services/stringTokenReplacer.service';
import ConfirmationModalComponent from '../modalComponents/ConfirmationModal.component';
import utilsService from '../../../services/utils.service';
import DropDownButtonComponent from '../dropDownButton/DropDownButton.component';
import './inboxMessaging.scss';
import EmailMessageModal from '../modalComponents/EmailMessageModal';
import JobSuggestionAlertModal from '../modalComponents/JobSuggestionAlertModal';
import JobDiscreteAlertModal from '../modalComponents/JobDiscreteAlertModal.component';
import profileStore from '../../../stores/profile.store';

@observer
class SendMessagesComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedEmailTemplate: 0,
            cc: '',
            bcc: '',
            ccShow: false,
            bccShow: false,
            emailBody: '',
            canSend: false,
            loader: false,
            smsBody: '',
            whatsappBody: '',
            tabIndex: 0,
            subject: '',
            errorsMessages: [],
            attachmentData: [],
            hasAttachment: false,
            isSendingMultiEmail: true,
            showWarningSinglEmailModal: false,
            showEmailMessageModal: false,
            showJobSuggestionAlertModal: false,
            showDiscretePositionModal: false,
            isUserApproveSend: false,
            listProfiles: []
        };

        this._isSendingMultiEmail = true;
    }

    onEditorStateChange = emailBody => {
        this.setState({
            emailBody: emailBody
        });
    };

    onSignatureEditorStateChange = signatureBody => {
        this.setState({
            signatureBody: signatureBody
        });
    };

    inputChange = (e, type) => {
        this.setState({
            [type]: e.target.value,
            canSend: this.isCanSend()
        });
    };

    ccButtonsShowHide = type => {
        this.setState({
            [type]: !this.state[type]
        });
    };

    isCanSend = () => {
        const { subject } = this.props;
        const { tabIndex, smsBody, whatsappBody } = this.state;
        let res = true;
        if (tabIndex) {
            if (!smsBody || !whatsappBody) {
                res = false;
            }
        } else {
            if (!subject) {
                res = false;
            }
        }

        return res;
    };

    async componentDidMount() {
        const {subject, body, selectedEmailTemplate, items} = this.props;

        await messagingStore.getMessagingTemplates({ field: 'userId', value: userStore.user._id });
        if (selectedEmailTemplate) {
            this.chooseTemplateEvent(selectedEmailTemplate)
        }

        if(items) {
            this.setState({listProfiles: items})
        }
        if(body) {
            this.setState({emailBody: body});
        }
        if(subject) {
            this.setState({subject: subject});
        }

    }

    componentDidUpdate(propsProps) {
        const {subject, body} = this.props;

        if (propsProps.body !== body) {
            this.setState({ emailBody: this.props.body })
        }
        if (propsProps.subject !== subject) {
            this.setState({ subject: this.props.subject })
        }
    }

    getPersons = () => {
        const { items, searchTo, type} = this.props;
        const searchToPersons = searchStore.profilesUsersSearchPersonsTo;
        let persons = [];

        // if profile and not company
        if(type !== 'company') {
            persons = this.state.listProfiles;
            if (searchTo) {
                persons = searchToPersons;
            }
        }
        return persons;

    }

    sendEvent = async () => {
        this.setState({
            loader: true
        });
        const {
            selectedEmailTemplate, cc, bcc, signatureBody,
            emailBody, smsBody, whatsappBody, attachmentData, hasAttachment, showWarningSinglEmailModal, isUserApproveSend, listProfiles
        } = this.state;

        const {
            subject, items, closeEvent, searchTo, tagSelectedId, replyTo, importance, hideSubject,
            conType, labelSelectedId, isDiscretePosition, type, jobUrls
        } = this.props;

        let persons = this.getPersons();


        //if hebrew template, should check first name and last name contain in hebrew name
        const isHebrewNameEmpty = persons.find(person => 
            (!person.firstNameHeb || person.firstNameHeb.length === 0) ||
            (!person.lastNameHeb || person.lastNameHeb.length === 0))
        const isBuildProfileTemplate = conType === 3 && selectedEmailTemplate?.name === 'טופס בניית פרופיל' ||
             selectedEmailTemplate.name === 'טופס בניית פרופיל ללא שם'
        if(selectedEmailTemplate.language === 2 && isHebrewNameEmpty && !isBuildProfileTemplate) {
            this.setState({showEmailMessageModal: true})
        }

        // if job suggestion template, should chekc if includes profiles/jobs have been sent job suggestion
        const isAlreadySuggested =  window.location.href.includes('/jobs') ? 
            listProfiles?.filter(person => person.suggestedJobs).length > 0 : 
            jobUrls?.filter(job => listProfiles[0].suggestedJobs?.includes(job)).length > 0
        const isJobSuggestionTemplate = conType === 3 && whatsappBody.includes('#jobsuggestionurl#')
        if(isAlreadySuggested && isJobSuggestionTemplate) {
            this.setState({showJobSuggestionAlertModal: true})
        }

        //if job discrete alert popup will be opened unless user approved it from popup
        else if(isDiscretePosition && !isUserApproveSend) {
            this.setState({showDiscretePositionModal: true})
        }
        else {
            const isMultiRecipientMode = utilsService.isMultiRecipientsMode() && this._isSendingMultiEmail;
            if (isMultiRecipientMode) {
                if(selectedEmailTemplate && this._isSendingMultiEmail && persons && persons.length > 1 && !showWarningSinglEmailModal) {
                    this.setState({showWarningSinglEmailModal: true, loader: false});
                    return;
                }
            }
    
            const subj = hideSubject ? subject : this.state.subject;
    
            let user = userStore.user;
    
            let response = null;
    
            // sms
            if (conType === 2) {
                const message = smsBody;
    
                const dataToSend = persons.map(item => {
                    const isUser = item.userType;
                    return {
                        messageBody: stringTokenReplacerService.messageStringTokensReplacer(message, item, user, selectedEmailTemplate),
                        targetParticipantProfileId: !isUser ? item._id : null,
                        targetParticipantProfileSlug: !isUser ? item.slug : null,
                        targetParticipantUserId: isUser ? item._id : null,
                        targetParticipantUserSlug: isUser ? item.slug : null,
                        senderUserId: user._id,
                        senderUserSlug: user.slug,
                        senderUserEmail: user ? user.email : null,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        notificationType: 'sms',
                        conversationType: 2,
                        targetParticipantType: isUser ? isUser : 1,
                        receiverType: isUser ? isUser : 1,
                        senderType: user.userType,
                        label: isUser ? 1 : 2,
                        conversationId: replyTo ? replyTo._id : null
                    };
                });
    
                response = await messagingStore.sendMessage(dataToSend, true);
            
            } 
            // whatsapp
            else if (conType === 3) {
                const message = whatsappBody;
    
                const dataToSend = persons.map(item => {
                    const isUser = item.userType;
                    const claimToken = item.talent?.claimToken
                    return {
                        messageBody: stringTokenReplacerService.messageStringTokensReplacer(message, item, user, selectedEmailTemplate, claimToken, jobUrls),
                        targetParticipantProfileId: !isUser ? item._id : null,
                        targetParticipantProfileSlug: !isUser ? item.slug : null,
                        targetParticipantUserId: isUser ? item._id : null,
                        targetParticipantUserSlug: isUser ? item.slug : null,
                        senderUserId: user._id,
                        senderUserSlug: user.slug,
                        senderUserEmail: user ? user.email : null,
                        firstName: item.firstName,
                        lastName: item.lastName,
                        // action: 'to_talent_send_whatsapp',
                        notificationType: 'whatsapp',
                        // type: 'recruiterWhatsapp',
                        conversationType: 3,
                        targetParticipantType: isUser ? isUser : 1,
                        receiverType: isUser ? isUser : 1,
                        senderType: user.userType,
                        label: isUser ? 1 : 2,
                        conversationId: replyTo ? replyTo._id : null,
                        contentType: this.getContentType(conType, selectedEmailTemplate),
                        name: selectedEmailTemplate.name,
                        groupType: selectedEmailTemplate.groupType,
                    };
                });
                
                response = await messagingStore.sendMessage(dataToSend, true);
                const promises = []
                response.forEach(res => {
                    if (!res.isError && isJobSuggestionTemplate && res.targetParticipantProfileId) {
                        const oldProfile = profileStore.profiles[res.targetParticipantProfileSlug] || profileStore.profilesJobsMatches[res.targetParticipantProfileSlug]
                        promises.push(profileStore.updateProfile(res.targetParticipantProfileId, {
                            suggestedJobs: oldProfile.suggestedJobs ? [...new Set(oldProfile.suggestedJobs.concat(jobUrls))] : [...new Set(jobUrls)]
                        }));
                    }
                })
                await Promise.all(promises)
            } 
            else {
                let message = emailBody;
                if(signatureBody) {
                    message += signatureBody;
                }
    
                if (isMultiRecipientMode) {
                    let company;
                    if (items && items.length === 1 && type === 'company') {
                        company = items[0];
                    }
                    let isMissingPrimaryEmail = false;
                    let toEmails = [];
                    if(company) {

                        // reply message
                        if(replyTo && replyTo.email) {
                            toEmails = [replyTo.email];
                        }

                        // new message
                        else if(company.email) {
                            toEmails = [company.email];
                        }
                    }
                    else {
                        toEmails = persons.map(item => {

                            //exists user
                            if(item._id) {
                                if(item.userType) {
                                    // user
                                    return item.email; 
                                }
                                else {
                                    // profile.
                                    const primaryEmail = item.emails && item.emails.find(email => email.isPrimary);
                                    if(primaryEmail) {
                                        return primaryEmail.email;
                                    }
                                    else {
                                        isMissingPrimaryEmail = true;
                                        return null;
                                    }
                                }
                            }
                            else {
                                return item.value
                            }
                        });
                    }

                    if(isMissingPrimaryEmail) {
                        notificationsStore.showToast(`Primary email is missing`, 'failure');
                        this.setState({
                            loader: false
                        });
                        return;
                    }
    
                    const dataToSend = {};
    
                    if(persons && persons.length === 1) {
                        const person = persons[0];  
                        
                        //exists user 
                        if(person._id) {
                            const isUser = person.userType;
                            if(isUser) {
                                dataToSend.targetParticipantUserId = person._id;
                                dataToSend.targetParticipantUserSlug = person.slug;
                            }
                            else {
                                dataToSend.targetParticipantProfileId = person._id;
                                dataToSend.targetParticipantProfileSlug = person.slug;
                            }
                        }
                    }
                    else {

                        //new message
                        if(company && !replyTo) {
                            dataToSend.targetCompanyId = company._id;
                            dataToSend.targetCompanySlug = company.slug;
                        }
                    }
  
                    dataToSend.senderUserId = user._id;
                    dataToSend.senderUserSlug = user.slug;
                    dataToSend.senderUserEmail = user ? user.email : null;
                    dataToSend.subject = subj;
                    dataToSend.to = toEmails;
                    dataToSend.cc = cc;
                    dataToSend.bcc = bcc;
                    dataToSend.importance = importance;
                    dataToSend.tag = 30;
                    dataToSend.label = labelSelectedId;
                    dataToSend.action = 'to_talent_send_message';
                    dataToSend.notificationType = 'mail';
                    dataToSend.type = 'recruiterMail';
                    dataToSend.conversationType = 1;
                    dataToSend.senderType = user.userType;
                    dataToSend.attachmentData = hasAttachment ? attachmentData : [];
                    dataToSend.conversationId = replyTo ? replyTo._id : null;
    
                    let item = null;
                    if(persons && persons.length === 1) {
                        item = persons[0];
                    }
    
                    dataToSend.messageBody = stringTokenReplacerService.messageStringTokensReplacer(message, item, user, selectedEmailTemplate);
                    dataToSend.messageRawBody = stringTokenReplacerService.messageStringTokensReplacer(message, item, user, selectedEmailTemplate);
                    if(signatureBody !== user.signatureBody) {
                        dataToSend.signatureBody = signatureBody;
                    }
                    dataToSend.multiRecipientsMode = true;
                    response = await messagingStore.sendMessage([dataToSend], true);
                }
                else { // production.
                    const dataToSend = persons.map(item => {
                        const isUser = item.userType;
                        return {
                            messageBody: stringTokenReplacerService.messageStringTokensReplacer(message, item, user, selectedEmailTemplate),
                            messageRawBody: stringTokenReplacerService.messageStringTokensReplacer(message, item, user, selectedEmailTemplate),
                            targetParticipantProfileId: !isUser ? item._id : null,
                            targetParticipantProfileSlug: !isUser ? item.slug : null,
                            targetParticipantUserId: isUser ? item._id : null,
                            targetParticipantUserSlug: isUser ? item.slug : null,
                            senderUserId: user._id,
                            senderUserSlug: user.slug,
                            senderUserEmail: user ? user.email : null,
                            subject: subj,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            cc: cc,
                            bcc: bcc,
                            importance: importance,
                            tag: 30,
                            label: labelSelectedId,
                            action: 'to_talent_send_message',
                            notificationType: 'mail',
                            type: 'recruiterMail',
                            conversationType: 1,
                            targetParticipantType: isUser ? isUser : 1,
                            senderType: user.userType,
                            attachmentData: hasAttachment ? attachmentData : [],
                            conversationId: replyTo ? replyTo._id : null
                        };
                    });
    
                    response = await messagingStore.sendMessage(dataToSend, true);
                }
            }
    
            let isError = false;
            let errorMessage = '';
            if (response && Array.isArray(response)) {
                response.forEach(resp => {
                    if (resp.isError) {
                        isError = true;
                        errorMessage = resp.errorMessage;
                    }
                });
            }
    
            if (isError) {
                notificationsStore.showToast(errorMessage, 'failure');
                if (!searchTo) {
                    closeEvent();
                } else {
                    this.setState({
                        loader: false
                    });
                }
            } else {
                notificationsStore.showToast(`Message sent`, 'success');
                if (!searchTo) {
                    closeEvent();
                } else {
                    this.setState({
                        loader: false,
                        selectedEmailTemplate: 0,
                        emailBody: '',
                        subject: '',
                        canSend: false,
                        smsBody: '',
                        whatsappBody: '',
                        cc: '',
                        bcc: '',
                        tabIndex: 0,
                        errorsMessages: [],
                        attachmentData: [],
                        hasAttachment: false
                    });
                    searchStore.profilesUsersSearchPersonsTo = [];
                }
            }
        }

       
    };

    rightClassesForSendButton = () => {
        const searchToPersons = searchStore.profilesUsersSearchPersonsTo;
        const {
            loader, smsBody, whatsappBody
        } = this.state;

        const { items, searchTo, subject, hideSubject, conType } = this.props;


        let classes = 'button';
        let isDisabled = false;

        if (loader) {
            classes += 'loading';
        }

        if (conType === 1) {
            const subj = hideSubject ? subject : this.state.subject;

            if (!subj) {
                isDisabled = true;
            }
        }

        if (conType === 2 || conType === 3) {
            if (!smsBody || !whatsappBody) {
                isDisabled = true;
            }
        }

        if (!searchTo && items.length === 0) {
            isDisabled = true;
        }

        if (searchTo && searchToPersons.length === 0) {
            isDisabled = true;
        }


        if (isDisabled) {
            classes += ' new-gray disabled-opacity-less';
        } else {
            classes += ' darkblue-new';
        }

        return classes;
    };

    sendButtonDisabled = () => {
        let res = false;
        const searchToPersons = searchStore.profilesUsersSearchPersonsTo;
        const { items, searchTo, subject, hideSubject, conType } = this.props;
        const { smsBody, selectedEmailTemplate } = this.state

        if (conType === 1) {
            const subj = hideSubject ? subject : this.state.subject;

            if (!subj) {
                res = true;
            }


        }

        if (conType === 3 && (!selectedEmailTemplate || !utilsService.hasPhones(searchToPersons))) {
            res = true;
        }

        if (!searchTo && items.length === 0) {
            res = true;
        }

        if (searchTo && searchToPersons.length === 0) {
            res = true;
        }

        return res;
    };

    tabClicked(index) {
        this.setState({
            tabIndex: index
        });
    }

    fileHandle = fileData => {
        this.setState({
            attachmentData: [...this.state.attachmentData, fileData],
            hasAttachment: true
        });
    };

    removeAttachment = (idx) => {
        let attachmentData = this.state.attachmentData;
        attachmentData = attachmentData.filter((o, i) => i !== idx);
        this.setState({
            attachmentData: attachmentData,
            hasAttachment: attachmentData.length > 0
        });
    };

    preDefinedTextHandleEmail = text => {
        this.setState({
            emailBody: text
        });
    };

    preDefinedTextHandleSms = text => {
        this.setState({
            smsBody: text
        });
    };

    chooseTemplateEvent = template => {
        const { conType, subjectAction, hideSubject } = this.props;

        const update = {
            selectedEmailTemplate: template
        };

        if (template.subject) {
            update.subject = template.subject;
            if(hideSubject){
                subjectAction(null, template.subject);   
            }
        }

        if (conType === 1) {
            update.emailBody = template.body;
        }
        else if (conType === 2) {
            update.smsBody = template.body;
        }
        else if (conType === 3) {
            update.whatsappBody = template.body;
        }

        
        this.setState(update);
    };

    handleEmailSendingTypeChange = () => {
        const {isSendingSingleEmail} = this.state;
        this.setState({isSendingSingleEmail: !isSendingSingleEmail});
    }

    handleConfirmSendingTemplateEmailAsSingle = () => {
        this.setState({showWarningSinglEmailModal: false});
        this.sendEvent();
    }

    handleMultiEmail = (isMultiEmail) => {
        this.setState({isSendingMultiEmail: isMultiEmail});
    }

    handleClickMainButton = () => {
        this._isSendingMultiEmail = true;

        this.sendEvent();
    }

    handleClickOptionButton = (optionText) => {
        this._isSendingMultiEmail = false;

        this.sendEvent();
    }

    getContentType = (conType, selectedEmailTemplate) => {
        if ( conType === 3 && selectedEmailTemplate?.name === 'מענה אוטומטי חדש לקבלת קורות חיים ללא שם' ||
            selectedEmailTemplate.name === 'מענה אוטומטי חדש לבניית פרופיל עם שם') {
            return 1;
        }
        return null;
    }

    removeSelection = (item) => {
        const tempProfileList = this.state.listProfiles.filter(i => i.slug != item)
        this.setState({listProfiles: tempProfileList});
    }

    render() {
        const {
            cc, bcc,
            ccShow, bccShow, emailBody,
            smsBody, whatsappBody, attachmentData,
            showWarningSinglEmailModal, 
            showEmailMessageModal, showDiscretePositionModal, showJobSuggestionAlertModal,
            listProfiles
        } = this.state;

        const { sendButtonWithText, items, closeEvent, isModal, searchTo, conType, subject, hideSubject, replyTo, hideTemplate, type } = this.props;
        const buttonClasses = this.rightClassesForSendButton();

        const isMultiRecipientMode = utilsService.isMultiRecipientsMode();

        return (
            <div className={isModal ? 'inbox-messaging-component is-modal' : 'inbox-messaging-component'}>
                {isModal && (
                    <div onClick={closeEvent}
                         className='circle-close modal-close'>
                        <CloseIcon/>
                    </div>
                )}
                <section className="dal-body">
                    {conType === 1 && (
                        <EmailMessaging
                            chooseTemplateEvent={this.chooseTemplateEvent}
                            cc={cc}
                            bcc={bcc}
                            ccShow={ccShow}
                            bccShow={bccShow}
                            inputChange={this.inputChange}
                            replyTo={replyTo}
                            preDefinedTextHandle={this.preDefinedTextHandleEmail}
                            ccButtonsShowHide={this.ccButtonsShowHide}
                            subject={hideSubject ? subject : this.state.subject}
                            hideSubject={hideSubject}
                            hideTemplate={hideTemplate}
                            emailBody={emailBody}
                            onEditorStateChange={this.onEditorStateChange}
                            onSignatureEditorStateChange={this.onSignatureEditorStateChange}
                            items={items}
                            searchTo={searchTo}
                            fileHandle={this.fileHandle}
                            removeSelection={this.props.removeSelection}
                            type={type}
                        />
                    )}


                    {conType === 2 && <SmsMessaging
                            chooseTemplateEvent={this.chooseTemplateEvent}
                            smsBody={smsBody}
                            inputChange={this.inputChange}
                            profiles={items}
                            replyTo={replyTo}
                            searchTo={searchTo}
                            preDefinedTextHandle={this.preDefinedTextHandleSms}
                            removeSelection={this.removeSelection}
                            conType={conType}
                    />}
                    {conType === 3 && <SmsMessaging
                        chooseTemplateEvent={this.chooseTemplateEvent}
                        smsBody={whatsappBody}
                        inputChange={this.inputChange}
                        profiles={listProfiles}
                        replyTo={replyTo}
                        searchTo={searchTo}
                        preDefinedTextHandle={this.preDefinedTextHandleSms}
                        removeSelection={this.removeSelection}
                        conType={conType}
                        isDisabled={true}
                    />}
                
                </section>
                <footer>
                    <div className="send-messages-attachment-data">
                        {attachmentData.map((att, idx) => {
                            return (
                                <div
                                    key={att.attachmentName + idx}
                                    className="send-messages-attachment-data-item">
                                    {att.attachmentName}
                                    <span
                                        onClick={() => this.removeAttachment(idx)}
                                    >X</span>
                                </div>
                            );
                        })}
                    </div>
                    {!isMultiRecipientMode && (
                        <button
                            disabled={this.sendButtonDisabled()}
                            onClick={this.sendEvent}
                            className={buttonClasses}>
                            <SendLightIcon
                                fill={'#fff'}
                            />
                            {sendButtonWithText && (
                                <span>Send</span>
                            )}
                        </button>
                    )}
                    {isMultiRecipientMode && (
                        <DropDownButtonComponent 
                            disabled={this.sendButtonDisabled()}
                            mainButtonText="Send"
                            mainButtonIcon={<SendLightIcon fill={'#fff'}/>}
                            selectorOptions={[{text: 'Single Emails', icon: null}]}
                            onClickMainButton={this.handleClickMainButton}
                            onClickOptionButton={this.handleClickOptionButton}
                        />
                    )}
                </footer>

                {isMultiRecipientMode && showWarningSinglEmailModal && (
                    <ConfirmationModalComponent
                        isLoading={false}
                        closeEvent={() => this.setState({ showWarningSinglEmailModal: false })}
                        confirmEvent={this.handleConfirmSendingTemplateEmailAsSingle}
                        title={'You are about to send template email to several recipients. Are you sure?'}
                        buttonText={'Yes'}
                    />
                )}

                {showEmailMessageModal && (
                    <EmailMessageModal
                        profiles={this.getPersons()}
                        closeEvent={() => this.setState({ showEmailMessageModal: false })}/>
                )}

                {showJobSuggestionAlertModal && (
                    <JobSuggestionAlertModal
                        profiles={this.state.listProfiles}
                        jobs={this.props.jobUrls}
                        closeEvent={() => this.setState({ showJobSuggestionAlertModal: false })}/>
                )}

                {showDiscretePositionModal && (
                    <JobDiscreteAlertModal
                        closeEvent={() => this.setState({ showDiscretePositionModal: false })}
                        okEvent={() => this.setState({ showDiscretePositionModal: false, isUserApproveSend: true}, () => {
                            this.sendEvent()
                        })}  
                    />
                )}
               

                
        </div>
        );
    }
}

export default SendMessagesComponent;






