import {nanoid} from 'nanoid';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import {orderAscPrimary} from '../../../shared/utils/model-operator.utils';

export function getLatestNewSummaries(items) {
  return [
    ...items.map(item => {
      return {
        ...item,
        isPrimary: false
      }
    }),
    {
      id: nanoid(),
      summary: '',
      text: '',
      creatorUserName: userStore.user.displayName,
      creatorUserId: userStore.user._id,
      createDate: Date.now(),
      updateDate: Date.now(),
      isEditMode: true,
      isPrimary: true
    }
  ].sort(orderAscPrimary);
}

export async function getEditorSummaries(summaries) {
  const creatorIds = summaries.map(item => item.creatorUser);

  await userStore.getUsersByIds(creatorIds);

  return summaries.map((summary) => {
    const creator = userStore.getUserById2(summary.creatorUser);

    return {
      id: summary._id,
      text: summary.summary,
      summary: summary.summary,
      creatorUserId: creator?._id,
      creatorUserName: utilsService.getDisplayName(creator),
      createDate: summary.createDate,
      updateDate: summary.updateDate,
      isEditMode: false,
      isPrimary: summary.isPrimary
    }
  }).sort(orderAscPrimary);
}

export function getLatestInterview(summaries) {
  if (!summaries?.length) return {
    summary: '',
    createDate: '',
    updateDate: '',
    creatorUser: '',
  };

  const primarySummary = summaries.find(summary => summary.isPrimary);

  if (primarySummary) {
    return primarySummary;
  }

  // Get the summary with the latest createDate
  return summaries.reduce((pre, current) => {
    return (pre.createDate > current.createDate) ? pre : current;
  }, {});
}

export function getSafeLatestInterview(profile) {
  return profile.summaries ? getLatestInterview(profile.summaries) : {
    summary: '',
    createDate: '',
    creatorUser: '',
  }
}

export function isSummaryMissing(profile) {
  return !profile?.summaries?.length;
}

export function createSummaryEditor(profileId) {
  const key = `SUMMARY_EDITOR_KEY_${profileId}`;

  return {
    save(summaries) {
      try {
        window.localStorage.setItem(key, JSON.stringify(summaries));
      } catch (e) {
        console.log('Error saving editing text', e);
      }
    },
    get() {
      const raw = window.localStorage.getItem(key);

      if (!raw) return null;

      return JSON.parse(raw);
    },
    clean() {
      window.localStorage.removeItem(key);
    }
  }
}
