import React, { Component, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import billingStore from '../../../entities/billings/model/billing.store';
import { InputForm, MultiSelectComponent, SelectForm, SuggestComponent } from '../../components/forms';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import { CalendarIcon, EditIcon } from '../../components/svg';

const BonusList = (props) => {

    const day = dayjs().startOf('month').add(1, 'month');
    const monthStr = (day.$M + 1 >= 10) ? day.$M + 1 : '0' + (day.$M + 1);
    const defaultMonthForSalary = ''.concat(day.$y, '-', monthStr, '-', '01');
    const defaultMonthForSalaryUts = new Date(day).getTime();

    const { slug } = props.match.params;
    const [data, setData] = useState([]);
    let [handlers, setHandlers] = useState([]);
    let [bonusTypes, setBonusTypes] = useState(['Select', 'Candidate', 'Job', 'Company', 'Sender', 'Other']);
    const [handler, setHandler] = useState(null);
    const [bonusType, setBonusType] = useState(null);
    const [amount, setAmount] = useState(null);
    const [salaryMonth, setSalaryMonth] = useState(defaultMonthForSalary);
    const [salaryMonthUTS, setSalaryMonthUTS] = useState(defaultMonthForSalaryUts);
    const [outcome, setOutcome] = useState(null);
    const [billingSlug, setBillingSlug] = useState(slug);
    const [note, setNote] = useState('');
    const [billing, setBilling] = useState(null);
    const [companyName, setCompanyName] = useState('');
    const [jobName, setJobName] = useState('');
    const [candidateName, setCandidateName] = useState('');
    const [companyHandler, setCompanyHandler] = useState('');
    const [jobHandler, setJobHandler] = useState('');
    const [candidateHandler, setCandidateHandler] = useState('');
    const [sendingHandler, setSendingHandler] = useState('');
    const [otherHandler, setOtherHandler] = useState('');
    const [bill_id, setBill_id] = useState(null);

    function compare(a, b) {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        // a must be equal to b
        return 0;
    }

    // todo: use efect
    useEffect(() => {
        setBillingSlug(slug);

        const getData = async () => {

            const promisses = [
                await userStore.getRecruiters()
            ];
            await Promise.all(promisses);
            const handlers = Object.values(userStore.users)
                .filter(item => item.isActivated && item.canSignIn)
                .map(item => {
                    return {
                        elId: item._id,
                        value: utilsService.getDisplayName(item.profile),
                        slug: item.slug,
                        bsId: item.bsId
                    };
                })
                .filter(handler => handler.value.length > 1)
                .sort(compare);

            handlers.unshift({
                elId: 'none_handler',
                value: 'Select'
            });
            setHandlers(handlers);

            const billingDataGet = slug ? await billingStore.getBillingData({ slug: slug }) : null;
            let billingItem = '';

            if (billingDataGet && billingDataGet.length > 0) {
                billingItem = billingDataGet[0] ? billingDataGet[0] : billingDataGet.filter(item => item.slug === slug);
                populateBillingData(billingItem);
                setBilling(billingItem);
            }

            // setOutcome(billingItem);
            bonusDataPopulation(billingItem);
        }

        getData();
    }, []);

    const bonusDataPopulation = (billingItem) => {
        let bonusDataGet = billingItem.bonusPayments ? billingItem.bonusPayments : [];  //await billingStore.getBonusPaymentsData({ billingSlug: billingSlug });

        const dataGet = bonusDataGet.sort((a, b) => {
            if (a._id > b._id) {
                return -1;
            }
            if (a._id < b._id) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });
        const data = dataGet.map(item => {
            const handler = item.handler && item.handler.name ? item.handler.name : "";
            const bonusType = (item.bonusType) ? item.bonusType : "";
            const amount = (item.amount) ? item.amount : "";
            const salaryMonth = dayjs(item.salaryMonth).format('DD/MM/YYYY');
            const note = item.note ? item.note : "";
            const _id = item._id ? item._id : "";

            return {
                billingSlug: billingSlug,
                handler: handler,
                bonusType: bonusType,
                amount: amount,
                salaryMonth: salaryMonth,
                note: note,
                _id: _id
            }
        });
        setData(data);
    }


    const populateBillingData = (item) => {
        setCompanyName(item.company.name);
        setJobName(item.job.name);
        setCandidateName(item.process.candidateName);

        item.companyHandler ? setCompanyHandler(item.companyHandler) : setCompanyHandler({ name: '' });
        item.jobHandler ? setJobHandler(item.jobHandler) : setJobHandler({ name: '' });
        item.candidateHandler ? setCandidateHandler(item.candidateHandler) : setCandidateHandler({ name: '' });
        item.sendingHandler ? setSendingHandler(item.sendingHandler) : setSendingHandler({ name: '' });
        item.otherHandler ? setOtherHandler(item.otherHandler) : setOtherHandler({ name: '' });
    }

    const selectHandler = async (item) => {
        let handlerToSet = {};
        const handler = await userStore.getUserById2(item.value);
        if (handler) {
            handlerToSet = {
                elId: handler._id,
                value: utilsService.getDisplayName(handler),
                slug: handler.slug,
                name: utilsService.getDisplayName(handler),
            }
            setHandler(handlerToSet);
        }
    }

    const resetForm = () => {
        setHandler({
            elId: 'none_handler',
            value: 'Select'
        });
        setBonusType({
            value: 'Select'
        });
        setAmount('');
        setNote('');
        setOutcome('');
    }

    // set to 1st of a month
    const selectSalaryMonth = (date) => {
        const d = dayjs(date);
        const monthStr = (d.$M + 1 >= 10) ? d.$M + 1 : '0' + (d.$M + 1);
        const salaryMonthToSet = ''.concat(d.$y, '-', monthStr, '-', '01');
        setSalaryMonth(salaryMonthToSet);
        setSalaryMonthUTS(dayjs(salaryMonthToSet).valueOf());
    }

    const addToBonusHandle = (handler, entityType) => {
        resetForm();
        setHandler(handler);
        setBonusType(entityType);
    }

    const updateBonusHandle = (item_id) => {
        resetForm();

        const bill = billing.bonusPayments.filter(b => b._id === item_id)[0];
        setBill_id(item_id);
        setAmount(bill.amount);
        setHandler(bill.handler);
        setBonusType(bill.bonusType);
        if (bill.note) {
            const cleanNote = (bill.note.indexOf(' (Edited') > -1) ? bill.note.substr(0, bill.note.indexOf(' (Edited')) : bill.note;
            setNote(cleanNote);
        }

        const d = dayjs(bill.salaryMonth);
        const monthStr = (d.$M + 1 >= 10) ? d.$M + 1 : '0' + (d.$M + 1);
        const salaryMonthToSet = ''.concat(d.$y, '-', monthStr, '-', '01');
        setSalaryMonth(salaryMonthToSet);
        setSalaryMonthUTS(dayjs(salaryMonthToSet).valueOf());
    }

    // insertRow
    const insertRow = async () => {
        // validate form data
        if (!amount || !handler || !bonusType || !salaryMonthUTS) {
            if (!amount) {
                setOutcome('Amount is missing');
            }
            if (!handler) {
                setOutcome('Bonus reciever name is missing');
            }
            if (!bonusType) {
                setOutcome('Bonus type is missing');
            }
            if (!salaryMonthUTS) {
                setOutcome('Salary month is missing');
            }
            return;
        }

        const bonusPayment = {
            amount: amount,
            handler: handler,
            bonusType: bonusType,
            salaryMonth: salaryMonthUTS,
            note: note
        }

        const billingItem = billing;
        let bonusPaymentsArray = billingItem.bonusPayments ? billingItem.bonusPayments : [];

        if (bill_id) {
            bonusPayment._id = bill_id;

            // check if there was any change

            if (bonusPayment.amount !== amount
                || bonusPayment.handler !== handler
                || bonusPayment.bonusType !== bonusType
                || bonusPayment.salaryMonthUTS !== salaryMonthUTS
                || bonusPayment.note !== note
            ) {
                const lastEdit = new Date();
                const lastEditReadable = dayjs(lastEdit).format('DD/MM/YYYY');
                bonusPayment.lastUpdate = lastEdit.getTime();
                bonusPayment.note = bonusPayment.note ? bonusPayment.note.concat(` (Edited at ${lastEditReadable})`) : ` (Edited at ${lastEditReadable})`;
                bonusPaymentsArray = bonusPaymentsArray.filter(b => b._id !== bill_id);
            }
        }

        bonusPaymentsArray.push(bonusPayment);
        billingItem.bonusPayments = bonusPaymentsArray;
        setBilling(billingItem);

        // const response = await billingStore.updataBonusPayment(body)
        const response = await billingStore.updataBilling(billing)
        console.log(response);

        if (response) {
            bonusDataPopulation(billingItem);
            resetForm();
        }
    }

    return (
        <div className="form border-radius">

            <div className="bonus-table-container">
                <div className="bonus-billing-info">
                    <span className="bonus-billing-info-entities">
                        <div><span className="bonus-billing-info-labels">Company:</span>{companyName}</div>
                        <div><span className="bonus-billing-info-labels">Job:</span>{jobName}</div>
                        <div><span className="bonus-billing-info-labels">Candidate:</span>{candidateName}</div>
                    </span>
                    <span className="bonus-billing-info-hanelders">
                        <div><span className="bonus-billing-info-labels">Company Handler:</span>{companyHandler.name}
                            <button onClick={(e) => addToBonusHandle(companyHandler, 'Company')}>+</button>
                        </div>
                        <div><span className="bonus-billing-info-labels">Job Handler:</span>{jobHandler.name}
                            <button onClick={(e) => addToBonusHandle(jobHandler, 'Job')}>+</button>
                        </div>
                        <div><span className="bonus-billing-info-labels">Candidate Handler:</span> {candidateHandler.name}
                            <button onClick={(e) => addToBonusHandle(candidateHandler, 'Candidate')}>+</button>
                        </div>
                        <div><span className="bonus-billing-info-labels">Sending Handler:</span> {sendingHandler.name}
                            <button onClick={(e) => addToBonusHandle(sendingHandler, 'Sender')}>+</button>
                        </div>
                        <div><span className="bonus-billing-info-labels">Other Handler Involved:</span> {otherHandler.name}
                            <button onClick={(e) => addToBonusHandle(otherHandler, 'Other')}>+</button>
                        </div>
                    </span>

                </div>
                <div className="bonus-table-header">
                    <span className="bonus-header-item">Handler</span>
                    <span className="bonus-header-item">Bonus For</span>
                    <span className="bonus-header-item">Sum</span>
                    <span className="bonus-header-item">Pay Month</span>
                    <span className="bonus-header-item">Note</span>
                    <span className="bonus-header-item">Action</span>
                </div>
                <div className="bonus-table-body">

                    {
                        data.map(item => {
                            return (
                                <div className="bonus-table-row">
                                    <span className="bonus-row-item">{item.handler}</span>
                                    <span className="bonus-row-item">{item.bonusType}</span>
                                    <span className="bonus-row-item">{item.amount}</span>
                                    <span className="bonus-row-item">{item.salaryMonth}</span>
                                    <span className="bonus-row-item">{item.note}</span>
                                    <button className={`edit-item`} onClick={(e) => updateBonusHandle(item._id)}>
                                        <EditIcon />
                                    </button>
                                </div>
                            )
                        })
                    }

                </div>
                <div className="bonus-table-new-item">
                    <SelectForm
                        label={'Handler'}
                        name={'handler'}
                        value={handler}// TODO: populate on demand to edit
                        selectList={handlers}
                        inputChange={e => selectHandler(e.target)}
                    />
                    <SelectForm
                        label={'Bonus For'}
                        name={'bonusType'}
                        value={bonusType}// TODO: populate on demand to edit
                        selectList={bonusTypes}
                        inputChange={e => setBonusType(e.target.value)}
                    />
                    <div className="input-wrapper">
                        <InputForm
                            label={'Amount'}
                            value={amount}
                            type={'text'}
                            inputChange={e => setAmount(e.target.value)}
                        />
                    </div>

                    <div className="input-wrapper">
                        <InputForm
                            label={'Salary Month'}
                            value={salaryMonth}
                            type={'date'}
                            inputChange={e => selectSalaryMonth(e.target.value)}
                        />
                        <span className="open-button">
                            <CalendarIcon />
                        </span>
                    </div>

                    <div className="input-wrapper">
                        <InputForm
                            label={'Note'}
                            value={note}
                            type={'text'}
                            inputChange={e => setNote(e.target.value)}
                        />
                    </div>

                    <button className="bonus-row-input-button"
                        onClick={insertRow}
                    >
                        Insert
                    </button>
                    <div className="bonus-form-alert">{outcome}</div>
                    {/* <div>{JSON.stringify(outcome, null, 10)}</div> */}
                </div>
            </div>
        </div >
    );
}

export default BonusList;
