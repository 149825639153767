import React, {Component} from 'react';
import './log.scss';
import TimeLineItemComponent from './TimeLineItem.component';
import {observer} from 'mobx-react';

@observer
class LogComponent extends Component {
    render() {
        return (
            <div className={this.props.shadow ? 'log-tab' : 'log-tab white-box'}>
                <div className="log-timeline">
                    {this.props.data.eventLog.map((log, idx) => {
                        return (<TimeLineItemComponent
                            data={log}
                            key={idx}
                        />);
                    })}

                    {this.props.data.eventLog.length === 0 &&
                    (<p>No log</p>)}
                </div>
            </div>
        );
    }
}

export default LogComponent;
