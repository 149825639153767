import React, { PureComponent } from 'react';
import { AttachmentIcon2 } from '../svg';

class FileUpload extends PureComponent {
    constructor(props) {
        super(props);
        this.__attachment = null;
    }

    attachmentOnClick = () => {
        this.__attachment.click();
    };

    onFileUpload = async e => {
        const file = e.target.files[0];

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        const attachmentBase64 = await toBase64(file);

        const attachmentData = {
            attachmentName: file.name,
            attachmentBase64: attachmentBase64
        };

        this.props.fileHandle(attachmentData);
    };

    render() {
        const { label, icon, accept } = this.props;

        return (
            <div className='file-upload-forms'>
                <div
                    onClick={this.attachmentOnClick}
                    className='inbox-messages-reply-attachment'>
                    {label && (
                        <span className="file-upload-forms-label">
                            {label}
                    </span>
                    )}
                    
                    {icon ?? <AttachmentIcon2/>}
                </div>
                <input
                    type="file"
                    name="file"
                    onChange={this.onFileUpload}
                    style={{ display: 'none' }}
                    ref={input => this.__attachment = input}
                    onClick={(e) => e.target.value = ''}
                    accept={accept}
                />
            </div>
        );
    }
}

export default FileUpload;
