import {action, observable} from 'mobx';

export class ApiOverviewEngine {
  @observable
  properties = {
    limit: 20,
    page: 0,
  };
  @observable.deep
  source = {}
  @observable
  items = [];
  @observable
  total = 0;
  @observable
  pageCount = 0;
  @observable
  isLoading = false;
  @observable
  hasNoContent = false;
  @observable
  isError = false;
  @observable
  isCallAPI = true;

  loaders = [];
  apiCaller = null;
  keyCaller = null;

  constructor({ propertiesRegistry: registries, apiCaller }) {
    const props = {};
    const loaders = [];

    Object.keys(registries).forEach((key) => {
      props[key] = registries[key]?.default;

      if (registries[key]?.options) {
        if (typeof registries[key].options === 'function') {
          loaders.push({
            key,
            loader: registries[key].options,
          });
        } else {
          this.source[key] = registries[key].options;
        }
      }
    });

    this.properties = props;
    this.loaders = loaders;
    this.apiCaller = apiCaller;
  }

  @action.bound
  loadByFilter(key, value) {
    this.properties.page = 0;
    this.properties[key] = value;
    this.isCallAPI = true;
    this.getResource();
  }

  updatePage(page) {
    this.properties.page = page;
    this.isCallAPI = true;
    this.getResource();
  }

  @action.bound
  async onInit() {
    this.isLoading = true;
    await Promise.all(this.loaders.map(async (loader) => {
      const { key, loader: dataLoader } = loader;
      this.source[key] = await dataLoader();
    }));
    await this.getResource();
    this.isLoading = false;
  }

  @action.bound
  refetch() {
    this.isCallAPI = true;
    return this.getResource();
  }

  @action.bound
  async getResource() {
    if (!this.apiCaller) return;
    if (!this.isCallAPI) return;

    this.isLoading = true;
    this.hasNoContent = false;

    const keyCaller = Date.now();

    this.keyCaller = keyCaller;
    const result = await this.apiCaller(this.properties);

    if (this.keyCaller !== keyCaller) return;

    this.isLoading = false;
    this.isError = !!result.isError;
    this.isCallAPI = false;
    this.items = result.data?.data ?? [];
    this.total = result.data?.metadata?.total || this.properties?.limit;
    this.pageCount = Math.ceil(this.total / this.properties?.limit);
    this.hasNoContent = this.items?.length === 0;

    return result;
  }

  @action.bound
  resetFilters() {
    this.properties.limit = 20;
    this.properties.page = 0;

    this.properties.companyHandler = null;
    this.properties.interviewer = null;
    this.properties.jobHandler = null;
    this.properties.sender = null;
    this.properties.jobStatus = {
      elId: 1,
      value: 'Open'
    };
    this.properties.candidateAvailability = {
      elId: 0,
      value: 'Available'
    };
    this.properties.lastUpdate = null;
    this.properties.stage = null;
    this.properties.candidateName = '';

    this.properties.recruiterId = null;
    this.properties.fromDate = new Date();
    this.properties.toDate = new Date();
    this.properties.state = null;

    this.isCallAPI = true;
    this.getResource();
  }
}
