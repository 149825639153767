import React, {Component} from 'react';
import PromoteProcessComponent from '../../components/actions/PromoteProcess.component';
import processStore from '../../../stores/process.store';
import profileStore from '../../../stores/profile.store';
import jobStore from '../../../stores/job.store';
import companyStore from '../../../stores/company.store';
import Loader from '../../components/loader/Loader.component';
import './mailactions.scss';
import TerminateProcessComponent from '../../components/actions/TerminateProcess.component';
import 'firebase/auth';
import {doSignInEmailLink, isSignInWithEmailLink} from '../../../services/auth.service';
import utilsService from '../../../services/utils.service';
import QuickDetailsTalentComponent from '../../components/quickDetails/QuickDetailsTalent.component';
import ExposeProcessComponent from '../../components/actions/ExposeProcess.component';
import PauseProcessComponent from '../../components/actions/PauseProcess.component';

class MailActionsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            success: false,
            logged: false,
            successMessage: ''
        };
    }

    signUpHandle = (ftoken) => {
        const email = window.atob(ftoken);
        if (isSignInWithEmailLink(window.location.href)) {
            if (!email) {
                // OOPS
            }

            doSignInEmailLink(email, window.location.href)
                .then(resp => {
                    console.log('resp', resp);
                })
                .catch(error => {
                    console.log('error', error);

                    setTimeout(() => {
                        if (!this.state.logged) {
                            window.location.href = '/login';
                        }
                    }, 1500);
                });

        } else {
            this.setState({ message: 'Error' });
        }
    };

    async componentDidMount() {
        const { action } = this.props.match.params;
        document.title = `Action - Ethosia`;
        await this.getData();
    }

    getData = async () => {
        const { processId, action } = this.props.match.params;
        await processStore.getProcessById(processId);
        const processSlug = processStore.findProcessSlugById(processId);
        const process = processStore.processes[processSlug];

        await Promise.all([
            profileStore.getProfileById(process.profile),
            jobStore.getJobBySlug(process.jobSlug),
            companyStore.getCompanyBySlug(process.companySlug)
        ]);

        // this.checkStatuses(action, process.status);
        this.setState({ loader: false });
    };

    checkStatuses = (action, processStatus) => {
        if (action === 'interested') {
            if (this.checkIfExposed(processStatus)) {
                this.setState({ successMessage: 'Talent already has been exposed', success: true });
            }
        }

        if (action === 'hold') {
            if (processStatus === 6) {
                this.setState({ successMessage: 'The process is already have status paused', success: true });
            }
        }

        if (action === 'offer') {
            if (processStatus === 4) {
                this.setState({ successMessage: 'The process is already have status offered', success: true });
            }
        }

        if (action === 'terminate') {
            if (processStatus === 29) {
                this.setState({ successMessage: 'The process is already have status rejected', success: true });
            }
        }

        if (action === 'hire') {
            if (processStatus === 7) {
                this.setState({ successMessage: 'The process is already have status hired', success: true });
            }
        }
    };

    // checkIfExposed = (status) => {
    //     let exposed = true;
    //     if (status === 12 || status === 76 || status === 111) {
    //         exposed = false;
    //     }
    //     return exposed;
    // };
    checkIfExposed = (status) => {
        return true;
    };

    componentWillUnmount() {
        // this.unregisterAuthObserver();
    }

    getRightAction = (action, data) => {
        switch (action) {
            case 'terminate':
                return (<TerminateProcessComponent
                    data={data}
                    successEvent={() => this.setState({ success: true })}
                    cancelEvent={() => this.props.history.push('/pipelines')}
                />);
            case 'hold':
                return (<PauseProcessComponent
                    data={data}
                    successEvent={() => this.setState({ success: true })}
                    cancelEvent={() => this.props.history.push('/pipelines')}
                />);
            case 'interested':
                return (<ExposeProcessComponent
                    data={data}
                    successEvent={() => this.setState({ success: true })}
                    cancelEvent={() => this.props.history.push('/pipelines')}
                />);
            default:
                return (<PromoteProcessComponent
                    data={data}
                    successEvent={() => this.setState({ success: true })}
                    cancelEvent={() => this.props.history.push('/pipelines')}
                />);
        }
    };

    render() {
        const { action, processId } = this.props.match.params;
        const processSlug = processStore.findProcessSlugById(processId);
        const process = processStore.processes[processSlug];


        let profile = null;
        let company = null;
        let job = null;
        if (process) {
            profile = profileStore.profiles[process.profileSlug];
            company = companyStore.companies[process.companySlug];
            job = jobStore.jobs[process.jobSlug];
        }

        const data = {
            process: process,
            profile: profile,
            modalType: action,
            company: company,
            job: job
        };

        if(!this.state.loader && this.state.success && process) {
            this.props.history.push(`/process/${process.slug}/profile/${profile.slug}`);
            return null;
        }

        return (
            <div className={this.state.success
                ? 'mail-action shadow4 border-radius success'
                : 'mail-action shadow4 border-radius'}>
                {this.state.loader && (<Loader/>)}

                {!this.state.loader && !this.state.success && process && (
                    <React.Fragment>
                        {<QuickDetailsTalentComponent
                            noShadow={true}
                            action={action}
                            data={utilsService.createQuickDetailsData(profile, process)}/>}
                        {this.getRightAction(action, data)}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default MailActionsView;
