import React from 'react';
import './predefinedInboxMessages.scss';
import data from '../../../../constants/data';

const PredefinedInboxMessagesComponent = props => {
    const {preDefinedTextHandle} = props;

    return (
        <div className="inbox-messages-reply-header-predefined-messages ">
            {data.preDefinedInboxMessages.map((pmes, idx) => {
                return (
                    <div
                        onClick={() => preDefinedTextHandle(pmes.value)}
                        key={idx + pmes.elId}
                        className="inbox-messages-reply-header-predefined-messages-tag">
                        {pmes.value}
                    </div>
                );
            })}
        </div>
    );
};

export default PredefinedInboxMessagesComponent;
