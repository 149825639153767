import {nanoid} from "nanoid";
import utilsService from "../../../services/utils.service";
import {DEFAULT_COUNTRY_STATE} from "../configs/constants";


/**
 * @typedef SocialLinkItem
 * @type {object}
 * @property {string} id
 * @property {string} link
 * @property {number} linkType
 * @property {number} isPrimary.
 */

/**
 * @typedef FormListItem
 * @type {object}
 * @property {string} id
 * @property {object} value
 */

export const profileService = {
  /**
   * @param socialLinks
   * @return FormListItem[]
   */
  buildSocialLinkItems: (socialLinks) => {
    return socialLinks?.map(linkItem => {
      return {
        id: linkItem._id ?? nanoid(),
        value: linkItem,
      }
    }) ?? [];
  },
  buildPhoneItems: (phones) => {
    if (!phones) return [];

    return phones.map(item => {
      return {
        id: item._id ?? nanoid(),
        ...item,
      }
    })
  },
  /**
   *
   * @param {FormListItem[]} socialLinkItems
   */
  mapSocialLinks: (socialLinkItems) => {
    const PREFIX_LINKEDIN = 'https://www.linkedin.com/in/';

    return socialLinkItems.map(({ value }) => {
      const {link, linkType, isPrimary} = value;
      const cleanedSubPath = utilsService.getCleanLinkedinPublicProfileName(link);

      if (!cleanedSubPath) {
        return null
      }

      return {
        link: PREFIX_LINKEDIN + cleanedSubPath,
        linkType,
        isPrimary
      }
    }).filter(Boolean);
  },
  mapPhones: (phones) => {
    return phones.map(({ callingCode = DEFAULT_COUNTRY_STATE.CALLING_CODE, country = DEFAULT_COUNTRY_STATE.COUNTRY, phone, ...item }) => {
      return {
        phone,
        callingCode,
        country,
        ...item
      }
    })
  }
}
