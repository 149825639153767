import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react"
import { getCode } from 'country-list';
import utilsService from '../../../../../services/utils.service';
import companyStore from '../../../../../stores/company.store';
import userStore from '../../../../../stores/user.store';
import ContactWithCoordinatorComponent from '../../../../components/ContactWithCoordinator.component';
import CustomCheckboxComponent from '../../../../components/customCheckbox/CustomCheckbox.component';
import DefaultImageComponent from '../../../../components/DefaultImage.component';
import MoreMenuActionComponent from '../../../../components/processActions/MoreMenuAction.component';
import StatusBarComponent from '../../../../components/statusBar/StatusBar.component';
import { AddToMatchesIcon, Bookmark, SendCvIcon, TrashIcon } from '../../../../components/svg';
import CompanyOverviewComponent from '../../../company/components/CompanyOverview.component';
import './searchResultJobItem.scss';

const SearchResultJobItem = observer(React.forwardRef((props, ref) => {

    const { job } = props;

    const checkAction = (e) => {
        props.checkActionChanged(job);
    }

    const handleItemSelected = (e) => {
        props.itemSelected(job)
    }

    const handleOnClick = (e) => {
        let path = '/company/' + job.companySlug;
        props.history.push(path);
    }

    const getDataForMoreMenu = () => {
        let temp = [];
        temp = [
            { modal: 'sendCV', text: 'Send CV', icon: 'sendCV' },
            { modal: 'addToMatches', text: 'Add to Matches', icon: 'addToMatches' },
        ];
        return temp;
    }

    const handleMoreMenuClick = (currentModal, e) => {
        e.stopPropagation();
        props.actionEventHandler(job, currentModal, null, e);
    }

    const MoreDetailsItem = (props) => {
        return <div className="more-details-item">
            <label className="more-details-title">{props.title}</label>
            {props.isLink ?
                <Link to={`/jobs/${job.slug}?tab=2&stage=${props.title.toLowerCase()}`} className="more-details-link">{props.value}</Link>
                :
                <label className="more-details-value">{props.value}</label>
            }
        </div>
    }

    const buildSkills = () => {
        const skills = utilsService.filterSkillsFromMandatorySkills(job.skills, job.mandatorySkills);
        const allSkills =  [...utilsService.getSkills(job.mandatorySkills, true), 
            ...utilsService.getSkills(skills, false)];
        return allSkills;
    }

    const company = companyStore.companies[job.companySlug]
    const hrManager = job.hrManagers && job.hrManagers.length > 0 && 
        userStore.getUserById2(job.hrManagers[0]);
    const recruiterInCharge = job && job.recruiterInChargeId && 
        userStore.getUserById2(job.recruiterInChargeId);
    const industry = utilsService.getValueFromArray(job.industries, 0);
    const industryMessage = industry.length > 0 ? industry : 'N/A';
    const languages = utilsService.joinByComma(job.languages, 'value');
    const seniority = job.seniorityLevel && job.seniorityLevel.length > 0 ? 
        job.seniorityLevel[0].value : 'N/A'
    const city = job.location ? job.location.city : 'N/A';    
    const country = job.location ? getCode(job.location.country) : 'N/A';  
    const processesCounter = job.processesCounter;
    return (
        <div className={"search-result-job-item-wrapper " + (job.slug === props.selectedItem.slug ? 'selected' : '')}
            ref={ref} onKeyDown={props.keyDownPress} tabIndex="0">
            <div className="content-wrapper" onClick={(e) => handleItemSelected(e)}>
                <CustomCheckboxComponent
                    checkAction={(e) => checkAction(e)}
                    checked={job.check} />
                <div className="content">
                    <div className="row">
                        <div className="job-display">
                            {company && <DefaultImageComponent
                                url={utilsService.getCompanyImage(company)}
                                alt={company.name}
                                profileName={company.name}
                                imageClick={handleOnClick}
                            />}
                        </div>
                        <div className="inner-row">
                            <div className="row1">
                                <div className="left-part">
                                    <Link className='black-link' to={'/jobs/' + job.slug}>
                                        <h1 className="company">{utilsService.removeWrongCharsFromString(job.title)}</h1>
                                    </Link>  
                                </div>
                                <div className="right-part">
                                    <div className="activity-part">
                                        <div className="remove"
                                            onClick={(e) => props.removeItem(e, [job])}
                                            title={'Remove'}>
                                            <TrashIcon />
                                        </div>
                                        <div className="send-cv"
                                            onClick={(e) => props.actionEventHandler(job, 'sendCV', null, e)}
                                            title={'Send CV'}>
                                            <SendCvIcon />
                                        </div>
                                        <div className="add-to-matches"
                                            onClick={(e) => props.actionEventHandler(job, 'addToMatches', null, e)}
                                            title={'Add To Matches'}>
                                            <AddToMatchesIcon />
                                            <Bookmark />
                                        </div>
                                    </div>
                                    <div className="more-menu">
                                        <MoreMenuActionComponent
                                            process={null}
                                            menu={getDataForMoreMenu()}
                                            actionEvent={(process, modal, e) => handleMoreMenuClick(modal, e)}
                                            icons={true} />
                                    </div>
                                </div>
                            </div>
                            <div className="row2">
                                {company && <Link className='black-link' to={'/company/' + job.companySlug}>
                                    <label title={company.name} className="company">{company.name}</label>
                                </Link>}     
                                <label title= {`${city}, ${country}`} className="city">{`${city}, ${country}`}</label>
                                <label title={industryMessage} className="industry">{industryMessage}
                                </label>
                                <StatusBarComponent text={utilsService.getJobStatus(job.status, true)}/>
                                <label className="date">{utilsService.getFormattedDate(job.dateCreated)}</label>                              
                            </div>
                        </div>
                    </div>
                    <div className="more-details">
                        <div className="column-one">
                            <MoreDetailsItem title='Salary' value={job.salary && job.salary !== 0 ? job.salary : 'N/A'}/>
                            <MoreDetailsItem title='Type' value={utilsService.getObjValue(job.positionType)}/>
                            <MoreDetailsItem title='Seniority' value={seniority}/>
                            <MoreDetailsItem title='Languages' value={languages ? languages : 'N/A'}/>
                            <MoreDetailsItem title='Job Type' value={job.projectType ? job.projectType.value : 'N/A'}/>
                        </div>
                        <div className="column-two">
                            <label className='more-details-title'>Required Skills</label>
                            <div className="skills">
                                {buildSkills()}
                            </div>                        
                        </div>
                        <div className="column-three">
                            <label className='more-details-title'>Our Talents</label>
                            <MoreDetailsItem title='Leads' 
                                value={processesCounter ? processesCounter.leadsCounter : '-'} 
                                isLink={true}
                            />
                            <MoreDetailsItem title='New' 
                                value={processesCounter ? processesCounter.newCounter : '-'}
                                isLink={true}
                            />
                            <MoreDetailsItem title='In-Progress' 
                                value={processesCounter ? processesCounter.inProgressCounter : '-'}
                                isLink={true}
                            />
                            <MoreDetailsItem title='Interview' 
                                value={processesCounter ? processesCounter.interviewCounter : '-'}
                                isLink={true}
                            />
                            <MoreDetailsItem title='Offered'
                                 value={processesCounter ? processesCounter.offeredCounter : '-'}
                                isLink={true}
                            />
                        </div>
                    </div>
                    <div className="contact-person">
                        <div className="hr-manager">
                            {hrManager && <ContactWithCoordinatorComponent 
                                type={"hr"} 
                                name={CompanyOverviewComponent.getRecruiterName(hrManager)}
                                image={CompanyOverviewComponent.getRecruiterImage(hrManager)}
                                user={hrManager}
                                email={hrManager.email} 
                                showPhoneNumber={false}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}));




SearchResultJobItem.propTypes = {
    // bla: PropTypes.string,
};

SearchResultJobItem.defaultProps = {
    // bla: 'test',
};

export default SearchResultJobItem;




