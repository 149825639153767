import React, { Component } from 'react';
import './sendMessages2.scss';
import SendConversationHeaderComponent from './SendConversationHeader.component';
import Loader from '../../loader/Loader.component';
import { CloseIcon } from '../../svg';
import SendMessagesComponent from '../SendMessages.component';
import metaDataStore from '../../../../stores/meta-data.store';

class SendConversationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conType: props.conType || 1,
            loader: true,
            assigmentSelectSelectedSlug: null,
            tagSelectedId: 1,
            actions: {
                importance: false
            }
        };
    }

    bringMetaDataCategories() {
        const categories = [
            'conversationsSauce',
            'conversationsLabels',
            'conversationsTags'
        ];
        return categories.map(cat => {
            return metaDataStore.getCategory(cat);
        });
    }

    async componentDidMount() {
        const { replyTo } = this.props;
        await this.bringMetaDataCategories();

        const update = {
            loader: false
        };

        if (replyTo && replyTo.conversationType) {
            update.conType = replyTo.conversationType;
        }

        this.setState(update);
    }


    getTitle(conType, replyTo) {
        let text = 'New';
        if (replyTo) {
            text = 'Reply';
        }
        switch (conType) {
            case 1:
                text += ' Email';
                break;
            case 2:
                text += ' SMS';
                break;
        }

        return text;
    }

    action = () => {
        const actions = {
            importance: !this.state.actions.importance
        };

        this.setState({
            actions: actions
        });
    };
    assigmentSelectEvent = slug => {
        this.setState({
            assigmentSelectSelectedSlug: slug
        });
    };

    tagSelectEvent = tag => {
        this.setState({
            tagSelectedId: tag.elId
        });
    };

    conTypeSelectHandle = conType => {
        this.setState({
            conType: conType.elId
        });
    };

    render() {
        const { closeEvent, replyTo, items, subject, body, hideTemplate, isDiscretePosition, type, languageSelectEvent, operationType, selectedEmailTemplate, jobUrls } = this.props;
        const { assigmentSelectSelectedSlug, tagSelectedId, actions, loader, conType } = this.state;

        return (
            <div className='send-new-message-wrap'>
                {loader && (
                    <Loader/>
                )}

                {!loader && (
                    <div className="send-new message">
                        <div className='send-new-message-top'>
                            <h2>{this.getTitle(conType, replyTo)}</h2>

                            <div onClick={closeEvent}
                                 className='circle-close modal-close'>
                                <CloseIcon/>

                            </div>


                            {!replyTo && (
                                <SendConversationHeaderComponent
                                    conType={conType}
                                    replyTo={replyTo}
                                    conTypeSelectHandle={this.conTypeSelectHandle}
                                    assigmentSelectSelectedSlug={assigmentSelectSelectedSlug}
                                    tagSelectedId={tagSelectedId}
                                    tagSelectEvent={this.tagSelectEvent}
                                    assigmentSelectEvent={this.assigmentSelectEvent}
                                    languageSelectEvent={languageSelectEvent}
                                    operationType={operationType}
                                />
                            )}

                            <SendMessagesComponent
                                hideTemplate={hideTemplate}
                                items={items}
                                subject={subject}
                                body={body}
                                searchTo={false}
                                replyTo={replyTo}
                                sendButtonWithText={true}
                                assigmentSelectSelectedSlug={assigmentSelectSelectedSlug}
                                tagSelectedId={tagSelectedId}
                                importance={actions.importance}
                                conType={conType}
                                closeEvent={closeEvent}
                                removeSelection={() => null}
                                isDiscretePosition={isDiscretePosition}
                                type={type}
                                selectedEmailTemplate={selectedEmailTemplate}
                                jobUrls={jobUrls}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default SendConversationComponent;
