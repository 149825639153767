import React, { useState } from 'react';
import PropTypes from 'prop-types'
import './searchResultsTitle.scss';
import CustomCheckboxComponent from '../../../components/customCheckbox/CustomCheckbox.component';
import utilsService from '../../../../services/utils.service';
import DropdownWithSubDirectories from '../../../components/dropdown/dropdownWithSubDirectories/DropdownWithSubDirectories.component';
import data from '../../../../constants/data';
import { MailIcon2, SendCvIcon, SendToSuggestIcon, ProjectIcon, AddToMatchesIcon, TrashIcon } from '../../../components/svg';
import MoreMenuActionComponent from '../../../components/processActions/MoreMenuAction.component';
import searchStore from '../../../../stores/search.store';


const SearchResultsTitle = (props) => {

    const { results = [], fullResults = [] } = props;

    //status or availability
    const [status, setStatus] = useState(-1);
    const [check, setIsChecked] = useState(false)

    const checkAction = () => {
        props.checkActionChanged(!check);
        setIsChecked(!check);
    }

    const handleTitleFilterClick = (column, selectedItem) => {
        if (column === 'status') {
            if(selectedItem.elId !== status) {
                setStatus(selectedItem.elId);
                props.statusChanged(selectedItem.elId);
            }
        }
    }

    const getStatusClasses = () => {
        const searchType = searchStore.selectedTopAdvanced;
        if(searchType === 0) {
            switch (status) {
                case 0:
                    return 'available'
                case 1:
                    return 'maybe'
                case 2:
                    return 'unavailable'
    
                default:
                    return null;
            }
        }
        else if(searchType === 1) {
            switch (status) {
                case 0:
                    return 'close'
                case 1:
                    return 'open'
                case 2:
                    return 'maybe'
    
                default:
                    return null;
            }
        }
    }

    const getStatusColumnName = () => {
        const searchType = searchStore.selectedTopAdvanced;
        let item;
        if(searchType === 0) {
            item = data.availabilityOptionsForSearchResults.find
                (item => item.elId === status);
        }
        else if(searchType === 1) {
            item = data.statusOptionsForSearchResults.find
                (item => item.elId === status);
        }
        return item && item.value;
    }

    const createList = (column) => {
        if (column === 'status') {
            const searchType = searchStore.selectedTopAdvanced;
            let tempArray;
            if(searchType === 0) {
                tempArray = utilsService.doMeAFavor(data.availabilityOptionsForSearchResults);
            }
            if(searchType === 1) {
                tempArray = utilsService.doMeAFavor(data.statusOptionsForSearchResults);
            }
            const list = tempArray && tempArray.map(item => {
                item.value = `${item.value} (${item.elId === -1 ?  
                    fullResults.length : 
                    fullResults.filter(result => getItemFromList(result) === item.elId).length})`;
                return item;
            });
            return list;
        }
        else if (column === 'projects') {
            return data.projectsOptions;
        }
    }

    const getItemFromList = (item) => {
        const searchType = searchStore.selectedTopAdvanced;
        if(searchType === 0) {
            return utilsService.getCandidateAvailabilityNumber(item);
        }
        if(searchType === 1) {
            return item.status;
        }
    }

    const getDataForMoreMenu = () => {
        let temp = [];
        const searchType = searchStore.selectedTopAdvanced;
        if(searchType === 0) {
            temp = [
                { modal: 'sendCV', text: 'Send CV', icon: 'sendCV' },
                { modal: 'isModalOpen_mark_as_withdrawn', text: 'Add to Project', icon: 'project' },
                { modal: 'sendSuggest', text: 'Send Suggest', icon: 'sendSuggest' },
                { modal: 'addToMatches', text: 'Add to Matches', icon: 'addToMatches' },
                { modal: 'isModalOpen_Share', text: 'Merge Candidates', icon: 'mergeCandidates' }
            ];
        }
        else if(searchType === 1) {
            temp = [
                { modal: 'sendCV', text: 'Send CV', icon: 'sendCV' },
                { modal: 'addToMatches', text: 'Add to Matches', icon: 'addToMatches' },
            ];
        }
        return temp;
    }

    const handleMoreMenuClick = (currentModal, e) => {
        e.stopPropagation();
        props.actionEventHandler(null, currentModal, null, e);
    }

    const getActivitiesClasses = () => {
        const searchType = searchStore.selectedTopAdvanced;
        let classes = 'activities '
        if(searchType === 1) {
            classes += 'jobs'
        }

        return classes;
    }

    const shouldRender = (type) => {

        const isPeopleSearch = searchStore.selectedTopAdvanced === 0;

        switch(type) {
            case 'send to suggest':
            case 'projects':
            case 'mail':  
                return isPeopleSearch;
    
            default: {
                return false;
            }
        }

    }

    const atLeastOneCheckboxSelected = results && results.find(item => item.check);
    return (
        <div className='search-results-title-wrapper'>
            <header>
                <div className="row all" >
                    <CustomCheckboxComponent
                        checkAction={checkAction}
                        checked={check}
                    />
                    <h1>All</h1>
                </div>
                <div className="status-wrapper">
                    <h2>Show:</h2>
                    <div className="status">
                        <DropdownWithSubDirectories
                            column={'status'}
                            currentColumnName={getStatusColumnName()}
                            list={createList('status')}
                            titleFilterClick={(column, item) => handleTitleFilterClick(column, item)}
                            leftIcon={getStatusClasses()} rightIcon='arrow' />
                    </div>
                </div>
                {atLeastOneCheckboxSelected && <div className={getActivitiesClasses()}>
                    <div className="remove"
                        onClick={(e) => props.removeFromTitle()}
                        title={'Remove'}>
                        <TrashIcon/>
                    </div>
                    <div className="send-cv"
                        onClick={(e) => props.actionEventHandler(null, 'sendCV', null, e)}
                        title={'Send CV'}>
                        <SendCvIcon />
                    </div>
                    {shouldRender('send to suggest') && <div className="send-to-suggest"
                        title={'Send To Suggest'}>
                        <SendToSuggestIcon />
                    </div>}
                    <div className="add-to-matches"
                        onClick={(e) => props.actionEventHandler(null, 'addToMatches', null, e)}
                        title={'Add To Matches'}>                                         
                        <AddToMatchesIcon />
                    </div>
                    {shouldRender('projects') && <div className="projects">
                        <DropdownWithSubDirectories list={createList('projects')}
                            titleFilterClick={(column, item) => handleTitleFilterClick(column, item)}
                            leftIconImg={<ProjectIcon />} rightIcon='arrow'
                            title={'Projects'}
                        />
                    </div>}
                    {shouldRender('mail') && <div className="mail"
                         onClick={(e) => props.actionEventHandler(null, 'sendMessage', null, e)}
                         title={'Send Mail'}>                                          
                        <MailIcon2 />
                    </div>}
                    <div className="more-menu">
                        <MoreMenuActionComponent
                            process={null}
                            menu={getDataForMoreMenu()}
                            actionEvent={(process, modal, e) => handleMoreMenuClick(modal, e)}
                            icons={true} />
                    </div>
                </div>}
            </header>
        </div>

    )
};

SearchResultsTitle.propTypes = {
    // bla: PropTypes.string,
};

SearchResultsTitle.defaultProps = {
    // bla: 'test',
};

export default SearchResultsTitle;




