import React, { PureComponent } from 'react';
import searchStore from '../../../stores/search.store';
import ModalComponent from '../modal/Modal.component';
import notificationsStore from '../../../stores/notifications.store';
import { SuggestComponent } from '../../components/forms';
import { RadioGroup, DateInput, Autocomplete } from '@brightsource/brightsource-ui-lib';
import { CalendarIcon1 } from '../svg';
import userStore from '../../../stores/user.store';
import utilsService from '../../../services/utils.service';
import './AddReminderModal.scss';
import remindersStore from '../../../stores/reminders.store';
import SuggestImageComponent from '../forms/SuggestImage.component';

class AddEditReminderModalComponent extends PureComponent {
    constructor(props) {
        super(props);
        const { data } = props;
        
        this.state = {
            loader: false,
            selectedObject: data ? this.buildSelectedObject(data) : {},
            listObjects: [],
            objectType: data ? data.entityType : 'profile',
            dueDate: data && data?.dueDate ? new Date(data.dueDate) : null,
            assignee: data ? this.getAssigneeFromSlug(data.assigneeSlug) : null,
            reminderDate: data && data?.reminderDate ? new Date(data.reminderDate) : null,
            subject: data ? data.detail : '',
            isSaving: false,
            isLoading: false
        };
    }
    
    closeModalEvent = () => {
        const { closeEvent } = this.props;
        closeEvent();
    };


    handleSubmit = async () => {
        this.setState({isSaving: true})
        const { data } = this.props;

        const payload = {
            entitySlug: this.state.selectedObject?.slug,
            entityType: this.state.objectType,
            dueDate: this.state.dueDate?.getTime(),
            reminderDate: this.state.reminderDate?.getTime(),
            detail: this.state.subject,
            assigneeSlug: this.state.assignee?.slug
        }

        if (data && data._id) {
            const resp = await remindersStore.updateReminder(data._id, payload)
            if (resp && resp.data && resp.data.isError) {
                notificationsStore.showToast(`Update task failed. ${resp.data.errorMessage}`, 'error');
            }
            else {
                notificationsStore.showToast(`Update task successfully`, 'success');
            }
        } else {
            const resp = await remindersStore.createReminder(payload)
            if (resp && resp.data && resp.data.isError) {
                notificationsStore.showToast(`Create new task failed. ${resp.data.errorMessage}`, 'error');
            }
            else {
                notificationsStore.showToast(`Create new task successfully`, 'success');
            }
        }
        this.setState({isSaving: false})
        const { refresh } = this.props;
        refresh();
        this.closeModalEvent()
    }


    getObjects = async(value) => {
        this.setState({isLoading: true})
        if (!value) {
            this.setState({listObjects: []})
            this.setState({isLoading: false})
            return;
        }

        if(this.typingTimeout){
            clearTimeout(this.typingTimeout);
        }

        if(value.length >= 2) {
            this.typingTimeout = setTimeout(async () => {
                await searchStore.objectForReminderSearch(this.state.objectType, value);
                const data = this.state.objectType == 'profile' ? searchStore.objectForReminderSearchResults.profiles : this.state.objectType == 'job' ? searchStore.objectForReminderSearchResults.jobs : searchStore.objectForReminderSearchResults.companies;

                if (data?.length > 0) {
                    this.setState({listObjects: data.map(item => {
                        const profileName = `${item.name} - ${item.linkedInData?.currentPosition ? item.linkedInData.currentPosition : item.position}`;
                        return {
                            ...item, 
                            elId: item.slug, 
                            value: this.state.objectType == 'profile' ? profileName : this.state.objectType == 'job' ? `${item.title} - ${item.companyName}` : item.name
                        }
                    })})
                }
                this.setState({isLoading: false})
            }, 300);
        }
    }

    buildSelectedObject = (data) => {
        const { entitySlug, entityType, entityData } = data;
        let value = '';
        if (entityType === 'profile') {
            value = `${entityData?.candidateName} - ${entityData?.linkedInData?.currentPosition || entityData.position}`;
        } else if (entityType === 'job') {
            value = `${entityData?.jobName} - ${entityData?.companyName}`;
        } else if (entityType === 'company') {
            value = entityData?.companyName;
        }
        return {
            slug: entitySlug,
            type: entityType,
            ...entityData,
            value
        };
    }

    getAssigneeFromSlug = (assigneeSlug) => {
        const assignee = userStore.recruitersList.find(user => user.slug === assigneeSlug);
        if (assignee) {
            return {
                elId: assignee._id,
                value: utilsService.getDisplayName(assignee),
                ...assignee
            };
        }
        return null;
    }

    render() {
        const { loader } = this.state;

        let buttonClasses = 'button darkblue-new';
        if (loader) {
            buttonClasses += ' loading';
        }

        const recruitersList = userStore.recruitersList.map(i => ({...i, elId: i._id, value: utilsService.getDisplayName(i)}))

        const isValid = this.state.selectedObject?.slug && this.state.objectType && this.state.dueDate && this.state.subject && this.state.assignee?.slug

        return (
            <ModalComponent onClose={() => this.closeModalEvent}>
                <div className='modal-box find-profile-modal'>
                    <header>
                        <h2>{this.props.data ? "Edit Reminder":"Add Reminder"}</h2>
                    </header>

                    <div className="form">
                        <div className='object-type'>
                            <RadioGroup
                                name=''
                                items={[{label: "Profile", value: "profile"}, {label: "Job", value: "job"}, {label: "Company", value: "company"}]}
                                value={this.state.objectType}
                                onChange={(event, value) => {
                                    this.setState({objectType: value})
                                    this.setState({listObjects: []})
                                    this.setState({selectedObject: {}})
                                    this.getObjects()
                                }}
                            />
                        </div>
                        <div className='reminder-infor'>
                            <SuggestImageComponent
                                label={'Object'}
                                items={this.state.listObjects}
                                selectedItem={this.state.selectedObject}
                                selectItem={item => this.setState({selectedObject: item})}
                                query={this.getObjects}
                                numberOfCharsToPredicate={2}
                                isLoading={this.state.isLoading}
                                entityType={this.state.objectType}
                            />
                            <Autocomplete
                                freeSolo={true}
                                multiple={false}
                                value={this.state.assignee}
                                onChange={(e, item) => {this.setState({assignee: item})}}
                                options={recruitersList}
                                popupIcon={null}
                                clearIcon={null}
                                forcePopupIcon={false}
                                getOptionLabel={(option) => `${option.value || ''}`}
                                label='Assigned To'
                            />
                            <div className='infor-date'>
                                <DateInput
                                    value={this.state.dueDate ? new Date(this.state.dueDate) : null}
                                    onChange={(date) => {
                                        this.setState({dueDate: date})
                                    }}
                                    onReset={() => {
                                        this.setState({dueDate: null})
                                    }}
                                    label={'Due Date'}
                                    maxDate={new Date('2100')}
                                    rightIcon={<CalendarIcon1 />}
                                />
                                <DateInput
                                    value={this.state.reminderDate ? new Date(this.state.reminderDate) : null}
                                    onChange={(date) => {
                                        this.setState({reminderDate: date})
                                    }}
                                    onReset={() => {
                                        this.setState({reminderDate: null})
                                    }}
                                    label={'Reminder Date'}
                                    maxDate={new Date('2100')}
                                    rightIcon={<CalendarIcon1 />}
                                />
                            </div>
                        </div>
                        <div className='subject'>
                            Subject
                            <textarea
                                className={'textarea'}
                                value={this.state.subject}
                                onChange={(e) => this.setState({subject: e.target.value})}
                            />
                        </div>
                    </div>
                    <div className='action-button'>
                        <button
                            onClick={this.closeModalEvent}
                            className={'button gray'}>
                            Cancel
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            disabled={!isValid}
                            className={`button blue ${this.state.isSaving ? 'loading' : ''} ${isValid ? '' : 'disabled'}`}>
                            Save and Submit
                        </button>
                    </div>
                    
                </div>
            </ModalComponent>
        );
    }
}

export default AddEditReminderModalComponent;
