import React, { Component } from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import './complexMultiSelect2.scss';
import utilsService from '../../../../services/utils.service';
import { ChangeIcon } from '../../svg';
import OperatorMessageNavigate from './operatorMessageNavigate/OperatorMessageNavigate.component';
import onClickOutside from 'react-onclickoutside';

class ComplexMultiSelectComponent2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isFocus: this.props.isUseSimpleMode ? true : false,
            hiddenSuggestedList: props.hasSuggested && props.selectedItems && Array.isArray(props.selectedItems) ?
                props.selectedItems.map(item => item.elId) : [],
            inputValue: "",
            isListShouldBeOpened: null
        }

        this.typingTimeout = 0;
    }

    //tag change
    tagItemChange = (item) => {
        const { itemClicked, openOnKeyDown } = this.props
        if (!openOnKeyDown) {
            this.closeMultiSuggestList();
        }
        itemClicked(item);
    }

    //tag remove
    tagRemoveHandle = (item) => {
        const { hasSuggested, isSingle, removeItem, openOnKeyDown, isUseSimpleMode } = this.props
        const { hiddenSuggestedList } = this.state
        if (hasSuggested) {
            const hiddenSuggestedListTemp = hiddenSuggestedList.filter(hiddenItem => hiddenItem !== item.elId);
            this.setState({ hiddenSuggestedList: hiddenSuggestedListTemp });
        }

        if (isSingle) {
            this.setState({ isFocus: isUseSimpleMode ? true : false });
            this.inputRef.blur();
        }

        if (!openOnKeyDown) {
            this.closeMultiSuggestList();
        }

        removeItem(item);
    };

    //item added from list
    multiSelectHandle = item => {
        const { hasSuggested, isSingle } = this.props
        const { hiddenSuggestedList } = this.state
        if (hasSuggested) {
            let hiddenSuggestedListTemp = [...hiddenSuggestedList];
            if (!hiddenSuggestedListTemp.some(objItem => objItem === item.elId)) {
                hiddenSuggestedListTemp.push(item.elId);
            }
            else {
                hiddenSuggestedListTemp = hiddenSuggestedListTemp.filter(object => object !== item.elId);
            }

            this.setState({ hiddenSuggestedList: hiddenSuggestedListTemp });
        }

        if (isSingle) {
            this.setState({ isFocus: false })
            this.closeMultiSuggestList();
            this.inputRef.blur();
        }
        this.props.selectItem(item);
    };

    //multi suggest click
    addRelevantSuggestion = (item) => {
        const { hiddenSuggestedList } = this.state
        const { hasSuggested } = this.props
        if (hasSuggested) {
            const hiddenSuggestedListTemp = [...hiddenSuggestedList];
            hiddenSuggestedListTemp.push(item.elId);
            this.setState({ hiddenSuggestedList: hiddenSuggestedListTemp });
        }
        this.inputRef.focus();
        this.handleFocus(true);
        this.props.addRelevantSuggestion(item)
    }

    //list render
    renderMultiItem = (item, { handleClick, modifiers, index }) => {

        const { selectedItems, outerMultiItemClass } = this.props;
        if(!selectedItems) {
            return;
        }

        let classes = 'multi-item';
        if (selectedItems.some(objItem => objItem.elId === item.elId)) {
            classes += ' selected';
        }
        if (modifiers.active) {
            classes += ' active';
        }
        if (outerMultiItemClass) {
            classes += " " + outerMultiItemClass;
        }


        return (
            <div
                className={classes}
                key={index}
                onClick={handleClick}
            >
                <label>{item.value}</label>
            </div>
        );
    };

    tagsMultiRender = (item) => {
        if(!this.props.useTagRander) {
            return '';
        }

        const { isFocus } = this.state

        if (isFocus) {
            if (item.elId !== -1) {
                return this.renderSelectedTag(item);
            }
            else {
                return <div className={'title'}>
                    {item ? (item.value) : ''}
                </div>
            }
        }
        else {
            return ''
        }
    };

    getTagsMultiRenderClass = (item) => {
        if(!this.props.useTagRander) {
            return '';
        }
        
        const { supportSpecialTags } = this.props
        const { isFocus } = this.state
        let classes = 'tag-multi-render';

        if (supportSpecialTags) {
            classes = classes + ' support-special-tags'
            if (item.type === 2) {
                classes = classes + ' excluded'
            }

            if (!isFocus) {
                classes = classes + ' not-focus'
            }
        }

        return classes;
    }

    renderSelectedTag = (item, index) => {
        const { itemClicked } = this.props;
        const hasIncludedTag = itemClicked;
        return (
            <div key={index} className={this.getTagsMultiRenderClass(item)}>
                {hasIncludedTag && <div className={"change-icon " + (item.type !== 2 ? 'include' : '')}
                    onClick={(e) => this.tagItemChange(item)}>
                    <ChangeIcon />
                </div>}
                <div className={"close-icon " + (hasIncludedTag ? '' : 'included-tag-disabled')} 
                    onClick={(e) => this.tagRemoveHandle(item)} >
                </div>
                <label className="multi-render-item-label">{item.value}</label>
            </div>
        )
    }

    renderSelectedItems = selectedItems => {
        return (
            <div className="selected-item-wrapper">
                {selectedItems.filter(item => item.elId !== -1)
                    .map((item, index) => {
                        return this.renderSelectedTag(item, index)
                    })}
            </div>
        )
    }

    // SEARCH ITEMS 
    itemPredicate = (item, cat) => {
        const { numberOfCharsToPredicate } = this.props;
        return item.length >= numberOfCharsToPredicate && cat.value &&
            cat.value.toLowerCase().indexOf(item.toLowerCase()) >= 0;

    };

    createNewItem(item) {
        return {
            value: item,
            elId: item
        };
    }

    getRelevantClasses(classes, validation, valid, isLoading, hasRightIcon) {
        const { isFocus } = this.state
        let res = '';

        if (classes) {
            res += ` ${classes} valid`;
        }

        // if (validation) {
        //     res += ` ${valid ? 'valid' : 'invalid'} `;
        // }

        if (isLoading) {
            res += 'loading';
        }
        else {
            res += 'arrow-icon';
        }

        return res;
    }

    onQueryChange = text => {
        if (this.props.query) {
            this.props.query(text);
        }

        if (!this.isMultiSuggestListOpen()) {
            this.openMultiSuggestList();
        }

    };

    getMainClass = () => {
        const { isFocus } = this.state
        const { isDisabled, valid } = this.props
        let mainClass = 'complex-multi-select-wrapper focus';
        // if (isFocus) {
        //     mainClass += 'focus '
        // }
        if(!valid) {
            mainClass += ' invalid'
        }
        if (isDisabled) {
            mainClass += ' disabled '
        }

        return mainClass;
    }

    isMultiSuggestListOpen = () => {
        return document.getElementsByClassName('bp3-menu').length > 0
    }

    openMultiSuggestList = () => {
        this.setState({ isListShouldBeOpened: true }, () => {
            this.setState({ isListShouldBeOpened: null })
        })
    }

    closeMultiSuggestList = () => {
        this.setState({ isListShouldBeOpened: false }, () => {
            this.setState({ isListShouldBeOpened: null })
        })
    }

    handleIncludeOperatorCheckAction = (e) => {
        const { includeOperatorCheckAction } = this.props;
        includeOperatorCheckAction(e);
        this.inputRef.focus();
        this.handleFocus(true);
    }

    handleOperatorMessageNavigateClick = () => {
        this.inputRef.focus();
        this.handleFocus(true);
    }

    getOperatorText = () => {
        const { placeholder, singularPlaceHolder, includeOperatorChecked } = this.props;
        const message = includeOperatorChecked ? 'At least one' : 'All'
        const operator = includeOperatorChecked ? singularPlaceHolder : placeholder
        return `${message} ${operator}`
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            items, selectedItems, includeOperatorChecked
        } = this.props;

        if (this.returnable(this.props, nextProps, 'allowCreate')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'valid')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'validation')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'classes')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'placeholder')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'label')) {
            return true;
        }
        if (this.returnable(this.props, nextProps, 'isLoading')) {
            return true;
        }

        if (nextProps.items !== items) {
            if (items && nextProps.items) {
                if (items.length !== nextProps.items.length) {
                    return true;
                }
                else if (items.length > 0) {
                    const itemKeys = Object.keys(items[0]);
                    const nextItemKeys = Object.keys(nextProps.items[0]);

                    if (itemKeys.length !== nextItemKeys.length) {
                        return true;
                    }
                    if (itemKeys.length > 0) {
                        if (items[0][itemKeys[0]] !== nextItemKeys[0][nextItemKeys[0]]) {
                            return true;
                        }
                    }

                    if (items.length > 1) {
                        const itemKeys = Object.keys(items[items.length - 1]);
                        const nextItemKeys = Object.keys(nextProps.items[nextProps.length - 1]);
                        if (itemKeys.length > 0) {
                            if (items[0][itemKeys[0]] !== nextItemKeys[0][nextItemKeys[0]]) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        if (nextProps.selectedItems.length === 0) {
            return true;
        }

        if (nextProps.selectedItems !== selectedItems) {
            if (selectedItems && nextProps.selectedItems) {
                if (selectedItems.length !== nextProps.selectedItems.length) {
                    return true;
                }

                if (!utilsService.isTwoObjectsEquivalent(nextProps.selectedItems, selectedItems)) {
                    return true;
                }
            }
        }

        if (!utilsService.isTwoObjectsEquivalent(this.state, nextState)) {
            return true;
        }

        if (includeOperatorChecked !== nextProps.includeOperatorChecked) {
            return true;
        }

        return false;
    }

    returnable(props, nextProps, val) {
        return props[val] !== nextProps[val];
    }

    onBlur = () => {
        this.setState({ inputValue: "" });
        this.handleFocus(false);
    }
    onFocus = () => {
        this.handleFocus(true);
    }

    handleFocus = (isFocus) => {
        const {isUseSimpleMode} = this.props;

        if (this.typingTimeout) {
            clearTimeout(this.typingTimeout);
        }

        this.typingTimeout = setTimeout(async () => {
            // this.setState({ isFocus: isUseSimpleMode ? true : isFocus })
            this.setState({ isFocus: isFocus })
        }, 130);
    }

    handleClickOutside = evt => {
        this.props.onClickOutside && this.props.onClickOutside();
        this.setState({ opened: false });
    };

    render() {
        const {
            allowCreate,
            valid,
            validation,
            classes,
            items,
            selectedItems,
            openOnKeyDown,
            placeholder,
            customRenderer,
            customPredicate,
            isLoading,
            hasRightIcon,
            isInitStateOpened,
            hasSuggested,
            selectedSuggested,
            normalSuggested,
            hasIncludeOperator,
            includeOperatorChecked
        } = this.props;

        const { isFocus, hiddenSuggestedList, inputValue, isListShouldBeOpened } = this.state

        const maybeCreateNewItemFromQuery = allowCreate ? this.createNewItem : undefined;

        const multiClasses = this.getRelevantClasses(classes, validation, valid,
            isLoading, hasRightIcon);

        let selectedItemsTemp = selectedItems;
        if (selectedItems && selectedItems.length > 1 && selectedItems[1].elId !== -1) {
            selectedItemsTemp = utilsService.doMeAFavor(selectedItems);
            selectedItemsTemp.unshift({ elId: -1, value: placeholder });
        }
        
        return (
            <div className={this.getMainClass()}>

                <MultiSelect
                    placeholder={selectedItems.length === 0 || isFocus ? placeholder: ''}
                    className={'multi ' + multiClasses}
                    items={items}
                    onItemSelect={this.multiSelectHandle}
                    itemRenderer={customRenderer ? customRenderer : this.renderMultiItem}
                    tagRenderer={this.tagsMultiRender}
                    selectedItems={selectedItemsTemp}
                    tagInputProps={{
                        inputRef: (input) => { this.inputRef = input },
                        addOnBlur: this.tagAdd,
                        inputProps: {
                            onChange: (e) => this.setState({ inputValue: e.target.value }),
                            value: inputValue,
                            onBlur: () => { this.onBlur() },
                            onFocus: () => {
                                this.onFocus()
                            }
                        }
                    }}
                    itemPredicate={customPredicate ? customPredicate : this.itemPredicate}
                    resetOnQuery={true}
                    resetOnSelect={true}
                    scrollToActiveItem={true}
                    popoverProps={openOnKeyDown ? true : { isOpen: isListShouldBeOpened }}
                    openOnKeyDown={openOnKeyDown} //true - not open automatically
                    onQueryChange={this.onQueryChange}
                    createNewItemFromQuery={maybeCreateNewItemFromQuery}
                    noResults={<MenuItem disabled={true} text="No results." />}
                />

                {!isFocus && this.renderSelectedItems(selectedItems)}
                {/* {!isFocus && hasIncludeOperator && selectedItems.length > 0 &&
                    <OperatorMessageNavigate 
                    textOperator={this.getOperatorText()}
                    OperatorMessageNavigateClick={this.handleOperatorMessageNavigateClick}
                />} */}

            </div>
        );
    }
}

ComplexMultiSelectComponent2.defaultProps = {
    hasRightIcon: true,
    openOnKeyDown: true,
    normalSuggested: false,
    numberOfCharsToPredicate: 0,
    valid: true
};

export default onClickOutside(ComplexMultiSelectComponent2);
