import React from 'react';
import './customCheckbox.scss';

const CustomCheckboxComponent = props => {
    const { checked, readOnly, checkAction } = props;

    const changeHandle = event => {
        if (!readOnly) {
            checkAction(event);
        }
    };

    return (
        <span className="customCheckbox" onClick={evt => evt.stopPropagation()}>
                    <input
                        type='checkbox'
                        checked={checked}
                        readOnly={readOnly}
                        onChange={changeHandle}
                    />
                </span>
    );
};

export default CustomCheckboxComponent;
