import React, {PureComponent} from 'react';

class SuggestSkillsComponent extends PureComponent {
    filterList(list, hidden) {
        return list.filter(item => !hidden.includes(item));
    }

    getSkillsList(listObj, listOfParents) {
        const tempArr = [];

        if (listOfParents.length > 0 && listObj) {
            listOfParents.forEach(item => {
                tempArr.push.apply(tempArr, listObj[item]);
            });
        } else if (listObj) {
            const keys = Object.keys(listObj);
            tempArr.push.apply(tempArr, listObj[keys[0]]);
        }

        return tempArr;
    }

    getRelevantSkills(topSkills, selectedRoles, hiddenList) {
        let tempArr = [];

        if (selectedRoles.length > 0 && topSkills) {
            selectedRoles.forEach(roleId => {

                let topSkill = topSkills[roleId];
                if (topSkill) {
                    topSkill = topSkill.filter(item => !hiddenList.includes(item));
                    tempArr.push.apply(tempArr, topSkill);
                }
            });

            tempArr = [...new Set(tempArr)];
        }

        return tempArr;
    }

    handleRender = (relevantSkills, skills) => {
        const toRender = [];
        relevantSkills.forEach(skillId => {
            const relevantSkill = skills[skillId];
            if (relevantSkill) {
                toRender.push(relevantSkill);
            }
        });

        return toRender;
    };

    renderAllButton(renderSkills) {
        return (
            <div
                className='suggest-item all'
                key='jd9d9awdja9wd9ajwd'
                onClick={() => this.props.addRelevantSkill(renderSkills)}
            >
                All
            </div>
        );
    }

    render() {

        const { hiddenList, topSkills, selectedRoles, skills } = this.props;
        const relevantSkills = this.getRelevantSkills(topSkills, selectedRoles, hiddenList);
        const renderSkills = this.handleRender(relevantSkills, skills);

        return (
            <div className='suggested-wrapper'>
                {renderSkills && renderSkills.length > 0 && (
                    <React.Fragment>
                        {this.renderAllButton(renderSkills)}
                        {renderSkills.map(skill => {
                            return (
                                <div
                                    className='suggest-item skill-tag'
                                    onClick={() => this.props.addRelevantSkill(skill)}
                                    key={skill.elId}
                                > {skill.value}

                                </div>
                            );
                        })}
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default SuggestSkillsComponent;
