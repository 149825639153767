import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import {CloseIcon} from '../svg';

class AddToJobModalComponent extends PureComponent {
    render() {
        return (
            <ModalComponent onClose={this.props.closeEvent}>
                <div className='modal-box add-tp-job-modal'>
                    <header>
                        <div onClick={this.props.closeEvent}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                        <h2>Add candidates to job</h2>
                    </header>


                    <section className="modal-body">

                    </section>

                </div>
            </ModalComponent>
        );
    }
}

export default AddToJobModalComponent;
