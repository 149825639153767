import { action, observable } from 'mobx';
import fetchService from '../services/fetch.service';


class LogsStore {

    @observable jobsStatus = [];

    @action.bound
    async getJobsStatus(body) {
        const response = await fetchService.authenticatedPost(`/get-logs/job-manipulation`, body);
        if(response && response.data && !response.data.error) {
            this.jobsStatus = response.data;
        }
        return response && response.data;
        
    }

}

const logsStore = new LogsStore();

export default logsStore;