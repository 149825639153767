import React, {PureComponent} from 'react';
import ModalComponent from '../modal/Modal.component';
import userStore from '../../../stores/user.store';
import {Editor} from 'react-draft-wysiwyg';
import {EditorState} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {CloseIcon} from '../svg';
import utilsService from '../../../services/utils.service';

class AddNoteModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            note: EditorState.createEmpty(),
            title: ''
        };
    }

    handleSubmit = (e) => {
        const note = stateToHTML((this.state.note.getCurrentContent()));
        if (note === '<p><br></p>' && !this.state.title) return;
        e.preventDefault();
        const body = {
            creator: userStore.user._id,
            creatorType: userStore.users.userType,
            creatorSlug: userStore.user.slug,
            text: note,
            title: this.state.title
        };

        this.props.data(body);
    };

    onEditorStateChange = note => {
        this.setState({
            note
        });
    };

    render() {
        const isExposed = utilsService.checkIfExposed(this.props.data.process);
        return (
            <ModalComponent onClose={() => this.props.closeEvent()}>
                <div className='modal-box add-note-modal'>
                    <header>
                        <h2>{isExposed ? 'Add a note for ' : 'Add a note '}<span
                            className="capitalized">{isExposed ? utilsService.getDisplayName(this.props.data.profile) : ''}</span>
                        </h2>
                        <div onClick={() => this.props.closeEvent()}
                             className='circle-close modal-close'>
                            <CloseIcon/>
                        </div>
                    </header>
                    <section className="modal-body">

                        <div className='form'>
                            <div>
                                <div className="form-item">
                                    <label>Title</label>
                                    <input type="text"
                                           value={this.state.title}
                                           onChange={(e) => this.setState({ title: e.target.value })}
                                    />
                                </div>
                                <div className="form-item">
                                    <label>Text</label>
                                    <Editor
                                        toolbarHidden={true}
                                        editorState={this.state.note}
                                        onEditorStateChange={this.onEditorStateChange}
                                    />
                                </div>
                            </div>

                        </div>
                    </section>

                    <footer>
                        <button onClick={() => this.props.closeEvent()}
                                className='button gray'>
                            Cancel
                        </button>
                        <button
                            onClick={this.handleSubmit}
                            className={`button blue ${this.state.updating ? 'loading' : ''}`}
                        >
                            Add
                        </button>
                    </footer>
                </div>
            </ModalComponent>
        );
    }
}

export default AddNoteModal;
